import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useSelector } from "react-redux"
import tw from "twin.macro"
import Slider from "react-slick"
import { get, hasIn, isNull } from "lodash"

import ProductPrice from "./ProductPrice"
import WarningOverlay from "design/components/WarningOverlay"
import noImage from "assets/images/design/block/noimage.png"
import { useBlockImage } from "reaverUtil/image"
import { getCurrencySymbol } from "reaverUtil/currency"

const Wrapper = styled.div`
  ${tw``}
`
const Thumbnail = styled.div`
  ${tw`overflow-hidden relative bg-white border border-white`}
  padding-bottom : ${({ portrait }) => (portrait ? "150%" : "100%")};

  ${({ border }) => border && tw`border-gray-100`}
`
const Item = styled.div`
  ${tw`flex flex-col`}
  p {
    ${tw`mt-2 block overflow-hidden truncate text-gray-800`}
    font-size: 13px;
    line-height: 1.3em;
    /* min-height: 2.6em; */
    white-space: normal;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
`
const List = styled.div`
  ${({ layout }) => {
    switch (layout) {
      case "horizontal":
        return [
          tw`pl-4 overflow-auto flex flex-row flex-no-wrap`,
          `-webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;`,
        ]
      case "vertical":
        return tw`grid grid-cols-2 gap-4 px-4 pb-4`
      case "carousel":
        return [
          tw`pb-2`,
          css`
            .slick-slide {
              ${tw`text-center px-4 py-4`}
            }
            .slick-center {
              ${tw`py-0 px-0`}
            }
          `,
        ]
      default:
        break
    }
  }}

  ${Item} {
    ${({ layout }) => {
      switch (layout) {
        case "horizontal":
          return [tw`mr-3 pb-4 flex-no-wrap`, `flex: 0 0 auto; width: 120px;`]
        default:
          break
      }
    }}
  }
`

const ProductItem = ({
  thumbnail,
  symbol,
  title,
  price,
  compareAtPrice,
  border,
  portrait,
  imageScaleFilled,
}) => {
  return (
    <Item>
      <Thumbnail border={border} portrait={portrait}>
        {thumbnail && (
          <img
            src={thumbnail}
            alt=""
            css={[
              tw`absolute inset-0 w-full h-full object-contain`,
              imageScaleFilled && tw`object-cover`,
            ]}
          />
        )}
      </Thumbnail>
      <div className="flex flex-col justify-between flex-grow">
        <p css={tw`truncate block`}>{title}</p>
        <ProductPrice
          symbol={symbol}
          price={price}
          compareAtPrice={compareAtPrice}
        />
      </div>
    </Item>
  )
}

export default function CollectionBlock(props) {
  const { id, title, display_type, collection_id, preview, item_count } = props
  const { setBlockValid } = props
  const layout = display_type

  const [item, setItem] = useState(null)
  const [error, setError] = useState(null)
  const collections = useSelector(state => state.shopify.collections.byId)
  const imageScaleFilled =
    useSelector(state => state.design.ui.imageScaleFilled) || false
  const supportPortrait =
    useSelector(state => state.design.ui.supportPortrait) || false
  const currencyCode = useSelector(
    ({ firebase }) => firebase.profile.shop_owner.currency
  )
  const currencySymbol = getCurrencySymbol(currencyCode)

  const images = useBlockImage()
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "80px",
    slidesToShow: 1,
    speed: 500,
  }

  useEffect(() => {
    if (collection_id) {
      const collection = get(collections, collection_id, null)
      const hasDeletedCollection = isNull(collection)
      setError(hasDeletedCollection)
      hasDeletedCollection && setBlockValid({ id, isValid: false })
      setItem(collection)
    }
  }, [collection_id])

  const renderPreviewItem = () => {
    let list = []

    switch (layout) {
      case "vertical":
        if (supportPortrait) {
          list = [
            {
              thumbnail:
                images[
                  "collection-vertical-hover-trendyol-handles-flywheel-mini-dress-cream"
                ].src,
              title: "Wrap Over Midi Dress",
              price: 125,
              compareAtPrice: 990,
            },
            {
              thumbnail:
                images[
                  "collection-vertical-hover-kae-sutherland-deep-v-neck-maxi-dress-green"
                ].src,
              title: "Deep V Neck Dress",
              price: 85,
              compareAtPrice: 249,
            },
            {
              thumbnail:
                images[
                  "collection-vertical-hover-nakd-wrap-over-midi-blazer-dress"
                ].src,
              title: "Sweetheart Wrap Dress",
              price: 76,
              compareAtPrice: 95,
            },
            {
              thumbnail:
                images[
                  "collection-vertical-hover-trendyol-puffy-sleeve-midi-dress"
                ].src,
              title: "Puffy Sleeve Midi Dress",
              price: 98,
              compareAtPrice: 130,
            },
          ]

          break
        }
        list = [
          {
            thumbnail: images["colleciton-hover-ciao-bello-espadrilles"].src,
            title: "Ciao Bella Espadrilles",
            price: 85,
            compareAtPrice: 165,
          },
          {
            thumbnail: images["colleciton-hover-little-love-earrings"].src,
            title: "Little Love Earrings",
            price: 78,
            compareAtPrice: 0,
          },
          {
            thumbnail: images["colleciton-hover-madday-slip-dress"].src,
            title: "Maddy Slip Dress",
            price: 62,
            compareAtPrice: 129,
          },
          {
            thumbnail: images["colleciton-hover-snapshot-camera-bag"].src,
            title: "Snapshot Camera Bag",
            price: 280,
            compareAtPrice: 295,
          },
        ]
        break
      case "horizontal":
        if (supportPortrait) {
          list = [
            {
              thumbnail:
                images["collection-vertical-hover-trendyol-bat-sleeve-sweater"]
                  .src,
              title: "Distreseed Jeans",
              price: 26.5,
              compareAtPrice: 76.8,
            },
            {
              thumbnail:
                images["collection-vertical-hover-nakd-midi-pleated-skirt-pink"]
                  .src,
              title: "Pink Pleated Skirts",
              price: 58.5,
              compareAtPrice: 0,
            },
            {
              thumbnail:
                images[
                  "collection-vertical-hover-nakd-crew-neck-volume-sleeve-knitted-sweater"
                ].src,
              title: "White Trousers",
              price: 65.5,
              compareAtPrice: 0,
            },
          ]
          break
        }
        list = [
          {
            thumbnail: images["collection-hover-carmen-mini-bag"].src,
            title: "Carmen Mini Bag",
            price: 125,
            compareAtPrice: 235,
          },
          {
            thumbnail: images["collection-hover-metro-watch"].src,
            title: "Metro Watch",
            price: 195,
            compareAtPrice: 355,
          },
          {
            thumbnail: images["collection-hover-feather-hoop-earing"].src,
            title: "Feather Hoop",
            price: 45,
            compareAtPrice: 65,
          },
        ]
        break
      case "carousel":
        if (supportPortrait) {
          list = [
            {
              thumbnail:
                images["collection-vertical-hover-collins-pearl-clutch"].src,
              title: "Collins Pearl Clutch",
              price: 398,
            },
            {
              thumbnail:
                images[
                  "collection-vertical-hover-chanel-green-suede-stitch-jumbo"
                ].src,
              title: "Chanel Green Suede Stitch Jumbo",
              price: 5400,
              compareAtPrice: 0,
            },
            {
              thumbnail: images["collection-vertical-hover-mini-lady-bag"].src,
              title: "Mini Lady Bag",
              price: 123,
              compareAtPrice: 358,
            },
          ]
          break
        }
        list = [
          {
            thumbnail: images["collection-carousel-hover-lace-strap-dress"].src,
            title: "Lace Strap Dress",
            price: 115,
            compareAtPrice: 285,
          },
          {
            thumbnail:
              images["collection-carousel-hover-square-neckline-bodysuit"].src,
            title: "Square Neckline Bodysuit",
            price: 45,
          },
          {
            thumbnail: images["collection-carousel-hover-lace-singlet"].src,
            title: "Plain Lace Singlet",
            price: 35,
            compareAtPrice: 48,
          },
        ]
        break
      default:
        break
    }

    return list.map((item, index) => (
      <ProductItem
        portrait={supportPortrait}
        imageScaleFilled={imageScaleFilled}
        key={index}
        symbol={currencySymbol}
        title={item.title}
        thumbnail={item.thumbnail}
        price={item.price}
        compareAtPrice={item.compareAtPrice}
      />
    ))
  }

  const renderPreview = () => {
    switch (layout) {
      case "carousel":
        return <Slider {...settings}>{renderPreviewItem()}</Slider>
      default:
        return renderPreviewItem()
    }
  }

  const renderPlaceholder = () => {
    switch (layout) {
      case "horizontal":
        return (
          <>
            <ProductItem
              portrait={supportPortrait}
              imageScaleFilled={imageScaleFilled}
              title="Product Name"
              symbol={currencySymbol}
              thumbnail={images["collection-pher-chair"].src}
              price={84}
              compareAtPrice={120}
              border={true}
            />
            <ProductItem
              portrait={supportPortrait}
              imageScaleFilled={imageScaleFilled}
              title="Product Name"
              symbol={currencySymbol}
              thumbnail={images["collection-pher-fire"].src}
              price={45}
              border={true}
            />
            <ProductItem
              portrait={supportPortrait}
              imageScaleFilled={imageScaleFilled}
              title="Product Name"
              symbol={currencySymbol}
              thumbnail={images["collection-pher-lamp"].src}
              price={32}
              compareAtPrice={40}
              border={true}
            />
          </>
        )
      case "vertical":
        return (
          <>
            <ProductItem
              portrait={supportPortrait}
              imageScaleFilled={imageScaleFilled}
              title="Product Name"
              symbol={currencySymbol}
              thumbnail={images["collection-pher-tshirt"].src}
              price={85}
              compareAtPrice={120}
              border={true}
            />
            <ProductItem
              portrait={supportPortrait}
              imageScaleFilled={imageScaleFilled}
              title="Product Name"
              symbol={currencySymbol}
              thumbnail={images["collection-pher-camera"].src}
              price={40}
              compareAtPrice={80}
              border={true}
            />
            <ProductItem
              portrait={supportPortrait}
              imageScaleFilled={imageScaleFilled}
              title="Product Name"
              symbol={currencySymbol}
              thumbnail={images["collection-pher-beauty"].src}
              price={35}
              compareAtPrice={40}
              border={true}
            />
            <ProductItem
              portrait={supportPortrait}
              imageScaleFilled={imageScaleFilled}
              title="Product Name"
              symbol={currencySymbol}
              thumbnail={images["collection-pher-shoulder"].src}
              price={65}
              compareAtPrice={80}
              border={true}
            />
          </>
        )
      case "carousel":
        return (
          <Slider {...settings}>
            <ProductItem
              portrait={supportPortrait}
              imageScaleFilled={imageScaleFilled}
              title="Product Name"
              symbol={currencySymbol}
              thumbnail={images["collection-carousel-pher-fire"].src}
              price={80}
              compareAtPrice={120}
              border={true}
            />
            <ProductItem
              portrait={supportPortrait}
              imageScaleFilled={imageScaleFilled}
              title="Product Name"
              symbol={currencySymbol}
              thumbnail={images["collection-carousel-pher-lamp"].src}
              price={80}
              compareAtPrice={120}
              border={true}
            />
            <ProductItem
              portrait={supportPortrait}
              imageScaleFilled={imageScaleFilled}
              title="Product Name"
              symbol={currencySymbol}
              thumbnail={images["collection-carousel-pher-chair"].src}
              price={80}
              compareAtPrice={120}
              border={true}
            />
          </Slider>
        )
      default:
        break
    }
  }

  const renderItems = () => {
    if (!hasIn(item, "products.edges")) {
      return false
    }

    if (item.products.edges.length === 0) {
      return (
        <div css={tw`flex items-center justify-center`}>
          <span css={tw`text-xl font-semibold`}>Empty Product</span>
        </div>
      )
    }

    return item.products.edges.map((product, index) => {
      if (index < item_count) {
        return (
          <ProductItem
            portrait={supportPortrait}
            imageScaleFilled={imageScaleFilled}
            thumbnail={get(
              product,
              "node.images.edges[0].node.transformedSrc",
              noImage
            )}
            symbol={currencySymbol}
            title={product.node.title}
            price={product.node.variants.edges[0].node.price}
            compareAtPrice={product.node.variants.edges[0].node.compareAtPrice}
            key={index}
          />
        )
      }
    })
  }

  const renderContent = () => {
    switch (layout) {
      case "carousel":
        return <Slider {...settings}>{renderItems()}</Slider>
      default:
        return renderItems()
    }
  }

  const getContent = () => {
    if (preview) {
      return renderPreview()
    }

    if (item) {
      return renderContent()
    }

    return renderPlaceholder()
  }

  return (
    <Wrapper>
      <div
        css={tw`flex flex-row justify-between flex-1 py-2 bg-white px-4 items-center`}
      >
        <h2
          css={[
            tw`text-lg font-medium text-gray-800 flex-1 truncate`,
            css`
              min-width: 0;
            `,
          ]}
        >
          {get(item, "title", title)}
        </h2>
        <span css={tw`text-xs`}>See All {">"}</span>
      </div>
      <List layout={layout}>{getContent()}</List>
      {error && <WarningOverlay />}
    </Wrapper>
  )
}

CollectionBlock.defaultProps = {
  title: "Collection",
  display_type: "horizontal",
  item_count: 6,
  preview: false,
}
