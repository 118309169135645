import React, { useState } from "react"
import Modal from "react-modal"
import { has } from "lodash"
import moment from "moment"
import Button from "general/components/Button"
import DatetimePicker from "general/components/DatetimePicker"

export default function PushReservationModal(props) {
  const { isOpen, datetime, meta } = props
  const { onAction, onRequestClose, handleChangeDatetime } = props
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          overflowY: "auto",
          overflowX: "hidden",
          background: "rgba(33,43,54,.4)",
          zIndex: 500,
        },
        content: {},
      }}
      shouldCloseOnEsc={true}
      className={"modal modal--sm"}
    >
      <div className="bg-white w-full rounded-md">
        <div className="py-3 px-4 border-b">
          <h2 className="font-semibold text-lg">Push Reservation</h2>
        </div>
        <div className="px-4 py-3">
          <DatetimePicker
            label="Date"
            error={
              has(meta, "errors.date") && "Please select the future datetime."
            }
            selected={datetime}
            onChange={handleChangeDatetime}
            showTimeSelect={true}
            timeFormat="HH:mm"
            timeIntervals={10}
            minDate={new Date()}
            minTime={
              moment(datetime).format("YYYYMMDD") !==
              moment().format("YYYYMMDD")
                ? moment(datetime).hours(0).minutes(0).toDate()
                : moment(new Date()).add(10, "minutes").toDate()
            }
            maxTime={moment().hours(23).minutes(50).toDate()}
            showDisabledMonthNavigation={true}
            showPopperArrow={false}
            shouldCloseOnSelect={true}
            dateFormat="MM/dd/yyyy HH:mm"
            todayButton="Today"
          />
          <p className="mt-4 leading-tight text-gray-600">
            You can schedule a push marketing to be sent to consumers at the time
            you want.
          </p>
        </div>
        <div className="py-3 px-4 border-t flex flex-row flex-wrap justify-end">
          <div className="ml-1 md:ml-2">
            <Button onClick={() => onRequestClose()}>Cancel</Button>
          </div>
          <div className="ml-1 md:ml-2">
            <Button primary onClick={onAction}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
