import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
  useEffect,
} from "react"
import tw from "twin.macro"
import { css } from "styled-components"
import { useForm } from "react-hook-form"
import { get, isUndefined, has } from "lodash"
import { useSelector } from "react-redux"
import produce from "immer"
import Modal from "general/components/Modal"
import ProductBlock from "design/components/BlockItem/ProductBlock"
import ShopifySearch from "shopify/components/ShopifySearch"

const FormProduct = forwardRef(({ onSubmit, title, handlePreview }, ref) => {
  const { register, handleSubmit, errors } = useForm()

  const handleChange = event =>
    handlePreview({
      title: event.target.value.length === 0 ? undefined : event.target.value,
    })

  const getValues = data => {
    onSubmit(data)
  }

  useImperativeHandle(ref, () => ({
    formSubmit: handleSubmit(getValues),
  }))

  return (
    <form>
      <div css={tw`pt-4 px-6`}>
        <label css={tw`block`}>
          <span css={tw`block text-base text-gray-800 font-semibold mb-2`}>
            Marketing Message (Optional)
          </span>
          <input
            name="title"
            type="text"
            defaultValue={title}
            className="form-input mt-1 block w-full"
            maxLength="35"
            ref={register()}
            onChange={handleChange}
            placeholder="ex) Trendy idea for your Chirstmas."
          />
        </label>
      </div>
    </form>
  )
})

export default function EditProductBlockModal(props) {
  const {
    isOpen,
    onRequestClose,
    onAction,
    meta,
    updateProductsSuccess,
  } = props
  const searchRef = useRef(null)
  const formRef = useRef(null)
  const [preview, setPreview] = useState({})
  const [selected, setSelected] = useState([])
  const products = useSelector(state => state.shopify.products.byId)

  useEffect(() => {
    if (selected.length > 0) {
      const product_id = selected[0]

      return setPreview(
        produce(draft => {
          draft.product_id = product_id
        })
      )
    }

    if (selected.length === 0) {
      return setPreview(
        produce(draft => {
          draft.product_id = undefined
        })
      )
    }
  }, [selected])

  useEffect(() => {
    if (meta && meta.product_id) {
      const { title, product_id } = meta

      setPreview({ ...preview, title })
      setSelected([product_id])
    }
  }, [])

  const handleChange = (nextSelected, item) => {
    const product = get(products, item.id, undefined)

    if (isUndefined(product)) {
      updateProductsSuccess({ [item.id]: item })
    }

    setSelected(nextSelected)
  }

  const handlePreview = data => {
    setPreview({ ...preview, ...data })
  }

  const handlePrimary = () => {
    formRef.current.formSubmit()
  }

  const handleSubmit = formData => {
    if (selected.length === 0) {
      searchRef.current.focus()
    }

    if (selected.length > 0 && has(formData, "title")) {
      const data = {
        title: formData.title,
        product_id: selected[0],
      }

      onAction(data)
    }
  }

  return (
    <Modal
      primaryAction={{ content: "Save", onAction: () => handlePrimary() }}
      isOpen={isOpen}
      size={"lg"}
      onRequestClose={onRequestClose}
      title={"Show special product with message"}
      subtitle={
        "The selected product will be used in this block. (*Product`s Image Required)"
      }
    >
      <div css={tw`flex flex-row flex-wrap`}>
        <div css={[tw`bg-gray-100 px-6 py-4 flex-1`]}>
          <div
            css={[
              tw`mx-auto`,
              css`
                max-width: 360px;
              `,
            ]}
          >
            <span css={tw`block text-base text-gray-900 font-semibold mb-2`}>
              Preview
            </span>
            <div css={tw`shadow-sm`}>
              <ProductBlock
                product_id={preview.product_id}
                title={preview.title}
              />
            </div>
          </div>
        </div>
        <div css={tw`flex-1 overflow-hidden border-l border-gray-300`}>
          <FormProduct
            ref={formRef}
            title={preview.title}
            onSubmit={handleSubmit}
            handlePreview={handlePreview}
          />
          <ShopifySearch
            label={"Select a product"}
            ref={searchRef}
            onChange={handleChange}
            selected={selected}
            resourceType={"products"}
            disabledEmpty={true}
          />
        </div>
      </div>
    </Modal>
  )
}
