import React from "react"
import { useSelector } from "react-redux"
import { isLoaded, isEmpty } from "react-redux-firebase"
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"

const GraphqlProvider = props => {
  const { children } = props
  const profile = useSelector(state => state.firebase.profile)

  if (
    isLoaded(profile) &&
    !isEmpty(profile) &&
    isLoaded(profile.shopify_url) &&
    // isLoaded(profile.access_token)
    isLoaded(profile.shopify.sf_access_token)
  ) {
    const shopifyUrl = profile.shopify_url
    const storefrontAccessToken = profile.shopify.sf_access_token
    // const storefrontAccessToken = profile.access_token

    const httpLink = createHttpLink({
      uri: `https://${shopifyUrl}/api/2020-07/graphql.json`,
    })
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
        },
      }
    })
    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    })

    return <ApolloProvider client={client}>{children}</ApolloProvider>
  }
  return null
}

export default GraphqlProvider
