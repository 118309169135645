import React, { useState, useEffect } from "react"
import tw, { styled } from "twin.macro"
import { useSelector } from "react-redux"
import { useFirebase, useFirestore } from "react-redux-firebase"
import ImgIcon from "assets/images/icons/icon-ask-help.jpg"
import { intercomTrack } from "../../../services/analytics"
import FeedbackModal from "modal/components/FeedbackModal"
import ImgSuccess from "assets/images/feedback/push-success.png"

const Wrapper = styled.div`
  ${tw`flex flex-row px-4 py-4 bg-primary-200 items-center text-xs`}

  @media (min-width: 1280px) {
    ${tw`text-base`}
  }
`

const Icon = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 100%;
  border: 1px solid #eee;
  ${tw`mr-3`}
`

export default function AskHelpBanner() {
  const [feedback, setFeedback] = useState(false)
  const firebase = useFirebase()
  const firestore = useFirestore()
  const shopifyUrl = useSelector(state => state.firebase.profile.shopify_url)

  const handleClick = () => {
    const new_customer_request = {
      request_help_design: firebase.firestore.FieldValue.serverTimestamp(),
    }

    firestore
      .collection("user_list")
      .doc(shopifyUrl)
      .set(new_customer_request, { merge: true })
      .then(() => {
        setFeedback(true)
      })

    intercomTrack("ask_help")
  }

  return (
    <>
      <Wrapper>
        <Icon src={ImgIcon} />
        <div>
          <p className="font-light">Hard to design alone? We will design.</p>
          <button
            onClick={() => handleClick()}
            className="text-primary-500 font-semibold underline outline-none focus:outline-none"
          >
            Ask for Design{" >"}
          </button>
        </div>
      </Wrapper>
      <FeedbackModal
        isOpen={feedback}
        title={
          <span>
            Confirmed!
            <br />
            Our design team will help your app design in 24hr.
            <br />
            After our design team touch it, you can modify as you wish.
          </span>
        }
        source={ImgSuccess}
        onRequestClose={() => setFeedback(false)}
        primaryAction={{
          content: "See you!",
          onAction: () => {
            setFeedback(false)
          },
        }}
      />
    </>
  )
}
