import React from "react"
import Toggle from "react-toggle"
import tw from "twin.macro"

export default function ReactToggle({ label, onChange, checked }) {
  return (
    <label css={tw`inline-flex items-center leading-none`}>
      <Toggle onChange={onChange} checked={checked} />
      {label && <span css={tw`ml-2 text-gray-900`}>{label}</span>}
    </label>
  )
}
