import { useEffect } from "react"
import { connect } from "react-redux"
import { map, get, includes, isEqual, find, omit } from "lodash"
import store from "store2"

import { changedDesign } from "design/redux"

function StateListener(props) {
  const {
    menuById,
    menuIds,
    sectionById,
    sectionIds,
    blockById,
    blockIds,
    ui,
    initailized,
    isMenuActive,
  } = props
  const { changedDesign } = props

  const isEqualBlocks = (blocks, prevBlocks) => {
    let isBlockEqual = isEqual(blocks, prevBlocks)

    if (isBlockEqual) {
      return true
    }

    if (!isBlockEqual) {
      const validateBlocks = []

      map(blocks, (block, index) => {
        const prevBlock = get(prevBlocks, index, undefined)

        if (block.type === "countdown") {
          const isEqualInfo = isEqual(
            omit(block.info, ["event_finish"]),
            omit(prevBlock.info, ["event_finish"])
          )

          if (isEqualInfo) {
            const event_finish = get(block.info, "event_finish", undefined)
            const prev_event_finish = get(
              prevBlock.info,
              "event_finish",
              undefined
            )

            if (!isEqual(event_finish, prev_event_finish)) {
              if (event_finish.isEqual(prev_event_finish)) {
                return validateBlocks.push(true)
              }
            }
            return validateBlocks.push(false)
          }
        }

        if (block.type === "collection_list") {
          if (
            get(block, "info.orientation", false) ||
            get(prevBlock, "info.orientation", false)
          ) {
            return validateBlocks.push(
              isEqual(
                get(block, "info.orientation", false),
                get(prevBlock, "info.orientation", false)
              )
            )
          }

          const prevInfo = prevBlock.info.collection_ids
          const info = block.info.collection_ids

          return validateBlocks.push(isEqual(info, prevInfo))
        }

        return validateBlocks.push(isEqual(block, prevBlock))
      })
      if (includes(validateBlocks, false)) {
        return false
      }

      return true
    }
  }

  useEffect(() => {
    if (initailized === true) {
      const design = store.session.get("design")

      const prevMenuById = get(design, "menuById", undefined)
      const prevMenuIds = get(design, "menuIds", undefined)
      const prevSectionById = get(design, "sectionById", undefined)
      const prevSectionIds = get(design, "sectionIds", undefined)
      const prevBlockById = get(design, "blockById", undefined)
      const prevBlockIds = get(design, "blockIds", undefined)
      const prevUi = get(design, "ui", undefined)

      if (isMenuActive) {
        if (
          !isEqual(menuById, prevMenuById) ||
          !isEqual(menuIds, prevMenuIds)
        ) {
          changedDesign({ type: "menu", value: true })
        }

        if (isEqual(menuById, prevMenuById) && isEqual(menuIds, prevMenuIds)) {
          changedDesign({ type: "menu", value: false })
        }
      } else {
        if (
          !isEqual(sectionById, prevSectionById) ||
          !isEqual(sectionIds, prevSectionIds) ||
          !isEqualBlocks(blockById, prevBlockById) ||
          !isEqual(blockIds, prevBlockIds) ||
          !isEqual(ui, prevUi)
        ) {
          changedDesign({ type: "design", value: true })
        }

        if (
          isEqual(sectionById, prevSectionById) &&
          isEqual(sectionIds, prevSectionIds) &&
          isEqualBlocks(blockById, prevBlockById) &&
          isEqual(blockIds, prevBlockIds) &&
          isEqual(ui, prevUi)
        ) {
          changedDesign({ type: "design", value: false })
        }
      }
    }
  }, [
    menuById,
    menuIds,
    sectionById,
    sectionIds,
    blockById,
    blockIds,
    ui,
    initailized,
  ])

  return null
}

const mapStateToProps = state => {
  const initailized = state.design.initailized
  const menuById = state.design.menu.byId
  const menuIds = state.design.menu.ids
  const sectionById = state.design.sections.byId
  const sectionIds = state.design.sections.ids
  const blockById = state.design.blocks.byId
  const blockIds = state.design.blocks.ids
  const ui = state.design.ui
  const isMenuActive = state.design.isMenuActive

  return {
    initailized,
    menuById,
    menuIds,
    sectionById,
    sectionIds,
    blockById,
    blockIds,
    ui,
    isMenuActive,
  }
}

const mapDispatchToProps = { changedDesign }

export default connect(mapStateToProps, mapDispatchToProps)(StateListener)
