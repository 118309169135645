import React, { useRef, useState, useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import { navigate } from "gatsby"
import tw, { styled, css } from "twin.macro"
import { connect, useSelector } from "react-redux"
import { get, isNil } from "lodash"
import firebase from "gatsby-plugin-firebase"
import { useFirestoreConnect, isLoaded } from "react-redux-firebase"
import { useDocumentDataOnce } from "react-firebase-hooks/firestore"
import produce from "immer"
import { toast } from "react-toastify"
import { openDialog } from "dialog/redux"

import Spinner from "general/components/Spinner"

function ListingPage(props) {
  const { children, location, openDialog } = props
  const { pathname } = location

  const [formValid, setFormValid] = useState(true)
  const [formDirty, setFormDirty] = useState(false)

  const profile = useSelector(state => state.firebase.profile)
  const shopify_url = profile.shopify_url
  const payment = get(profile, "payment")
  const applisting = useSelector(
    ({ firestore: { data } }) =>
      data[shopify_url] && data[shopify_url].applisting
  )

  useFirestoreConnect(() => [{ collection: shopify_url, doc: "applisting" }])

  const Breadcumbs = ({ routes, path }) => {
    return (
      <div className="flex flex-row flex-wrap -mx-2">
        {routes &&
          routes.map(item => {
            return (
              <div
                className="flex items-center"
                key={item.path}
                css={css`
                  &::before {
                    display: inline-block;
                    margin: 0 0.5em;
                    width: 16px;
                    height: 16px;
                    content: "";
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' clip-rule='evenodd' /%3E%3C/svg%3E");
                  }

                  &:first-child::before {
                    display: none;
                  }
                `}
              >
                <button
                  onClick={item.onAction}
                  className="text-gray-600 hover:text-primary-500"
                  css={[item.path === path && tw`text-primary-500`]}
                >
                  {item.content}
                </button>
              </div>
            )
          })}
      </div>
    )
  }

  if (!isLoaded(applisting)) {
    return (
      <div
        css={[
          tw`flex items-center justify-center h-full `,
          css`
            height: 550px;
            background: #f6f4f5;
          `,
        ]}
      >
        <Spinner color="primary" />
      </div>
    )
  }

  return (
    <div>
      <div className="max-w-screen-lg mx-auto px-4 mt-8">
        <Breadcumbs
          path={pathname}
          routes={[
            {
              path: "/app/listing",
              content: "Step1. App Listing",
              onAction: () => navigate("/app/listing?edit=true"),
            },
            {
              path: "/app/listing/developer",
              content: "Step2. Connect Developer Account",
              onAction: () => {
                if (!get(payment, "paid")) {
                  return openDialog({
                    type: "GROWTH_PLAN",
                  })
                }
                if (!formValid) {
                  return toast(
                    "Please fill out the required fields and try again."
                  )
                }
                if (formDirty) {
                  return toast(
                    "Your changes have not been saved. Please save and try again."
                  )
                }
                navigate("/app/listing/developer")
              },
            },
            {
              path: "/app/listing/status",
              content: "Step3. Application Status",
              onAction: () => {
                const selectedPlatform = get(payment, "platform", undefined)
                const hasAndroidAccount = get(
                  applisting,
                  "has_android_account",
                  undefined
                )
                const hasIosAccount = get(
                  applisting,
                  "has_ios_account",
                  undefined
                )

                if (!selectedPlatform) {
                  return toast("Please select platform first.")
                }

                if (
                  selectedPlatform === "android" &&
                  isNil(hasAndroidAccount)
                ) {
                  return toast("Do you have an Android Developer account?")
                }
                if (selectedPlatform === "ios" && isNil(hasIosAccount)) {
                  return toast("Do you have an iOS Developer account?")
                }
                if (
                  selectedPlatform === "all" &&
                  (isNil(hasAndroidAccount) || isNil(hasIosAccount))
                ) {
                  return toast("Do you have developer accounts?")
                }

                if (!get(payment, "paid")) {
                  return openDialog({
                    type: "GROWTH_PLAN",
                  })
                }
                if (!formValid) {
                  return toast(
                    "Please fill out the required fields and try again."
                  )
                }
                if (formDirty) {
                  return toast(
                    "Your changes have not been saved. Please save and try again."
                  )
                }
                navigate("/app/listing/status")
              },
            },
          ]}
        />
      </div>
      {React.cloneElement(children, {
        children: React.Children.map(children.props.children, child => {
          return React.cloneElement(child, { setFormValid, setFormDirty })
        }),
      })}
    </div>
  )
}

export default connect(undefined, { openDialog })(ListingPage)
