import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { navigate } from "gatsby"
import { includes } from "lodash"
import { isLoaded, isEmpty } from "react-redux-firebase"

import Layout from "layouts"
import LogoSpinner from "general/components/LogoSpinner"
import GraphqlProvider from "shopify/containers/GraphqlProvider"

function ShopifyAuth(props) {
  const { children, location } = props

  const [loading, setLoading] = useState(true)

  const auth = useSelector(state => state.firebase.auth)
  const profile = useSelector(state => state.firebase.profile)

  let content = children

  useEffect(() => {
    if (
      isLoaded(auth) &&
      isLoaded(profile) &&
      !isEmpty(auth) &&
      !isEmpty(profile)
    ) {
      const { providerData } = auth
      const { appInitialized } = profile.shopify
      const isLinkedEmail = providerData.filter(
        item => item.providerId === "password"
      )

      if (!appInitialized) {
        if (isLinkedEmail.length === 0 && location.pathname !== "/app/signup") {
          navigate("/app/signup", { replace: true })
        } else if (
          isLinkedEmail.length !== 0 &&
          location.pathname !== "/app/sync" &&
          !appInitialized
        ) {
          navigate("/app/sync", { replace: true })
        }

        if (includes(["/app/signup", "/app/sync"], location.pathname)) {
          setLoading(false)
        }
      } else {
        if (isLinkedEmail.length === 0 && location.pathname !== "/app/signup") {
          navigate("/app/signup", { replace: true })
        }

        if (includes(["/app/signup"], location.pathname)) {
          setLoading(false)
        }

        if (isLinkedEmail.length > 0) {
          setLoading(false)
        }
      }
    }
  }, [auth, profile, location])

  if (!isLoaded(auth) || !isLoaded(profile) || loading) {
    return null
    return (
      <div className="h-screen flex flex-1 items-center justify-center">
        <LogoSpinner />
      </div>
    )
  }

  if (
    isLoaded(auth) &&
    isLoaded(profile) &&
    !isEmpty(auth) &&
    !isEmpty(profile) &&
    !loading
  ) {
    const { shopify_url } = profile
    const { sf_access_token } = profile.shopify

    if (shopify_url && sf_access_token) {
      if (!["/app/", "/app/signup", "/app/sync"].includes(location.pathname)) {
        content = <Layout location={location}>{children}</Layout>
      }

      return (
        <GraphqlProvider shop={shopify_url} token={sf_access_token}>
          {content}
        </GraphqlProvider>
      )
    }
  }

  if (
    isLoaded(auth) &&
    isLoaded(profile) & isEmpty(auth) &&
    isEmpty(profile) &&
    !loading
  ) {
    navigate("/login")
  }

  return null
}

export default ShopifyAuth
