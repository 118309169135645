import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import { useSelector } from "react-redux"
import Countdown from "react-countdown"
import { isObject, isNull, get, isUndefined } from "lodash"
import moment from "moment"

import WarningOverlay from "design/components/WarningOverlay"

import { useBlockImage } from "reaverUtil/image"

const CounterItem = styled.div`
  ${tw`text-center mx-2 flex-1`}

  .counter-item__number {
    ${tw`bg-gray-100 border border-gray-200 py-2 rounded-md leading-none`}

    ${({ isHighlight, disabled }) => {
      if (isHighlight && !disabled) {
        return css`
          background-image: linear-gradient(
            to bottom,
            #d6085c 17%,
            #ff537b 114%
          );
        `
      } else {
        if (!disabled) {
          return css`
            background-image: linear-gradient(
              180deg,
              rgba(0, 0, 0, 1) 0%,
              rgba(67, 67, 67, 1) 100%
            );
          `
        }
      }

      if (disabled) {
      }
    }}

    span {
      ${tw`leading-none inline-block text-3xl font-medium tracking-tighter`}
      font-size: 48px;

      ${({ disabled }) => (disabled ? tw`text-gray-800` : tw`text-white`)}
    }
  }
  .counter-item__unit {
    ${tw`uppercase font-semibold text-sm mt-1 text-gray-800`}
  }
`

export default function CountdownBlock(props) {
  const {
    id,
    setBlockValid,
    event_title,
    preview,
    image_url,
    event_finish,
    mode,
    action_type,
    action_value,
  } = props
  const collections = useSelector(state => state.shopify.collections.byId)
  const [error, setError] = useState(false)
  const images = useBlockImage()

  useEffect(() => {
    if (action_type && action_value && action_type === "view_collection") {
      const nextCollection = get(collections, action_value, null)
      const hasDeletedCollection = isNull(nextCollection)

      hasDeletedCollection && setBlockValid({ id, isValid: false })
      return setError(hasDeletedCollection)
    }
    setError(false)
  }, [action_type, action_value])

  const getHighlightTime = datetime => {
    const diff = moment(datetime).diff(moment().add(24, "hours"))

    if (diff < 0) {
      return true
    }
    return false
  }

  const renderer = ({ formatted, completed, props }) => {
    const { isHighlight } = props

    return (
      <div css={tw`flex flex-row flex-wrap -mx-2`}>
        <CounterItem disabled={completed} isHighlight={isHighlight}>
          <div className="counter-item__number">
            <span>{formatted.days}</span>
          </div>
          <div className="counter-item__unit">Days</div>
        </CounterItem>
        <CounterItem disabled={completed} isHighlight={isHighlight}>
          <div className="counter-item__number">
            <span>{formatted.hours}</span>
          </div>
          <div className="counter-item__unit">Hours</div>
        </CounterItem>
        <CounterItem disabled={completed} isHighlight={isHighlight}>
          <div className="counter-item__number">
            <span>{formatted.minutes}</span>
          </div>
          <div className="counter-item__unit">Mins</div>
        </CounterItem>
        <CounterItem disabled={completed} isHighlight={isHighlight}>
          <div className="counter-item__number">
            <span>{formatted.seconds}</span>
          </div>
          <div className="counter-item__unit">Secs</div>
        </CounterItem>
      </div>
    )
  }

  const CountdownItem = ({ thumbnail, datetime, title, preview }) => {
    const renderThumbnail = () => {
      if (preview) {
        return (
          <div css={tw`pb-9/16`}>
            <img
              src={thumbnail}
              alt=""
              css={tw`absolute inset-0 w-full h-full object-cover`}
            />
          </div>
        )
      }

      if (isNull(thumbnail)) {
        return null
      }

      if (thumbnail) {
        return <img src={thumbnail} css={tw`w-full h-auto`} />
      }

      return (
        <div css={[tw`pb-9/16 bg-gray-100`, mode === "edit" && tw`hidden`]}>
          <div
            css={tw`absolute inset-0 flex items-center justify-center text-center`}
          >
            <h2 css={tw`text-xl font-medium text-gray-800`}>
              Countdown <br />
              Image Banner
            </h2>
          </div>
        </div>
      )
    }

    return (
      <div css={tw`bg-white`}>
        <div css={tw`relative`}>{renderThumbnail()}</div>
        <div css={tw`px-4 py-6 text-center`}>
          <h2 css={tw`text-xl font-medium mb-4`}>{title}</h2>
          <Countdown
            date={datetime}
            isHighlight={getHighlightTime(datetime)}
            renderer={renderer}
            zeroPadTime={2}
            zeroPadDays={2}
          />
          <div css={tw`mt-6`}>
            <button
              css={tw`leading-none rounded-full border border-black text-gray-900 py-2 px-4 text-sm font-bold `}
            >
              SHOP NOW
            </button>
          </div>
        </div>
        {error && <WarningOverlay />}
      </div>
    )
  }

  if (preview) {
    return (
      <CountdownItem
        thumbnail={images["countdown-hover"].src}
        datetime={Date.now() + 255555000}
        title={'"Black Friday Deal End Soon!"'}
        preview
      />
    )
  }

  const renderDateTime = time => {
    if (isUndefined(time)) {
      return new Date()
    }

    if (isObject(time)) {
      return new Date(time.seconds * 1000 + time.nanoseconds / 1000000)
    }

    return time.toDate()
  }

  return (
    <CountdownItem
      thumbnail={image_url}
      datetime={renderDateTime(event_finish)}
      title={event_title}
    />
  )
}

CountdownBlock.defaultProps = {
  event_title: "",
  preview: false,
}
