import React, { useRef, useState, useEffect } from "react"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import { assign, get } from "lodash"

import BlockItem from "../BlockItem"
import { useBlockImage } from "reaverUtil/image"

const Wrapper = styled.div`
  ${tw`block shadow-sm my-2`}
  outline: 1px solid ${({ theme }) => theme.colors.gray[200]};
  transform-origin: left top;
  
  width: 360px;
  transform: scale(0.746);
  
  @media (min-width: 1280px) {
    transform: scale(1);
  }

  ${({ size }) => {
    return css`
      margin-bottom: ${size.height * -1 * (1 - 0.746)}px;

      @media (min-width: 1280px) {
        margin-top: 16px;
        margin-bottom: 16px;
      }
    `
  }}

  &:hover {
    ${tw`relative`}
    outline: ${({ theme }) => theme.colors.primary["500"] + " solid 3px;"};

    &::after {
      ${tw`absolute inset-0 z-40 block`}
      content: '';
    }
  }
`

const BlockListItem = React.memo(props => {
  const { actionId, type, name, info } = props
  const itemRef = useRef(null)
  const [size, setSize] = useState({ width: null, height: null })
  const [isHover, setIsHover] = useState(false)
  const [meta, setMeta] = useState({ ...info })

  useEffect(() => {
    if (info) {
      setMeta({ ...info })
    }
  }, [info])

  useEffect(() => {
    if (itemRef) {
      const width = itemRef.current.clientWidth
      const height = itemRef.current.clientHeight
      const nextSize = assign(size, { width, height })
      setSize(nextSize)
    }
  }, [itemRef])

  const images = useBlockImage()

  const getPherMeta = () => {
    switch (actionId) {
      case "promocode":
        return {
          name: "fff",
          type: "promocode",
          promo_title: "Sale Starts Now! PROMO CODE IS:",
          promo_code: "JCURVE",
        }
      case "product":
        return { name: "fff", preview: true }
      case "collection_banner":
        return { name: "fff", preview: true }
      case "collection_list":
        return { name: "fff", preview: true }
      case "countdown":
        return { name: "fff", preview: true }
      case "collection_horizontal":
        return {
          name: "fff",
          title: "Deal of the Day",
          preview: true,
          item_count: 4,
          display_type: "horizontal",
        }
      case "collection_vertical":
        return {
          name: "fff",
          title: "Pretty Little Extras",
          preview: true,
          item_count: 4,
          display_type: "vertical",
        }
      case "custom_banner":
        return { name: "fff", preview: true }
      case "slideshow":
        return { name: "fff", preview: true }
      case "collection_carousel":
        return {
          name: "fff",
          title: "Most Hearted Styles",
          preview: true,
          item_count: 4,
          display_type: "carousel",
        }
      default:
        break
    }
  }

  useEffect(() => {
    const placeholder = getPherMeta()

    setMeta(isHover ? placeholder : { ...info })
  }, [isHover])

  const handleMouseEnter = () => {
    setIsHover(true)
  }
  const handleMouseLeave = () => {
    setIsHover(false)
  }

  return (
    <Wrapper
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={itemRef}
      size={size}
    >
      <BlockItem type={type} isValid={isHover ? true : false} info={meta} />
    </Wrapper>
  )
})

export default BlockListItem
