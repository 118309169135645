import React from "react"
import tw, { styled } from "twin.macro"

const Wrapper = styled.span`
  ${tw`bg-gray-300 inline-block h-3 max-w-lg w-full`}
  min-width: 140px;
`

export default function SkeletonDisplayText({ size }) {
  return <Wrapper size={size} />
}
