import React, { useEffect } from "react"
import Modal from "react-modal"
import tw, { styled, css } from "twin.macro"
import Button from "general/components/Button"

const styles = {
  overlay: {
    overflowY: "auto",
    overflowX: "hidden",
    background: "rgba(33,43,54,.4)",
    zIndex: 500,
  },
  content: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    background: "transparent",
  },
}

const CloseButton = ({ onClose, title = true }) => {
  return (
    <button
      onClick={onClose}
      css={[
        tw`rounded-md py-1 px-2 hover:bg-gray-100 transition-colors duration-500`,
        !title && tw`absolute right-0 top-0 z-20`,
      ]}
    >
      <i className="icon-cancel" css={tw`text-gray-600 text-sm`} />
    </button>
  )
}

export default function Dialog(props) {
  const { open, title, primaryAction, secondaryActions, children } = props
  const { onClose } = props

  // useEffect(() => {
  //   if (open) {
  //     document.body.className = "overflow-y-hidden"
  //   } else {
  //     document.body.className = ""
  //   }
  // }, [open])

  const headerMarkup = title ? (
    <div className="py-2 px-4 border-b border-gray-100 flex flex-row justify-between items-center">
      <h2 className="text-xl font-semibold">{title}</h2>
      <CloseButton onClose={onClose} />
    </div>
  ) : (
    <CloseButton onClose={onClose} title={false} />
  )

  return (
    <Modal
      isOpen={open}
      style={styles}
      shouldCloseOnEsc={true}
      onRequestClose={onClose}
    >
      <div
        className="max-w-lg relative rounded-md bg-white mx-auto w-full flex items-center flex-col"
        css={css`
          max-width: 800px;
          max-height: calc(100vh - 60px);
        `}
      >
        {headerMarkup}
        <div
          css={[
            tw`w-full`,
            css`
              display: flex;
              flex-grow: 1;
              overflow-x: hidden;
            `,
          ]}
        >
          <div className="w-full overflow-auto">{children}</div>
        </div>
        {(primaryAction || secondaryActions) && (
          <div className="w-full flex justify-end -mx-1 py-2 px-4 border-t border-gray-100">
            {secondaryActions &&
              secondaryActions.map((item, index) => {
                return (
                  <div className="px-1" key={`secondary-action-${index}`}>
                    <Button size="slim" onClick={item.onAction}>
                      {item.content}
                    </Button>
                  </div>
                )
              })}
            {primaryAction && (
              <div className="px-1">
                <Button primary size="slim" onClick={primaryAction.onAction}>
                  {primaryAction.content}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  )
}
