import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { useSpring, animated } from "react-spring"
import { times } from "lodash"
import ScreenTab from "./ScreenTab"

const Wrapper = styled(animated.div)`
  ${tw`bg-white overflow-auto flex flex-row flex-no-wrap box-content border-b border-gray-300`}
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    ${tw`hidden`}
  }
`

export default function ScreenTabs(props) {
  const { sections, selectedSection, isMultipageActive, isLoaded } = props
  const scrollRef = useRef()
  const itemsRef = useRef([])

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, sections.length)
  }, [sections])

  useEffect(() => {
    const to =
      itemsRef.current[
        sections.findIndex(section => section.id === selectedSection)
      ]

    if (to) {
      const position = to.offsetLeft
      scrollRef.current.scrollTo({ left: position, behavior: "smooth" })
    }
  }, [selectedSection, scrollRef, itemsRef])

  const animation = useSpring({
    height: isMultipageActive ? `42px` : `0px`,
  })

  return (
    <Wrapper ref={scrollRef} style={animation}>
      <div css={tw`w-full flex flex-no-wrap `}>
        {isLoaded
          ? times(3, index => {
              return <ScreenTab skeleton key={index} />
            })
          : sections.map((section, index) => {
              const sectionId = section.id

              return (
                <ScreenTab
                  key={sectionId}
                  ref={el => (itemsRef.current[index] = el)}
                  isActive={sectionId === selectedSection}
                  title={section.title}
                />
              )
            })}
      </div>
    </Wrapper>
  )
}
