import React from "react"
import tw, { css, styled } from "twin.macro"

const Wrapper = styled.div`
  ${tw`absolute inset-0 object-cover w-full h-full`}
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;

  ${({ zIndex }) => {
    return (
      zIndex &&
      css`
        z-index: ${zIndex};
      `
    )
  }}

  ${({ primary }) =>
    primary &&
    css`
      background-color: rgba(210, 3, 90, 0.46);
    `}

  ${({ danger }) =>
    danger &&
    css`
      background-color: rgba(64, 64, 64, 0.6);
    `}

  ${({ light }) => {
    return (
      light &&
      css`
        background-color: rgba(255, 255, 255, 0.7);
      `
    )
  }}
`

export default function Overlay({ danger, light, primary, zIndex }) {
  return (
    <Wrapper danger={danger} light={light} primary={primary} zIndex={zIndex} />
  )
}
