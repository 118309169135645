import { connect } from "react-redux"
import { get } from "lodash"

import ScreenHeader from "design/components/ScreenHeader"

const mapStateToProps = state => {
  return {
    primaryColor: state.design.ui.colors.primary,
    accentColor: state.design.ui.colors.accent,
    logo: get(state, "design.ui.logo", null),
    cartIconType: state.design.ui.cartIconType,
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenHeader)
