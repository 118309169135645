import React, { useState } from "react"
import tw, { css } from "twin.macro"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { animated } from "react-spring"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { isUndefined, isNull, get } from "lodash"
import Button from "general/components/Button"
import Spinner from "general/components/Spinner"
import IconHandle from "assets/svgs/icons/icon-handle.svg"
import IconFacebook from "assets/svgs/icons/icon-menu-facebook.svg"
import IconTwitter from "assets/svgs/icons/icon-menu-twitter.svg"
import IconInstagram from "assets/svgs/icons/icon-menu-instagram.svg"
import IconWhatsapp from "assets/svgs/icons/icon-menu-whatsapp.svg"
import IconYoutube from "assets/svgs/icons/icon-menu-youtube.svg"
import IconPinterest from "assets/svgs/icons/icon-menu-pinterest.svg"
import IconSnapchat from "assets/svgs/icons/icon-menu-snapchat.svg"
import IconFacebookChat from "assets/svgs/icons/icon-menu-facebook-chat.svg"
import ConfirmModal from "modal/components/ConfirmModal"
import produce from "immer"
import toast from "react-toastify"
import { Scrollbars } from "react-custom-scrollbars"

const Menu = styled.div`
  ${tw`absolute top-0 bottom-0 left-0 z-10 bg-white flex items-center py-8`}

  width: 330px;
  max-height: 616px;

  ${({ isActive }) => (isActive ? tw`block` : tw`hidden`)}
`
const ButtonAction = styled.button`
  ${tw`text-sm text-gray-700 w-8 h-8 inline-block hover:bg-gray-100 rounded-md`}
`
const ButtonHandle = styled.button`
  ${tw`text-sm text-gray-700 h-8 inline-block `}
  svg {
    ${tw`inline-block`}
  }
`
const ButtonEdit = styled(ButtonAction)``
const ButtonDelete = styled(ButtonAction)``

const getStyle = (style, snapshot) => {
  const dropping = snapshot.isDragging
  if (!dropping) {
    return style
  }

  return {
    ...style,
    ...tw`border border-primary-500 border-opacity-100 bg-primary-200`,
    ...tw`fill-current text-primary-500`,
  }
}

const MenuItem = props => {
  const {
    type,
    title,
    icon,
    direction,
    isVisible,
    isChat,
    isEditable,
    dragHandleProps,
    disabled,
  } = props
  const { removeMenu, editMenu } = props

  const renderIcon = () => {
    let Icon = null
    if (type === "custom_social") {
      const socialType = direction[0].social_account

      switch (socialType) {
        case "facebook":
          Icon = IconFacebook
          break
        case "twitter":
          Icon = IconTwitter
          break
        case "youtube":
          Icon = IconYoutube
          break
        case "pinterest":
          Icon = IconPinterest
          break
        case "snapchat":
          Icon = IconSnapchat
          break
        case "instagram":
          Icon = IconInstagram
          break
        case "whatsapp_chat":
          Icon = IconWhatsapp
          break
        case "facebook_chat":
          Icon = IconFacebookChat
          break
      }
    }

    if (Icon) {
      return (
        <div css={tw`mr-2`}>
          <Icon />
        </div>
      )
    }

    return null
  }

  return (
    <div
      css={[
        tw`px-2 h-12 flex flex-row items-center justify-between cursor-pointer w-full overflow-hidden`,
        tw`border border-primary-500 border-opacity-0 hover:border-opacity-100 hover:bg-primary-200 hover:text-primary-500`,
      ]}
      {...dragHandleProps}
    >
      <div css={tw`flex flex-row items-center`}>
        <div css={tw`mr-2 flex items-center`}>
          <IconHandle css={tw`fill-current`} />
        </div>
        {renderIcon()}
        <div
          css={[
            tw`text-gray-900 flex flex-row items-center overflow-hidden break-all`,
            css`
              min-width: 0;
              line-height: 1.4;
              white-space: normal;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              word-wrap: break-word;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            `,
            disabled && tw`text-gray-400`,
          ]}
        >
          {title}
        </div>
      </div>
      <div css={[tw`flex flex-row`, !isEditable && tw`hidden`]}>
        <ButtonEdit type="button" onClick={editMenu}>
          <span className="icon-pencil" />
        </ButtonEdit>
        <ButtonDelete type="button" onClick={removeMenu}>
          <span className="icon-trash-empty"></span>
        </ButtonDelete>
      </div>
    </div>
  )
}

function ScreenMenu(props) {
  const { menu, isActive } = props
  const { swapMenu, removeMenu, openModal } = props
  const [confirm, setConfirm] = useState({
    isOpen: false,
  })
  const design = useSelector(({ design }) => design)
  const status = design.status
  // const menuById = design.menu.byId
  const profile = useSelector(({ firebase }) => firebase.profile)

  const MENU_LIMIT = get(
    profile,
    "menu_item_limit",
    process.env.GATSBY_MENU_LIMIT
  )

  const handleCloseConfirm = () => {
    return setConfirm(
      produce(draft => {
        draft.isOpen = false
      })
    )
  }

  const handleDragEnd = result => {
    if (isUndefined(result) || isNull(result.destination)) {
      return false
    }

    if (result.source.index !== result.destination.index) {
      swapMenu({ from: result.source.index, to: result.destination.index })
    }
  }

  const handleRemoveMenu = id => {
    setConfirm(
      produce(draft => {
        draft.isOpen = true
        draft.title = "Are you sure you want to delete?"
        draft.primaryAction = {
          content: "Delete",
          onAction: () => {
            removeMenu({ id })
            handleCloseConfirm()
          },
        }
        draft.secondaryAction = {
          content: "Cancel",
          onAction: () => {
            handleCloseConfirm()
          },
        }
      })
    )
  }

  const handleAddMenu = () => {
    // if (Object.keys(menuById).length >= MENU_LIMIT) {
    //   return toast(`You can add menu up to ${MENU_LIMIT} items.`)
    // }

    openModal({ type: "MENU" })
  }

  if (status === "loading") {
    return (
      <Menu isActive={isActive}>
        <div className="flex items-center justify-center w-full h-full">
          <Spinner color="primary" />
        </div>
      </Menu>
    )
  }

  return (
    <Menu isActive={isActive}>
      <div css={tw`flex items-center w-full h-full`}>
        <Scrollbars>
          <DragDropContext onDragEnd={handleDragEnd}>
            <div css={tw`flex flex-col w-full`}>
              <Droppable droppableId={"MENU"}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    snapshot={snapshot}
                    {...provided.droppableProps}
                  >
                    {menu &&
                      menu.map((item, index) => {
                        return (
                          <Draggable
                            index={index}
                            key={index}
                            draggableId={`${index}`}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                snapshot={snapshot}
                                {...provided.draggableProps}
                                style={getStyle(
                                  provided.draggableProps.style,
                                  snapshot
                                )}
                              >
                                <MenuItem
                                  disabled={
                                    (item.type === "account" ||
                                      item.type === "order") &&
                                    get(
                                      profile,
                                      "settings.user_account",
                                      false
                                    ) === "disabled"
                                  }
                                  type={item.type}
                                  title={item.name}
                                  icon={item.icon}
                                  direction={item.direction}
                                  isEditable={get(item, "editable", false)}
                                  isVisible={item.visible}
                                  isChat={item.is_chat}
                                  editMenu={() =>
                                    openModal({
                                      type: "MENU",
                                      meta: { menuId: item.id },
                                    })
                                  }
                                  removeMenu={() => handleRemoveMenu(item.id)}
                                  dragHandleProps={provided.dragHandleProps}
                                />
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              {menu.length + 1 <= parseInt(MENU_LIMIT) && (
                <div css={tw`px-2 mt-4`}>
                  <Button
                    fullWidth
                    primary
                    size="large"
                    css={tw`uppercase`}
                    // onClick={() => navigate("/app/design/menu")}
                    onClick={() => handleAddMenu()}
                  >
                    Add new menu
                  </Button>
                </div>
              )}
            </div>
          </DragDropContext>
        </Scrollbars>
      </div>
      <ConfirmModal
        isOpen={confirm.isOpen}
        onRequestClose={handleCloseConfirm}
        primaryAction={confirm.primaryAction}
        secondaryAction={confirm.secondaryAction}
        title={confirm.title}
      />
    </Menu>
  )
}
export default ScreenMenu
