import React, { useEffect } from "react"
import Cookies from "universal-cookie"

export default function AppPage() {
  useEffect(() => {
    const cookies = new Cookies()

    cookies.set("test", "great")
  }, [])

  return <div>apppage</div>
}
