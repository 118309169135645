import React from "react"
import { connect } from "react-redux"
import { get } from "lodash"

import ScreenTabs from "design/components/ScreenTabs/index"
import { getSectionList } from "../../selector"

const mapStateToProps = state => {
  return {
    isLoaded: state.design.blocks.isLoaded,
    sections: getSectionList(state),
    selectedSection: state.design.selectedSection,
    isMultipageActive: get(state, "firebase.profile.settings.multipage", false),
  }
}

const mapDispatchToProps = null

export default connect(mapStateToProps, mapDispatchToProps)(ScreenTabs)
