import React, { useRef } from "react"
import Modal from "../../general/components/Modal"
import FormPromocode from "./FormPromocode"

export default function EditPromocodeBlockModal({
  isOpen,
  onRequestClose,
  meta,
  onAction,
}) {
  const formRef = useRef(null)

  const handlePrimary = () => {
    formRef.current.formSubmit()
  }

  const handleSubmit = formData => {
    onAction(formData)
  }

  return (
    <Modal
      primaryAction={{ content: "Save", onAction: () => handlePrimary() }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={"Add Discount Code"}
      subtitle={"Boost your sales with your discount code!"}
    >
      <FormPromocode ref={formRef} onSubmit={handleSubmit} meta={meta} />
    </Modal>
  )
}
