import React from "react"
import tw, { styled, css } from "twin.macro"
import { useSelector } from "react-redux"
import GraphemeSplitter from "grapheme-splitter"

import Overlay from "general/components/Overlay"
import IconRemoveCircle from "assets/images/design/icon-remove-circle.png"
import ThumbnailDeleted from "assets/images/design/thumbnail-deleted.png"

const Title = styled.span`
  ${tw`font-semibold text-white relative z-10 overflow-hidden`}
  min-width: 0;
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${({ error }) =>
    error &&
    css`
      color: #d2055c;
    `}
`

export default function CollectionItemSelection(props) {
  const splitter = new GraphemeSplitter()
  const { title, image, error, onAction } = props
  const primaryColor = useSelector(state => state.design.ui.colors.primary)

  const renderThumbnail = () => {
    if (error) {
      return (
        <div css={[tw`absolute w-full h-full z-10 inset-0`]}>
          <img
            src={ThumbnailDeleted}
            css={tw`absolute inset-0 w-full h-full object-cover`}
          ></img>
        </div>
      )
    }

    if (image) {
      return (
        <img
          src={image}
          css={tw`absolute inset-0 object-cover w-full h-full`}
          alt={title}
        />
      )
    } else {
      return (
        <div
          css={[
            tw`absolute inset-0 w-full h-full text-6xl inline-flex items-center justify-center text-white`,
            css`
              background-color: ${primaryColor};
            `,
          ]}
        >
          {title.length > 0 && splitter.splitGraphemes(title)[0]}
        </div>
      )
    }
  }

  return (
    <div
      className="group"
      css={[
        tw`inline-block relative cursor-pointer rounded-md overflow-hidden border border-gray-300 hover:border-primary-500`,
        css`
          width: 100px;
          height: 100px;
        `,
      ]}
    >
      <div css={tw`bg-gray-100 pb-1/1`}>
        <span
          css={[
            tw`absolute z-30`,
            css`
              top: 6px;
              right: 6px;
            `,
          ]}
        >
          <img
            onClick={onAction}
            src={IconRemoveCircle}
            alt="Remove"
            css={tw`w-4 h-4 relative z-20 group-hover:bg-white rounded-full`}
          />
        </span>
        <div>
          {renderThumbnail()}
          {!error && <Overlay danger={!image} />}
        </div>
        <div
          css={[
            tw`absolute inset-0 items-center inline-flex justify-center px-2 py-2 text-center z-20`,
          ]}
        >
          <Title error={error}>{title}</Title>
        </div>
      </div>
    </div>
  )
}
