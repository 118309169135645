import React, { useRef, useState, useEffect } from "react"
import produce from "immer"

import Modal from "general/components/Modal"
import ShopifySearch from "shopify/components/ShopifySearch"

export default function SearchModal({
  isOpen,
  meta,
  onRequestClose,
  onPrimaryAction,
  disabledEmpty,
}) {
  const customStyles = {
    overlay: {
      background: "rgba(33,43,54,.4)",
      zIndex: 600,
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      zIndex: 700,
      padding: "0",
      width: "100%",
      maxWidth: "620px",
      position: "absolute",
    },
  }

  const searchRef = useRef(null)

  const [mode] = useState("new")
  const [selected, setSelected] = useState([])
  const [items, setItems] = useState([])

  useEffect(() => {
    if (mode === "new") {
      if (selected.length > 0) {
        const index = items.findIndex(item => item.id === selected[0])
        const collection = items[index]

        return onPrimaryAction(selected[0], collection)
      }
    }
  }, [selected])

  const handleChange = (nextSelected, data) => {
    setSelected(nextSelected)
    setItems(
      produce(draft => {
        draft.push(data)
      })
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      shouldCloseOnEsc={false}
      size={"sm"}
      title={`Add ${meta.resourceType}`}
      onRequestClose={onRequestClose}
    >
      <ShopifySearch
        autoFocus
        ref={searchRef}
        defaultQuery={meta.query}
        onChange={handleChange}
        selected={selected}
        resourceType={meta.resourceType}
        disabledEmpty={disabledEmpty}
      />
    </Modal>
  )
}

SearchModal.defaultProps = {
  meta: {
    resourceType: "collection",
  },
}
