import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import { useSelector } from "react-redux"
import { includes, get, isNull } from "lodash"
import { useLazyQuery, gql } from "@apollo/client"
import Modal from "general/components/Modal"
import Button from "general/components/Button"
import ShopifySearch from "shopify/components/ShopifySearch"
import noImage from "assets/images/design/block/noimage.png"
import { GET_COLLECTION } from "shopify/queries"
import usePrevious from "reaverUtil/usePrevious"
import Overlay from "general/components/Overlay"

export default function EditCollectionBlockModal(props) {
  const { isOpen, meta } = props
  const { onRequestClose, onAction, updateCollectionsSuccess } = props
  const collections = useSelector(state => state.shopify.collections.byId)
  const primaryColor = useSelector(state => state.design.ui.colors.primary)
  const [mode, setMode] = useState("new")
  const [item, setItem] = useState(null)
  const [selected, setSelected] = useState([])
  const [selectedCollection, setSelectedCollection] = useState(null)

  useEffect(() => {
    if (meta && get(meta, "collection_id")) {
      const { collection_id } = meta
      const nextItem = get(collections, collection_id, null)

      setSelected([collection_id])
      setItem(nextItem)
      setSelectedCollection(nextItem)
      setMode("edit")
    }
  }, [meta])

  useEffect(() => {
    if (mode === "new") {
      if (selected.length > 0) {
        updateCollectionsSuccess({ [item.id]: item })

        return onAction({ collection_id: item.id })
      }
    }

    if (mode === "edit") {
      if (selected.length === 0) {
        return setSelectedCollection(null)
      }

      if (item && isNull(selectedCollection)) {
        updateCollectionsSuccess({ [item.id]: item })

        return onAction({ collection_id: item.id })
      }
    }
  }, [selected, selectedCollection])

  const handleChange = (nextSelected, data) => {
    setSelected(nextSelected)
    setItem(data)
  }

  const handleClick = nextSelected => {
    if (includes(selected, nextSelected)) {
      setSelected(selected.filter(item => item !== nextSelected))

      return false
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={"Choose a Collection"}
      subtitle={
        "The products from the selected collection will be used in this block."
      }
    >
      <div>
        {selectedCollection && (
          <div
            css={tw`bg-gray-100 flex flex-row flex-wrap w-full justify-between items-center border-b py-4 px-6`}
          >
            <div
              css={[
                tw`flex flex-row items-center truncate flex-1`,
                css`
                  min-width: 0;
                `,
              ]}
            >
              <div css={tw`w-16 mr-4`}>
                <div
                  css={tw`relative border border-gray-100 rounded-md overflow-hidden`}
                >
                  <div css={tw`pb-1/1 bg-gray-100`}></div>
                  {get(selectedCollection, "image.transformedSrc") ? (
                    <img
                      src={get(selectedCollection, "image.transformedSrc")}
                      css={tw`absolute inset-0 object-cover w-full h-full`}
                      alt={get(selectedCollection, "title")}
                    />
                  ) : (
                    <div
                      className="absolute w-full h-full inset-0 inline-flex items-center justify-center text-5xl font-bold text-white"
                      css={css`
                        background-color: ${primaryColor};
                      `}
                    >
                      {get(selectedCollection, "title", undefined) &&
                        get(selectedCollection, "title").charAt(0)}
                    </div>
                  )}
                </div>
              </div>
              <h3
                css={[
                  tw`text-gray-800 font-medium truncate flex-1 pr-4`,
                  css`
                    min-width: 0;
                  `,
                ]}
              >
                <span css={tw`block text-gray-500 font-semibold text-sm`}>
                  Selected collection
                </span>
                <span css={tw`block truncate font-medium`}>
                  {get(item, "title", "Deleted collection")}
                </span>
              </h3>
            </div>
            <div css={tw``}>
              <Button
                size="slim"
                primary
                onClick={() => handleClick(selected[0])}
              >
                Change
              </Button>
            </div>
          </div>
        )}
        <div css={tw`relative`}>
          <ShopifySearch
            onChange={handleChange}
            selected={selected}
            alwaysActive={true}
          />
          {selectedCollection && <Overlay />}
        </div>
      </div>
    </Modal>
  )
}
