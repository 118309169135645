import React from "react"
import { navigate } from "gatsby"
import tw, { css } from "twin.macro"

import Modal from "dialog/components/Modal"
import Button from "general/components/Button"

import Content from "assets/images/modal/trial-expired.png"
import Avatar from "assets/images/modal/avatar-push.jpg"
import IconPushMoney from "assets/images/modal/icon-push-money.png"
import IconPushBigMoney from "assets/images/modal/icon-push-bigmoney.png"
import ImagePush from "assets/images/modal/normal-push.png"
import GifImagePush from "assets/images/modal/visual_push.gif"

export default function PromotePush(props) {
  const { open, onClose } = props

  return (
    <Modal open={open} onClose={onClose}>
      <div
        css={css`
          background-color: #fdf9f9;
        `}
      >
        <div css={[tw`py-6 px-8 flex flex-row items-start`]}>
          <img src={Avatar} alt="Hernine" css={tw`w-20 mr-6`} />
          <div>
            <h4 css={tw`text-lg leading-snug mb-2`}>
              When we announced new lineup with an animated push, I had to
              say ‘wow.’ We’ve got 300% more traffic than used to be!
            </h4>
            <p css={tw`text-sm`}>
              <span className="text-gray-900 font-medium">
                Cristina Jimenez
              </span>
              , Co-founder <span className="text-primary-500">@Hernine</span>
            </p>
          </div>
        </div>
      </div>
      <div className="px-8 py-8 text-center">
        <h3 css={tw`text-2xl leading-snug text-gray-900 mb-6`}>
          Customers open image push marketing{" "}
          <span className="text-3xl font-medium">X3 better.</span>
        </h3>
        <div className="flex flex-row text-center items-center my-8">
          <div css={tw`flex-1`}>
            <div
              className="relative inline-block"
              css={css`
                width: 163px;
              `}
            >
              <img
                src={IconPushMoney}
                alt=""
                className="h-16 absolute right-0"
                css={css`
                  bottom: 24px;
                `}
              />
              <img
                src={ImagePush}
                alt="Image Push"
                css={[
                  tw` inline-block mb-4`,
                  css`
                    width: 163px;
                  `,
                ]}
              />
            </div>
            <h3
              css={[
                tw`uppercase font-semibold text-lg`,
                css`
                  color: #5db390;
                `,
              ]}
            >
              Starter
            </h3>
            <p>Simple Text Marketing</p>
          </div>
          <div css={tw`flex-1`}>
            <div
              className="relative inline-block"
              css={css`
                width: 163px;
              `}
            >
              <img
                src={IconPushBigMoney}
                alt=""
                className="h-16 absolute right-0"
                css={css`
                  bottom: 24px;
                `}
              />
              <img
                src={GifImagePush}
                alt="Image Push"
                css={[
                  tw` inline-block mb-4`,
                  css`
                    width: 163px;
                  `,
                ]}
              />
            </div>

            <h3
              css={[
                tw`uppercase font-semibold text-lg`,
                css`
                  color: #ce0c58;
                `,
              ]}
            >
              GROWTH
            </h3>
            <p>Advanced Animated Image Marketing</p>
            <p css={tw`font-semibold text-gray-900 text-lg`}>300% MORE OPEN!</p>
          </div>
        </div>
        <Button
          primary
          onClick={() => {
            onClose()
            navigate("/app/pricing")
          }}
        >
          Upgrade Now
        </Button>
      </div>
    </Modal>
  )
}
