import React from "react"
import tw from "twin.macro"
import styled, { css } from "styled-components"

export default function EmptyCollectionBlock({ onAction }) {
  return (
    <div
      css={[
        tw`relative cursor-pointer rounded-md overflow-hidden border border-dashed text-gray-600 leading-tight border-gray-300`,
        css`
          width: 100px;
          height: 100px;
        `,
      ]}
      onClick={() => onAction()}
    >
      <div css={tw` pb-1/1`}>
        <div
          css={tw`absolute inset-0 items-center inline-flex justify-center px-2 py-2 text-center`}
        >
          <span css={tw`font-medium text-sm`}>Add Collection</span>
        </div>
      </div>
    </div>
  )
}
