import React, { forwardRef, useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import { useSelector } from "react-redux"
import { useFirebase } from "react-redux-firebase"
import { has, includes } from "lodash"

import ProgressSpinner from "general/components/ProgressSpinner"
import noImagePng from "assets/images/design/bg-menu-item-noimage.png"

const PreviewImage = styled.img`
  ${tw`absolute inset-0 object-scale-down w-full h-full table-cell align-middle`}

  ${({ preview }) => preview && tw`object-cover`}
  ${({ fixedRatio }) =>
    fixedRatio && [
      tw`object-cover`,
      css`
        margin-bottom: ${parseFloat(fixedRatio) * 100}%;
      `,
    ]}
`
// applisting페이지에서 icon, launchscreen에 사용 (경로 : /icon ,/screen)
const ImageUploader = forwardRef(
  (
    { emptyImage, source, onAction, fixedRatio, customValidator, disabled, folder },
    ref
  ) => {
    const [preview, setPreview] = useState(undefined)
    const [progress, setProgress] = useState(undefined)
    const [dimensions, setDimensions] = useState(undefined)
    const firebase = useFirebase()
    const inputRef = useRef(null)
    const profile = useSelector(state => state.firebase.profile)

    useEffect(() => {
      setPreview(source)
    }, [source])

    const verifyImageProcess = file => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.onload = function () {
          const width = img.naturalWidth
          const height = img.naturalHeight

          resolve({ width, height, file })
        }
      })
    }

    const handleChange = event => {
      if (event.target.files.length === 0) {
        return false
      }
      const file = event.target.files[0]

      if (!includes(["image/jpeg", "image/png"], file.type)) {
        return alert("Your file must be in PNG or JPG format.")
      }

      verifyImageProcess(file).then(data => {
        const { width, height, file } = data
        const ratio = ((height * 1.0) / (width * 1.0)).toFixed(3)

        if (customValidator) {
          if (!customValidator(data)) {
            return false
          }
        }

        if (has(profile, "shopify_url") && file) {
          setProgress(0)

          const storageRef = firebase.storage().ref()
          const shopify_url = profile.shopify_url
          const fileRef = storageRef.child(
            `app/${shopify_url.replace(
              ".myshopify.com",
              ""
            )}/${folder}/${Date.now()}_${file.name}`
          )

          const uploadTask = fileRef.put(file)

          uploadTask.on(
            "state_changed",
            function (snapshot) {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              )

              setProgress(progress)

              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED:
                  break
                case firebase.storage.TaskState.RUNNING:
                  break
              }
            },
            function (error) {
              setProgress(undefined)
              window.Sentry.captureException(error)
            },
            function () {
              uploadTask.snapshot.ref.getDownloadURL().then(downLoadUrl => {
                onAction(downLoadUrl, { width, height, ratio })
                setProgress(undefined)
                // setPreview(undefined)

                if (ref) {
                  ref.current.value = null
                } else {
                  inputRef.current.value = null
                }
              })
            }
          )
        }
      })
    }

    const handleLoad = img => {
      setDimensions({ height: img.offsetHeight, width: img.offsetWidth })
    }

    const renderPreview = () => {
      if (preview) {
        return preview
      }

      if (emptyImage) {
        return emptyImage
      }

      return noImagePng
    }

    return (
      <div>
        <div css={[tw`relative text-center overflow-hidden`]}>
          <div css={tw`relative`}>
            <label
              css={[tw`w-full h-full shadow-lg cursor-pointer text-gray-800`]}
            >
              <div
                css={[
                  css`
                    padding-bottom: ${parseFloat(fixedRatio) * 100}%;
                  `,
                ]}
              ></div>

              {progress >= 0 ? (
                <div
                  css={tw`absolute inset-0 w-full h-full flex items-center justify-center`}
                >
                  <div css={tw`w-16`}>
                    <ProgressSpinner percentage={progress} />
                  </div>
                </div>
              ) : (
                <PreviewImage
                  onLoad={handleLoad}
                  preview={!preview && true}
                  src={renderPreview()}
                  alt="preview"
                  fixedRatio={fixedRatio}
                />
              )}

              <input
                onClick={event => {
                  disabled && event.preventDefault()
                }}
                ref={element => {
                  if (ref) {
                    ref.current = element
                  }

                  inputRef.current = element
                }}
                onKeyDown={e => {
                  e.target.keyCode === 13 && e.preventDefault()
                }}
                type="file"
                required
                name="targetUrl"
                css={tw`hidden`}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
      </div>
    )
  }
)

ImageUploader.defaultProps = {
  fixedRatio: 0.75,
  folder: "blocks"
}

export default ImageUploader
