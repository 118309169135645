import React, { useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import PropTypes from "prop-types"

const Controls = styled.div`
  ${tw`absolute top-0 right-0 z-40 pl-2`}

  margin-right: -178px;
  width: 178px;

  ${({ active, disabled }) => {
    if (disabled) {
      return tw`invisible`
    }

    if (active) {
      return tw`visible`
    }

    return tw`invisible`
  }}
`
const ControlList = styled.div`
  ${tw`flex flex-col  rounded-md overflow-hidden text-sm`}
`

const Wrapper = styled.div`
  ${tw`relative`}

  ${({ active }) => (active ? tw` z-30` : tw`z-20`)}

  transition: outline 0.25s;
  outline: ${({ active, theme }) => {
    return active
      ? theme.colors.primary["500"] + " solid 3px;"
      : "transparent solid 3px;"
  }}}

  /* &::after {
    ${tw`absolute inset-0 z-40 block`}
    content: '';
  } */
`

const ControlItem = styled.button`
  ${tw`bg-primary-500 py-4 px-4 border-b border-primary-600 flex flex-row items-center text-left text-white `}
  text-transform: capitalize;

  &:last-child {
    ${tw`border-0`}
  }
`

export default function BlockSelector(props) {
  const [isActive, setIsActive] = useState(false)
  const { children, controls, disabled } = props

  const onMouseEnter = () => {
    setIsActive(true)
  }

  const onMouseLeave = () => {
    setIsActive(false)
  }

  return (
    <Wrapper
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
      active={isActive}
    >
      <Controls active={isActive} disabled={disabled}>
        <ControlList>
          {controls &&
            controls.map((item, index) => {
              return (
                <ControlItem onClick={item.action} key={index}>
                  {item.icon && (
                    <span>
                      <img
                        src={item.icon}
                        alt={item.title}
                        css={tw`w-4 h-4 mr-3`}
                      />
                    </span>
                  )}
                  <span css={tw`leading-none`}>{item.title}</span>
                </ControlItem>
              )
            })}
        </ControlList>
      </Controls>
      <div>{children}</div>
    </Wrapper>
  )
}

BlockSelector.propTypes = {
  controls: PropTypes.array,
  children: PropTypes.node,
  disabled: PropTypes.bool,
}

BlockSelector.defaultProps = {
  children: null,
  disabled: false,
}
