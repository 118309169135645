import React from "react"
import tw from "twin.macro"
import Modal from "dialog/components/Modal"

export default function EditIntegration({ open, onClose, props }) {
  const { type } = props
  return (
    <Modal
      open={open}
      onClose={onClose}
      secondaryActions={[
        {
          content: "Disconnect",
          onAction: () => console.log("submit"),
        },
      ]}
      primaryAction={{
        content: "Connect",
        onAction: () => console.log("submit"),
      }}
    >
      integration {type}
    </Modal>
  )
}
