import React from "react"
import { useSelector } from "react-redux"
import { times } from "lodash"
import Card from "marketing/components/Card"
import firebase from "gatsby-plugin-firebase"
import { useCollection } from "react-firebase-hooks/firestore"

export default function MarketingPage() {
  const profile = useSelector(state => state.firebase.profile)
  const shopifyUrl = profile.shopify_url

  const [value, loading, error] = useCollection(
    firebase.firestore().collection(shopifyUrl).doc("ux").collection("blocks"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  if (loading) {
    return "loading..."
  }

  return (
    <>
      <div className="px-4 py-8">
        <h2 className="text-xl font-semibold">Marketing</h2>
      </div>
      <div className="max-w-screen-xl px-4 mb-8">
        <div className="flex flex-row flex-wrap -mx-2">
          {times(4, index => (
            <div className="flex flex-col px-4 py-4 bg-white rounded-md shadow-md mx-2">
              <span className="text-3xl font-bold">342%</span>
              <span className="text-gray-500 uppercase font-semibold">
                Sales
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="max-w-screen-xl px-4">
        <div className="grid grid-cols-4 gap-4">
          {times(4, index => (
            <Card key={index} />
          ))}
        </div>
      </div>
    </>
  )
}
