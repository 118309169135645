import React from "react"
import tw, { styled, css } from "twin.macro"

const Badge = styled.div`
  ${tw`bg-red-100 text-primary-500 px-2 py-2 rounded-md cursor-pointer flex flex-row items-center leading-none`}
`

export default function TagField({ tags, disabled, onAddition, onDelete }) {
  const addTags = event => {
    if (
      (event.key === "Enter" || event.keyCode === 188) &&
      event.target.value !== ""
    ) {
      onAddition(event.target.value.replace(/\,/g, ""))
      event.target.value = ""
    }
  }

  const deleteTag = index => {
    onDelete(index)
  }

  return (
    <div>
      {tags && (
        <div
          className="flex flex-row flex-wrap"
          css={css`
            margin-left: -4px;
            margin-right: -4px;
          `}
        >
          {tags.map((tag, index) => {
            return (
              <div
                key={`tag${index}`}
                css={css`
                  margin-bottom: 8px;
                  padding-left: 4px;
                  padding-right: 4px;
                `}
              >
                <Badge key={tag} onClick={() => deleteTag(index)}>
                  <span>{tag}</span>
                  <svg
                    className={`w-4 h-4 ml-1`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Badge>
              </div>
            )
          })}
        </div>
      )}
      <div>
        <input
          type="text"
          disabled={disabled}
          placeholder="Type a Keyword..."
          onKeyUp={event => addTags(event)}
          className="w-full form-input"
        />
      </div>
    </div>
  )
}
