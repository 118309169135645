import React from "react"
import tw, { styled } from "twin.macro"

const Wrapper = styled.div`
  ${tw`grid grid-cols-1 gap-2`}

  div {
    ${tw`block h-2 bg-gray-100`}
  }
  div:last-child {
    width: 80%;
  }
`
const Text = styled.div``

export default function SkeletonBodyText() {
  return (
    <Wrapper>
      <div />
      <div />
      <div />
    </Wrapper>
  )
}
