import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import { useSelector } from "react-redux"
import Slider from "react-slick"
import { get, some, isNull, isUndefined } from "lodash"
import WarningOverlay from "design/components/WarningOverlay"

import noImage from "assets/images/design/block/noimage-carousel.png"
import Spinner from "general/components/Spinner"
import { useBlockImage } from "reaverUtil/image"

const Wrapper = styled.div`
  ${tw`block`}

  .slick-dots li.slick-active button:before {
    color: ${({ isActive }) => isActive && `white !important`};
  }
  .slick-dots li:hover button:before {
    color: ${({ isActive }) => isActive && `white !important`};
  }
  .slick-dots li button:before {
    color: ${({ isActive }) => isActive && `rgba(255,255,255,0.4)`};
  }

  .slick-slide > div {
    font-size: 0;
    line-height: 0;
  }

  /* .slick-dots li.slick-active button:before {
    color: white !important;
  } */
`

export default function SlideshowBlock(props) {
  const { id, setBlockValid, slideshow_info, preview, ratio } = props
  const [error, setError] = useState(false)
  const collections = useSelector(state => state.shopify.collections.byId)
  const images = useBlockImage()
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: preview ? false : true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  useEffect(() => {
    if (slideshow_info && slideshow_info.length > 0) {
      const nextList = slideshow_info.map(item => {
        if (item.action_type === "view_collection") {
          // console.log(collections, item.action_value)
          return get(collections, item.action_value, null)
        }
      })

      // console.log(nextList)
      if (nextList.length > 0) {
        const hasDeletedItems = some(nextList, item => isNull(item))

        hasDeletedItems && setBlockValid({ id, isValid: false })
        setError(hasDeletedItems)
      }
    }
  }, [slideshow_info])

  const SliderItem = ({ thumbnail, ratio }) => {
    return (
      <div css={tw`relative`}>
        <div
          css={[
            tw`bg-gray-100 pb-3/4`,
            css`
              padding-bottom: ${ratio * 100}%;
            `,
          ]}
        ></div>
        {thumbnail ? (
          <img
            src={thumbnail}
            alt=""
            css={tw`absolute inset-0 object-cover w-full h-full`}
          />
        ) : (
          <div
            css={tw`absolute inset-0 flex items-center text-center justify-center`}
          >
            <h2 css={tw`text-xl font-medium text-gray-800`}>
              Slideshow Banner
            </h2>
          </div>
        )}
      </div>
    )
  }

  if (preview) {
    return (
      <Wrapper isActive={true}>
        <Slider {...settings}>
          <SliderItem ratio={0.75} thumbnail={images["slideshow-hover"].src} />
          <SliderItem ratio={0.75} thumbnail={images["slideshow-hover"].src} />
          <SliderItem ratio={0.75} thumbnail={images["slideshow-hover"].src} />
        </Slider>
      </Wrapper>
    )
  }

  if (isUndefined(slideshow_info) || slideshow_info.length === 0) {
    return (
      <Wrapper isActive={false}>
        <Slider {...settings}>
          <SliderItem ratio={0.75} />
          <SliderItem ratio={0.75} />
          <SliderItem ratio={0.75} />
        </Slider>
      </Wrapper>
    )
  }

  return (
    <Wrapper isActive={true}>
      <Slider {...settings}>
        {slideshow_info.map((slide, index) => {
          return (
            <SliderItem
              ratio={ratio}
              thumbnail={get(slide, "image_url", noImage)}
              key={index}
            />
          )
        })}
      </Slider>
      {error && <WarningOverlay />}
    </Wrapper>
  )
}

SlideshowBlock.propTypes = {
  ratio: PropTypes.number,
}

SlideshowBlock.defaultProps = {
  ratio: 0.75,
}
