import React, { useEffect } from "react"
import Modal from "react-modal"
import Button from "general/components/Button"

const customStyles = {
  overlay: {
    overflowY: "auto",
    overflowX: "hidden",
    background: "rgba(33,43,54,.4)",
    zIndex: 500,
  },
  content: {},
}

export default function ConfirmModal(props) {
  const { isOpen, title } = props
  const { onRequestClose, primaryAction, secondaryAction } = props

  useEffect(() => {
    if (isOpen) {
      document.body.className = "overflow-y-hidden"
    } else {
      document.body.className = ""
    }

    return () => {
      document.body.className = ""
    }
  }, [isOpen])

  return (
    <Modal
      className="modal modal--xs"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="modal-content w-full bg-white rounded-md overflow-hidden">
        <div className="px-4 py-8">
          <h2 className="text-lg font-medium">{title}</h2>
        </div>
        <div className="py-3 px-4 border-t flex flex-row flex-wrap justify-end -mx-1">
          {secondaryAction && (
            <div className="mx-1">
              <Button onClick={secondaryAction.onAction}>
                {secondaryAction.content}
              </Button>
            </div>
          )}
          {primaryAction && (
            <div className="mx-1">
              <Button onClick={primaryAction.onAction} primary>
                {primaryAction.content}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}
