import React from "react"
import { navigate } from "gatsby"
import tw, { styled, css } from "twin.macro"
import Button from "general/components/Button"

export default function Page({
  title,
  subtitle,
  thumbnail,
  titleMetadata,
  breadcrumbs,
  children,
  primaryAction,
  secondaryActions,
}) {
  return (
    <div className="mb-8">
      <div className="max-w-screen-lg mx-auto px-4">
        <div className="py-6 lg:py-8">
          {breadcrumbs && (
            <div className="mb-2">
              {breadcrumbs.map((item, index) => {
                const handleClick = () => {
                  if (item.onAction) {
                    return item.onAction()
                  }

                  return navigate(item.url)
                }

                return (
                  <button
                    key={index}
                    onClick={handleClick}
                    className="text-gray-600 hover:text-gray-800 "
                  >
                    <div className="flex flex-row items-center">
                      <svg
                        className="w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="ml-1">{item.content}</span>
                    </div>
                  </button>
                )
              })}
            </div>
          )}
          <div className="flex flex-row items-center">
            <div className="flex-1">
              <div css={[thumbnail && tw`flex flex-row`]}>
                {thumbnail && <div className="mr-3">{thumbnail}</div>}
                <div>
                  <div css={[titleMetadata && tw`flex flex-row items-center`]}>
                    <h2 className="text-xl md:text-2xl font-semibold text-gray-900">
                      {title}
                    </h2>
                    {titleMetadata && (
                      <div className="ml-3">{titleMetadata}</div>
                    )}
                  </div>
                  {subtitle && (
                    <h3 className="text-gray-600 text-lg">{subtitle}</h3>
                  )}
                </div>
              </div>
              {secondaryActions && (
                <div className="mt-2 lg:mt-4">
                  {secondaryActions.map((item, index) => (
                    <button
                      key={index}
                      type="button"
                      onClick={item.onAction}
                      className="text-gray-600 hover:text-gray-800"
                    >
                      {item.content}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <div>
              {primaryAction && (
                <Button
                  primary
                  onClick={primaryAction.onAction}
                  loading={primaryAction.loading}
                >
                  {primaryAction.content}
                </Button>
              )}
            </div>
          </div>
        </div>
        <div>{children}</div>
      </div>
    </div>
  )
}
