import React, { useRef, useState, useEffect } from "react"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import { useSpring, animated, interpolate } from "react-spring"
import compareVersions from "compare-versions" 

import FormLogoUpload from "design/components/FormLogoUpload"
import CartIconSection from "design/containers/CartIconSection"
import ColorSection from "design/containers/ColorSection"
import { Scrollbars } from "react-custom-scrollbars"
import PictureSizeSwitch from "../../containers/PictureSizeSwitch"
import DisplayTypeSwitch from "../../containers/DisplayTypeSwitch"

const Wrapper = styled(animated.div)`
  ${tw`absolute bg-white right-0 top-0 h-full z-20 shadow-md`}

  padding-bottom: 64px;
  width: 260px;

  ${({ $isFixed }) => $isFixed && tw`fixed`}
  ${({ disabled }) =>
    disabled && [
      tw`overflow-y-hidden`,
      css`
        &::after {
          content: "";
          background-color: rgba(255, 255, 255, 0.8);
          ${tw`absolute block inset-0 z-50`};
        }
      `,
    ]}

  .toggle-setting {
    ${tw`inline-block absolute top-0 text-center bg-primary-500 text-white rounded-tl-lg rounded-bl-lg`}

    width: 16px;
    height: 66px;
    left: -16px;
    width: 16px;
    height: 66px;
    line-height: 40px;
  }
`

const Subtitle = styled.div`
  ${tw`text-lg font-normal  mb-2 text-gray-800`}
`

const SettingsLayout = ({ children }) => {
  return (
    <div css={tw`grid grid-cols-1 gap-6 px-4 lg:px-6 py-4 lg:py-6`}>
      {children &&
        children.map((item, index) => {
          return <div key={index}>{item}</div>
        })}
    </div>
  )
}

export default function EditorSettings(props) {
  const ref = useRef(null)
  const {
    isSettingsVisible,
    logo,
    primaryColor,
    isFixed,
    disabled,
    version,
    loading,
  } = props
  const { setIsSettingsVisible, updateUI } = props

  const { transform } = useSpring({
    transform: isSettingsVisible ? "0px" : `260px`,
    config: { duration: 250 },
  })

  return (
    <Wrapper
      ref={ref}
      style={{
        transform: interpolate(
          [transform.interpolate(t => (t === "0px" ? 0 : `translateX(${t})`))],
          translateX => {
            return translateX === 0 ? "none" : `${translateX}`
          }
        ),
      }}
      $isFixed={isFixed}
      disabled={disabled}
    >
      <button
        className="toggle-setting"
        onClick={() => setIsSettingsVisible(!isSettingsVisible)}
      >
        {isSettingsVisible ? ">" : "<"}
      </button>
      <Scrollbars>
        <SettingsLayout>
        <div>
            <div css={tw`flex flex-row flex-wrap items-center justify-between`}>
              <Subtitle>Brand Logo</Subtitle>
              {logo && (
                <button
                  onClick={() => updateUI({ logo: "" })}
                  css={tw`text-xs text-primary-500`}
                >
                  Delete
                </button>
              )}
            </div>
            <FormLogoUpload
              source={logo}
              primaryColor={primaryColor}
              updateUI={updateUI}
            />
          </div>
          {compareVersions.compare(`${version}`, "1.3", ">=") && (
            <>
              <div css={tw`mb-2`}>
              <Subtitle>Display Theme</Subtitle>
                <DisplayTypeSwitch loading={loading} />
              </div>
              <div>
              <Subtitle>Image Display</Subtitle>
                <PictureSizeSwitch loading={loading} />
              </div>
            </>
          )}
          <div>
            <Subtitle>Colors</Subtitle>
            <ColorSection />
          </div>
          <div>
            <Subtitle>Icons</Subtitle>
            <CartIconSection />
          </div>
          
        </SettingsLayout>
      </Scrollbars>
    </Wrapper>
  )
}
