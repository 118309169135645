import React from "react"
import { useSelector } from "react-redux"
import firebase from "gatsby-plugin-firebase"
import { useDocumentOnce } from "react-firebase-hooks/firestore"
import Iframe from "react-iframe"
import { get } from "lodash"

import Spinner from "general/components/Spinner"

import ImgIcon from "assets/images/stats/icon.png"

import tw, { css } from "twin.macro"

export default function StatsPage() {
  const profile = useSelector(({ firebase }) => firebase.profile)
  const { payment, shopify_url } = profile

  const [snapshot, loading, error] = useDocumentOnce(
    firebase.firestore().collection(shopify_url).doc("analytics")
  )
  const paymentPlan = get(payment, "plan", undefined)

  if (loading) {
    return (
      <div
        css={[
          tw`flex items-center justify-center h-full `,
          css`
            height: 550px;
            background: #f6f4f5;
          `,
        ]}
      >
        <Spinner color="primary" />
      </div>
    )
  }

  if (snapshot.exists) {
    const data = snapshot.data()

    return (
      <div className="max-w-screen-xl mx-auto px-4">
        <Iframe
          url={get(
            data,
            "analytics_url",
            "https://datastudio.google.com/embed/reporting/1aLsw6PoXPubFmk6mj50GYJsYXYG4Irir/page/BAWY"
          )}
          width="100%"
          height="1200px"
          id="myId"
          display="initial"
          position="relative"
        />
      </div>
    )
  }

  return (
    <div
      css={[
        tw`flex items-center justify-center h-full `,
        css`
          height: 550px;
          background: #f6f4f5;
        `,
      ]}
    >
      <div className="max-w-screen-xl mx-auto px-4 text-center">
        <img
          src={ImgIcon}
          alt="Working"
          className="inline-block mb-8 mt-8"
          css={css`
            width: 32em;
          `}
        />
        <h2 className="text-gray-900 text-lg font-medium">
          We are working on your sales report. <br />
          Stats for your sales will be here soon.
        </h2>
      </div>
    </div>
  )

  return (
    <div className="max-w-screen-xl mx-auto px-4">
      <Iframe
        url="https://datastudio.google.com/embed/reporting/1aLsw6PoXPubFmk6mj50GYJsYXYG4Irir/page/BAWY"
        width="100%"
        height="1200px"
        id="myId"
        display="initial"
        position="relative"
      />
    </div>
  )
}
