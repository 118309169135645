import React, { useState, useEffect } from "react"
import tw, { css } from "twin.macro"
import { useSelector } from "react-redux"
import { useFirebase } from "react-redux-firebase"
import { has, includes } from "lodash"
import { toast } from "react-toastify"

import ProgressSpinner from "general/components/ProgressSpinner"

export default function FormLogoUpload(props) {
  const { primaryColor, source, updateUI } = props
  const [preview, setPreview] = useState(undefined)
  const [progress, setProgress] = useState(undefined)
  const firebase = useFirebase()
  const profile = useSelector(state => state.firebase.profile)

  useEffect(() => {
    setPreview(source)
  }, [source])

  const verifyImageProcess = file => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.src = window.URL.createObjectURL(file)
      img.onload = function () {
        const width = img.naturalWidth
        const height = img.naturalHeight

        resolve({ width, height, file })
      }
    })
  }

  const handleChange = event => {
    if (has(profile, "shopify_url")) {
      if (event.target.files.length === 0) {
        return false
      }
      const file = event.target.files[0]

      if (!includes(["image/jpeg", "image/png"], file.type)) {
        return toast("Your file must be in PNG or JPG format.")
      }

      verifyImageProcess(file).then(data => {
        const { file } = data

        // if (width > 2000 || height > 2000 || width < 500 || height < 50) {
        //   return toast(
        //     "Please check your image size. Minimum size is 500 x 50 pixels. Maximum size is 2,000 x 2,000 pixels."
        //   )
        // }

        setProgress(0)

        const storageRef = firebase.storage().ref()
        const shopify_url = profile.shopify_url
        const fileRef = storageRef.child(
          `app/${shopify_url.replace(
            ".myshopify.com",
            ""
          )}/logo/${Date.now()}_${file.name}`
        )

        const uploadTask = fileRef.put(file)

        uploadTask.on(
          "state_changed",
          function (snapshot) {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100

            setProgress(progress)

            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED:
                break
              case firebase.storage.TaskState.RUNNING:
                break
            }
          },
          function (error) {
            setProgress(undefined)
            window.Sentry.captureException(error)
          },
          function () {
            uploadTask.snapshot.ref.getDownloadURL().then(downLoadUrl => {
              setProgress(undefined)
              updateUI({ logo: { image_url: downLoadUrl } })
            })
          }
        )
      })
    }
  }

  return (
    <div
      css={[
        tw`relative border border-gray-300 hover:border-primary-500 border-dashed rounded-md text-center overflow-hidden`,
        css`
          padding-bottom: 30%;
        `,
      ]}
    >
      <div
        css={tw`absolute inset-0 flex flex-col items-center justify-center w-full h-full`}
      >
        {progress >= 0 ? (
          <div css={tw`w-16`}>
            <ProgressSpinner percentage={progress} />
          </div>
        ) : (
          <label
            css={[
              tw`flex flex-col items-center justify-center w-full h-full py-4 shadow-lg cursor-pointer text-gray-800`,
              css`
                background-color: ${primaryColor};
              `,
            ]}
          >
            <div
              css={tw`text-sm font-semibold leading-normal uppercase flex flex-row items-center`}
            >
              {preview ? (
                <img
                  src={preview}
                  alt=""
                  css={[
                    tw`inline-block`,
                    css`
                      width: auto;
                      max-width: 114px;
                      max-height: 25px;
                    `,
                  ]}
                />
              ) : (
                <>
                  <span
                    className="icon-upload-cloud-outline"
                    css={tw`text-xl mr-1`}
                  />
                  <span>Add your logo</span>
                </>
              )}
            </div>
            <input
              type="file"
              required
              name="logo"
              css={tw`hidden`}
              onChange={handleChange}
            />
          </label>
        )}
      </div>
    </div>
  )
}
