import React from "react"
import tw, { styled, css } from "twin.macro"
import firebase from "gatsby-plugin-firebase"
import { useCollection } from "react-firebase-hooks/firestore"
import { get } from "lodash"

import ImgIcon from "assets/images/listing/icon-status-check.png"
import ImgHeadingIos from "assets/images/listing/heading-ios.png"
import ImgHeadingAndroid from "assets/images/listing/heading-android.png"

// const STAGE_CONTENTS_BY_PLATFORM = {
//   ios: {
//     "1": {
//       title: "Checking",
//       content:
//         "We are checking your information before starting your Android app buidling",
//     },
//     C: {
//       title: "Customizing",
//       content: "We are building your brand Android app.",
//     },
//     "3": {
//       title: "App Building",
//       content: "We are building your brand Android app.",
//     },
//     "4": {
//       title: "Testing",
//       content: "Our QA team is testing your Android app for high quality app.",
//     },
//     "5": {
//       title: "Google in Review",
//       content:
//         "We sent your Android app to Google Playstore. Google is reviewing your Android app. It will take 2~3 business days. When Google finish app review, your app will be live on Google Playstore.",
//     },
//     "6": {
//       title: "Congratulation!!",
//       content:
//         "Congratulation!!!  Your Android app is live on Google Playstore now. You can download your app using this link. Searching your app takes up to to 24hrs in Google Playstore.",
//     },
//     "7": {
//       title: "Smart Banner is live!",
//       content: "Smartbanner connect complete!",
//     },
//     F: {
//       title: "Finish!",
//     },
//   },
//   android: {
//     "1": {
//       title: "Checking",
//       content:
//         "We are checking your information before starting your Android app buidling",
//     },
//     C: {
//       title: "Customizing",
//       content: "We are building your brand Android app.",
//     },
//     "3": {
//       title: "App Building",
//       content: "We are building your brand Android app.",
//     },
//     "4": {
//       title: "Testing",
//       content: "Our QA team is testing your Android app for high quality app.",
//     },
//     "5": {
//       title: "Google in Review",
//       content:
//         "We sent your Android app to Google Playstore. Google is reviewing your Android app. It will take 2~3 business days. When Google finish app review, your app will be live on Google Playstore.",
//     },
//     "6": {
//       title: "Congratulation!!",
//       content:
//         "Congratulation!!!  Your Android app is live on Google Playstore now. You can download your app using this link. Searching your app takes up to to 24hrs in Google Playstore.",
//     },
//     "7": {
//       title: "Smart Banner is live!",
//       content: "Smartbanner connect complete!",
//     },
//     F: {
//       title: "Finish!",
//     },
//   },
// }

const Timeline = styled.div`
  ${tw`text-white`}

  &.timeline--android {
    .timeline-item .timeline__ui--done::before {
      background-position: 0 12px;
    }
  }

  .timeline-item {
    .timeline__ui {
      ${tw`block`}

      font-size: 0;
      line-height: 15px;

      &::before {
        content: "";
        display: block;
        background: url(${ImgIcon});
        background-image: 12px auto;
        background-position: 0 0;
        float: left;
        width: 12px;
        height: 12px;
      }
      &::after {
        content: "";
        display: block;
        float: left;
        width: calc(100% - 12px);
        height: 1px;
        margin-top: 6px;
        background: linear-gradient(to right, #fff 50%, #0000 50%);
        background-size: 4px 1px;
        background-repeat: repeat-x;
      }

      &--done {
        &::before {
          background-position: 0 24px;
        }
        &::after {
          background: #fff;
        }
      }
    }

    .timeline__phase {
      ${tw`text-xs tracking-tight`}
    }

    &:last-child {
      .timeline__ui::after {
        display: none;
      }
    }
  }
`

const Heading = ({ platform, stage, title, smartbanner }) => {
  return (
    <div
      css={[
        platform === "ios" &&
          css`
            background-image: url(${ImgHeadingIos}),
              linear-gradient(to left, #525050, #343434);
            background-position: right bottom, 0 0;
            background-repeat: no-repeat;
          `,
        platform === "android" &&
          css`
            background-image: url(${ImgHeadingAndroid}),
              linear-gradient(to right, #5db634, #75be53);
            background-position: right 21px, 0 0;
            background-repeat: no-repeat;
          `,
        tw`px-6 py-8 text-white`,
      ]}
    >
      <div className="text-sm">
        Step {stage}{" "}
        {smartbanner && (
          <span
            className="ml-2 px-2 text-xs uppercase rounded-full font-semibold bg-white"
            css={[
              platform === "android" &&
                css`
                  color: #5db635;
                `,
              platform === "ios" &&
                css`
                  color: #353535;
                `,
            ]}
          >
            Smart app banner on
          </span>
        )}
      </div>
      <h3 className="text-xl font-semibold mb-6">{title}</h3>
      <Timeline
        className={`grid timeline--${platform}`}
        css={css`
          grid-template-columns: repeat(4, 1fr) auto;
        `}
      >
        <div className="timeline-item">
          <div className={`timeline__ui ${1 <= stage && "timeline__ui--done"}`}>
            1
          </div>
          <h5 className="timeline__phase">Checking</h5>
        </div>
        <div className="timeline-item">
          <div className={`timeline__ui ${2 <= stage && "timeline__ui--done"}`}>
            2
          </div>
          <h5 className="timeline__phase">Buidling</h5>
        </div>
        <div className="timeline-item">
          <div className={`timeline__ui ${3 <= stage && "timeline__ui--done"}`}>
            3
          </div>
          <h5 className="timeline__phase">Testing</h5>
        </div>
        <div className="timeline-item">
          <div className={`timeline__ui ${4 <= stage && "timeline__ui--done"}`}>
            4
          </div>
          <h5 className="timeline__phase">In Review</h5>
        </div>
        <div className="timeline-item">
          <div className={`timeline__ui ${5 <= stage && "timeline__ui--done"}`}>
            5
          </div>
          <h5 className="timeline__phase">Ready!</h5>
        </div>
      </Timeline>
    </div>
  )
}

const Content = ({ title, content }) => {
  return (
    <div className="px-6 py-6 border-t">
      <h3 className="text-lg font-medium text-gray-900 mb-1 leading-tight">
        {title}
      </h3>
      <div className=" text-gray-600">{content}</div>
    </div>
  )
}

export default function StatusCard(props) {
  const { applisting, platform, phase, download, smartbanner } = props
  const currentPhase = phase.toString().toLowerCase()
  const [snapshot, loading, error] = useCollection(
    firebase.firestore().collection("app_status"),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )

  if (loading) {
    return null
  }

  const STAGE_CONTENTS_BY_PLATFORM = {}

  snapshot.docs.forEach(doc => {
    STAGE_CONTENTS_BY_PLATFORM[doc.id] = doc.data()
  })

  const STAGE_ORDER = ["1", "3", "4", "5", "6", "7", "f"].reverse()

  const getStageByPhase = () => {
    switch (phase) {
      case "0":
        return 0
      case "1":
        return 1
      case "C":
      case "c":
      case "3":
        return 2
      case "4":
        return 3
      case "5":
        return 4
      case "6":
      case "7":
      case "F":
      case "f":
        return 5
    }
  }

  return (
    <div>
      <div>
        <Heading
          smartbanner={smartbanner}
          platform={platform}
          stage={getStageByPhase()}
          title={get(
            STAGE_CONTENTS_BY_PLATFORM,
            `${platform}.${currentPhase}.title`,
            ""
          )}
        />
      </div>
      <div>
        {currentPhase === "c" && (
          <Content
            title={get(
              STAGE_CONTENTS_BY_PLATFORM,
              `${platform}.${currentPhase}.title`,
              ""
            )}
            content={get(
              STAGE_CONTENTS_BY_PLATFORM,
              `${platform}.${currentPhase}.content`,
              ""
            )}
            key={`${platform}-C`}
          />
        )}
        {STAGE_ORDER.map((item, index) => {
          const currentIndex =
            currentPhase === "c"
              ? 5
              : STAGE_ORDER.findIndex(item => item === currentPhase)

          if (currentPhase === "c" && index === 5) {
            return false
          }

          if (index >= currentIndex) {
            // let content = STAGE_CONTENTS_BY_PLATFORM[platform][item].content
            let content = get(
              STAGE_CONTENTS_BY_PLATFORM,
              `${platform}.${item}.content`,
              ""
            )

            if (currentPhase === "c") {
              content = get(
                applisting,
                `what_is_${platform.toLowerCase()}_custom`,
                ""
              )
            }

            if (download && index === 2) {
              content = (
                <>
                  <div className="text-primary-500 font-medium">
                    DOWNLOAD LINK
                  </div>
                  <a
                    href={download}
                    target="_blank"
                    className="break-all inline-block underline mb-4"
                  >
                    {download}
                  </a>
                  <div>
                    {get(
                      STAGE_CONTENTS_BY_PLATFORM,
                      `${platform}.${item}.content`,
                      ""
                    )}
                  </div>
                </>
              )
            }

            return (
              <Content
                title={get(
                  STAGE_CONTENTS_BY_PLATFORM,
                  `${platform}.${item}.title`,
                  ""
                )}
                content={content}
                key={`${platform}-${index}`}
              />
            )
          }
        })}
      </div>
    </div>
  )
}
