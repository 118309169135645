import React, { useState, useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useForm, Controller } from "react-hook-form"
import { useFirestore, useFirestoreConnect } from "react-redux-firebase"
import Page from "general/components/Page"
import Card from "general/components/Card"
import ChoiceList from "general/components/ChoiceList"
import Overlay from "general/components/Overlay"
import SkeletonBodyText from "general/components/SkeletonBodyText"
import { isUndefined, get, has } from "lodash"
import { toast } from "react-toastify"
import compareVersions from "compare-versions"
import tw from "twin.macro"

const TextOverlay = ({ title }) => {
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="relative z-20 text-xl font-semibold text-black">
        {title}
      </div>
      <Overlay light />
    </div>
  )
}

export default function SettingsPage() {
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  const shopifyUrl = get(profile, "shopify_url")
  const version = get(profile, "settings.current_version", 1)
  useFirestoreConnect([`${shopifyUrl}/settings`])
  const data = useSelector(state => state.firestore.data)
  const settings = has(data, shopifyUrl) ? data[shopifyUrl].settings : undefined

  console.log("LOAD DATA", data)
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      user_account: "optional",
      support_quick_add: "false",
      support_order_note: "false",
    },
  })

  const [saveLoading, setSaveLoading] = useState(false)

  useEffect(() => {
    if (settings) {
      console.log(settings)
      const support_quick_add = get(
        settings,
        "mobile_remote_config.support_quick_add"
      )
        ? get(settings, "mobile_remote_config.support_quick_add")
        : false
      const support_order_note = get(
        settings,
        "mobile_remote_config.support_order_note"
      )
        ? get(settings, "mobile_remote_config.support_order_note")
        : false

      reset({
        user_account: `${get(settings, "user_account", "optional")}`,
        support_quick_add: `${support_quick_add}`,
        support_order_note: `${support_order_note}`,
      })
    }
  }, [settings, data, reset])

  const onSubmit = data => {
    setSaveLoading(true)
    const { user_account, support_quick_add, support_order_note } = data
    const batch = firestore.batch()
    const settingsRef = firestore.collection(shopifyUrl).doc("settings")
    const usersRef = firestore.collection("user_list").doc(shopifyUrl)

    batch.set(settingsRef, { user_account }, { merge: true })
    const mobile_remote_config = {
      support_quick_add: support_quick_add === "true",
      support_order_note: support_order_note === "true",
    }

    batch.set(
      settingsRef,
      {
        mobile_remote_config,
      },
      { merge: true }
    )

    batch.set(
      usersRef,
      {
        settings: {
          user_account,
        },
      },
      { merge: true }
    )

    batch
      .commit()
      .then(() => {
        setSaveLoading(false)
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        })
        toast("Settings saved")
      })
      .catch(error => {
        setSaveLoading(false)
        toast(error)
      })
  }

  return (
    <Page
      title="App Settings"
      primaryAction={{
        content: "Save",
        loading: saveLoading,
        onAction: () => {
          handleSubmit(onSubmit)()
        },
      }}
    >
      <form>
        <div className="">
          {isUndefined(settings) ? (
            <Card title="User Accounts">
              <SkeletonBodyText />
            </Card>
          ) : (
            <div className="grid grid-cols-1 gap-4">
              <Card title="User Accounts">
                <p className="mb-2">
                  Make sure this matches the setting on your Shopify dashboard.
                </p>

                {[
                  { value: "disabled", label: "Disabled" },
                  { value: "optional", label: "Optional" },
                  { value: "required", label: "Required" },
                ].map(item => {
                  return (
                    <label css={tw`block py-1 text-gray-800`} key={item.value}>
                      <input
                        ref={register}
                        css={tw`form-radio`}
                        type="radio"
                        name={"user_account"}
                        value={item.value}
                      />
                      <span css={tw`ml-2`}>{item.label}</span>
                    </label>
                  )
                })}
              </Card>
              {compareVersions.compare(`${version}`, "1.2", ">=") ? (
                <>
                  <Card title="Quick Add Button">
                    <p className="mb-2">
                      This will let users add products to cart from any screen.
                    </p>
                    {[
                      { value: true, label: "Enabled" },
                      { value: false, label: "Disabled" },
                    ].map(item => {
                      return (
                        <label
                          css={tw`block py-1 text-gray-800`}
                          key={`${item.label}-${item.value}`}
                        >
                          <input
                            ref={register}
                            css={tw`form-radio`}
                            type="radio"
                            name="support_quick_add"
                            value={item.value}
                          />
                          <span css={tw`ml-2`}>{item.label}</span>
                        </label>
                      )
                    })}
                  </Card>
                  <Card title="Order Notes">
                    <p className="mb-2">
                      You can use order notes to collect special instructions
                      from customers about how to prepare and deliver an order.
                    </p>
                    {[
                      { value: true, label: "Enabled" },
                      { value: false, label: "Disabled" },
                    ].map(item => {
                      return (
                        <label
                          css={tw`block py-1 text-gray-800`}
                          key={`support_order_note-${item.label}`}
                        >
                          <input
                            ref={register}
                            className="form-radio"
                            type="radio"
                            name="support_order_note"
                            value={item.value}
                          />
                          <span css={tw`ml-2`}>{item.label}</span>
                        </label>
                      )
                    })}
                  </Card>
                </>
              ) : (
                <>
                  <Card title="Quick Add Button">
                    <p className="mb-2">
                      This will let users add products to cart from any screen.
                    </p>
                    <ChoiceList
                      // onChange={handleOn}
                      name="quickadd"
                      selected={["disabled"]}
                      ref={register}
                      choices={[
                        { value: "enabled", label: "Enabled" },
                        { value: "disabled", label: "Disabled" },
                      ]}
                    />
                    <TextOverlay title={"Coming soon!"} />
                  </Card>
                  <Card title="Order Notes">
                    <p className="mb-2">
                      You can use order notes to collect special instructions
                      from customers about how to prepare and deliver an order.
                    </p>
                    <ChoiceList
                      name="ordernotes"
                      selected={["disabled"]}
                      ref={register}
                      choices={[
                        { value: "enabled", label: "Enabled" },
                        { value: "disabled", label: "Disabled" },
                      ]}
                    />
                    <TextOverlay title={"Coming soon!"} />
                  </Card>
                </>
              )}
            </div>
          )}
        </div>
      </form>
    </Page>
  )
}
