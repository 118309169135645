import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { useSelector } from "react-redux"
import tw from "twin.macro"
import { get, isNull } from "lodash"
import noImage from "assets/images/design/block/noimage.png"
import { useBlockImage } from "reaverUtil/image"
import ProductPrice from "design/components/BlockItem/ProductPrice"
import WarningOverlay from "design/components/WarningOverlay"
import { getCurrencySymbol } from "reaverUtil/currency"
import { setBlockValid } from "design/redux"

const Wrapper = styled.div`
  ${tw`bg-white py-8`}

  h2 {
    ${tw`italic font-serif text-lg font-medium`}
  }

  hr {
    ${tw`border-black bg-black my-4`}
    width: 30px;
  }
`
const Thumbnail = styled.div`
  ${tw`bg-gray-100 rounded-full border border-gray-200 overflow-hidden relative`}
  padding-bottom: 100%;
`

const ProductItem = ({
  thumbnail,
  title,
  symbol,
  productTitle,
  price,
  compareAtPrice,
}) => {
  return (
    <Wrapper>
      <div
        css={[
          tw`relative mx-auto mb-6`,
          css`
            width: 55%;
          `,
        ]}
      >
        <Thumbnail>
          {thumbnail && (
            <img
              src={thumbnail}
              alt=""
              css={tw`absolute inset-0 w-full h-full object-cover`}
            />
          )}
        </Thumbnail>
      </div>
      <div css={tw`text-center  px-4 overflow-hidden`}>
        <h2>{title}</h2>
        <hr css={tw`mx-auto`} />
        <div>
          <h3 css={tw`text-gray-800 text-sm mb-2 truncate`}>{productTitle}</h3>
        </div>
        <ProductPrice
          symbol={symbol}
          price={price}
          compareAtPrice={compareAtPrice}
        />
      </div>
    </Wrapper>
  )
}

export default function ProductBlock(props) {
  const { title, product_id, preview, id } = props
  const images = useBlockImage()
  const products = useSelector(state => state.shopify.products.byId)
  const currencyCode = useSelector(
    ({ firebase }) => firebase.profile.shop_owner.currency
  )
  const currencySymbol = getCurrencySymbol(currencyCode)

  const [item, setItem] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (product_id) {
      const nextProduct = get(products, product_id, null)
      const hasDeletedProduct = isNull(nextProduct)

      hasDeletedProduct && setBlockValid({ id, isValid: false })
      setError(hasDeletedProduct)
      return setItem(nextProduct)
    }

    return setItem(null)
  }, [product_id])

  const renderContent = () => {
    if (preview) {
      return (
        <ProductItem
          thumbnail={images["product-hover-shoes"].src}
          symbol={currencySymbol}
          productTitle={"Pink Metalic Mid Heel Pumps"}
          title="Ultra Luxe. Limited Inventory."
          price="125"
          compareAtPrice="195"
        />
      )
    }

    if (item) {
      return (
        <ProductItem
          thumbnail={get(item, "images.edges[0].node.src", noImage)}
          productTitle={get(item, "title", "Untitled")}
          title={title}
          symbol={currencySymbol}
          price={get(item, "variants.edges[0].node.price", 0)}
          compareAtPrice={get(
            item,
            "variants.edges[0].node.compareAtPrice",
            undefined
          )}
        />
      )
    }

    return (
      <ProductItem
        thumbnail={images["product-pher-diamond"].src}
        productTitle="Product Name"
        title={title}
        symbol={currencySymbol}
        price={100}
        compareAtPrice={150}
      />
    )
  }

  return (
    <div className="relative">
      {renderContent()}
      {error && <WarningOverlay />}
    </div>
  )
}

ProductBlock.defaultProps = {
  title: "Marketing Message",
}
