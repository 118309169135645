import React, { useRef, useState, useEffect } from "react"
import { navigate } from "gatsby"
import tw, { styled, css } from "twin.macro"
import { useSelector } from "react-redux"
import { get } from "lodash"
import firebase from "gatsby-plugin-firebase"
import Progress from "react-progressbar"
import Slider from "react-slick"
import Button from "general/components/Button"

import Install1 from "assets/images/install/install-1.jpg"
import Install2 from "assets/images/install/install-2.jpg"
import Install3 from "assets/images/install/install-3.jpg"
import Install4 from "assets/images/install/install-4.jpg"
import Install5 from "assets/images/install/install-5.jpg"
import Install6 from "assets/images/install/install-6.jpg"

import { track } from "services/analytics"

const PROGRESS_BY_INDEX = [55, 75, 85, 90, 95, 97]
const PROGRESS_MESSAGE_BY_INDEX = [
  "Let's get started! Auto Syncing",
  "Connecting with Your Store...",
  "Creating mobile app screen...",
  "Linking your products to JCurve...",
  "Generating your app basic colors palette...",
  "",
]

const SlideItem = styled.div`
  height: 100vh;
  width: 100vw;

  .slide-item__cover {
    width: 100vw;
    height: 100vh;
    object-fit: contain;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: ${({ background }) =>
    background ? `url(${background})` : "none"};
`

function SyncPage() {
  const auth = useSelector(state => state.firebase.auth)
  const shop = useSelector(state => state.firebase.profile.shopify_url)

  const slickRef = useRef(null)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [duration, setDuration] = useState(8800)

  const [hideProgress, setHideProgress] = useState(false)
  const [flag, setFlag] = useState("sent")
  const [progress, setProgress] = useState(0)

  const settings = {
    dots: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false,
  }

  useEffect(() => {
    if (auth) {
      track("VIEW_PAGE_SLIDING")

      const unmount = firebase
        .firestore()
        .collection("user_list")
        .doc(auth.uid)
        .onSnapshot(doc => {
          const data = doc.data()

          const sales_channel_on = get(
            data,
            "settings.sales_channel_on",
            undefined
          )

          if (sales_channel_on) {
            setFlag(sales_channel_on)
          }
        })
      return () => {
        return unmount
      }
    }
  }, [])

  const initialized = () => {
    const { uid } = auth

    if (!uid) {
      console.warn("User Uundefined")
      return false
    }

    const DB = firebase.firestore()
    const batch = DB.batch()
    const userRef = DB.collection("user_list").doc(uid)
    const shopRef = DB.collection(shop).doc("shopify")

    batch.update(userRef, { "shopify.appInitialized": true })
    batch.update(shopRef, { appInitialized: true })

    batch
      .commit()
      .then(() => navigate("/app/design"))
      .catch(error => window.Sentry.captureException(error))
    // const userRef = firebase.firestore().collection()
    // const ref = firebase.firestore().collection(shop).doc("shopify")
    // firebase.firestore().runTransaction(tr)
    // ref.set({ appInitialize: true }, { merge: true }).then(() => {
    //   navigate("/app/design")
    // })
  }

  return (
    <div className="h-screen relative">
      <Slider
        ref={slickRef}
        style={{ height: "100vh", background: "#fff" }}
        {...settings}
        onInit={() => setProgress(PROGRESS_BY_INDEX[0])}
        beforeChange={index => {
          setCurrentSlideIndex(index + 1)

          if (index === 4) {
            setHideProgress(true)
          }
        }}
        afterChange={index => {
          if (index === 4) {
            slickRef.current.slickPause()

            if (flag === "done") {
              setProgress(100)
              setDuration(2000)
              setTimeout(() => {
                initialized()
              }, 2500)

              return false
            }

            slickRef.current.slickPlay()
          }

          setProgress(PROGRESS_BY_INDEX[index])

          if (index === 5) {
            setProgress(100)
          }
        }}
      >
        <SlideItem>
          <img
            src={Install1}
            alt=""
            className="slide-item__cover h-screen mx-auto"
          />
        </SlideItem>
        <SlideItem>
          <img
            src={Install2}
            alt=""
            className="slide-item__cover h-screen mx-auto"
          />
        </SlideItem>
        <SlideItem>
          <img
            src={Install3}
            alt=""
            className="slide-item__cover h-screen mx-auto"
          />
        </SlideItem>
        <SlideItem>
          <img
            src={Install4}
            alt=""
            className="slide-item__cover h-screen mx-auto"
          />
        </SlideItem>
        <SlideItem>
          <img
            src={Install5}
            alt=""
            className="slide-item__cover h-screen mx-auto"
          />
        </SlideItem>
        <SlideItem background={Install6}>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/fingercart.appspot.com/o/web%2Fcommon%2Ftenor.gif?alt=media&token=44084cf5-be9d-483b-a658-1032e2f9192b"
            css={css`
              display: block;
              position: relative;
              margin: auto;
              top: 50%;
              transform: translateY(-94%);
              width: 25.97656vw;
              height: 25.97656vw;
              object-fit: contain;

              @media (min-width: 940px) {
                width: 34.635416vh;
                height: 34.635416vh;
              }
            `}
          />
          <div
            className="relative text-center left-0 right-0"
            css={css`
              top: 50%;
              transform: translateY(-94%);
              @media (min-width: 940px) {
                transform: translateY(-130%);
              }
            `}
          >
            <Button primary size="large" onClick={() => initialized()}>
              Go Ahead!
            </Button>
          </div>
        </SlideItem>
      </Slider>
      {!hideProgress && (
        <div
          className="absolute top-0 left-0 w-full z-30 h-screen"
          css={css`
            width: 100vw;
          `}
        >
          <div
            css={css`
              width: 50vw;
              margin: 0 auto;
              padding-top: 76vh;
            `}
          >
            <div
              css={css`
                .progressbar-container {
                  background: #eee;
                }
                .progressbar-progress {
                  transition-property: "linear";
                }
              `}
            >
              <Progress
                completed={progress}
                animation={duration}
                color={`#D2045A`}
              />
            </div>
            <p className="mt-4 text-center text-gray-800">
              {PROGRESS_MESSAGE_BY_INDEX[currentSlideIndex]}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

// <div className="mx-auto px-4 h-screen flex items-center">
// <div className="relative">
//   <div className="pb-3/4" />
//   <img
//     src={Install1}
//     alt=""
//     className="w-full absolute inset-0 object-cover"
//   />

//   <div className="text-center">
//     <Progress completed={75} />
//     <p>Let's get started! Auto Syncing...</p>
//   </div>
// </div>
// </div>

export default function ImageLoad() {
  const [load, setLoad] = useState(true)

  useEffect(() => {
    new Promise((resolve, reject) => {
      const img = new Image()
      img.src = Install1
      img.onload = () => resolve()
      img.onerror = () => reject()
    }).then(() => setLoad(false))
  }, [])

  return load ? null : <SyncPage />
}
