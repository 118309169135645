import React, { Fragment } from "react"
import tw, { styled, css } from "twin.macro"
import { isEqual } from "lodash"
import compareVersions from "compare-versions"
import { useSelector } from "react-redux"
import { Droppable, Draggable } from "react-beautiful-dnd"

import BlockTypeItem from "./BlockTypeItem"
import BlockItem from "../BlockItem"

const Wrapper = styled.div.attrs({
  // className: "pb-4",
})``
const CloneBlockTypeItem = styled.div`
  & ~ div {
    transform: none !important;
    transition: none !important;
  }
  & ~ [data-rbd-placeholder-context-id] {
    display: none !important;
  }
`

function BlockList(props) {
  const { types } = props
  const version = useSelector(
    state => state.firebase.profile.settings.current_version
  )

  const typeList = Object.keys(types)

  return (
    <Droppable
      renderClone={(provided, snapshot, rubric) => {
        const actionId = typeList[rubric.source.index]

        return (
          <div
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            className={snapshot.isDragging ? "dragging" : ""}
            css={
              snapshot.isDragging
                ? css`
                    width: 360px !important;
                  `
                : ""
            }
            style={provided.draggableProps.style}
          >
            <BlockItem
              actionId={actionId}
              type={types[actionId].type}
              info={types[actionId].info}
              isValid={false}
            />
          </div>
        )
      }}
      droppableId="BLOCKS"
      isDropDisabled={true}
    >
      {(provided, snapshot) => (
        <Wrapper ref={provided.innerRef} css={tw`relative`}>
          {typeList.map((type, index) => {
            const shouldRenderClone = type === snapshot.draggingFromThisWith

            if (!(version >= 1.2) && type === "slideshow") {
              return false
            }

            if (!(version >= 1.1) && type === "countdown") { 
              return false
            }

            return (
              <Fragment key={type}>
                {shouldRenderClone ? (
                  <CloneBlockTypeItem>
                    <BlockTypeItem
                      actionId={type}
                      name={types[type].name}
                      type={types[type].type}
                      info={types[type].info}
                      isValid={false}
                    />
                  </CloneBlockTypeItem>
                ) : (
                  <Draggable draggableId={type} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <BlockTypeItem
                          actionId={type}
                          name={types[type].name}
                          type={types[type].type}
                          info={types[type].info}
                          isValid={false}
                        />
                      </div>
                    )}
                  </Draggable>
                )}
              </Fragment>
            )
          })}
          {provided.placeholder}
        </Wrapper>
      )}
    </Droppable>
  )
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps, nextProps)
}
export default React.memo(BlockList, areEqual)
