import tw, { styled } from "twin.macro"
import { ToastContainer } from "react-toastify"

export default styled(ToastContainer).attrs({
  position: "bottom-left",
  autoClose: 4000,
  hideProgressBar: true,
  newestOnTop: false,
  pauseOnFocusLoss: true,
})`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    ${tw`bg-black text-white rounded-md`}
    min-height: auto;
    background: rgba(0, 0, 0, 0.8);

    svg {
      fill: white;
    }
  }
  .Toastify__toast--error {
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
    ${tw`px-2 py-2`}
  }
  .Toastify__progress-bar {
  }
`
