import React, { useState } from "react"
import PropTypes from "prop-types"
import tw, { styled, css } from "twin.macro"
import { useStaticQuery } from "gatsby"
import { get, find, isNull, isEmpty } from "lodash"
import MobileStatusbar from "assets/svgs/mobile-statusbar.svg"
import BrandLogoPlaceholder from "assets/svgs/brand-placeholder.svg"
import IconMenu from "assets/images/design/icon-menu.png"
import IconSearch from "assets/images/design/icon-search.png"

const Wrapper = styled.div`
  ${tw`relative z-20`}

  background-color: ${({ bgColor }) => {
    return bgColor ? bgColor : "#fff"
  }};
`
const Title = styled.div`
  ${tw`text-center flex flex-col items-center justify-center`}
  height: 46px;
  svg {
    ${tw`inline-block`}
    height: 40px;
  }
  & > img {
    width: auto;
    height: 40px;
  }
`
const Menu = styled.div`
  ${tw`inline-flex items-center justify-center`}
  width: 34px;
  height: 34px;

  & > img {
    width: auto;
    height: 20px;
  }
`
const Search = styled.div`
  ${tw`inline-flex items-center justify-center`}
  width: 34px;
  height: 34px;

  & > img {
    width: auto;
    height: 20px;
  }
`
const Cart = styled.div`
  ${tw`inline-flex items-center justify-center relative`}
  width: 34px;
  height: 34px;

  & > img {
    width: auto;
    height: 20px;
  }

  .badge {
    ${tw`rounded-full inline-block absolute right-0 z-20  text-white text-center font-bold`}
    top: 4px;
    width: 13px;
    height: 13px;
    font-size: 10px;
    line-height: 13px;
    color: white;
    background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.colors.primary[400]};
  }
`

const Left = styled.div`
  ${tw`absolute inline-block `}
  top: 50%;
  left: 0.5rem;
  margin-top: -17px;
  font-size: 0;
`
const Right = styled.div`
  ${tw`absolute inline-block `}
  top: 50%;
  right: 0.5rem;
  margin-top: -17px;
  font-size: 0;
`

const BrandImage = styled.div``

const BrandLogo = ({ source }) => {
  return (
    <BrandImage>
      <img
        src={source}
        alt="Brand"
        className={`inline-block w-auto h-full object-contain`}
        css={css`
          object-position: 50% 50%;
          width: 113px;
          height: 40px;
          object-position: 50% 50%;
        `}
      />
    </BrandImage>
  )
}

export default function ScreenHeader({
  primaryColor,
  accentColor,
  logo,
  cartIconType,
}) {
  const data = useStaticQuery(graphql`
    query {
      cartIcons: allFile(
        filter: { extension: {}, relativePath: { regex: "/design/cart/" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                src
                originalName
              }
            }
          }
        }
      }
    }
  `)

  const selectedIconImage = find(data.cartIcons.edges, icon => {
    return (
      icon.node.childImageSharp.fluid.originalName ===
      "icon-" + cartIconType + ".png"
    )
  })

  const cartIconTypeImage = get(
    selectedIconImage,
    "node.childImageSharp.fluid.src",
    ""
  )

  const logoUrl = get(logo, "image_url", null)

  return (
    <Wrapper bgColor={primaryColor}>
      <MobileStatusbar />
      <div css={tw`relative`}>
        <Left>
          <Menu>
            <img src={IconMenu} alt="" />
          </Menu>
        </Left>
        <Title>
          {!(isNull(logoUrl) || isEmpty(logoUrl)) ? (
            <BrandLogo source={logo.image_url} />
          ) : (
            <BrandLogoPlaceholder />
          )}
        </Title>
        <Right>
          <Search>
            <img src={IconSearch} alt="" />
          </Search>
          <Cart bgColor={accentColor} type={cartIconType}>
            <img src={cartIconTypeImage} alt="" />
            <span className="badge">3</span>
          </Cart>
        </Right>
      </div>
    </Wrapper>
  )
}

ScreenHeader.defaultProps = {
  cartIconType: "bag-filled",
}
