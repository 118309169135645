import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
  useRef,
} from "react"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import { get, isUndefined, omit, find } from "lodash"
import { useLazyQuery, gql } from "@apollo/client"
import produce from "immer"
import { useSelector } from "react-redux"
import { isURL, addHttp } from "reaverUtil/form"
import Modal from "general/components/Modal"
import ChoiceList from "general/components/ChoiceList"
import Button from "general/components/Button"
import TextField from "general/components/TextField"
import VerifyLinkButton from "form/components/VerifyLinkButton"
import FormBlockImageUpload from "design/components/FormBlockImageUpload"
import SearchAuto from "shopify/components/SearchAuto"
import noImage from "assets/images/design/block/noimage.png"
import WarningImage from "assets/images/design/icon-menu-warning-sqaure.png"
import compareVersions from "compare-versions"
import { toast } from "react-toastify"

const TabButton = styled.button`
  ${tw`flex-1 py-2 px-1 font-semibold`}
`

const TYPES = [
  { id: "view_collection", title: "Collection" },
  { id: "view_web", title: "Website" },
  { id: "view_static", title: "No link" },
]

const GET_COLLECTIONS_BY_IDS = gql`
  query getCollectionsByIds($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on Collection {
        id
        title
        image(maxWidth: 150, maxHeight: 150, crop: CENTER) {
          transformedSrc(maxWidth: 150, maxHeight: 150, crop: CENTER)
          originalSrc
        }
      }
    }
  }
`

export default function EditCustomBannerBlockModal(props) {
  const {
    isOpen,
    onRequestClose,
    onAction,
    meta,
    updateCollectionsSuccess,
    current_version,
  } = props
  const uploadRef = useRef(null)
  const [item, setItem] = useState({
    selected: "view_collection",
    view_collection: [],
    view_web: null,
    view_static: null,
    image_url: null,
    ratio: 0,
  })
  const [errors, setErrors] = useState({
    errors: {
      view_web: undefined,
      view_collection: undefined,
    },
  })
  const collections = useSelector(state => state.shopify.collections.byId)

  const [loadCollections, { loading, error, data }] = useLazyQuery(
    GET_COLLECTIONS_BY_IDS,
    {
      variables: {
        ids: item.view_collection,
      },
    }
  )

  useEffect(() => {
    if (meta) {
      const { action_type, action_value, image_url, ratio } = meta

      let value = {}

      if (action_type) {
        value = {
          [action_type]:
            action_type === "view_collection" ? [action_value] : action_value,
        }
      }

      const selected = action_type ? action_type : "view_collection"

      const nextItem = {
        selected,
        image_url,
        ratio,
        view_collection: [],
        view_web: "",
        view_static: "",
        ...value,
      }

      setItem(nextItem)
    }
  }, [meta])

  useEffect(() => {
    if (item.selected === "view_collection") {
      loadCollections({ variables: { ids: item.view_collection } })
    }
  }, [item.view_collection])

  const handleCollectionChange = (selected, item) => {
    const collection = get(collections, item.node.id, undefined)

    if (isUndefined(collection)) {
      updateCollectionsSuccess({ [item.node.id]: item.node })
    }

    setItem(
      produce(draft => {
        draft.view_collection = selected
      })
    )
  }

  const handleCollectionRemove = id => {
    const newCollection = item.view_collection.filter(item => item !== id)
    setItem({ ...item, view_collection: newCollection })
  }

  const handleUpload = (imageUrl, data) => {
    const { ratio } = data

    setItem({ ...item, image_url: imageUrl, ratio })
  }

  const handleChange = event => {
    setItem({ ...item, selected: event.target.value })
  }

  const handleTextChange = event => {
    const text = event.target.value

    if (errors.view_web) {
      setErrors(
        produce(draft => {
          draft.view_web = undefined
        })
      )
    }

    setItem(
      produce(draft => {
        draft.view_web = text
      })
    )
  }

  const handleTypeChange = value => {
    setItem(
      produce(draft => {
        draft.selected = value
      })
    )
  }

  const renderCollection = () => {
    return (
      <div>
        <SearchAuto
          resourceType="collections"
          onChange={handleCollectionChange}
          selected={item.view_collection}
          error={errors.view_collection && errors.view_collection}
          onInputFocus={() =>
            errors.view_collection &&
            setErrors(
              produce(draft => {
                draft.view_collection = undefined
              })
            )
          }
        />
        <div>
          {!loading &&
            data &&
            data.nodes.length > 0 &&
            item.view_collection.map(itemId => {
              const item = find(data.nodes, obj => obj && obj.id === itemId)

              if (!item) {
                return (
                  <div
                    key={itemId}
                    css={tw`p-2 bg-red-100 flex flex-row w-full items-center justify-between mt-2`}
                  >
                    <div
                      css={[
                        tw`flex flex-row items-center truncate `,
                        css`
                          max-width: 360px;
                          min-width: 0;
                        `,
                      ]}
                    >
                      <div css={tw`w-12 relative mr-4`}>
                        <div css={tw`pb-1/1`}></div>
                        <img
                          css={tw`absolute inset-0 w-full h-full object-cover`}
                          src={WarningImage}
                          alt={"Deleted collection"}
                        />
                      </div>
                      <h3
                        css={[
                          tw`text-gray-800 font-normal truncate flex-1 pr-4`,
                          css`
                            min-width: 0;
                          `,
                          tw`text-red-600`,
                        ]}
                      >
                        Deleted collection
                      </h3>
                    </div>
                    <div css={tw``}>
                      <div
                        css={tw`text-xs text-gray-600 hover:bg-gray-100 w-6 h-6 inline-flex items-center justify-center cursor-pointer rounded-sm transition duration-500 `}
                        onClick={() => handleCollectionRemove(itemId)}
                      >
                        <span className="icon-cancel"></span>
                      </div>
                    </div>
                  </div>
                )
              }

              return (
                <div
                  key={itemId}
                  css={tw`flex flex-row w-full items-center justify-between mt-2`}
                >
                  <div
                    css={[
                      tw`flex flex-row items-center truncate`,
                      css`
                        max-width: 360px;
                        min-width: 0;
                      `,
                    ]}
                  >
                    <div css={tw`w-12 relative mr-4`}>
                      <div css={tw`pb-1/1`}></div>
                      <img
                        css={tw`absolute inset-0 w-full h-full object-cover`}
                        src={get(item, "image.transformedSrc", noImage)}
                        alt={get(item, "title", "Deleted collection")}
                      />
                    </div>
                    <h3
                      css={[
                        tw`text-gray-800 font-normal truncate flex-1 pr-4`,
                        css`
                          min-width: 0;
                        `,
                      ]}
                    >
                      {get(item, "title")}
                    </h3>
                  </div>
                  <div css={tw``}>
                    <div
                      css={tw`text-xs text-gray-600 hover:bg-gray-100 w-6 h-6 inline-flex items-center justify-center cursor-pointer rounded-sm transition duration-500 `}
                      onClick={() => handleCollectionRemove(item.id)}
                    >
                      <span className="icon-cancel"></span>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    )
  }
  const renderWeb = () => {
    return (
      <div>
        <TextField
          type="url"
          name="url"
          placeholder="https://yourdomainhere/page"
          value={item.view_web}
          onChange={handleTextChange}
          error={errors.view_web && errors.view_web}
          connectedRight={<VerifyLinkButton url={item.view_web} />}
        />
      </div>
    )
  }
  const renderStatic = () => {
    return <div>This function is for image banner purposes only.</div>
  }

  const handlePrimary = () => {
    const action_type = item.selected

    if (!item.image_url) {
      return toast("Please upload a image.")
    }

    let isValid = true;

    switch (action_type) {
      case "view_collection":

        item.view_collection.forEach(itemId => {
          const selectedCollection = find(data.nodes, obj => obj && obj.id === itemId)

          if (!selectedCollection) {
            setErrors(
              produce(draft => {
                draft.view_collection = "Please select a new collection."
              })
            )

            return isValid = false
          }
        })

        if (item.view_collection.length === 0) {
          setErrors(
            produce(draft => {
              draft.view_collection = "Please select a collection."
            })
          )

          return isValid = false
        }
        break
      case "view_web":
        if (item.view_web.length === 0) {
          setErrors(
            produce(draft => {
              draft.view_web = "Please fill out website address field."
            })
          )

          return isValid = false
        }

        // if (!isURL(item.view_web)) {
        //   return setErrors(
        //     produce(draft => {
        //       draft.view_web = "Please fill out valid website address."
        //     })
        //   )
        // }

        break
      case "view_static":
      default:
        break
    }

    if (!isValid) {
      return false;
    }

    let action_value = item[action_type]

    if (action_type === "view_collection") {
      action_value = get(item[action_type], 0, undefined)
    }

    if (action_type === "view_web") {
      action_value = addHttp(action_value)
    }

    let validData = {
      ratio: item.ratio,
      image_url: item.image_url,
      action_type,
      action_value,
    }

    if (action_type === "view_static") {
      validData = omit(validData, "action_value")
    }

    onAction(validData)
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      size="lg"
      title={"Add a Custom Image Banner"}
      subtitle={"Show your image banner! Link to the collection or web page."}
      primaryAction={{ content: "Save", onAction: () => handlePrimary() }}
    >
      <div css={tw`flex flex-row flex-wrap`}>
        <div css={tw`flex-1 py-4 px-6 bg-gray-100`}>
          <div
            css={[
              tw`mx-auto`,
              css`
                max-width: 360px;
              `,
            ]}
          >
            <FormBlockImageUpload
              source={item.image_url}
              onAction={handleUpload}
              ref={uploadRef}
            />
            <p css={tw`pt-2 text-gray-800 text-sm`}>
              Min 500 x 50 to Max 2,000 x 2,000 pixels
            </p>
            <div
              css={tw`text-center mt-4 flex flex-row flex-wrap items-center justify-center`}
            >
              <div css={tw`mx-1`}>
                <Button
                  primary
                  size="slim"
                  onClick={() => uploadRef.current.click()}
                >
                  {item.image_url ? `Replace image` : `Upload image`}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div css={tw`flex-1 px-6 py-4 border-l border-gray-300`}>
          <ChoiceList
            title="Link type"
            choices={[
              {
                label: "Collection",
                value: "view_collection",
                renderChildren: renderCollection,
              },
              {
                label: "Web Page",
                value: "view_web",
                renderChildren: renderWeb,
              },
              // rollback 가능성으로 인해 임시로 version을 분리하는 부분
              ...(current_version >= 1.1
                ? [
                    {
                      label: "No link",
                      value: "view_static",
                      renderChildren: renderStatic,
                    },
                  ]
                : []),
            ]}
            selected={item.selected}
            onChange={handleTypeChange}
          />
        </div>
      </div>
    </Modal>
  )
}
