import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import firebase from "gatsby-plugin-firebase"
import tw, { styled, css } from "twin.macro"
import { get, isNull } from "lodash"
import IconLive from "assets/svgs/icons/icon-live.svg"

const Wrapper = styled.div`
  ${tw`flex flex-row items-center`}
  text-shadow: 0 0 9px #ffd36b;
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: #fff8e7;
`

export default function SyncFlag() {
  const auth = useSelector(({ firebase }) => firebase.auth)
  const profile = useSelector(({ firebase }) => firebase.profile)
  const shop = profile.shopify_url
  const [flag, setFlag] = useState(null)

  useEffect(() => {
    if (shop) {
      const unmount = firebase
        .firestore()
        .collection("user_list")
        .doc(auth.uid)
        .onSnapshot(doc => {
          const { settings } = doc.data()
          const sales_channel_on = get(settings, "sales_channel_on", undefined)

          if (sales_channel_on) {
            setFlag(sales_channel_on)
          }
        })
      return () => {
        return unmount
      }
    }
  }, [shop])

  if (isNull(flag)) {
    return null
  }

  if (flag === "done") {
    return (
      <Wrapper>
        <div
          css={css`
            margin-right: 2px;
          `}
        >
          <IconLive />
        </div>
        <span>SYNCED</span>
      </Wrapper>
    )
  }

  return <Wrapper>Store Syncing…</Wrapper>
}
