import React from "react"
import { navigate } from "gatsby"
import tw, { styled, css } from "twin.macro"
import Button from "general/components/Button"

export default function PageActions({ actions }) {
  console.log('pageactions', actions)
  return (
    <div className="flex flex-row justify-end border-t border-gray-300 py-4 mt-4">
      <div className="-mx-2 flex flex-row flex-wrap">
        {actions &&
          actions.map((action, index) => {
            return (
              <div className="px-2" key={`page-action-${index}`}>
                <Button
                  type="button"
                  primary={action.primary}
                  disabled={action.disabled}
                  onClick={action.onAction}
                  loading={action.loading}
                >
                  {action.content}
                </Button>
              </div>
            )
          })}
      </div>
    </div>
  )
}
