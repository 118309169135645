import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import DatePicker from "react-datepicker"

const Picker = React.forwardRef((props, ref) => {
  const {
    selected,
    showTimeSelect,
    timeFormat,
    timeIntervals,
    minDate,
    minTime,
    maxTime,
    showDisabledMonthNavigation,
    showPopperArrow,
    dateFormat,
    todayButton,
    onChange,
    label,
    error,
    shouldCloseOnSelect,
  } = props

  return (
    <div css={tw`block`}>
      {label && (
        <span css={tw`block text-base text-gray-900 font-medium mb-2`}>
          {label}
        </span>
      )}
      <div css={tw`flex flex-row`}>
        <DatePicker
          ref={ref}
          selected={selected}
          onChange={onChange}
          showTimeSelect={showTimeSelect}
          timeFormat={timeFormat}
          timeIntervals={timeIntervals}
          minDate={minDate}
          minTime={minTime}
          maxTime={maxTime}
          showDisabledMonthNavigation={showDisabledMonthNavigation}
          showPopperArrow={showPopperArrow}
          dateFormat={dateFormat}
          todayButton={todayButton}
          shouldCloseOnSelect={true}
          popperPlacement="bottom-start"
        />
      </div>
      {error && <span css={tw`block mt-1 text-red-500`}>{error}</span>}
    </div>
  )
})

Picker.propTypes = {
  type: PropTypes.string,
}

Picker.defaultProps = {
  type: "text",
}

export default Picker
