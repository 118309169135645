import React from "react"
import tw, { styled } from "twin.macro"

const Wrapper = styled.div`
  height: 80px;
  width: 80px;
  position: absolute;
  right: -4px;
  top: -4px;
  overflow: hidden;
  ${tw`z-20`}
  .cover-ribbon-inside {
    ${tw`absolute  text-center py-1 uppercase font-semibold bg-primary-500 text-white`}
    transform: rotate(45deg);
    right: -26px;
    top: 14px;
    min-width: 100px;
    font-size: 10px;
    background: #7ed6df;
  }
  .cover-ribbon-inside:before {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid ${({ theme }) => theme.colors.primary[800]};
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    top: 22px;
    transform: rotate(-45deg);
  }
  .cover-ribbon-inside:after {
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-left: 4px solid ${({ theme }) => theme.colors.primary[800]};
    border-bottom: 4px solid transparent;
    position: absolute;
    left: 92%;
    right: 0;
    top: 20px;
    bottom: 0;
    content: "";
    transform: rotate(-45deg);
  }
`

export default function Ribbon({ children }) {
  return (
    <Wrapper>
      <span className="cover-ribbon-inside">{children}</span>
    </Wrapper>
  )
}
