import React, { useState, useEffect } from "react"
import { connect, useSelector } from "react-redux"
import { isLoaded, isEmpty } from "react-redux-firebase"
import { get } from "lodash"

import FeedbackModal from "modal/components/FeedbackModal"
import Editor from "design/containers/Editor"
import StateListener from "design/containers/StateListener"
import ImgSuccess from "assets/images/feedback/handshake.png"

import { getDesign } from "design/redux"
import usePrevious from "reaverUtil/usePrevious"
import { navigate } from "gatsby"
import moment from "moment"
import { track } from "services/analytics"

function DesignPage(props) {
  const { getDesign } = props
  const profile = useSelector(state => state.firebase.profile)
  const isMultipage = get(profile, "settings.multipage", false)
  const prevIsMultipage = usePrevious(isMultipage)

  const queryParams = window.location.search
  const urlParams = new URLSearchParams(queryParams)
  const payment = urlParams.get("payment")

  const [paymentFeedback, setPaymentFeedback] = useState(payment === "success")

  // useEffect(() => {
  //   window.onbeforeunload = e => {
  //     const message = "Are you sure?"
  //     e.returnValue = message
  //     return message
  //   }
  // }, [])
  useEffect(() => {
    if (paymentFeedback) {
      const dateString = moment().format("LLL")
      window.Intercom("update", { subscribed_at: dateString })
      track("SUBSCRIBED", { subscribed_at: dateString })
      track("VIEW_POPUP_THANKYOU")

      if (urlParams.get("upgrade") === "true") {
        window.Intercom("update", { upgraded_at: dateString })
        track("UPGRADED", { upgraded_at: dateString })
      }
    }
  }, [])

  useEffect(() => {
    if (isMultipage !== prevIsMultipage) {
      getDesign()
    }
  }, [isMultipage])

  return (
    <>
      <Editor />
      <StateListener />
      <FeedbackModal
        isOpen={paymentFeedback}
        title={
          <span>
            Thank you for choosing the JCurve <br />
            as your mobile partner!
          </span>
        }
        source={ImgSuccess}
        onRequestClose={() => setPaymentFeedback(false)}
        primaryAction={{
          content: "GO TO MY APPS",
          onAction: () => {
            navigate("/app/listing")
            setPaymentFeedback(false)
          },
        }}
      />
    </>
  )
}

const mapDispatchToProps = { getDesign }

export default connect(undefined, mapDispatchToProps)(DesignPage)
