import React from "react"
import tw, { css, styled } from "twin.macro"
import { connect } from "react-redux"

import Card from "general/components/Card"
import Button from "general/components/Button"

import BgHide from "assets/images/listing/hide-cover.png"
import IconApple from "assets/images/pricing/icon-apple.png"

import { openDialog } from "dialog/redux"

const Icon = styled.img`
  ${tw`align-middle`}
  display: inline-block;
  width: 21px;
  height: 21px;
`

const AccountItem = ({ content, checked, disabled, onAction }) => {
  return (
    <button
      onClick={onAction}
      className="relative rounded-md w-full border-4 py-8 px-4 h-full flex items-center justify-center hover:border-primary-500 cursor-pointer shadow-sm focus:outline-none"
      css={[checked && tw`border-primary-500`]}
    >
      <h3 className="text-2xl text-gray-800 text-center">{content}</h3>
      <div
        className="absolute"
        css={[
          checked ? tw`inline-block` : tw`hidden`,
          css`
            right: -1em;
            bottom: -1em;
          `,
        ]}
      >
        <div className="w-12 h-12 bg-primary-500 rounded-full inline-flex items-center justify-center">
          {disabled ? (
            <svg
              className="text-white w-8 h-8"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
              />
            </svg>
          ) : (
            <svg
              className="text-white w-8 h-8"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          )}
        </div>
      </div>
    </button>
  )
}

function AppleCard({ onAction, checked, openDialog, disabled }) {
  return (
    <Card
      title={
        <div className="flex space-x-2">
          <Icon src={IconApple} />
          <span className="align-middle">Do you have an Apple Developer account?</span>
        </div>
      }
    >
      <p className="mb-4">
        The Apple Developer Account is required for your business to be listed on the App Store. 
      </p>
      <p className="text-primary-500"> *This is not the personal itunes account that you used for app download.
      </p>
      <div className="flex flex-row flex-wrap -mx-2">
        <div className="flex-1 px-2">
          <AccountItem
            disabled={disabled}
            onAction={() => onAction("ios", false)}
            content={`No, I don't have`}
            checked={checked === false}
          />
        </div>
        <div className="flex-1 px-2">
          <AccountItem
            disabled={disabled}
            onAction={() => onAction("ios", true)}
            content={
              <span className="align-middle">
                I have an Apple <Icon src={IconApple} checked={checked} />
                Developer Account
              </span>
            }
            checked={checked}
          />
        </div>
      </div>
      {/* <div
        className="absolute inset-0 flex items-center"
        css={css`
          background-image: linear-gradient(
            to bottom,
            rgba(255, 253, 255, 0.5),
            rgba(255, 250, 252, 0.98) 56%,
            rgba(255, 242, 246, 0.98)
          );
        `}
      >
        <div className="relative z-20 px-8 pt-12">
          <h2 className="mb-4 text-3xl text-gray-900 font-semibold leading-tight">
            You can launch
            <br />
            <span className="text-primary-500">on the both app store!</span>
          </h2>
          <Button
            primary
            size="slim"
            onClick={() => openDialog({ type: "GROWTH_PLAN_LISTING" })}
          >
            Boost you sales
          </Button>
        </div>
        <img
          src={BgHide}
          alt=""
          className="absolute bottom-0 top-0 right-0 h-full"
        />
      </div> */}
    </Card>
  )
}

export default connect(undefined, { openDialog })(AppleCard)
