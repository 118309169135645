import React, { useEffect } from "react"
import { Router } from "@reach/router"
import Modal from "react-modal"
import { Helmet } from "react-helmet"
import firebase from "gatsby-plugin-firebase"
import { get, isEmpty } from "lodash"
import axios from "axios"

// import "../../static/fontello/css/fontello.css"
// import "react-toastify/dist/ReactToastify.css"

// PROVIDER
import ShopifyAuth from "auth/containers/ShopifyAuth"
import ModalProvider from "modal/containers/ModalProvider"
import DialogProvider from "dialog/containers/DialogProvider"
import ToastProvider from "general/containers/ToastProvider"
// AUTH
import SignupPage from "auth/containers/SignupPage"
// GENERAL
import StatsPage from "general/containers/StatsPage"
import SettingsPage from "general/containers/SettingsPage"
import PricingPage from "general/containers/PricingPage"
import SyncPage from "general/containers/SyncPage"
import Default from "general/containers/AppPage"
// DESIGN
import DesignPage from "design/containers/DesignPage"
// PUSH
import PushPage from "push/containers/PushPage"
import PushHistoryPage from "push/containers/PushHistoryPage"
import MarketingPage from "marketing/containers/MarketingPage"
// INTEGRATION
import IntegrationPage from "integration/containers/IntegrationPage"
import IntegrationDetailPage from "integration/containers/IntegrationDetailPage"
// APPLISTING
import ListingPage from "listing/containers/ListingPage"
import ListingApplicationPage from "listing/containers/ListingApplicationPage"
import ListingDeveloperPage from "listing/containers/ListingDeveloperPage"
import ListingStatusPage from "listing/containers/ListingStatusPage"

import { renderIntercomName, track } from "services/analytics"

function App(props) {
  typeof window !== "undefined" &&
    Modal.setAppElement(document.getElementById("___gatsby"))

  const { location } = props

  useEffect(() => {
    if (typeof window !== "undefined") {
      const app_id = process.env.GATSBY_INTERCOM_APP_ID

      firebase.auth().onAuthStateChanged(async user => {
        if (user) {
          const profile = await firebase
            .firestore()
            .collection("user_list")
            .doc(user.uid)
            .get()

          const {
            shopify_url,
            settings,
            email,
            first_name,
            last_name,
            payment,
            shop_owner,
          } = profile.data()

          const userId = shopify_url.replace(".myshopify.com", "")
          const user_email = email
          const shop_name = userId
          const identities = [user_email, shop_name]

          window.Sentry.configureScope(scope => {
            scope.setUser({ shop_url: shopify_url })
            scope.setTag("shop_url", shopify_url)
          })
          // Sentry.captureException(error)

          // window.hj("identify", userId, {
          //   email: email,
          //   company: shopify_url,
          // })

          let intercomEmail = email

          if (process.env.NODE_ENV === "development") {
            intercomEmail = "jcurveshowjcurve@jcurve.xyz"
          }

          if (!intercomEmail) {
            return window.Intercom("boot", {
              app_id: app_id,
            })
          }

          window.Intercom("boot", {
            app_id: app_id,
            email: intercomEmail,
            name: renderIntercomName({
              shopify_url,
              first_name,
              last_name,
              payment,
            }),
            user_id: intercomEmail,
            company_name: get(settings, "app_name", ""),
            website: shopify_url,
            test_plan: get(payment, "plan", ""),
            test_shopify_plan: get(shop_owner, "plan_display_name", ""),
            test_created_at: get(shop_owner, "created_at", ""),
            test_version: "1.1",
          })

          if (isEmpty(get(shop_owner, "intercom_id", ""))) {
            user
              .getIdToken(true)
              .then(token =>
                axios({
                  method: "post",
                  url: "/api/intercom",
                  params: { email: intercomEmail },
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: token,
                  },
                })
              )
              .catch(window.Sentry.captureException)
          }
        }
      })
    }
  }, [])

  useEffect(() => {
    if (location) {
      const { pathname } = location

      track("VIEW_PAGE", { pathname })
    }
  }, [location])

  return (
    <ShopifyAuth location={location}>
      <Helmet title="JCurve" />
      <Router basepath="/app"> 
        <SignupPage path="signup" />
        <SyncPage path="sync" />
        <DesignPage path="design" />
        <PushPage path="push" />
        <PushHistoryPage path="push/history" />
        <StatsPage path="stats" />
        <MarketingPage path="marketing" />
        <PricingPage path="pricing" />
        <IntegrationPage path="integration" />
        <IntegrationDetailPage path="integration/:serviceId" />
        <IntegrationDetailPage path="integration/:serviceId/connect" />
        <SettingsPage path="settings" />
        <ListingPage path="listing">
          <ListingApplicationPage path="/" />
          <ListingDeveloperPage path="developer/:action" />
          <ListingDeveloperPage path="developer" />
          <ListingStatusPage path="status" />
        </ListingPage>
        <Default path="/" />
      </Router>

      <ToastProvider />
      <ModalProvider />
      <DialogProvider />
    </ShopifyAuth>
  )
}

export default App
