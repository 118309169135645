import React from "react"
import { navigate } from "gatsby"
import { css } from "twin.macro"

import Card from "general/components/Card"
import Button from "general/components/Button"
import ListingTimeline from "general/components/ListingTimeline"

import IconApple from "assets/images/pricing/icon-apple.png"

export default function AppleSyncCard({ checked }) {
  if (checked) {
    return (
      <Card title={`Connect your Apple Developer account`}>
        <p className="mb-4">
          Invite us to your iTunes connect and Apple Developer accounts.
        </p>
        <ListingTimeline>
          <ListingTimeline.Item
            color="#79c257"
            icon={
              <img
                src={IconApple}
                css={css`
                  width: 15px;
                  height: 15px;
                `}
              />
            }
            label="STEP 1"
          >
            <div className="shadow-sm px-4 py-4 border border-gray-300">
              <h3 className="mb-2 font-medium text-lg text-gray-900">
                Invite jay@jcurve.xyz
              </h3>
              <p className="leading-snug mb-4">
                Navigate to your iTunes Connect account to the{" "}
                <span className="font-medium text-gray-900">
                  Users and Roles
                </span>{" "}
                section. Click on the{" "}
                <span className="font-medium text-gray-900">+ button</span> and
                add 
                <span className="font-medium text-gray-900">
                  jay@jcurve.xyz
                </span>{" "}
                as an admin to the account.
              </p>
              <a
                href="https://itunesconnect.apple.com/WebObjects/iTunesConnect.woa/ra/ng/users_roles/new"
                target="_blank"
                className="text-primary-500 font-medium text-base"
              >
                iTunes Connect Account invite ↗
              </a>
            </div>
            <div className="mt-8">
              <h4 className="text-xl text-gray-900 mb-4">
                Once you invite{" "}
                <span className="text-primary-500">jay@jcurve.xyz</span>
                <br />
                you will be able to publish your app.
              </h4>
              <Button primary onClick={() => navigate("/app/listing/status")}>
                APPLICATION STATUS
              </Button>
            </div>
          </ListingTimeline.Item>
        </ListingTimeline>
      </Card>
    )
  }

  return (
    <Card title={`We already start to publish your app.`}>
      <ListingTimeline>
        <ListingTimeline.Item
          color="#79c257"
          icon={
            <img
              src={IconApple}
              css={css`
                width: 15px;
                height: 15px;
              `}
            />
          }
          label="DONE"
        >
          <div className="shadow-sm mb-8 px-4 py-4 border border-gray-300">
            <h3 className="mb-2 font-medium text-lg text-gray-900">All set!</h3>
            <p className="leading-tight">
              We will publish your app under our developer account. <br />
              You can save{" "}
              <span className="font-medium text-gray-900">$99/year!</span>
            </p>
            <p></p>
          </div>
          <div className="mt-8">
            <h4 className="text-xl text-gray-900 mb-4">
              When you app is live, we will let you know!
            </h4>
            <Button primary onClick={() => navigate("/app/listing/status")}>
              APPLICATION STATUS
            </Button>
          </div>
        </ListingTimeline.Item>
      </ListingTimeline>
    </Card>
  )
}
