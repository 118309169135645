import React, { useState, useEffect } from "react"
import tw, { css } from "twin.macro"
import { isUndefined } from "lodash"
import DisplayTypeSquare from "assets/images/design/icon-display-type-square.png"
import DisplayTypeVertical from "assets/images/design/icon-display-type-vertical.png"

const SwitchButton = ({ onClick, active, label, loading, icon }) => {
  return (
    <div onClick={onClick} css={tw`cursor-pointer`}>
      <div
        css={[
          tw`text-center p-2 border-2
                border-transparent
                rounded-md
                hover:border-primary-500`,
          active && tw`border-primary-500`,
        ]}
      >
        <div
          css={[
            tw`overflow-hidden relative
                `,
          ]}
        >
          <div
            css={[
              tw`
                bg-transparent
              `,
              css`
                padding-bottom: 185%;
              `,
            ]}
          ></div>
          <img src={icon} alt={label} css={tw`absolute top-0 w-full h-full`} />
        </div>
      </div>
      <div css={tw`block mt-1 text-center`}>
        <div
          css={[
            tw`px-3 rounded-full text-sm inline-block`,
            active && tw`bg-primary-500 text-white inline-block`,
          ]}
        >
          <span css={[loading && tw`bg-gray-100 text-gray-100`]}>{label}</span>
        </div>
      </div>
    </div>
  )
}

export default function DisplayTypeSwitch(props) {
  const { supportPortrait, loading } = props
  const { updateUI } = props

  const [selected, setSelected] = useState(supportPortrait)

  const options = [
    {
      id: "square",
      label: "Square",
      value: false,
      icon: DisplayTypeSquare,
    },
    {
      id: "Vertical",
      label: "Long",
      value: true,
      icon: DisplayTypeVertical,
    },
  ]

  useEffect(() => {
    if (!isUndefined(supportPortrait)) {
      setSelected(supportPortrait)
    }
  }, [supportPortrait])

  useEffect(() => {
    updateUI({
      supportPortrait: selected,
    })
  }, [selected])

  const handleClick = value => {
    setSelected(value)
  }
  return (
    <div css={tw`flex flex-row -mx-2`}>
      {options.map(option => {
        return (
          <div css={tw`px-2 w-2/5`} key={option.id}>
            <SwitchButton
              onClick={() => handleClick(option.value)}
              active={!loading && option.value === selected}
              label={option.label}
              loading={loading}
              icon={option.icon}
            />
          </div>
        )
      })}
    </div>
  )
}
