import React, { forwardRef } from "react"
import styled, { withTheme } from "styled-components"
import tw, { css } from "twin.macro"
import Modal from "react-modal"
import Button from "./Button"

const Header = styled.div`
  ${tw`flex flex-row items-center justify-between rounded-md border-b border-gray-300 py-4 px-4`}
`
const Body = styled.div`
  /* ${tw`overflow-y-scroll`} */
  flex: 1 1 auto;
  height: calc(100% - 126px);
`
const Footer = styled.div`
  ${tw`px-4 py-3 border-t border-gray-300 flex flex-row items-center justify-end bg-white relative`}

  z-index: 19;

  ${({ size }) => size === "fullpage" && tw`justify-center`}
`

const GeneralModal = forwardRef(
  (
    {
      title,
      subtitle,
      children,
      primaryAction,
      secondaryAction,
      isOpen,
      onRequestClose,
      size,
      extraClass,
    },
    ref
  ) => {
    const styles = {
      overlay: {
        overflowY: "auto",
        overflowX: "hidden",
        background: "rgba(33,43,54,.4)",
        zIndex: 500,
      },
      content: {},
    }

    const getClassName = () => {
      let baseClass = `modal ${extraClass || ""}`
      let sizeClass

      switch (size) {
        case "fullpage":
          sizeClass = "modal--fullpage"
          break
        case "xl":
          sizeClass = "modal--xl"
          break
        case "lg":
          sizeClass = "modal--lg"
          break
        default:
          sizeClass = "modal--md"
          break
      }

      return `${baseClass} ${sizeClass}`
    }

    return (
      <Modal
        isOpen={isOpen}
        style={styles}
        shouldCloseOnEsc={true}
        className={getClassName()}
        onRequestClose={onRequestClose}
      >
        <div css={[tw`w-full bg-white rounded-md`]} className="modal-content">
          <Header className="modal__header">
            <div>
              <h2
                css={tw`font-serif font-bold text-lg text-gray-800 leading-tight`}
              >
                {title}
              </h2>
              {subtitle && (
                <p css={tw`text-base font-normal text-gray-600`}>{subtitle}</p>
              )}
            </div>
            <button
              onClick={() => onRequestClose()}
              css={tw`rounded-md py-1 px-2 hover:bg-gray-100 transition-colors duration-500`}
            >
              <i className="icon-cancel" css={tw`text-gray-600 text-sm`} />
            </button>
          </Header>
          <Body ref={ref} className="modal__body">
            {children}
          </Body>
          {(primaryAction || secondaryAction) && (
            <Footer size={size}>
              {secondaryAction && (
                <div css={tw`ml-3`}>
                  <Button onClick={secondaryAction.onAction}>
                    {secondaryAction.content}
                  </Button>
                </div>
              )}
              {primaryAction && (
                <div css={tw`ml-3`}>
                  <Button primary onClick={primaryAction.onAction}>
                    {primaryAction.content}
                  </Button>
                </div>
              )}
            </Footer>
          )}
        </div>
      </Modal>
    )
  }
)

export default withTheme(GeneralModal)
