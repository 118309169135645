import React, { useState, useEffect, useRef } from "react"
import tw, { css, styled } from "twin.macro"
import { CompactPicker, CustomPicker } from "react-color"
import tinycolor from "tinycolor2"
import { updateColors } from "../redux"
import Portal from "general/components/Portal"
const ColorBullet = styled.div`
  ${tw`inline-flex rounded-full w-8 h-8 border border-gray-300 shadow-md items-center justify-center`}
`
const Color = styled.span`
  ${tw`rounded-full inline-block border border-gray-200 w-full h-full`}

  width: 24px;
  height: 24px;
  background-color: ${({ hex }) => (hex ? hex : "#ffffff")};
`

export default function ColorPicker({ type, color, updateColors }) {
  const [input, setInput] = useState(color || "")
  const [isActive, setIsActive] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const ref = useRef(null)

  useEffect(() => {
    setPosition({ x: 0, y: ref.current.clientHeight + 10 })
  }, [ref])

  useEffect(() => {
    setInput(color)
  }, [color])

  useEffect(() => {
    const handleScroll = event => {
      let scrollY = window.scrollY

      if (scrollY) {
        setIsActive(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      return window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleOnChangeComplete = event => {
    setInput(event.hex)
    return updateColors({ [type]: event.hex })
  }

  const handleOnClick = event => {
    const rect = event.target.getBoundingClientRect()

    setPosition({ x: 0, y: rect.top + 32 })
    setIsActive(true)
  }

  const handleChange = event => {
    const value = `#${event.target.value.replace("#", "")}`

    setInput(value)
    if (/^#([0-9A-F]{3}){1,2}$/i.test(value)) {
      const hexValue = tinycolor(value).toHexString()

      updateColors({ [type]: hexValue })
    }
  }
  const handleBlur = event => {
    const value = `#${input.replace("#", "")}`
    const lh = String(value).charAt(0) === "#" ? 1 : 0

    if (/^#([0-9A-F]{3}){1,2}$/i.test(value)) {
      value.length === 3 + lh && setInput(tinycolor(value).toHexString())
    }
  }

  return (
    <div css={tw`relative block`} ref={ref}>
      <div css={tw`flex flex-row items-center`} onClick={handleOnClick}>
        <div css={tw`mr-2 flex items-center`}>
          <ColorBullet>
            <Color hex={color} />
          </ColorBullet>
        </div>
        <input
          type="text"
          value={input}
          css={tw`w-full uppercase`}
          onChange={handleChange}
          maxLength={7}
          onBlur={handleBlur}
        />
      </div>
      {isActive && (
        <Portal node={document.body}>
          {/* <div css={tw`fixed inset-0 flex items-center justify-center`}> */}
          <div
            css={css`
              line-height: 1.2 !important;
              input {
                letter-spacing: -1.5px;
              }
            `}
            style={{
              position: "fixed",
              right: position.x,
              top: position.y,
              zIndex: 80,
            }}
          >
            <div
              style={{
                position: "fixed",
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
                width: "100%",
              }}
              onClick={() => {
                setIsActive(false)
              }}
            />
            <CompactPicker
              colors={[
                "#FFCDD2",
                "#F8BBD0",
                "#E1BEE7",
                "#D1C4E9",
                "#C5CAE9",
                "#BBDEFB",
                "#B3E5FC",
                "#B2EBF2",
                "#B2DFDB",
                "#C8E6C9",
                "#DCEDC8",
                "#F0F4C3",
                "#FFF9C4",
                "#FFECB3",
                "#FFE0B2",
                "#FFCCBC",
                "#D7CCC8",
                "#FFFFFF",
                "#CFD8DC",
                "#EF9A9A",
                "#F48FB1",
                "#CE93D8",
                "#B39DDB",
                "#9FA8DA",
                "#90CAF9",
                "#81D4FA",
                "#80DEEA",
                "#80CBC4",
                "#A5D6A7",
                "#C5E1A5",
                "#E6EE9C",
                "#FFF59D",
                "#FFE082",
                "#FFCC80",
                "#FFAB91",
                "#BCAAA4",
                "#F5F5F5",
                "#B0BEC5",
                "#E57373",
                "#F06292",
                "#BA68C8",
                "#9575CD",
                "#7986CB",
                "#64B5F6",
                "#4FC3F7",
                "#4DD0E1",
                "#4DB6AC",
                "#81C784",
                "#AED581",
                "#DCE775",
                "#FFF176",
                "#FFD54F",
                "#FFB74D",
                "#FF8A65",
                "#A1887F",
                "#E0E0E0",
                "#90A4AE",
                "#EF5350",
                "#EC407A",
                "#AB47BC",
                "#7E57C2",
                "#5C6BC0",
                "#42A5F5",
                "#29B6F6",
                "#26C6DA",
                "#26A69A",
                "#66BB6A",
                "#9CCC65",
                "#D4E157",
                "#FFEE58",
                "#FFCA28",
                "#FFA726",
                "#FF7043",
                "#8D6E63",
                "#BDBDBD",
                "#78909C",
                "#F44336",
                "#E91E63",
                "#9C27B0",
                "#673AB7",
                "#3F51B5",
                "#2196F3",
                "#03A9F4",
                "#00BCD4",
                "#009688",
                "#4CAF50",
                "#8BC34A",
                "#CDDC39",
                "#FFEB3B",
                "#FFC107",
                "#FF9800",
                "#FF5722",
                "#795548",
                "#9E9E9E",
                "#607D8B",
                "#E53935",
                "#D81B60",
                "#8E24AA",
                "#5E35B1",
                "#3949AB",
                "#1E88E5",
                "#039BE5",
                "#00ACC1",
                "#00897B",
                "#43A047",
                "#7CB342",
                "#C0CA33",
                "#FDD835",
                "#FFB300",
                "#FB8C00",
                "#F4511E",
                "#6D4C41",
                "#757575",
                "#546E7A",
                "#D32F2F",
                "#C2185B",
                "#7B1FA2",
                "#512DA8",
                "#303F9F",
                "#1976D2",
                "#0288D1",
                "#0097A7",
                "#00796B",
                "#388E3C",
                "#689F38",
                "#AFB42B",
                "#FBC02D",
                "#FFA000",
                "#F57C00",
                "#E64A19",
                "#5D4037",
                "#616161",
                "#455A64",
                "#C62828",
                "#AD1457",
                "#6A1B9A",
                "#4527A0",
                "#283593",
                "#1565C0",
                "#0277BD",
                "#00838F",
                "#00695C",
                "#2E7D32",
                "#558B2F",
                "#9E9D24",
                "#F9A825",
                "#FF8F00",
                "#EF6C00",
                "#D84315",
                "#4E342E",
                "#424242",
                "#37474F",
                "#B71C1C",
                "#880E4F",
                "#4A148C",
                "#311B92",
                "#1A237E",
                "#0D47A1",
                "#01579B",
                "#006064",
                "#004D40",
                "#1B5E20",
                "#33691E",
                "#827717",
                "#F57F17",
                "#FF6F00",
                "#E65100",
                "#BF360C",
                "#3E2723",
                "#212121",
                "#263238",
              ]}
              color={color}
              onChangeComplete={handleOnChangeComplete}
            />
          </div>
          {/* </div> */}
        </Portal>
      )}
    </div>
  )
}
