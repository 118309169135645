import React, { useState, useEffect } from "react"
import tw, { styled, css } from "twin.macro"
import { navigate } from "gatsby"
import { connect, useSelector } from "react-redux"
import firebase from "gatsby-plugin-firebase"
import { get, find, values } from "lodash"
import { toast } from "react-toastify"
import { useForm, Controller } from "react-hook-form"
import ConfirmModal from "modal/components/ConfirmModal"
import produce from "immer"
import { getPlan } from "auth/services"

import Page from "general/components/Page"
import PageActions from "general/components/PageActions"
import Card from "general/components/Card"
import TextField from "general/components/TextField"
import SkeletonDisplayText from "general/components/SkeletonDisplayText"
import SkeletonBodyText from "general/components/SkeletonBodyText"
import Button from "general/components/Button"
import { track } from "services/analytics"

import { openDialog } from "dialog/redux"

const Badge = styled.span`
  ${tw`py-1 px-2 leading-none inline-block text-white uppercase rounded-full text-xs`}

  ${({ primary }) => {
    return primary && tw`bg-primary-500`
  }}
  ${({ success }) => {
    return success && tw`bg-green-500`
  }}
  ${({ incomplete }) => {
    return incomplete && tw`bg-gray-500`
  }}
`

const Thumbnail = ({ source }) => {
  return (
    <div className="w-16 h-16 relative bg-white">
      <img src={source} className="absolute inset-0 object-cover" />
    </div>
  )
}

const Details = styled.div`
  h3 {
    ${tw`text-xl font-medium text-black mt-4`}
  }

  p {
    ${tw`mt-4`}
  }

  ul {
    ${tw`mt-4`}
    li {
      ${tw`pl-8 relative mt-2`}
    }
    li::before {
      ${tw`w-6 h-6 inline-block absolute left-0`}
      content: "";
      top: 0px;
      color: #f8b500;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23d2035a'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M5 13l4 4L19 7' /%3E%3C/svg%3E");
    }
  }
`

function IntegrationDetailPage(props) {
  const { serviceId, action, children, openDialog } = props
  const [page, setPage] = useState(null)
  const [service, setService] = useState(null)
  const [form, setForm] = useState(null)
  const [loading, setLoading] = useState({
    initialize: false,
    save: false,
    update: false,
  })
  const [confirm, setConfirm] = useState({
    isOpen: false,
  })
  const profile = useSelector(state => state.firebase.profile)
  const shop = profile.shopify_url
  const payment = profile.payment
  const { handleSubmit, register, errors, control, setValue } = useForm()

  const fetchIntegration = async () => {
    const serviceSnapshot = await firebase
      .firestore()
      .collection("_integrations")
      .doc(serviceId)
      .get()

    if (!serviceSnapshot.exists) {
      return navigate("/app/integration")
    }

    const nextPage = serviceSnapshot.data()

    const installedSnapshot = await firebase
      .firestore()
      .collection(shop)
      .doc("apps")
      .collection("integrations")
      .doc(nextPage.name)
      .get()

    const nextService = installedSnapshot.data()

    setPage({ id: serviceSnapshot.id, ...nextPage })
    setService(
      nextService ? { id: installedSnapshot.id, ...nextService } : undefined
    )

    track("VIEW_INTEGRATION_APPPAGE", {
      appname: nextPage.name,
    })
    setLoading(
      produce(draft => {
        draft["initialize"] = true
      })
    )
  }

  useEffect(() => {
    if (serviceId) {
      fetchIntegration()
    }
  }, [])

  const renderMeta = () => {
    const plan = get(page, "minimum_plan", undefined)
    const category = get(page, "category", undefined)

    if (!plan) {
      return false
    }

    return (
      <div css={tw`flex flex-row items-center`}>
        <span css={tw`text-gray-600 mr-1`}>{category}</span>
        <Badge success>{plan} PLAN</Badge>
      </div>
    )
  }

  const onSubmit = formData => {
    setLoading(
      produce(draft => {
        draft["save"] = true
      })
    )
    const category = get(page, "category", "").toLowerCase()

    const payload = Object.keys(formData).map(item => {
      return {
        key_name: item,
        key_value: formData[item],
      }
    })

    const ref = firebase
      .firestore()
      .collection(shop)
      .doc("apps")
      .collection("integrations")
      .doc(page.name)

    ref
      .set({ available: true, information: payload, category }, { merge: true })
      .then(() => {
        setLoading(
          produce(draft => {
            draft["save"] = false
          })
        )
        window.Intercom("update", {
          [`integration_${page.name.toLowerCase().replaceAll(" ", "_")}`]: true,
        })
        toast("Service connected")
        fetchIntegration()
      })
  }

  const handlePrimary = type => {
    if (type === "edit") {
      return handleSubmit(onSubmit)()
    }
    return handleSubmit(onSubmit)()
  }

  const handleConnect = () => {
    const currentPlan = get(payment, "plan", undefined)
    // console.log("good click", currentPlan, getPlan(currentPlan))

    if (
      !currentPlan ||
      getPlan(currentPlan) === "starter" ||
      getPlan(currentPlan) === "growth"
    ) {
      return openDialog({ type: "POWER_PLAN" })
    }

    navigate(`/app/integration/${serviceId}/connect`)
  }

  const renderPrimaryAction = () => {
    // if (serviceId === "wishlist") {
    //   return { content: "Connect", onAction: handleConnect }
    // }
    if (!page) {
      return false
    }

    if (props.path === "integration/:serviceId/connect") {
      return null
    }

    if (page.id === "wishlist") {
      return null
    }

    return {
      content: service ? "Update" : "Connect",
      onAction: () => handleConnect(),
    }
  }

  const renderFormFields = () => {
    const { integration_information } = page

    if (!integration_information) {
      return null
    }

    if (integration_information.length > 0) {
      return (
        <Card>
          {integration_information.map((item, index) => {
            const installedData = get(service, "information", undefined)
            const fieldData = installedData
              ? get(
                  find(
                    service.information,
                    obj => obj.key_name === item.key_name
                  ),
                  "key_value",
                  ""
                )
              : ""

            return (
              <TextField
                key={item.key_name}
                ref={event => {
                  register(event, { required: true })
                }}
                defaultValue={fieldData}
                name={item.key_name}
                label={item.title}
                placeholder={item.placeholder}
                helpText={item.guide}
              />
            )
          })}
        </Card>
      )
    }
  }

  const renderContent = () => {
    // console.log(props)
    if (props.path === "integration/:serviceId/connect") {
      return (
        <div>
          {loading["initialize"] && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 gap-4">{renderFormFields()}</div>
            </form>
          )}

          <PageActions
            primaryAction={
              page
                ? {
                    loading: loading.save,
                    content: service ? "Update" : "Connect",
                    onAction: () => {
                      if (service) {
                        return handlePrimary("edit")
                      }

                      return handlePrimary()
                    },
                  }
                : undefined
            }
            secondaryActions={
              service && [
                {
                  content: "Disconnect",
                  destructive: true,
                  onAction: () => {
                    setConfirm(
                      produce(draft => {
                        draft.isOpen = true
                      })
                    )
                  },
                },
              ]
            }
          />
          {get(page, "detail_guide", undefined) && (
            <Card>
              <Details
                dangerouslySetInnerHTML={{ __html: page.detail_guide }}
              />
            </Card>
          )}
          <ConfirmModal
            isOpen={confirm.isOpen}
            onRequestClose={() =>
              setConfirm(
                produce(draft => {
                  draft.isOpen = false
                })
              )
            }
            primaryAction={{
              content: "Disconnect",
              onAction: () => {
                setLoading(
                  produce(draft => {
                    draft["disconnect"] = true
                  })
                )

                const ref = firebase
                  .firestore()
                  .collection(shop)
                  .doc("apps")
                  .collection("integrations")
                  .doc(page.name)

                ref
                  .delete()
                  .then(() => navigate(`/app/integration/${serviceId}`))
              },
            }}
            secondaryAction={{
              content: "Cancel",
              onAction: () =>
                setConfirm(
                  produce(draft => {
                    draft.isOpen = false
                  })
                ),
            }}
            title={
              "Are you sure you want to disconnect the integration app that is connected now?"
            }
          />
        </div>
      )
    }

    return (
      <div>
        <Card>
          {!page ? (
            <SkeletonBodyText />
          ) : (
            <>
              <img
                src={page.images[0]}
                alt={page.name}
                className="w-full max-w-3xl"
              />
              <Details dangerouslySetInnerHTML={{ __html: page.how }} />
            </>
          )}
        </Card>
      </div>
    )
  }

  return (
    <Page
      title={get(page, "name", <SkeletonDisplayText />)}
      subtitle={get(page, "summary", <SkeletonDisplayText />)}
      thumbnail={<Thumbnail source={get(page, "logo")} />}
      titleMetadata={renderMeta()}
      breadcrumbs={[{ content: "Integration", url: "/app/integration" }]}
      primaryAction={renderPrimaryAction()}
    >
      {renderContent()}
    </Page>
  )
}

export default connect(undefined, { openDialog })(IntegrationDetailPage)
