import React, { useState } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { toast } from "react-toastify"

const Wrapper = styled.div`
  ${tw`bg-white shadow-md rounded-full flex flex-row flex-wrap`}
`
const Button = styled.button`
  ${tw`inline-block text-center py-1 px-2 flex-1 font-medium cursor-pointer rounded-full outline-none`}

  ${({ active }) =>
    active
      ? tw`bg-primary-500 text-white`
      : tw`bg-white text-gray-500`}

    &:hover, &:focus {
    ${tw`outline-none`}
  }
`

export default function ScreenSwitch({ isActive, toggleMenu, disabled }) {
  const handleClick = () => {
    if (disabled) {
      return toast(
        "Your changes have not been saved. Please save and try again."
      )
    }

    toggleMenu()
  }

  return (
    <Wrapper>
      <Button onClick={() => handleClick()} active={!isActive}>
        Edit Design
      </Button>
      <Button onClick={() => handleClick()} active={isActive}>
        Edit Menu
      </Button>
    </Wrapper>
  )
}
