import React, { useState, useEffect } from "react"
import tw, { css } from "twin.macro"
import { isUndefined } from "lodash"
import IconFit from "assets/images/design/icon-picture-size-fit.png"
import IconFill from "assets/images/design/icon-picture-size-fill.png"

const SwitchButton = ({ onClick, active, icon, label, loading }) => {
  return (
    <div onClick={onClick} css={tw`cursor-pointer`}>
      <div css={tw`text-center`}>
        <div css={tw`relative`}>
          <div
            css={[
              tw`
                relative
                pb-1/1
                border-2
                border-transparent
                hover:border-primary-500 rounded-md z-20
                rounded-md
              `,
              active && tw`border-primary-500`,
            ]}
          ></div>
          {icon}
        </div>
      </div>
      <div css={tw`block mt-1 text-center`}>
        <div
          css={[
            tw`px-3 rounded-full text-sm inline-block`,
            active && tw`bg-primary-500 text-white inline-block`,
          ]}
        >
          <span css={[loading && tw`bg-gray-100 text-gray-100`]}>{label}</span>
        </div>
      </div>
    </div>
  )
}

export default function PictureSizeSwitch(props) {
  const { imageScaleFilled, loading } = props
  const { updateUI } = props

  const [selected, setSelected] = useState(imageScaleFilled)

  const options = [
    {
      id: "fit",
      label: "Fit",
      value: false,
      icon: <img src={IconFit} css={tw`absolute inset-0 w-full h-full z-10 transform scale-75`} />,
    },
    {
      id: "fill",
      label: "Fill",
      value: true,
      icon: (
        <img src={IconFill} css={tw`absolute inset-0 w-full h-full z-10 transform scale-75`} />
      ),
    }
  ]

  useEffect(() => {
    if (!isUndefined(imageScaleFilled)) {
      setSelected(imageScaleFilled)
    }
  }, [imageScaleFilled])

  useEffect(() => {
    updateUI({
      imageScaleFilled: selected,
    })
  }, [selected])

  const handleClick = value => {
    setSelected(value)
  }

  return (
    <div css={tw`flex flex-row -mx-2`}>
      {options.map(option => {
        return (
          <div css={tw`px-1 w-2/5`} key={option.id}>
            <SwitchButton
              loading={loading}
              onClick={() => handleClick(option.value)}
              active={!loading && option.value === selected}
              label={option.label}
              icon={option.icon}
            />
          </div>
        )
      })}
    </div>
  )
}
