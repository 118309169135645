import React from "react"
import tw, { css, styled } from "twin.macro"
import { useStaticQuery } from "gatsby"
import { get, find } from "lodash"

const CART_ICON_TYPES = {
  bag: {
    image: "",
  },
  "bag-filled": {
    image: "",
  },
  tags: {
    image: "",
  },
  basket: {
    image: "",
  },
  "basket-filled": {
    image: "",
  },
  openbox: {
    image: "",
  },
  cart: {
    image: "",
  },
  "cart-filled": {
    image: "",
  },
  cloakroom: {
    image: "",
  },
}

const CartIconItem = styled.button`
  ${tw`border cursor-pointer flex justify-center items-center rounded-md relative`}

  &:focus {
    ${tw`outline-none`}
  }

  &:hover {
    ${tw`border-primary-500`}
  }

  img {
    ${tw`py-1 px-1`}
  }

  ${({ isActive }) =>
    isActive
      ? [
          tw`border-primary-500`,
          css`
            background-color: #ffecf04c;
          `,
        ]
      : "border-gray-100"}
`

export default function CartIconList({
  selectedCartIconType,
  setCartIconType,
}) {
  const data = useStaticQuery(graphql`
    query {
      cartIconImages: allFile(
        filter: { extension: {}, relativePath: { regex: "/design/cart/" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                src
                originalName
              }
            }
          }
        }
      }
    }
  `)

  const getCartIconUrl = cartIconType => {
    const cartType = find(
      data.cartIconImages.edges,
      icon =>
        icon.node.childImageSharp.fluid.originalName ===
        "icon-" + cartIconType + ".png"
    )

    return get(cartType, "node.childImageSharp.fluid.src", "")
  }

  return (
    <div>
      <div css={tw`grid grid-cols-4 gap-2`}>
        {Object.keys(CART_ICON_TYPES).map(type => (
          <CartIconItem
            key={type}
            isActive={type === selectedCartIconType}
            onClick={() => setCartIconType(type)}
          >
            <div className="pb-1/1 w-full" />
            <img
              src={getCartIconUrl(type)}
              alt={`${type}`}
              className="absolute inset-0 object-cover w-12 transform -translate-y-1/2 -ml-6 scale=75"
              css={css`
                top: 50%;
                left: 50%;
              `}
            />
          </CartIconItem>
        ))}
      </div>
    </div>
  )
}
