import React from "react"
import { Link, navigate } from "gatsby"
import tw, { styled, css } from "twin.macro"
import Slider from "react-slick"
import Modal from "dialog/components/Modal"
import Button from "general/components/Button"

import Logo from "assets/images/modal/logo-jcurve-powerplan.png"
import IconSquare from "assets/images/modal/icon-square.png"
import IconCart from "assets/images/modal/icon-cart.png"
import ImgPowerPlanPromote1 from "assets/images/modal/powerplan-promote-1.jpg"
import ImgPowerPlanPromote2 from "assets/images/modal/powerplan-promote-2.jpg"
import ImgPowerPlanPromote3 from "assets/images/modal/powerplan-promote-3.jpg"

const Slide = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }
  return (
    <Slider {...settings}>
      <img src={ImgPowerPlanPromote1} className="w-full" />
      <img src={ImgPowerPlanPromote2} className="w-full" />
      <img src={ImgPowerPlanPromote3} className="w-full" />
    </Slider>
  )
}

export default function PowerPlan(props) {
  const { open, onClose } = props
  return (
    <Modal open={open} onClose={onClose}>
      <div className="flex flex-col md:flex-row items-center">
        <div className="w-1/2 flex-1 px-6 py-6">
          <div className="grid grid-cols-1 gap-6">
            <img src={Logo} alt="" className="h-6" />
            <div className="flex flex-row">
              <img src={IconSquare} alt="" className="w-12 h-12 mr-4" />
              <div>
                <h3>Popular App Integration</h3>
                <p>
                  Leverage customer reviews to boost social proof and trust.
                </p>
              </div>
            </div>
            <div className="flex flex-row">
              <img src={IconCart} alt="" className="w-12 h-12 mr-4" />
              <div>
                <h3>Cart Abandon Notification</h3>
                <p>
                  Leverage customer reviews to boost social proof and trust.
                </p>
              </div>
            </div>
            <Button
              fullWidth
              primary
              size="large"
              onClick={() => {
                navigate("/app/pricing")
                onClose()
              }}
            >
              GET JCURVE POWER PLAN
            </Button>
          </div>
        </div>
        <div className="w-1/2 flex-1 hidden md:block">
          <Slide />
        </div>
      </div>
    </Modal>
  )
}
