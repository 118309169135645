import { connect } from "react-redux"

import DisplayTypeSwitch from "design/components/DisplayTypeSwitch"
import { updateUI } from "design/redux"

const mapStateToProps = state => {
  return {
    supportPortrait: state.design.ui.supportPortrait,
  }
}

const mapDispatchToProps = { updateUI }

export default connect(mapStateToProps, mapDispatchToProps)(DisplayTypeSwitch)
