import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import tw, { styled, css } from "twin.macro"
import { connect, useSelector } from "react-redux"
import { get, isNull } from "lodash"
import firebase from "gatsby-plugin-firebase"
import produce from "immer"
import { toast } from "react-toastify"
import { openDialog } from "dialog/redux"
import { track } from "services/analytics"
import { getPlan } from "auth/services"
import { renderIntercomName } from "services/analytics"

import Page from "general/components/Page"
import PageActions from "listing/components/PageActions"
import Card from "general/components/Card"
import Button from "general/components/Button"
import AndroidCard from "listing/components/AndroidCard"
import AppleCard from "listing/components/AppleCard"
import AndroidSyncCard from "listing/components/AndroidSyncCard"
import AppleSyncCard from "listing/components/AppleSyncCard"

import BgHide from "assets/images/listing/hide-cover.png"
import IconAndroid from "assets/images/pricing/icon-android.png"
import IconApple from "assets/images/pricing/icon-apple.png"

const Hide = ({ onAction }) => {
  return (
    <div
      className="absolute inset-0 flex items-center"
      css={css`
        background-image: linear-gradient(
          to bottom,
          rgba(255, 253, 255, 0.5),
          rgba(255, 250, 252, 0.98) 56%,
          rgba(255, 242, 246, 0.98)
        );
      `}
    >
      <div className="relative z-20 px-8 pt-12">
        <h2 className="mb-4 text-3xl text-gray-900 font-semibold leading-tight">
          You can launch
          <br />
          <span className="text-primary-500">on the both app store!</span>
        </h2>
        <Button primary size="slim" onClick={onAction}>
          Boost your sales
        </Button>
      </div>
      <img
        src={BgHide}
        alt=""
        className="absolute bottom-0 top-0 right-0 h-full"
      />
    </div>
  )
}

function ListingDeveloperPage(props) {
  const { action, openDialog } = props
  const profile = useSelector(state => state.firebase.profile)
  const { first_name, last_name, payment } = profile
  const { shopify_url } = profile
  const applisting = useSelector(
    ({ firestore: { data } }) =>
      data[shopify_url] && data[shopify_url].applisting
  )
  const locked = get(applisting, "locked", false)

  const [loading, setLoading] = useState(false)
  const [platform, setPlatform] = useState({
    ios: null,
    android: null,
  })

  useEffect(() => {
    if (applisting) {
      const ios = get(applisting, "has_ios_account", null)
      const android = get(applisting, "has_android_account", null)

      setPlatform({
        ios,
        android,
      })
    }

    track("VIEW_PAGE_ACCOUNT_SELECTION")
  }, [])

  const handlePlatform = selectedPlatform => {
    setPlatform(
      produce(draft => {
        draft[selectedPlatform] = !platform[selectedPlatform]
      })
    )
  }

  const handlePlatformHasAccountToggle = (selectedPlatform, checked) => {
    //각 ios/android hasaccount toggle 함수

    if (
      !isNull(get(applisting, `has_${selectedPlatform}_account`, null)) &&
      !isNull(checked) &&
      locked
    ) {
      if (getPlan(payment.plan) !== "starter") {
        if (selectedPlatform === "ios") {
          if (!isNull(platform.ios)) {
            return toast("You cannot change the setting at this time.")
          }
        } else if (selectedPlatform === "android") {
          //android
          if (!isNull(platform.android)) {
            return toast("You cannot change the setting at this time.")
          }
        }
      } else {
        return toast("You cannot change the setting at this time.")
      }
      // return toast("You cannot change the setting at this time.")
    }

    setPlatform(
      produce(draft => {
        draft[selectedPlatform] = checked
      })
    )
  }

  const handleSelectionPlatformHasAccount = () => {
    //ios/android has_account 최종 선택시 저장한수
    setLoading(true)
    const DB = firebase.firestore()
    const ref = DB.collection(shopify_url).doc("applisting")

    if (getPlan(payment.plan) === "starter") {
      if (payment.platform === "ios") {
        if (isNull(platform.ios)) {
          setLoading(false)
          return toast("Please answer this question.")
        }
      } else if (payment.platform === "android") {
        if (isNull(platform.android)) {
          setLoading(false)
          return toast("Please answer this question.")
        }
      }
    } else {
      if (isNull(platform.android) || isNull(get(platform.ios))) {
        setLoading(false)
        return toast("Please answer these questions.")
      }
    }

    ref
      .set(
        {
          has_android_account: platform.android,
          has_ios_account: platform.ios,
        },
        { merge: true }
      )
      .then(() => {
        setLoading(false)
        navigate("/app/listing/developer/sync")
      })
      .catch(error => {
        window.Sentry.captureException(error)
      })
  }

  const paymentPlatform = get(payment, "platform", undefined)

  const handleSelectPlatform = platform => {
    //각 ios/android 플랫폼선택시 저장함수
    const DB = firebase.firestore()
    const batch = DB.batch()
    const ref = DB.collection("user_list").doc(shopify_url)
    const paymentRef = DB.collection(shopify_url).doc("payment")

    if (locked) {
      return toast("You cannot change the setting at this time.")
    }

    batch.set(ref, { payment: { platform: platform } }, { merge: true })
    batch.set(paymentRef, { platform }, { merge: true })

    batch
      .commit()
      .then(() => {
        window.Intercom("update", {
          name: renderIntercomName({
            shopify_url,
            first_name,
            last_name,
            payment,
          }),
        })
      })
      .catch(error => {
        window.Sentry.captureException(error)
      })
  }

  const renderDeveloper = () => {
    switch (paymentPlatform) {
      case "all":
        return (
          <div css={tw`grid grid-cols-1 gap-8`}>
            <AppleCard
              onAction={handlePlatformHasAccountToggle}
              checked={platform.ios}
              disabled={locked}
            />
            <AndroidCard
              disabled={
                !isNull(get(applisting, "has_android_account", null)) && locked
              }
              onAction={handlePlatformHasAccountToggle}
              checked={platform.android}
            />
          </div>
        )
      case "android":
        return (
          <>
            <AndroidCard
              onAction={handlePlatformHasAccountToggle}
              checked={platform.android}
              disabled={locked}
            />
            <div className="relative">
              <AppleCard
                onAction={handlePlatformHasAccountToggle}
                checked={false}
                disabled={locked}
              />
              <Hide
                onAction={() =>
                  openDialog({
                    type: "GROWTH_PLAN_LISTING",
                    modalProps: locked ? {} : { platform: "android" },
                  })
                }
              />
            </div>
          </>
        )
      case "ios":
        return (
          <>
            <AppleCard
              onAction={handlePlatformHasAccountToggle}
              checked={platform.ios}
              disabled={locked}
            />
            <div className="relative">
              <AndroidCard
                onAction={handlePlatformHasAccountToggle}
                checked={false}
                disabled={locked}
              />
              <Hide
                onAction={() =>
                  openDialog({
                    type: "GROWTH_PLAN_LISTING",
                    modalProps: locked ? {} : { platform: "ios" },
                  })
                }
              />
            </div>
          </>
        )
    }
  }

  const renderSync = () => {
    switch (paymentPlatform) {
      case "all":
        return (
          <div css={tw`grid grid-cols-1 gap-8`}>
            <AppleSyncCard checked={platform.ios} />
            <AndroidSyncCard checked={platform.android} />
          </div>
        )
      case "android":
        return (
          <>
            <AndroidSyncCard checked={platform.android} />
          </>
        )
      case "ios":
        return (
          <>
            <AppleSyncCard checked={platform.ios} />
          </>
        )
    }
  }

  if (action === "sync") {
    return (
      <Page title="Connect Developer Account">
        <div className="flex flex-row flex-wrap -mx-3">
          <div className="px-3 lg:w-8/12">
            <div className="grid grild-cols-1 gap-4">{renderSync()}</div>
          </div>
          <div className="px-3 lg:w-4/12">
            <Card>
              <p className="text-gray-800">
                Helpful Articles
              </p>
              <br></br>
              <a
                href="http://help.jcurve.xyz/en/articles/4107584-company-account-vs-jcurve-account"
                target="_blank"
                className="text-primary-500 flex flex-row items-center"
              >
                <span>Company Account vs Jcurve Account</span>
              </a>
              <br></br>
              <a
                href="http://help.jcurve.xyz/en/articles/4118034-how-do-i-connect-my-developer-account"
                target="_blank"
                className="text-primary-500 flex flex-row items-center"
              >
                <span>How Do I Connect My Developer Account?</span>
              </a>
            </Card>
          </div>
        </div>
      </Page>
    )
  }

  if (!paymentPlatform) {
    return (
      <Page title="Connect Developer Account">
        <div className="flex flex-row flex-wrap -mx-3">
          <div className="px-3 lg:w-8/12">
            <div className="grid grild-cols-1 gap-4">
              <Card title="On which device do you plan to launch your store app?">
                <p className="text-gray-600 mb-4">
                  Choose from the Android Google Play Store or iOS App Store to
                  launch your app.
                </p>
                <div className="flex flex-row bg-white -mx-2">
                  <div className="w-full px-2">
                    <button
                      onClick={() => handleSelectPlatform("ios")}
                      className="rounded-md w-full border-4 py-8 px-4 h-full flex flex-col items-center justify-center hover:border-primary-500 cursor-pointer shadow-sm focus:outline-none"
                    >
                      <img
                        src={IconApple}
                        alt="Apple"
                        className="w-8 mx-auto block mb-4"
                      />
                      <h3 className="text-2xl text-gray-800 text-center">
                        <span className="block leading-tight font-medium text-gray-900">
                          Apple
                        </span>
                        <span className="block leading-tight">
                          iOS Apple Store
                        </span>
                      </h3>
                    </button>
                  </div>
                  <div className="w-full px-2">
                    <button
                      onClick={() => handleSelectPlatform("android")}
                      className="rounded-md w-full border-4 py-8 px-4 h-full flex flex-col items-center justify-center hover:border-primary-500 cursor-pointer shadow-sm focus:outline-none"
                    >
                      <img
                        src={IconAndroid}
                        alt="Android"
                        className="w-8 mx-auto block mb-4"
                      />
                      <h3 className="text-2xl text-gray-800 text-center">
                        <span className="block leading-tight font-medium text-gray-900">
                          Android
                        </span>
                        <span className="block leading-tight">
                          Google Play Store
                        </span>
                      </h3>
                    </button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="px-3 lg:w-4/12">
            <Card>
              <p className="text-gray-800">
                Help : Connecting Developer Account 
              </p>
              <a
                href="http://help.jcurve.xyz/en/articles/4420690-how-to-fill-out-app-listing-information"
                target="_blank"
                className="text-primary-500 flex flex-row items-center"
              >
                <svg
                  className="w-4 h-4 mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <span>jay@jcurve.xyz</span>
              </a>
            </Card>
          </div>
        </div>
      </Page>
    )
  }

  return (
    <Page title="Connect Developer Account">
      <div className="flex flex-row flex-wrap -mx-3">
        <div className="px-3 lg:w-8/12">
          <div className="grid grild-cols-1 gap-4">{renderDeveloper()}</div>
        </div>
        <div className="px-3 lg:w-4/12">
          <Card>
            <p className="text-gray-800">
            FAQ
            </p>
            <br></br>
            <a
              href="http://help.jcurve.xyz/en/articles/3744356-how-to-create-an-apple-developer-account"
              target="_blank"
              className="text-primary-500 flex flex-row items-center"
            >
              <span>Making Apple Developer Account</span>
            </a>
            <br></br>
            <a
              href="http://help.jcurve.xyz/en/articles/4089532-how-to-create-a-google-play-developer-account"
              target="_blank"
              className="text-primary-500 flex flex-row items-center"
            >
              <span>Making Google Developer Account</span>
            </a>
          </Card>
        </div>
      </div>
      <PageActions
        actions={[
          {
            content: "Previous",
            onAction: () => navigate("/app/listing?edit=true"),
          },
          {
            loading: loading,
            content: "Save & Next",
            primary: true,
            onAction: () => handleSelectionPlatformHasAccount(),
          },
        ]}
      />
    </Page>
  )
}

export default connect(undefined, { openDialog })(ListingDeveloperPage)
