import { connect } from "react-redux"

import ScreenCanvas from "design/components/ScreenCanvas"

import { updateBlock, removeBlock } from "design/redux"
import { openModal } from "modal/redux"

const mapStateToProps = state => {
  const selectedSection = state.design.selectedSection
  const section = state.design.sections.byId[selectedSection]
  const blockIds = section ? section.blocks : []
  const blocks =
    blockIds.length > 0
      ? blockIds.map(blockId => {
          return state.design.blocks.byId[blockId]
        })
      : []

  return {
    section,
    blocks,
    isLoaded: state.design.blocks.isLoaded,
    isEmpty: state.design.blocks.isEmpty,
  }
}

const mapDispatchToProps = { removeBlock, openModal, updateBlock }

export default connect(mapStateToProps, mapDispatchToProps)(ScreenCanvas)
