import React from "react"
import tw, { styled, css } from "twin.macro"
import { connect, useSelector } from "react-redux"
import LogoSpinner from "general/components/LogoSpinner"
import { isLoaded, isEmpty } from "react-redux-firebase"

import { authenticated } from "auth/redux"

import Heading from "general/components/Heading"
import SignupForm from "auth/components/SignupForm"

function SignupPage(props) {
  const auth = useSelector(({ firebase }) => firebase.auth)
  const profile = useSelector(({ firebase }) => firebase.profile)
  const { authenticated } = props

  if (!isLoaded(auth) || !isLoaded(profile)) {
    return (
      <div className="h-screen flex flex-1 items-center justify-center">
        <LogoSpinner />
      </div>
    )
  }

  if (isLoaded(auth) && isEmpty(auth)) {
    return "Undefined"
  }

  return (
    <div css={tw`flex flex-col justify-center items-center min-h-screen`}>
      <div
        css={[
          tw`w-full max-w-xs bg-white px-8 py-8 shadow-md`,
          css`
            min-width: 320px;
            max-width: 420px;
          `,
        ]}
      >
        <div className="mb-8">
          <Heading>Start Making Your Mobile App</Heading>
          <p className="text-gray-600">
            Get started absolutely free for 30 days.
          </p>
        </div>
        <SignupForm authenticated={authenticated} />
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  authenticated,
}

export default connect(undefined, mapDispatchToProps)(SignupPage)
