import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  forwardRef,
} from "react"
import { connect } from "react-redux"
import { createPortal } from "react-dom"
import styled from "styled-components"
import tw from "twin.macro"
import produce from "immer"
import { v4 as uuidv4 } from "uuid"
import { isUndefined, isNull } from "lodash"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { useForm } from "react-hook-form"
import Modal from "general/components/Modal"
import TextField from "general/components/TextField"
import { updateSections } from "design/redux"
import IconHandle from "assets/svgs/icons/icon-handle.svg"

const ModalBody = styled.div`
  ${tw`block py-4 px-4`}
`
const RemoveButton = styled.button`
  ${tw`inline-block text-xs leading-none `}
  width: 42px;
  height: 42px;
`
const HandleButton = styled.button`
  ${tw`inline-flex items-center justify-center text-center outline-none`}
  width: 42px;
  height: 42px;
`

const SectionItemRight = styled.div`
  ${tw`inline-flex self-end invisible`}
`

const SectionItemWrapper = styled.div`
  &:hover {
    ${SectionItemRight} {
      ${tw`visible`}
    }
  }
`

const portal = typeof window !== "undefined" && document.createElement("div")
portal && portal.classList.add("portal-multipage-dnd")

if (typeof window !== "undefined" && !document.body) {
  throw new Error("body not ready for portal creation!")
}

typeof window !== "undefined" && document.body.appendChild(portal)

const SectionItem = forwardRef(
  (
    {
      errors,
      provided,
      snapshot,
      index,
      sectionId,
      title,
      onChange,
      removeSection,
      isRemoveInvisible,
    },
    ref
  ) => {
    const usePortal = snapshot.isDragging
    const child = (
      <div ref={provided.innerRef} {...provided.draggableProps} css={tw`mb-4`}>
        <SectionItemWrapper css={tw`flex flex-row items-center`}>
          <HandleButton {...provided.dragHandleProps} type="button">
            <IconHandle />
          </HandleButton>
          <div css={tw`flex flex-col w-full`}>
            <TextField
              name={`input${sectionId}`}
              label={`Page ${index + 1} title`}
              type="text"
              ref={ref}
              onChange={event => onChange(event.target.value, sectionId)}
              value={title}
              maxLength={20}
              error={
                errors &&
                errors[`input${sectionId}`] &&
                "This field is required"
              }
            />
          </div>
          <SectionItemRight
            css={errors && errors[`input${sectionId}`] && tw`self-center`}
          >
            <RemoveButton
              onClick={removeSection}
              type="button"
              css={isRemoveInvisible && tw`invisible`}
            >
              <i className="icon-cancel" css={tw`text-primary-500`} />
            </RemoveButton>
          </SectionItemRight>
        </SectionItemWrapper>
      </div>
    )

    if (!usePortal) {
      return child
    }

    return createPortal(child, portal)
  }
)

const LIMIT_SECTIONS = 5

function EditMultipageModal({
  isOpen,
  onRequestClose,
  sections,
  updateSections,
}) {
  const formRef = useRef(null)
  const [nextSections, setNextSections] = useState(sections)
  const { register, handleSubmit, errors } = useForm()

  useEffect(() => {
    setNextSections(sections)
  }, [])

  const customStyles = {
    overlay: {
      background: "rgba(33,43,54,.4)",
      zIndex: 500,
    },
    content: {
      width: "100%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      padding: 0,
      transform: "translate(-50%, -50%)",
      overflow: "visible",
      zIndex: 1000,
      maxWidth: "640px",
      borderRadius: "0.5rem",
    },
  }

  const addSection = () => {
    const INIT_SECTION = {
      id: uuidv4(),
      title: "",
      blocks: [],
    }

    if (nextSections.ids.length >= LIMIT_SECTIONS) {
      return false
    }

    setNextSections(
      produce(draft => {
        draft.byId[INIT_SECTION.id] = INIT_SECTION
        draft.ids.push(INIT_SECTION.id)
      })
    )
  }

  const removeSection = sectionId => {
    if (nextSections.ids.length < 2) {
      return false
    }

    if (sectionId) {
      setNextSections(
        produce(draft => {
          delete draft.byId[sectionId]
          const index = draft.ids.findIndex(id => id === sectionId)
          draft.ids.splice(index, 1)
        })
      )
    }
  }

  const swapSection = (from, to) => {
    setNextSections(
      produce(draft => {
        if (to < 0) return draft.ids

        const dragged = draft.ids[from]

        draft.ids.splice(from, 1)
        draft.ids.splice(to, 0, dragged)
      })
    )
  }

  const onDragEnd = result => {
    if (isUndefined(result) || isNull(result.destination)) {
      return false
    }

    if (result.source.index !== result.destination.index) {
      swapSection(
        result.source.index,
        result.destination.index,
        result.draggableId
      )
    }
  }

  const handleTitleChange = useCallback((title, sectionId) => {
    setNextSections(
      produce(draft => {
        draft.byId[sectionId].title = title
      })
    )
  }, [])

  const saveMultipage = () => {
    updateSections(nextSections)
    onRequestClose()
  }

  const handlePrimary = () => {
    handleSubmit(saveMultipage)()
    // saveMultipage()
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      title={"Edit Multipage"}
      contentLabel="Edit Multipage"
      size={"md"}
      primaryAction={{
        content: "Save",
        onAction: () => handlePrimary(),
      }}
    >
      <ModalBody>
        <form ref={formRef} onSubmit={handleSubmit(saveMultipage)}>
          <DragDropContext onDragEnd={onDragEnd}>
            <div>
              <Droppable droppableId={"multipage"}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    snapshot={snapshot}
                    {...provided.droppableProps}
                  >
                    {nextSections.ids.map((sectionId, index) => {
                      return (
                        <Draggable
                          draggableId={sectionId}
                          index={index}
                          key={sectionId}
                        >
                          {(provided, snapshot) => (
                            <SectionItem
                              ref={register({
                                required: "Required",
                              })}
                              errors={errors}
                              sectionId={sectionId}
                              removeSection={() => removeSection(sectionId)}
                              index={index}
                              isRemoveInvisible={nextSections.ids.length === 1}
                              title={nextSections.byId[sectionId].title}
                              onChange={handleTitleChange}
                              provided={provided}
                              snapshot={snapshot}
                            />
                          )}
                        </Draggable>
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div
              css={[
                tw`text-center`,
                nextSections.ids.length >= LIMIT_SECTIONS && tw`hidden`,
              ]}
            >
              <button
                type="button"
                css={tw`focus:outline-none text-primary-500 hover:text-primary-600 text-lg font-semibold uppercase`}
                onClick={() => addSection()}
              >
                Add new page
              </button>
            </div>
          </DragDropContext>
        </form>
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = state => {
  return {
    sections: state.design.sections,
  }
}

const mapDispatchToProps = {
  updateSections,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMultipageModal)
