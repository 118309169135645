import { connect } from "react-redux"
import { get } from "lodash"

import Multipage from "design/components/MultiPage"
import { getSectionList } from "design/selector"
import { setSelectedSection, validateBlocks } from "design/redux"
import { openModal } from "modal/redux"

const mapStateToProps = state => {
  return {
    disabled: state.design.isMenuActive,
    isLoaded: state.design.blocks.isLoaded,
    sections: getSectionList(state),
    selectedSection: state.design.selectedSection,
    isMultipageActive: get(state, "firebase.profile.settings.multipage", false),
  }
}

const mapDispatchToProps = { setSelectedSection, openModal, validateBlocks }

export default connect(mapStateToProps, mapDispatchToProps)(Multipage)
