import React, { useState, useCallback, useEffect } from "react"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import { debounce } from "lodash"
import { connect, useSelector } from "react-redux"
import { DragDropContext } from "react-beautiful-dnd"
import { useSpring, animated, interpolate } from "react-spring"
import { toast } from "react-toastify"
import { get } from "lodash"

import ScreenSwitch from "./ScreenSwitch"
import Multipage from "./Multipage"
import ScreenMenu from "design/containers/ScreenMenu"
import ScreenHeader from "./ScreenHeader"
import ScreenTabs from "./ScreenTabs"
import ScreenCanvas from "./ScreenCanvas"
import SaveSuccess from "assets/images/feedback/save-success.png"

import EditorSidebar from "design/components/Editor/EditorSidebar"
import EditorSettings from "design/components/Editor/EditorSettings"
import EditorFooter from "design/components/Editor/EditorFooter"
import FeedbackModal from "modal/components/FeedbackModal"
import LogoSpinner from "general/components/LogoSpinner"
import Overlay from "general/components/Overlay"

import {
  addBlock,
  swapBlock,
  saveDesign,
  updateUI,
  getDesign,
  setSelectedSection,
  validateBlocks,
  setDesignStatus,
} from "design/redux"
import { openDialog, closeDialog } from "dialog/redux"
import { navigate } from "gatsby"

const Wrapper = styled.div`
  ${tw`relative h-full bg-gray-100`}/* padding-left: calc(312px + 16px);

  @media (min-width: 1280px) {
    padding-left: calc(360px);
  } */
`

const Content = styled.div`
  ${tw`flex flex-col flex-grow bg-gray-100 min-h-screen`}
  flex: 1 1 auto;
  overflow-y: auto;
`

const Screen = ({ children }) => {
  return (
    <div
      css={[
        tw`pb-24 min-h-screen mx-auto`,
        css`
          width: 100%;
          max-width: 360px;
        `,
      ]}
    >
      {children.map((item, index) => (
        <div css={tw`mt-6`} key={index}>
          {item}
        </div>
      ))}
    </div>
  )
}

function Editor(props) {
  const {
    selectedSection,
    logo,
    primaryColor,
    isMenuActive,
    isDesignChanged,
    isMenuChanged,
    isSupportMultipage,
    isMultipageActive,
    blockCount,
    status,
    afterAction,
  } = props
  const {
    addBlock,
    swapBlock,
    saveDesign,
    updateUI,
    getDesign,
    setSelectedSection,
    validateBlocks,
    setDesignStatus,
    openDialog,
    closeDialog,
  } = props
  const [isSidebarFixed, setIsSidebarFixed] = useState(false)
  const [isSettingsVisible, setIsSettingsVisible] = useState(true)
  const [placeholderProps, setPlaceholderProps] = useState({})
  const [preventOverflow, setPreventOverflow] = useState(false)
  const [saveFeedback, setSaveFeedback] = useState(false)
  const profile = useSelector(state => state.firebase.profile)
  const version = get(profile, "settings.current_version")
  const isDesignLoading = status === "loading"

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  const BLOCK_LIMIT = get(
    profile,
    "design_block_limit",
    isMultipageActive
      ? process.env.GATSBY_MULTIPAGE_BLOCK_LIMIT
      : process.env.GATSBY_BLOCK_LIMIT
  )

  useEffect(() => {
    if (status && status === "saved") {
      if (afterAction) {
        const afterActionType = get(afterAction, "type", undefined)

        if (afterActionType === "redirect") {
          navigate(afterAction.action)
          setDesignStatus("loaded")
        }
      } else {
        return setSaveFeedback(true)
      }
    }
  }, [status])

  useEffect(() => {
    const onResizeListener = event => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }

    window.addEventListener("resize", onResizeListener)

    return () =>
      window.removeEventListener("resize", debounce(onResizeListener, 400))
  }, [])

  useEffect(() => {
    const onScrollListener = event => {
      const { scrollTop } = event.target.documentElement
      const SITE_HEADER_HEIGHT = 89

      setIsSidebarFixed(scrollTop > SITE_HEADER_HEIGHT)
    }

    window.addEventListener("scroll", onScrollListener)

    return () =>
      window.removeEventListener("scroll", debounce(onScrollListener, 200))
  }, [])

  const getDraggedDom = draggableId => {
    const domQuery = `[data-rbd-drag-handle-draggable-id='${draggableId}']`
    const draggedDOM = document.querySelector(domQuery)

    return draggedDOM
  }

  const handleDragEnd = useCallback(
    result => {
      const { destination, source, draggableId } = result

      setPlaceholderProps({})

      if (!destination) {
        return false
      }

      const sourceDroppableId = source.droppableId

      if (sourceDroppableId === "BLOCKS") {
        const type = draggableId
        const section = destination.droppableId
        const order = destination.index

        if (blockCount + 1 > BLOCK_LIMIT) {
          return toast(
            `You can add block up to ${BLOCK_LIMIT}. ${BLOCK_LIMIT} is beautiful number in mobile app.`
          )
        }

        addBlock({ type, section, order })
      } else {
        const from = source.index
        const to = destination.index
        const section = destination.droppableId

        swapBlock({ from, to, section })
      }
    },
    [addBlock, swapBlock, blockCount]
  )
  const handleDragStart = event => {
    const draggedDOM = getDraggedDom(event.draggableId)
    let parentNode = draggedDOM.parentNode

    if (event.source.droppableId === "BLOCKS") {
      parentNode = document.querySelector(
        `[data-section-id='${selectedSection}']`
      )
    }

    if (!draggedDOM) {
      return
    }

    const { clientWidth, clientHeight } = draggedDOM
    const sourceIndex = event.source.index
    const dropZone =
      event.source.droppabledId === "BLOCKS"
        ? draggedDOM.parentNode
        : draggedDOM.parentNode

    const clientY =
      parseFloat(window.getComputedStyle(dropZone).paddingTop) +
      [...dropZone.children].slice(0, sourceIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr)
        const marginBottom = parseFloat(style.marginBottom)

        return total + curr.clientHeight + marginBottom
      }, 0)

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    })
  }

  const handleDragUpdate = event => {
    if (!event.destination) {
      return
    }
    const draggedDOM = getDraggedDom(event.draggableId)
    let parentNode = draggedDOM.parentNode

    if (event.source.droppableId === "BLOCKS") {
      parentNode = document.querySelector(
        `[data-section-id='${selectedSection}']`
      )
    }

    if (!draggedDOM) {
      return
    }

    const { clientHeight, clientWidth } = draggedDOM
    const destinationIndex = event.destination.index
    const sourceIndex = event.source.index
    const childrenArray = [...parentNode.children]

    let updatedArray = childrenArray

    if (event.source.droppableId !== "BLOCKS") {
      const movedItem = childrenArray[sourceIndex]
      childrenArray.splice(sourceIndex, 1)

      updatedArray = [
        ...childrenArray.slice(0, destinationIndex),
        movedItem,
        ...childrenArray.slice(destinationIndex + 1),
      ]
    }

    const clientY =
      parseFloat(window.getComputedStyle(parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr)
        const marginBottom = parseFloat(style.marginBottom)

        return total + curr.clientHeight + marginBottom
      }, 0)

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY: clientY,
      clientX: parseFloat(window.getComputedStyle(parentNode).paddingLeft),
    })
  }

  const { scale, transform } = useSpring({
    scale: isMenuActive ? 0.8 : 1,
    transform: isMenuActive ? "424px" : "0px",
    config: { duration: 200 },
    onRest: () => {
      isMenuActive ? setPreventOverflow(true) : setPreventOverflow(false)
    },
  })

  return (
    <Wrapper
      isSidebarFixed={isSidebarFixed}
      isSettingsVisible={isSettingsVisible}
    >
      {/* <animated.div
        style={useSpring({
          paddingRight: dimensions.width > 1000 && isSettingsVisible ? 260 : 0,
        })}
      > */}
      <DragDropContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragUpdate={handleDragUpdate}
      >
        <EditorSidebar isFixed={isSidebarFixed} disabled={isMenuActive} />
        <Content>
          <Screen>
            <ScreenSwitch />
            {isSupportMultipage && <Multipage />}
            <div
              css={[
                preventOverflow && tw`overflow-hidden`,
                tw`relative shadow-md bg-white border border-gray-200`,
                isMenuActive &&
                  css`
                    max-height: 616px;
                    overflow: hidden;
                  `,
              ]}
            >
              <div css={tw`overflow-y-scroll`}>
                <ScreenMenu />
              </div>
              <animated.div
                style={{
                  transform: interpolate(
                    [
                      scale.interpolate(s => `scale(${s})`),
                      transform.interpolate(t =>
                        t === "0px" ? 0 : `translateX(${t})`
                      ),
                    ],
                    (scale, translateX) => {
                      return translateX === 0
                        ? "none"
                        : `${scale} ${translateX}`
                    }
                  ),
                }}
                css={[
                  isMenuActive && [
                    tw`relative z-40 `,
                    css`
                      max-height: 616px;
                      overflow: hidden;
                      transform-origin: 0 308px;
                      box-shadow: 0 0 48px rgba(0, 0, 0, 0.25);
                    `,
                  ],
                ]}
              >
                <ScreenHeader />
                <ScreenTabs />
                <ScreenCanvas placeholderProps={placeholderProps} />
              </animated.div>
            </div>
          </Screen>
        </Content>
        <EditorSettings
          loading={isDesignLoading}
          version={version}
          disabled={isMenuActive}
          isFixed={isSidebarFixed}
          logo={logo}
          primaryColor={primaryColor}
          updateUI={updateUI}
          isSettingsVisible={isSettingsVisible}
          setIsSettingsVisible={setIsSettingsVisible}
        />
      </DragDropContext>
      <EditorFooter
        openDialog={openDialog}
        closeDialog={closeDialog}
        setSelectedSection={setSelectedSection}
        validateBlocks={validateBlocks}
        isDesignChanged={isDesignChanged}
        isMenuChanged={isMenuChanged}
        onSaveAction={() => saveDesign()}
        saveDesign={saveDesign}
        onDiscardAction={() => getDesign()}
      />
      <FeedbackModal
        isOpen={saveFeedback}
        title={"Good! Check the app in 15min!"}
        source={SaveSuccess}
        onRequestClose={() => setSaveFeedback(false)}
        primaryAction={{
          content: "Ok",
          onAction: () => setSaveFeedback(false),
        }}
        onAfterClose={() => setDesignStatus("loaded")}
      />
      {status === "saving" && (
        <div css={tw`fixed inset-0 flex items-center justify-center z-50`}>
          <LogoSpinner />
          <Overlay light />
        </div>
      )}
    </Wrapper>
  )
}

const mapStateToProps = state => {
  const blocks = state.design.sections.byId[state.design.selectedSection].blocks

  return {
    status: state.design.status,
    afterAction: state.design.afterAction,
    isSupportMultipage: get(
      state,
      "firebase.profile.settings.support_multi_page",
      false
    ),
    isDesignChanged: state.design.isDesignChanged,
    isMenuChanged: state.design.isMenuChanged,
    isMenuActive: state.design.isMenuActive,
    isMultipageActive: state.design.ui.multipage,
    selectedSection: state.design.selectedSection,
    logo: get(state, "design.ui.logo.image_url", ""),
    primaryColor: state.design.ui.colors.primary,
    blockCount: blocks ? blocks.length : 0,
  }
}

const mapDispatchToProps = {
  addBlock,
  swapBlock,
  saveDesign,
  updateUI,
  getDesign,
  setSelectedSection,
  validateBlocks,
  setDesignStatus,
  openDialog,
  closeDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor)
