import React, { useRef } from "react"
import styled, { css } from "styled-components"
import { useSelector } from "react-redux"
import tw from "twin.macro"
import { useFirebase } from "react-redux-firebase"
import { get, times, upperCase } from "lodash"
import Toggle from "general/components/Toggle"
import BlockSelector from "design/components/BlockSelector"
import IconEdit from "assets/images/design/icon-edit.png"
import { toast } from "react-toastify"

const Wrapper = styled.div`
  ${tw`relative border border-gray-200`}
  ${({ disabled }) =>
    disabled && [
      tw`overflow-hidden`,
      css`
        &::after {
          content: "";
          background-color: rgba(255, 255, 255, 0.8);
          ${tw`absolute block inset-0 z-50 `};
        }
      `,
    ]}
`
const Content = styled.div`
  ${tw`bg-white shadow-md px-4 py-4`}
`

const Title = styled.div`
  ${tw`font-medium text-lg text-gray-900`}
`

const TabButton = styled.button`
  ${tw`mx-1 py-1 px-2 text-sm font-medium rounded-md outline-none transition duration-100`}
  min-height: 29px;

  ${({ skeleton }) =>
    skeleton &&
    css`
      min-width: 58px;
    `}

  ${({ isActive }) =>
    isActive ? tw`bg-primary-500 text-white` : tw`bg-gray-200`}

  &:hover {
    ${tw`bg-primary-500 text-white`}
  }
  &:focus {
    ${tw`outline-none`}
  }
`

function MultiPage(props) {
  const {
    sections,
    selectedSection,
    isMultipageActive,
    isLoaded,
    disabled,
  } = props
  const { setSelectedSection, openModal, validateBlocks } = props
  const firebase = useFirebase()
  const blocks = useSelector(({ design }) => design.blocks.byId)
  const currentSection = useSelector(
    state => state.design.sections.byId[state.design.selectedSection]
  )

  const validToastRef = useRef(null)

  const handleMultipageChange = () => {
    const result = window.confirm("ALERT do you want to change?")

    if (result) {
      firebase.updateProfile({ settings: { multipage: !isMultipageActive } })
    }
  }

  const handleTabClick = id => {
    let isBlockValid = true
    let invalidBlockCount = 0
    const invalidBlocks = []
    toast.dismiss(validToastRef.current)

    const currentSectionBlocks = currentSection.blocks

    if (currentSectionBlocks.length > 0) {
      currentSectionBlocks.forEach(blockId => {
        const block = blocks[blockId]

        if (get(block, "isValid", false) === false) {
          invalidBlockCount++
          invalidBlocks.push(block)
        }
      })
    }
    console.log(invalidBlocks)

    if (invalidBlockCount > 0) {
      isBlockValid = false
    }

    if (isBlockValid === false) {
      validateBlocks()

      let message = ""

      invalidBlocks.forEach(item => {
        const type = upperCase(get(item, "type", ""))
        message = message.concat(`- <span class="text-sm">${type}</span><br/>`)
      })

      return (validToastRef.current = toast(
        <div
          dangerouslySetInnerHTML={{
            __html: `<span class="block mb-2">Please check the error or remove the empty block below:</span> ${message}`,
          }}
        ></div>,
        {
          closeOnClick: true,
          autoClose: false,
        }
      ))
    }
    setSelectedSection(id)
  }

  return (
    <Wrapper disabled={disabled}>
      <BlockSelector
        disabled={!isMultipageActive}
        controls={[
          {
            icon: IconEdit,
            title: "Edit Contents",
            action: () =>
              openModal({
                type: "EDIT_MULTIPAGE",
              }),
          },
        ]}
      >
        <Content>
          <div css={tw`flex flex-row justify-between items-center mb-4`}>
            <Title>Multi-page support</Title>
            <Toggle
              checked={isMultipageActive}
              onChange={handleMultipageChange}
            />
          </div>

          <div css={tw`block`}>
            <div css={tw`flex flex-wrap -mx-1 -my-1`}>
              {isLoaded ? (
                <>
                  {times(isMultipageActive ? 2 : 1, index => (
                    <div css={tw`my-1 inline-block leading-none`} key={index}>
                      <TabButton skeleton />
                    </div>
                  ))}
                </>
              ) : (
                sections.map(section => {
                  const isActive = selectedSection === section.id

                  return (
                    <div
                      css={tw`my-1 inline-block leading-none`}
                      key={section.id}
                    >
                      <TabButton
                        skeleton
                        isActive={isActive}
                        onClick={() => !isActive && handleTabClick(section.id)}
                      >
                        {section.title}
                      </TabButton>
                    </div>
                  )
                })
              )}
            </div>
          </div>
        </Content>
      </BlockSelector>
    </Wrapper>
  )
}

export default MultiPage
