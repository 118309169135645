import React from "react"
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"

const GraphqlProvider = props => {
  const { children, shop, token } = props

  const httpLink = createHttpLink({
    uri: `https://${shop}/api/2020-07/graphql.json`,
  })
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": token,
      },
    }
  })
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default GraphqlProvider
