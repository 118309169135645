import React from "react"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import { Droppable, Draggable } from "react-beautiful-dnd"
import { isEmpty, isUndefined } from "lodash"
import { useSelector } from "react-redux"

import Spinner from "general/components/Spinner"
import BlockSelector from "design/components/BlockSelector"
import BlockItem from "design/containers/BlockItem"
import ImageBgCanvas from "assets/images/design/bg-section-plceholder@2x.png"
import IconEdit from "assets/images/design/icon-edit.png"
import IconRemove from "assets/images/design/icon-remove.png"

const Canvas = styled.div`
  ${tw`relative bg-no-repeat`}
  background-color: #f6f4f5;
  min-height: 550px;
  background-position: center 30%;
  background-size: 70%;
  background-image: ${({ hasBlock, isLoaded }) => {
    if (isLoaded) {
      return "none"
    }

    if (hasBlock) {
      return "none"
    }

    return `url(${ImageBgCanvas})`
  }};
`

const Placeholder = styled.div`
  position: absolute;
  border: dashed 1px ${({ theme }) => theme.colors.primary[400]};
  background-color: ${({ theme }) => theme.colors.primary[100]};
`

function ScreenCanvas(props) {
  const { blocks, section, placeholderProps, isLoaded } = props
  const { updateBlock, removeBlock, openModal } = props
  const supportPortrait =
    useSelector(state => state.design.ui.supportPortrait) || false

  if (isLoaded) {
    return (
      <div
        css={[
          tw`flex items-center justify-center h-full `,
          css`
            height: 550px;
            background: #f6f4f5;
          `,
        ]}
      >
        <Spinner color="primary" />
      </div>
    )
  }

  const sectionId = section.id
  const hasBlock = blocks.length > 0 ? true : false

  return (
    <Droppable droppableId={sectionId}>
      {(provided, snapshot) => (
        <Canvas
          ref={provided.innerRef}
          isLoaded={isLoaded}
          hasBlock={hasBlock}
          data-section-id={sectionId}
        >
          {/* {isLoaded && (
            <div
              css={[
                tw`flex items-center justify-center h-full z-40 relative`,
                css`
                  height: 550px;
                  background: #f6f4f5;
                `,
              ]}
            >
              <Spinner color="primary" />
            </div>
          )} */}
          {/* {!isEmpty(placeholderProps) &&
            snapshot.isDraggingOver &&
            placeholderProps.new && (
              <Draggable key={blockId} draggableId={blockId} index={index}>
                {(provided, snapshot) => (
                  <Placeholder
                    className="placeholder"
                    style={{
                      top: placeholderProps.clientY,
                      left: placeholderProps.clientX,
                      height: placeholderProps.clientHeight,
                      width: placeholderProps.clientWidth,
                    }}
                  />
                )}
              </Draggable>
            )} */}
          {hasBlock
            ? blocks.map((block, index) => {
                const blockId = block.id
                let controls = [
                  {
                    icon: IconEdit,
                    title: "Edit Contents",
                    action: function () {
                      openModal({
                        type: "EDIT_BLOCK",
                        meta: { blockId },
                      })
                    },
                  },
                  {
                    icon: IconRemove,
                    title: "Remove Block",
                    action: function () {
                      removeBlock({ id: blockId })
                    },
                  },
                ]

                // if (block.type === "collection_list" && supportPortrait) {
                //   const nextOption =
                //     block.info.orientation === "horizontal"
                //       ? "vertical"
                //       : "horizontal"

                //   controls.splice(1, 0, {
                //     icon: IconEdit,
                //     title: `Show ${nextOption}`,
                //     action: () => {
                //       updateBlock({
                //         id: blockId,
                //         info: {
                //           orientation: nextOption,
                //         },
                //       })
                //     },
                //   })
                // }

                if (block.type === "collection") {
                  if (block.info.display_type === "vertical") {
                    const nextCount = block.info.item_count === 4 ? 2 : 4

                    controls.splice(1, 0, {
                      icon: IconEdit,
                      title: `Show ${nextCount} products`,
                      action: () => {
                        updateBlock({
                          id: blockId,
                          info: {
                            item_count: nextCount,
                          },
                        })
                      },
                    })
                  }
                }

                if (isUndefined(blockId)) {
                  return null
                }

                return (
                  <Draggable key={blockId} draggableId={blockId} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        snapshot={snapshot}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <BlockSelector id={blockId} controls={controls}>
                          <BlockItem
                            id={blockId}
                            error={block.error}
                            type={block.type}
                            info={block.info}
                            isValid={block.isValid}
                          />
                        </BlockSelector>
                      </div>
                    )}
                  </Draggable>
                )
              })
            : !provided.placeholder && <div>drop here</div>}

          {provided.placeholder}
          {!isEmpty(placeholderProps) && snapshot.isDraggingOver && (
            <Placeholder
              className="placeholder"
              style={{
                top: placeholderProps.clientY,
                left: placeholderProps.clientX,
                height: placeholderProps.clientHeight,
                width: placeholderProps.clientWidth,
              }}
            />
          )}
        </Canvas>
      )}
    </Droppable>
  )
}

export default React.memo(ScreenCanvas)
