import React, { Fragment } from "react"
import tw from "twin.macro"
import PropTypes from "prop-types"
import { includes } from "lodash"

const ChoiceList = React.forwardRef((props, ref) => {
  const { title, choices, selected, error, name } = props
  const { onChange } = props

  return (
    <div css={tw`block`}>
      {title && (
        <span css={tw`block text-base text-gray-900 font-medium mb-1`}>
          {title}
        </span>
      )}
      {choices.map(item => {
        const checked  = selected === item.value
        // const checked = includes(selected, item.value)

        return (
          <Fragment key={item.value}>
            <label css={tw`block py-1 text-gray-800`} key={item.value}>
              <input
                ref={ref}
                css={tw`form-radio`}
                type="radio"
                name={name ? name : "radio"}
                value={item.value}
                checked={checked}
                onChange={() => onChange(item.value)}
              />
              <span css={tw`ml-2`}>{item.label}</span>
            </label>
            {item.renderChildren && checked && (
              <div css={tw`ml-6`}>{item.renderChildren()}</div>
            )}
          </Fragment>
        )
      })}

      {error && <span css={tw`block mt-1 text-red-500`}>{error}</span>}
    </div>
  )
})

ChoiceList.defaultProps = {
  onChange: () => console.log("undefined"),
}

export default ChoiceList
