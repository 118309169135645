import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

const Button = styled.button`
  ${tw`flex-1 text-primary-500 self-stretch outline-none`}

  &:focus {
    ${tw`outline-none`}
  }
`

export default function LinkCollectionButton({ children, onAction }) {
  return (
    <div css={tw`flex items-center w-full justify-center self-stretch`}>
      <Button type={"button"} onClick={onAction}>
        {children}
      </Button>
    </div>
  )
}
