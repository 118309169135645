import { connect } from "react-redux"

import PictureSizeSwitch from "design/components/PictureSizeSwitch"
import { updateUI } from "design/redux"

const mapStateToProps = state => {
  return {
    imageScaleFilled: state.design.ui.imageScaleFilled,
  }
}

const mapDispatchToProps = { updateUI }

export default connect(mapStateToProps, mapDispatchToProps)(PictureSizeSwitch)
