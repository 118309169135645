import React, { useState, useEffect } from "react"
import tw, { styled, css } from "twin.macro"
import { get, isEqual } from "lodash"
import produce from "immer"
import JcurveLogo from "assets/images/push/logo-jcurve.png"

const Bubble = styled.div`
  padding: 10px;
  backdrop-filter: blur(30px);
  background-color: #ffffffc0;
  border-radius: 10px;
  position: absolute;
  top: 173px;
  z-index: 20;
  width: 235px;
  margin: 0 7px;
  overflow: hidden;
  transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;

  ${({ expended }) => {
    return expended
      ? css`
          backdrop-filter: none;
          top: 81px;
          background-color: #fff;
        `
      : css``
  }}
`

const TextBody = styled.div`
  ${tw`block`}
  min-height: 30px;

  ${({ hasImage, expended }) =>
    hasImage &&
    !expended &&
    css`
      padding-right: 48px;
    `}
`

const Title = styled.h4`
  ${tw`truncate overflow-hidden`}
  font-size: 11px;
  color: #1e1e1e;
`

const Message = styled.p`
  ${tw`leading-tight block overflow-hidden break-words`}
  font-size: 11px;
  color: #575757;
`

const Image = styled.img`
  ${tw`ml-2 bg-gray-100 float-right absolute top-0 right-0 object-cover`}
  width: 30px;
  height: 30px;
`
export default function PushBubble({
  title,
  message,
  appName,
  appIcon,
  thumbnails,
  expended,
}) {
  const [preview, setPreview] = useState({
    index: 0,
    thumbnails,
  })

  useEffect(() => {
    if (expended) {
      const switchImage = () => {
        if (
          thumbnails &&
          thumbnails.length > 0 &&
          preview.index < thumbnails.length - 1
        ) {
          return setPreview(
            produce(draft => {
              draft.index = preview.index + 1
            })
          )
        }

        return setPreview(
          produce(draft => {
            draft.index = 0
          })
        )
      }

      const interval = setInterval(switchImage, 2000)
      return () => clearInterval(interval)
    } else {
      setPreview(
        produce(draft => {
          draft.index = 0
        })
      )
    }
  }, [expended, preview])

  useEffect(() => {
    if (!isEqual(thumbnails, preview.thumbnails)) {
      setPreview(
        produce(draft => {
          draft.thumbnails = thumbnails
        })
      )
    }
  }, [thumbnails, preview])

  console.log("appIcon", appIcon)
  return (
    <div>
      <Bubble expended={expended}>
        <div className="flex flex-row mb-1">
          <img
            src={appIcon ? appIcon : JcurveLogo}
            alt=""
            css={css`
              width: 15px;
              height: 15px;
              border-radius: 5px;
              padding-left: 0;
              display: inline-block;
              object-fit: cover;
              border: 0 !important;
              background: white;
            `}
          />
          <span
            css={css`
              width: 90%;
              max-width: 195px;
              height: 15px;
              font-size: 9px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: 15px;
              letter-spacing: -0.1px;
              text-align: left;
              color: #575757;
              text-transform: uppercase;
              overflow: hidden;
              word-wrap: break-word;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              padding-left: 5px;
              float: left;
            `}
          >
            {appName}
          </span>
        </div>
        <div css={tw`relative block`}>
          <TextBody
            hasImage={thumbnails && thumbnails.length > 0}
            expended={expended}
          >
            {expended && (
              <div
                css={css`
                  margin-left: -10px;
                  margin-right: -10px;
                `}
              >
                <div css={tw`pb-1/1 bg-gray-100 relative mb-1 overflow-hidden`}>
                  {preview.thumbnails && preview.thumbnails.length > 0 && (
                    <img
                      src={preview.thumbnails[preview.index].node.src}
                      alt=""
                      css={tw`absolute inset-0 object-cover`}
                    />
                  )}
                </div>
              </div>
            )}
            <Title>{title}</Title>
            <Message>{message}</Message>
          </TextBody>
          {!expended && preview.thumbnails && preview.thumbnails.length > 0 && (
            <Image src={preview.thumbnails[0].node.src} />
          )}
        </div>
      </Bubble>
    </div>
  )
}
