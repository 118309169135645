import React, { forwardRef } from "react"
import styled, { css } from "styled-components"
import tw from "twin.macro"

const Wrapper = styled.div`
  ${tw`flex items-end px-2 text-gray-900 font-medium text-base uppercase`}
  flex: 0 0 auto;
  margin-bottom: -1px;

  div {
    ${tw`border-b-4 pb-1`}

    ${({ isActive }) =>
      isActive ? tw`border-primary-500` : tw`border-transparent`}

    span {
      ${({ skeleton }) => {
        if (skeleton) {
          return [
            tw`inline-block bg-gray-100`,
            css`
              width: 50px;
              height: 24px;
            `,
          ]
        }
      }}
    }
  }
`

const ScreenTab = forwardRef(({ skeleton, title, isActive }, ref) => {
  return (
    <Wrapper skeleton={skeleton} isActive={isActive} ref={ref}>
      <div css={tw`inline-flex items-center justify-center`}>
        <span>{title}</span>
      </div>
    </Wrapper>
  )
})

export default ScreenTab
