import React, { useEffect } from "react"
import { get } from "lodash"
import { connect } from "react-redux"
import { closeModal } from "../redux"
import EditBlockModal from "modal/containers/EditBlockModal"
import EditMultipageModal from "modal/containers/EditMultipageModal"
import MenuModal from "modal/containers/MenuModal"

const MODAL_TYPES = {
  EDIT_BLOCK: EditBlockModal,
  EDIT_MULTIPAGE: EditMultipageModal,
  MENU: MenuModal,
}

function ModalProvider({ modal, closeModal }) {
  const { type, meta } = modal
  const isOpen = type ? true : false
  const ModalContent = get(MODAL_TYPES, type, undefined)

  useEffect(() => {
    if (isOpen) {
      document.body.className = "overflow-y-hidden"
    } else {
      document.body.className = ""
    }
    return () => {
      document.body.className = ""
    }
  }, [isOpen])

  const afterOpenModal = () => {}

  return (
    <div>
      {ModalContent && (
        <ModalContent
          meta={meta}
          isOpen={isOpen}
          onRequestClose={closeModal}
          onAfterOpen={afterOpenModal}
        />
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    modal: state.modal,
  }
}

const mapDispatchToProps = {
  closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalProvider)
