import React, { useState } from "react"
import { navigate } from "gatsby"
import tw from "twin.macro"
import { useSelector } from "react-redux"
import { get } from "lodash"
import { useForm } from "react-hook-form"
import axios from "axios"
import { useFirebase, useFirestore } from "react-redux-firebase"

import FormLayout from "general/components/FormLayout"
import TextField from "general/components/TextField"
import Button from "general/components/Button"
import { renderIntercomName } from "services/analytics"

export default function SignupForm(props) {
  const firebase = useFirebase()
  const firestore = useFirestore()
  const profile = useSelector(state => state.firebase.profile)
  let firstName = ""
  let lastName = ""
  const shopOwnerEmail = get(profile, "shop_owner.email", "")

  if (get(profile, "shop_owner.name", undefined)) {
    const fullName = get(profile, "shop_owner.name")

    firstName = fullName.split(" ").slice(0, -1).join(" ")
    lastName = fullName.split(" ").slice(-1).join(" ")
  }

  const [saveLoading, setSaveLoading] = useState(false)
  const [error, setError] = useState(null)
  const { handleSubmit, register, errors, watch } = useForm({
    defaultValues: {
      email: shopOwnerEmail,
      first_name: firstName,
      last_name: lastName,
    },
  })

  const fetch_retry = (url, options, n) =>
    fetch(url, options).catch(function (error) {
      if (n === 1) throw error
      return fetch_retry(url, options, n - 1)
    })

  const linkWithEmail = async credential => {
    try {
      const response = await firebase
        .auth()
        .currentUser.linkWithCredential(credential)

      if (response) {
        firebase.reloadAuth().then(() => {
          navigate("/app/sync")
        })
      }

      return response
    } catch (error) {
      const { code, message } = error

      if (code) {
        switch (code) {
          case "auth/email-already-in-use":
            setError(message)
            break
          case "auth/requires-recent-login":
            const idToken = await firebase.auth().currentUser.getIdToken(true)

            axios({
              method: "post",
              url: "/api/createCustomToken",
              data: {
                id_token: idToken,
              },
            }).then(response => {
              const customToken = response.data

              firebase
                .login({ token: customToken })
                .then(uid => {
                  if (uid) {
                    handleSubmit(onSubmit)()
                  }
                })
                .catch(error => {
                  // console.log("login error", error)
                  window.Sentry.captureException(error)
                })
            })
            break
          default:
            setError(message)
            setSaveLoading(false)
        }
      }
    }
  }

  const onSubmit = async values => {
    setSaveLoading(true)
    const { email, first_name, last_name, password } = values

    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    )

    const response = await linkWithEmail(credential)

    if (response) {
      const DB = firestore
      const shopify_url = profile.shopify_url
      const appInitialized = profile.shopify.appInitialized

      const shopOwnerRef = firestore.collection(shopify_url).doc("shop_owner")
      const userOwnerRef = firestore.collection("user_list").doc(shopify_url)

      const batch = DB.batch()

      batch.set(
        shopOwnerRef,
        {
          first_name,
          last_name,
        },
        { merge: true }
      )
      batch.set(
        userOwnerRef,
        {
          email,
          first_name,
          last_name,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      )

      batch
        .commit()
        .then(() => {
          const singup_intercom_name = `[${shopify_url.replace(".myshopify.com", "")}] ${first_name} ${last_name}`
          window.Intercom("update", {
            email,
            first_name: first_name,
            last_name: last_name,
            name: singup_intercom_name,
            website: shopify_url,
          })
          appInitialized ? navigate("/app/design") : navigate("/app/sync")
        })
        .catch(error => window.Sentry.captureException(error))
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormLayout>
        {error && (
          <div css={tw`bg-red-100 py-4 px-4 mb-4 rounded-md`}>
            {error.message}
          </div>
        )}
        <div>
          <div className="flex flex-row flex-wrap -mx-2">
            <div className="w-1/2 px-2">
              <TextField
                ref={register({ required: true })}
                label="First Name"
                name="first_name"
                placeholder="First Name"
              />
            </div>
            <div className="w-1/2 px-2">
              <TextField
                ref={register({ required: true })}
                label="Last Name"
                name="last_name"
                placeholder="Last Name"
              />
            </div>
          </div>
        </div>
        <TextField
          label="Email"
          type="email"
          name="email"
          placeholder="Email address"
          ref={register({
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Invalid email address",
            },
          })}
        />
        <TextField
          label="Password"
          type="password"
          name="password"
          placeholder="Password"
          ref={register({
            required: true,
            minLength: {
              value: 5,
              message: "min length is 5",
            },
          })}
        />
        <TextField
          label="Confirm password"
          type="password"
          name="confirm_password"
          placeholder="Repeat your password"
          ref={register({
            required: true,
            validate: value => value === watch("password"),
          })}
        />
        <div className="mt-8">
          <Button
            primary
            size="large"
            type="submit"
            fullWidth
            loading={saveLoading}
            disabled={saveLoading}
          >
            Sign up
          </Button>
        </div>
      </FormLayout>
    </form>
  )
}
