import React, { useState } from "react"
import PropTypes from "prop-types"
import tw, { styled, css } from "twin.macro"
import { useQuery } from "@apollo/client"
import InfiniteScroll from "react-infinite-scroller"
import { get, includes } from "lodash"

import Button from "general/components/Button"
import Spinner from "general/components/Spinner"

import noImage from "assets/images/design/block/noimage.png"
import { COLLECTIONS, PRODUCTS } from "shopify/queries"

const TYPES = {
  collections: COLLECTIONS,
  products: PRODUCTS,
}

const Content = styled.div`
  ${tw`z-40 absolute w-full left-0 right-0 bg-white overflow-y-scroll overflow-x-hidden border border-gray-300 shadow-md mt-1`}
  ${({ allowMultiple }) =>
    allowMultiple && tw`mt-4 absolute left-0 right-0 border border-gray-300 `}
  ${({ isActive }) => (isActive ? tw`block` : tw`hidden`)}
  height: 320px;
`

const ShopifySearch = React.forwardRef((props, ref) => {
  const {
    allowMultiple,
    onChange,
    selected,
    resourceType,
    error,
    onInputFocus,
  } = props

  const [isActive, setIsActive] = useState(false)
  const [query, setQuery] = useState("")

  const { loading, data, fetchMore } = useQuery(TYPES[resourceType], {
    variables: {
      first: 10,
      query: query,
    },
  })

  const getLastItem = arr => arr[arr.length - 1]

  const handleLoadMore = () => {
    fetchMore({
      variables: {
        query: query,
        after:
          data[resourceType].edges.length > 0
            ? getLastItem(data[resourceType].edges).cursor
            : undefined,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult[resourceType].edges
        const pageInfo = fetchMoreResult[resourceType].pageInfo

        const previousEdges = get(previousResult[resourceType], "edges", [])

        return newEdges.length
          ? {
              [resourceType]: {
                __typename: get(
                  previousResult[resourceType],
                  "__typename",
                  undefined
                ),
                edges: [...previousEdges, ...newEdges],
                pageInfo,
              },
            }
          : previousResult
      },
    })
  }

  const handleChange = event => {
    const query = event.target.value

    if (allowMultiple) {
      if (query.length === 0) {
        setIsActive(false)
      }

      if (query.length > 0) {
        setIsActive(true)
      }
    }

    setQuery(query)
  }

  const handleClick = (event, data) => {
    if (includes(selected, event)) {
      onChange(selected.filter(item => item !== event))

      return false
    }

    if (allowMultiple) {
      return onChange(item => [...item, event])
    }

    onChange([event], data)

    if (!allowMultiple) {
      setIsActive(false)
    }
  }

  const getThumbnail = item => {
    let thumbnail = noImage

    switch (resourceType) {
      case "collections":
        thumbnail = get(item, "node.image.transformedSrc", noImage)
        break
      case "products":
        thumbnail = get(
          item,
          "node.images.edges[0].node.transformedSrc",
          noImage
        )
        break
      default:
        break
    }

    return thumbnail
  }

  return (
    <div>
      {isActive && (
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            width: "100%",
          }}
          onClick={() => {
            setIsActive(false)
          }}
        />
      )}
      <div css={tw`relative z-20`}>
        <div>
          <label>
            <input
              ref={ref}
              placeholder={`Search ${resourceType}`}
              type="text"
              css={tw`form-input block w-full`}
              onChange={handleChange}
              value={query}
              onFocus={() => {
                onInputFocus && onInputFocus()
                setIsActive(true)
              }}
            />
            {error && (
              <span css={tw`block mt-1 text-red-500 text-sm`}>{error}</span>
            )}
          </label>
        </div>
        <Content isActive={isActive}>
          {loading && (
            <div css={tw`flex flex-col items-center justify-center h-full`}>
              <Spinner color="primary" />
            </div>
          )}

          {get(data, `${resourceType}.edges`, false) &&
            data[resourceType].edges.length === 0 && (
              <div css={tw`flex flex-col items-center justify-center h-full`}>
                <h2 css={tw`text-2xl font-medium`}>
                  Could not find any {resourceType}.
                </h2>
                <p css={tw`text-lg`}>Try changing search terms</p>
              </div>
            )}

          {data && (
            <InfiniteScroll
              pageStart={0}
              loadMore={handleLoadMore}
              hasMore={true || false}
              loader={
                data[resourceType].pageInfo.hasNextPage && (
                  <div className="loader" key={0} css={tw`text-center py-4`}>
                    Loading ...
                  </div>
                )
              }
              useWindow={false}
            >
              {data[resourceType].edges.map(item => (
                <div
                  onClick={() => handleClick(item.node.id, item)}
                  key={item.node.id}
                  css={tw`flex flex-row flex-wrap w-full justify-between items-center border-b hover:bg-red-100 cursor-pointer py-2 px-2`}
                >
                  <div
                    css={[
                      tw`flex flex-row items-center truncate flex-1`,
                      css`
                        min-width: 0;
                      `,
                    ]}
                  >
                    <div css={tw`w-12 mr-4`}>
                      <div
                        css={tw`relative border border-gray-100 rounded-md overflow-hidden`}
                      >
                        <div css={tw`pb-1/1 bg-white`}></div>
                        <img
                          src={getThumbnail(item)}
                          css={tw`absolute inset-0 object-cover w-full h-full`}
                          alt={item.node.title}
                        />
                      </div>
                    </div>
                    <h3
                      css={[
                        tw`text-gray-800 font-normal truncate flex-1 pr-4`,
                        css`
                          min-width: 0;
                        `,
                      ]}
                    >
                      {item.node.title}
                    </h3>
                  </div>
                  <div css={tw``}>
                    {includes(selected, item.node.id) ? (
                      <Button size="slim" primary>
                        Remove
                      </Button>
                    ) : (
                      <Button size="slim" outline>
                        Add
                      </Button>
                    )}
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          )}
        </Content>
      </div>
    </div>
  )
})

ShopifySearch.propTypes = {
  resourceType: PropTypes.string,
  onChange: PropTypes.func,
}

ShopifySearch.defaultProps = {
  resourceType: "collections",
  onChange: () => console.error("undefined"),
}

export default ShopifySearch
