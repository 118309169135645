import React, { useState, useEffect } from "react"
import tw, { css, styled } from "twin.macro"
import { useSelector } from "react-redux"
import { get, includes, isNull } from "lodash"
import { navigate } from "gatsby"
import { toast } from "react-toastify"
import firebase from "gatsby-plugin-firebase"
import { track } from "services/analytics"
import { getPlan } from "auth/services"

import Page from "general/components/Page"
import Button from "general/components/Button"
import StatusCard from "listing/components/StatusCard"

import ImgSmartbanner from "assets/images/listing/smartbanner.png"
import ImgPromoteReview from "assets/images/listing/promote-review.png"
import ImgIconIos from "assets/images/listing/icon-ios.png"
import ImgIconAndroid from "assets/images/listing/icon-android.png"
import axios from "axios"

const Card = styled.div`
  ${tw`relative rounded-md shadow-md bg-white overflow-hidden`}
`

const PlatformColor = styled.span`
  color: ${({ color }) => {
    switch (color) {
      case "ios":
        return "#617079"
      case "android":
        return "#79c257"
      default:
        return "#000"
    }
  }};
`

const getIcon = platform => {
  switch (platform) {
    case "ios":
      return (
        <img src={ImgIconIos} alt="iOS" className="block mx-auto w-16 mb-4" />
      )
    case "android":
      return (
        <img
          src={ImgIconAndroid}
          alt="Android"
          className="block mx-auto w-16 mb-4"
        />
      )
    default:
      return null
  }
}

const Hide = ({ platform }) => {
  const getLabel = () => {
    if (platform === "ios") {
      return "iOS"
    }

    if (platform === "android") {
      return "Android"
    }
  }

  return (
    <div
      className="absolute inset-0 flex justify-center items-center px-8 py-8"
      css={css`
        background-image: linear-gradient(
          to bottom,
          #ffffffb2,
          #fffffff3 33%,
          #fffffff6
        ) !important;
      `}
    >
      <div>
        {getIcon(platform)}
        <h2 className="text-center text-2xl font-semibold leading-snug">
          Launch <PlatformColor color={platform}>{getLabel()}!</PlatformColor>
          <br />
          Double(2X) <br />
          your customer base.
        </h2>
        <div className="text-center mt-4">
          <Button size="slim" primary onClick={() => navigate("/app/pricing")}>
            <span className="uppercase font-semibold">Launch now</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default function ListingStatusPage() {
  const [loading, setLoading] = useState(true)
  const [statusContent, setStatusContent] = useState(null)
  const [loadingSmartbanner, setLoadingSmartbanner] = useState(false)

  const profile = useSelector(state => state.firebase.profile)
  const { shopify_url, payment } = profile
  const paymentPlatform = get(payment, "platform", undefined)
  const plan = get(payment, "plan")
  const applisting = useSelector(
    ({ firestore: { data } }) =>
      data[shopify_url] && data[shopify_url].applisting
  )

  const {
    ios_status_step,
    android_status_step,
    android_appstore_link,
    ios_appstore_link,
  } = applisting

  useEffect(() => {
    track("VIEW_PAGE_APPSTATUS")
  }, [])

  const handleInstallSmartBanner = () => {
    if (!paymentPlatform) {
      return toast(
        "Please wait. When app is live, you can install the smart app banner."
      )
    }
    if (paymentPlatform === "android" && !android_appstore_link) {
      return toast(
        "Please wait. When app is live, you can install the smart app banner."
      )
    }

    if (paymentPlatform === "ios" && !ios_appstore_link) {
      return toast(
        "Please wait. When app is live, you can install the smart app banner."
      )
    }

    if (
      paymentPlatform === "all" &&
      !android_appstore_link &&
      !ios_appstore_link
    ) {
      return toast(
        "Please wait. When app is live, you can install the smart app banner."
      )
    }
    setLoadingSmartbanner(true)
    const token = firebase.auth().currentUser.getIdToken(true)

    axios({
      method: "post",
      url: `/api/updateScopes`,
      data: {
        shop: profile.shopify_url,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        setLoadingSmartbanner(false)
        if (response.status === 200) {
          window.top.location.href = response.data.body
        }
      })
      .catch(error => {
        setLoadingSmartbanner(false)
      })
  }

  return (
    <Page title="Application Status">
      <div className="mb-8">
        <Card>
          <div className="py-4 px-4">
            <h3 className="text-gray-900 font-medium text-xl">
              Where is my app? Please check your app status.
            </h3>
            <ul className="list-disc ml-6 text-gray-600">
              <li>iOS app will be live in 10~14 days on Apple Appstore.</li>
              <li>
                Android app will be live in 10~14 days on Google PlayStore.
              </li>
            </ul>
          </div>
        </Card>
      </div>
      <div className="flex flex-row flex-wrap -mx-2">
        <div className="w-full lg:w-4/6 px-2">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div>
              <Card>
                {ios_status_step &&
                includes(
                  ["1", "C", "c", "3", "4", "5", "6", "7", "F", "f"],
                  ios_status_step.toString().toLocaleLowerCase()
                ) ? (
                  <>
                    <StatusCard
                      smartbanner={includes(
                        ["ios", "all"],
                        get(applisting, "smartbanner.platform", undefined)
                      )}
                      applisting={applisting}
                      download={ios_appstore_link}
                      platform="ios"
                      phase={
                        ios_status_step.toString().toLocaleLowerCase() || "1"
                      }
                    />
                    {paymentPlatform === "android" &&
                      (isNull(plan) ||
                        (includes(["starter"], getPlan(plan)) && (
                          <Hide platform="ios" />
                        )))}
                  </>
                ) : (
                  <p className="py-4 px-4 text-center">
                    Build phase will be here soon.
                  </p>
                )}
              </Card>
            </div>
            <div>
              <Card>
                {android_status_step &&
                includes(
                  ["1", "C", "c", "3", "4", "5", "6", "7", "F", "f"],
                  android_status_step.toString().toLocaleLowerCase()
                ) ? (
                  <>
                    <StatusCard
                      smartbanner={includes(
                        ["android", "all"],
                        get(applisting, "smartbanner.platform", undefined)
                      )}
                      applisting={applisting}
                      download={android_appstore_link}
                      platform="android"
                      phase={
                        android_status_step.toString().toLocaleLowerCase() || 1
                      }
                    />
                    {paymentPlatform === "ios" &&
                      (isNull(plan) ||
                        includes(["starter"], getPlan(plan))) && (
                        <Hide platform="android" />
                      )}
                  </>
                ) : (
                  <p className="py-4 px-4 text-center">
                    Build phase will be here soon.
                  </p>
                )}
              </Card>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/6 px-2">
          <Card
            className="text-center text-white pt-6 px-6 mb-6"
            css={css`
              background-image: url(${ImgSmartbanner}),
                linear-gradient(to right, #094c97, #1471af);
              background-size: auto 128px, 100% 100%;
              background-position: 50% 100%, 0 0;
              background-repeat: no-repeat;
              padding-bottom: 120px;
            `}
          >
            <h3 className="text-xl uppercase font-semibold mb-1">
              SMART APP BANNER
            </h3>
            <p className="leading-tight text-sm mb-3">
              Increase mobile app installs by guiding your website customers to
              your app.
            </p>
            <Button
              size="slim"
              primary
              loading={loadingSmartbanner}
              onClick={() => handleInstallSmartBanner()}
            >
              FREE INSTALL
            </Button>
          </Card>
          <Card className="px-4 py-4 text-center">
            <img src={ImgPromoteReview} alt="" className="px-8 mb-4" />
            <h3 className="text-primary-500">Satisfied with JCurve?</h3>
            <p className="text-gray-900 leading-tight mb-4">
              Please show your love on app review. (just 30sec work)
            </p>
            <Button
              size="slim"
              primary
              onClick={() =>
                window.open(
                  "https://apps.shopify.com/jcurve-mobile-app?#modal-show=ReviewListingModal",
                  "_review"
                )
              }
            >
              Write A Review
            </Button>
          </Card>
        </div>
      </div>
      <div className="mt-24">
        <h4 className="text-sm text-gray-500 mb-4">Partnership app</h4>
      </div>
    </Page>
  )
}
