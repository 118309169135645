import React from "react"
import { connect } from "react-redux"
import { get } from "lodash"

import ScreenMenu from "design/components/ScreenMenu"
import { addMenu, removeMenu, swapMenu } from "design/redux"
import { openModal } from "modal/redux"

const mapStateToProps = state => {
  const menu = state.design.menu.ids.map(menuId => {
    return state.design.menu.byId[menuId]
  })

  return {
    isActive: state.design.isMenuActive,
    menu,
  }
}

const mapDispatchToProps = {
  addMenu,
  removeMenu,
  swapMenu,
  openModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenMenu)
