import React from "react"
import tw, { styled, css } from "twin.macro"

import Card from "general/components/Card"

import IconAndroid from "assets/images/pricing/icon-android.png"

const Icon = styled.img`
  ${tw`align-middle`}
  display: inline-block;
  width: 21px;
  height: 21px;
`

const AccountItem = ({ content, checked, disabled, onAction }) => {
  return (
    <button
      onClick={onAction}
      className="relative rounded-md w-full border-4 py-8 px-4 h-full flex items-center justify-center hover:border-primary-500 cursor-pointer shadow-sm focus:outline-none"
      css={[checked && tw`border-primary-500`]}
    >
      <h3 className="text-2xl text-gray-800 text-center">{content}</h3>
      <div
        className="absolute"
        css={[
          checked ? tw`inline-block` : tw`hidden`,
          css`
            right: -1em;
            bottom: -1em;
          `,
        ]}
      >
        <div className="w-12 h-12 bg-primary-500 rounded-full inline-flex items-center justify-center">
          {disabled ? (
            <svg
              className="text-white w-8 h-8"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
              />
            </svg>
          ) : (
            <svg
              className="text-white w-8 h-8"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          )}
        </div>
      </div>
    </button>
  )
}

export default function AndroidCard({ onAction, checked, disabled }) {
  return (
    <Card
      title={
        <div className="flex space-x-2">
          <Icon src={IconAndroid} />
          <span className="align-middle">Do you have an Google Developer account?</span>
        </div>
      }
    >
      <p className="mb-4">
        The Android Developer Account is required for your business to be
        listed on the Play Store. 
      </p>
      <p className="text-primary-500"> *This is not the personal google playstore account that you used for app download.</p>
      <div className="flex flex-row flex-wrap -mx-2">
        <div className="flex-1 px-2">
          <AccountItem
            disabled={disabled}
            onAction={() => onAction("android", false)}
            content={`No, I don't have`}
            checked={checked === false}
          />
        </div>
        <div className="flex-1 px-2">
          <AccountItem
            disabled={disabled}
            onAction={() => onAction("android", true)}
            content={
              <span className="align-middle">
                I have an Google <Icon src={IconAndroid} checked={checked} />
                Developer Account
              </span>
            }
            checked={checked}
          />
        </div>
      </div>
    </Card>
  )
}
