import React from "react"
import { css } from "styled-components"
import tw from "twin.macro"
import { createPortal } from "react-dom"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { isEqual, get } from "lodash"

import CollectionBuilderItem from "modal/components/CollectionBuilder/CollectionBuilderItem"
import LinkCollectionButton from "modal/components/CollectionBuilder/LinkCollectionButton"
import BgMenuCollectionList from "assets/svgs/bg-menu-collection-list.svg"
import WarningImage from 'assets/images/design/icon-menu-warning-sqaure.png'

function CollectionBuilderList(props) {
  const { list, errors, portal, shopify } = props
  const {
    buildRefs,
    removeItem,
    changeText,
    changeImage,
    openSearchModal,
    DragEndSubitem,
    getCollectionSuccess,
  } = props

  const getThumbnailByCollectionId = cid => {
    let thumbnail

    thumbnail = get(shopify, `${cid}.image.transformedSrc`, undefined)

    if (!thumbnail) {
      thumbnail = get(
        shopify,
        `${cid}.products.edges.0.node.images.edges.0.node.transformedSrc`,
        undefined
      )
    }

    return thumbnail
  }

  const renderGroupItem = (parentIndex, parentId, sublist) => {
    return (
      <DragDropContext onDragEnd={DragEndSubitem}>
        <div css={tw`mx-4`}>
          <Droppable droppableId={`${parentIndex}`}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                snapshot={snapshot}
                {...provided.droppableProps}
              >
                {sublist &&
                  sublist.map((item, childrenIndex) => {
                    return (
                      <Draggable
                        draggableId={`${parentId}-${item.id}`}
                        index={childrenIndex}
                        key={item.id}
                      >
                        {(provided, snapshot) => {
                          const usePortal = snapshot.isDragging
                          const collectionData = get(shopify, item.collection_id, undefined)

                          const child = (
                            <div
                              key={childrenIndex}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              css={
                                childrenIndex > 0 &&
                                css`
                                  margin-top: -1px;
                                `
                              }
                            >
                              <CollectionBuilderItem
                                shopify={shopify}
                                provided={provided}
                                subitem
                                error={get(
                                  errors,
                                  `${item.id}`,
                                  false
                                )}
                                type={"subitem"}
                                name={collectionData ? item.collection_name : 'Deleted collection'}
                                image={collectionData ? getThumbnailByCollectionId(
                                  item.collection_id
                                ) : WarningImage}
                                collection={item.collection_id}
                                removeItem={() =>
                                  removeItem(parentIndex, childrenIndex)
                                }
                                changeText={event =>
                                  changeText(
                                    event.target.value,
                                    parentIndex,
                                    childrenIndex
                                  )
                                }
                              />
                            </div>
                          )

                          if (!usePortal) {
                            return child
                          }

                          return createPortal(child, portal)
                        }}
                      </Draggable>
                    )
                  })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div
            css={[
              tw`block bg-white border border-dashed py-3 rounded-b-lg`,
              sublist &&
              sublist.length > 0 &&
              css`
                  margin-top: -1px;
                `,
            ]}
          >
            <LinkCollectionButton
              onAction={() => openSearchModal(parentIndex, "group")}
            >
              <span className="flex flex-row items-center justify-center w-full">
                <svg
                  className="w-4 h-4 mr-2 text-primary-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                  />
                </svg>
                <span>Link collection</span>
              </span>
            </LinkCollectionButton>
          </div>
        </div>
      </DragDropContext>
    )
  }

  if (list.length === 0) {
    return (
      <div
        css={[
          tw`flex items-center justify-center text-center h-full`,
          css`
            min-height: 420px;
            svg {
              display: inline-block;
            }
          `,
        ]}
      >
        <BgMenuCollectionList />
      </div>
    )
  }

  return (
    <div>
      {list.map((collection, index) => {
        const {
          display_type,
          display_name,
          display_image,
          display_value,
          id,
        } = collection

        const singleCollectionId = get(display_value, "0.collection_id", undefined);
        const singleCollectionData = get(shopify, singleCollectionId, undefined)

        const renderParentName = () => {
          if (display_type === 'single') {
            return singleCollectionId && singleCollectionData ? display_name : 'Deleted collection'
          }

          return display_name
        }

        const renderParentImage = () => {
          if (display_type === 'single') {
            return singleCollectionId && singleCollectionData ? display_image : WarningImage
          }

          return display_image
        }

        return (
          <Draggable
            draggableId={`${id}`}
            index={index}
            key={id}
          >
            {(provided, snapshot) => {
              const usePortal = snapshot.isDragging
              const child = (
                <div
                  css={tw`mt-2`}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                >
                  <CollectionBuilderItem
                    shopify={shopify}
                    provided={provided}
                    index={index}
                    type={display_type}
                    name={renderParentName()}
                    image={renderParentImage()}
                    error={get(
                      errors,
                      `${id}`,
                      false
                    )}
                    collection={get(display_value, "0.collection_id")}
                    removeItem={() => removeItem(index)}
                    changeText={event => changeText(event.target.value, index)}
                    openSearchModal={() => openSearchModal(index, display_type)}
                    changeImage={event => {
                      return changeImage(event, index)
                    }}
                    getCollectionSuccess={getCollectionSuccess}
                  />
                  {display_type === "group" &&
                    renderGroupItem(index, id, display_value)}
                </div>
              )

              if (!usePortal) {
                return child
              }

              return createPortal(child, portal)
            }}
          </Draggable>
        )
      })}
    </div>
  )
}

export default CollectionBuilderList

// function areEqual(prevProps, nextProps) {
//   console.log("LIST EQUAL", isEqual(prevProps.list, nextProps.list))
//   return isEqual(prevProps.list, nextProps.list)
// }

// export default React.memo(CollectionBuilderList, areEqual)
