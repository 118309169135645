import { createSelector } from "reselect"

export const getSectionsById = state => state.design.sections.byId
export const getSectionsIds = state => state.design.sections.ids
export const getSectionList = createSelector(
  [getSectionsById, getSectionsIds],
  (byId, ids) => {
    return ids.map(id => {
      return { id, ...byId[id] }
    })
  }
)
