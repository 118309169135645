import React, { useRef, useState, useEffect, useCallback } from "react"
import { connect, useSelector } from "react-redux"
import tw from "twin.macro"
import produce from "immer"
import { v4 as uuidv4 } from "uuid"
import { get, uniqBy, assign, omit } from "lodash"
import Modal from "general/components/Modal"
import TextField from "general/components/TextField"
import ChoiceList from "general/components/ChoiceList"
import Select from "general/components/Select"
import CollectionBuilder from "modal/components/CollectionBuilder"
import ShopifyModalProvider from "shopify/components/ShopifyModalProvider"
import ShopifyProvider from "shopify/containers/ShopifyProvider"
import { updateMenu } from "design/redux"
import { getCollectionSuccess } from "shopify/redux"
import usePrevious from "reaverUtil/usePrevious"
import { toast } from "react-toastify"
import { addHttp } from "reaverUtil/form"
import arrayToObject from "reaverUtil/arrayToObject"
import VerifyLinkButton from "form/components/VerifyLinkButton"
import Spinner from "general/components/Spinner"
import { track } from "services/analytics"

import { getCollectionsByIds } from "shopify/services"

const COLLECTION_ITEM_LIMIT = process.env.GATSBY_MENU_COLLECTION_ITEM_LIMIT
const COLLECTION_SUBITEM_LIMIT =
  process.env.GATSBY_MENU_COLLECTION_SUBITEM_LIMIT
const FACEBOOK_CHAT_SCHEME_URL = "https://m.me/"
const WHATSAPP_CHAT_SCHEME_URL = "https://wa.me/"

function MenuModal(props) {
  const { isOpen, menuId, menuMeta } = props
  const { onRequestClose, updateMenu, getCollectionSuccess } = props

  const profile = useSelector(({ firebase }) => firebase.profile)

  const scrollRef = useRef(null)
  const toastId = React.useRef(null)
  const titleRef = useRef(null)
  const buildRefs = useRef([])

  const [state, setState] = useState({
    id: menuId || uuidv4(),
    name: "",
    type: "collection",
    collection: {
      isLinkedAll: false,
    },
    page: {
      type: "page",
      name: null,
      value: "",
      placeholder: "Page Name",
    },
    social: {
      type: "facebook",
      value: "https://www.facebook.com/",
      placeholder: "Social media url",
    },
    chat: {
      type: "facebook_chat",
      value: "",
      placeholder: "Facebook username",
    },
    loading: {
      build: true,
    },
    shopify: {},
    build: [],
    buildErrors: {},
  })

  // const [loading, setLoading] = useState({
  //   build: true,
  // })
  // const [shopify, setShopify] = useState({})
  const [searchModal, setSearchModal] = useState({
    isOpen: false,
    meta: null,
  })
  // const [item, setItem] = useState({
  //   id: menuId || uuidv4(),
  //   name: "",
  //   type: "collection",
  //   collection: {
  //     isLinkedAll: false,
  //   },
  //   page: {
  //     type: "page",
  //     name: null,
  //     value: "",
  //     placeholder: "Page Name",
  //   },
  //   social: {
  //     type: "facebook",
  //     value: "https://www.facebook.com/",
  //     placeholder: "Social media url",
  //   },
  //   chat: {
  //     type: "facebook_chat",
  //     value: "",
  //     placeholder: "Facebook username",
  //   },
  // })
  // const [build, setBuild] = useState([])
  // const [buildErrors, setBuildErrors] = useState({})

  const previousbuild = usePrevious(state.build)

  const getCids = data => {
    let ids = []

    data.forEach(menuItem => {
      const { display_value } = menuItem

      if (display_value.length > 0) {
        display_value.forEach(collection => {
          ids.push(collection.collection_id)
        })
      }
    })

    return uniqBy(ids)
  }

  const fetchMenuCollection = async () => {
    const collections = get(menuMeta, "direction.custom_collection", [])

    let nextShopify = {}
    let nextBuild = []
    let nextBuildErrors = {}

    if (collections.length > 0) {
      const cids = getCids(collections)
      const results = await getCollectionsByIds(
        cids,
        profile.shopify_url,
        profile.shopify.sf_access_token
      )

      nextShopify = arrayToObject(results, "id")

      collections.forEach(item => {
        let display_value = item.display_value
        const uid = uuidv4()

        if (item.display_type === "single") {
          const collectionId = get(
            item,
            "display_value.0.collection_id",
            undefined
          )
          const collectionData = get(nextShopify, collectionId, undefined)

          if (!collectionData) {
            assign(nextBuildErrors, { [uid]: true })
          }
        }

        if (item.display_type === "group" && item.display_value.length > 0) {
          display_value = item.display_value.map(item => {
            const subUid = uuidv4()
            const collectionId = get(item, "collection_id", undefined)
            const collectionData = get(nextShopify, collectionId, undefined)

            if (!collectionData) {
              assign(nextBuildErrors, { [`${subUid}`]: true })
            }

            return {
              ...item,
              id: subUid,
            }
          })
        }

        nextBuild.push({
          ...item,
          display_value,
          id: uid,
        })
      })
    }

    setState(
      produce(draft => {
        draft.shopify = nextShopify
        draft.build = nextBuild
        draft.buildErrors = nextBuildErrors
        draft.loading.build = false

        if (menuMeta.type === "custom_collection") {
          draft.type = "collection"
        }

        if (menuMeta.type === "catalog") {
          draft.type = "collection"
          draft.collection.isLinkedAll = true
        }

        if (menuMeta.type === "custom_page") {
          const { display_name, url } = menuMeta.direction
          draft.type = "page"

          if (display_name === url) {
            draft.page.type = "url"
          } else {
            draft.page.type = "page"
            draft.page.name = display_name
          }

          draft.page.value = url
        }

        if (menuMeta.type === "custom_social") {
          if (menuMeta.is_chat) {
            draft.type = "chat"
            draft.chat.type = menuMeta.direction[0].social_account
            draft.chat.value = menuMeta.direction[0].url
          } else {
            draft.type = "social"
            draft.social.type = menuMeta.direction[0].social_account
            draft.social.value = menuMeta.direction[0].url
          }
        }

        draft.name = menuMeta.name
      })
    )

    // // setShopify(nextShopify)
    // // setBuild(nextBuild)
    // // setBuildErrors(nextBuildErrors)
    // // setLoading(
    // //   produce(draft => {
    // //     draft["build"] = false
    // //   })
    // // )
    // // setItem(
    // //   produce(draft => {
    // //     if (menuMeta.type === "custom_collection") {
    // //       draft.type = "collection"
    // //     }

    // //     if (menuMeta.type === "catalog") {
    // //       draft.type = "collection"
    // //       draft.collection.isLinkedAll = true
    // //     }

    // //     if (menuMeta.type === "custom_page") {
    // //       const { display_name, url } = menuMeta.direction
    // //       draft.type = "page"

    // //       if (display_name === url) {
    // //         draft.page.type = "url"
    // //       } else {
    // //         draft.page.type = "page"
    // //         draft.page.name = display_name
    // //       }

    // //       draft.page.value = url
    // //     }

    // //     if (menuMeta.type === "custom_social") {
    // //       if (menuMeta.is_chat) {
    // //         draft.type = "chat"
    // //         draft.chat.type = menuMeta.direction[0].social_account
    // //         draft.chat.value = menuMeta.direction[0].url
    // //       } else {
    // //         draft.type = "social"
    // //         draft.social.type = menuMeta.direction[0].social_account
    // //         draft.social.value = menuMeta.direction[0].url
    // //       }
    // //     }

    // //     draft.name = menuMeta.name
    // //   })
    // )
  }

  useEffect(() => {
    if (menuId) {
      fetchMenuCollection()
    } else {
      setState(
        produce(draft => {
          draft.loading.build = false
        })
      )
    }

    track("EDIT_MENU")
    
    // if (menuId) {
    //   // if (menuMeta.type === "custom_collection") {
    //   //   const nextBuild = [];
    //   //   console.log('START LOOP', shopify)

    //   //   menuMeta.direction.custom_collection.forEach(item => {
    //   //     let display_value = item.display_value;

    //   //     if (item.display_type === 'group' && item.display_value.length > 0) {
    //   //       display_value = item.display_value.map(item => {
    //   //         return {
    //   //           ...item,
    //   //           id: uuidv4()
    //   //         }
    //   //       })
    //   //     }

    //   //     nextBuild.push({
    //   //       ...item,
    //   //       display_value,
    //   //       id: uuidv4()
    //   //     })
    //   //   })

    //   //   console.log('load nextBuild', nextBuild)

    //   //   setBuild(nextBuild)
    //   //   // setBuild(menuMeta.direction.custom_collection)
    //   // }

    // }
  }, [])

  useEffect(() => {
    if (
      previousbuild &&
      previousbuild.length > 0 &&
      state.build.length > previousbuild.length
    ) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      })
    }
  }, [state.build, scrollRef])

  const getBuildLength = () => {
    // let count = 0

    // build.forEach(item => {
    //   if (item.display_type === "group") {
    //     count = count + item.display_value.length
    //   }

    //   return count++
    // })

    // return count

    return state.build.length
  }

  const sendToast = type => {
    if (type === "group") {
      return toast(`You can add up to ${COLLECTION_SUBITEM_LIMIT} items.`)
    }

    if (!toast.isActive(toastId.current)) {
      toastId.current = toast(
        `You can add up to ${COLLECTION_ITEM_LIMIT} items.`
      )
    }

    return false
  }

  const handleSocialTextChange = useCallback(event => {
    const value = event.target.value
    setState(
      produce(draft => {
        draft.social.value = value
      })
    )
  })

  const handleSocialTypeChange = useCallback(event => {
    const value = event.target.value
    let defaultValue = ""

    switch (value) {
      case "youtube":
        defaultValue = "https://www.youtube.com/"
        break
      case "instagram":
        defaultValue = "https://www.instagram.com/"
        break
      case "facebook":
        defaultValue = "https://www.facebook.com/"
        break
      case "twitter":
        defaultValue = "https://twitter.com/"
        break
      case "pinterest":
        defaultValue = "https://www.pinterest.com/"
        break
      case "snapchat":
        defaultValue = "https://www.snapchat.com/add/"
        break
      default:
        break
    }

    setState(
      produce(draft => {
        draft.social.type = value
        draft.social.value = defaultValue
      })
    )
  })

  const handleChatTextChange = useCallback(event => {
    const value = event.target.value

    setState(
      produce(draft => {
        if (draft.chat.type == "facebook_chat") {
          draft.chat.value = FACEBOOK_CHAT_SCHEME_URL + value
        } else if (draft.chat.type == "whatsapp_chat") {
          draft.chat.value = WHATSAPP_CHAT_SCHEME_URL + value
        }
      })
    )
  })

  const handleChatTypeChange = useCallback(event => {
    const value = event.target.value

    let placeholder = ""

    switch (value) {
      case "facebook_chat":
        placeholder = "Facebook username"
        break
      case "whatsapp_chat":
        placeholder = "Phone number ex)+1 1231231234"
        break
      default:
        break
    }

    setState(
      produce(draft => {
        draft.chat.type = value
        draft.chat.value = ""
        draft.chat.placeholder = placeholder
      })
    )
  })

  const handleMenuNameChange = useCallback(event => {
    const value = event.target.value

    setState(
      produce(draft => {
        draft.name = value
      })
    )
  }, [])

  const handleTypeChange = useCallback(value => {
    setState(
      produce(draft => {
        draft.type = value
      })
    )
  }, [])

  const handleWebTextChange = useCallback(
    event => {
      const value = event.target.value

      if (state.page.type === "url") {
        return setState(
          produce(draft => {
            draft.page.value = value
          })
        )
      }

      // if (item.page.type === "page") {
      //   return setSearchModal(
      //     produce(draft => {
      //       draft.isOpen = true
      //       draft.meta = {
      //         type: "page",
      //         resourceType: "pages",
      //         query: value,
      //       }
      //     })
      //   )
      // }
    },
    [state]
  )

  const handleWebTextFocus = useCallback(
    event => {
      const value = event.target.value

      if (state.page.type === "page") {
        return setSearchModal(
          produce(draft => {
            draft.isOpen = true
            draft.meta = {
              type: "page",
              resourceType: "pages",
              query: value,
            }
          })
        )
      }
    },
    [state]
  )

  const handleClearButtonClick = () => {
    setState(
      produce(draft => {
        draft.page.name = null
        draft.page.value = ""
      })
    )
    setSearchModal(
      produce(draft => {
        draft.isOpen = true
        draft.meta = {
          type: "page",
          resourceType: "pages",
        }
      })
    )
  }

  const handleWebTypeChange = useCallback(event => {
    const value = event.target.value
    let placeholder = ""

    switch (value) {
      case "url":
        placeholder = "https://domain.com/page"
        break
      case "page":
        placeholder = "Page name"
        break
      default:
        break
    }

    setState(
      produce(draft => {
        draft.page.type = value
        draft.page.name = null
        draft.page.value = ""
        draft.page.placeholder = placeholder
      })
    )
  }, [])

  const handleToggleLinkAllCollection = () => {
    return setState(
      produce(draft => {
        draft.collection.isLinkedAll = !state.collection.isLinkedAll
      })
    )
  }

  const handleSearchModalClose = () => {
    setSearchModal(
      produce(draft => {
        draft.isOpen = false
      })
    )
  }
  const handleSearchPrimary = (collectionId, data) => {
    const { type } = searchModal.meta

    setState(
      produce(draft => {
        draft.shopify[collectionId] = data
      })
    )

    if (type === "page") {
      if (state.name.length === 0) {
        setState(
          produce(draft => {
            draft.name = data.title
          })
        )
      }

      setState(
        produce(draft => {
          draft.page.name = data.title
          draft.page.value = data.url
        })
      )
    } else {
      const { parentIndex } = searchModal.meta

      setState(
        produce(draft => {
          if (type === "single") {
            let thumbnail = get(data, "image.transformedSrc", undefined)

            if (!thumbnail) {
              thumbnail = get(
                data,
                "products.edges.0.node.images.edges.0.node.transformedSrc",
                null
              )
            }

            draft.build[parentIndex].display_value.push({
              collection_id: collectionId,
              collection_name: data.title,
            })
            draft.build[parentIndex].display_name = data.title
          }

          if (type === "group") {
            let thumbnail = get(data, "image.transformedSrc", undefined)

            if (!thumbnail) {
              thumbnail = get(
                data,
                "products.edges[0].node.images.edges[0].node.transformedSrc",
                null
              )
            }

            draft.build[parentIndex].display_value.push({
              id: uuidv4(),
              collection_id: collectionId,
              collection_name: data.title,
              // collection_image: thumbnail,
            })

            // if (!build[parentIndex].display_image) {
            //   draft[parentIndex].display_image = thumbnail
            // }
          }
        })
      )
    }

    handleSearchModalClose()
  }

  const getSubItemLengthByGroupId = parentIndex => {
    return state.build[parentIndex].display_value.length
  }

  const handleOpenSearchModal = (parentIndex, type) => {
    if (getBuildLength() === COLLECTION_ITEM_LIMIT && type === "single") {
      sendToast()
      return false
    }

    if (
      getSubItemLengthByGroupId(parentIndex) === COLLECTION_SUBITEM_LIMIT &&
      type === "group"
    ) {
      return sendToast("group")
    }

    return setSearchModal(
      produce(draft => {
        draft.isOpen = true
        draft.meta = { parentIndex, type, resourceType: "collections" }
      })
    )
  }

  const validateMenuData = () => {
    if (state.name.length === 0) {
      titleRef.current.focus()
      toast("Please fill out the Menu name.")

      return false
    }

    if (state.type === "collection") {
      let isValid = true

      if (!state.collection.isLinkedAll && state.build.length === 0) {
        toast("Please fill out the Collection Group name.")

        return false
      }

      state.build.forEach((item, index) => {
        // if (!isValid) {
        //   return false
        // }
        // if(item.display_type === 'group') {
        if (item.display_name.length === 0) {
          isValid = false

          setState(
            produce(draft => {
              draft.buildErrors[`${item.id}`] = true
            })
          )
          // toast("Title is required field.")
        }
        // }

        if (item.display_value.length === 0) {
          isValid = false

          setState(
            produce(draft => {
              draft.buildErrors[`${item.id}`] = true
            })
          )
        } else if (item.display_type === "single") {
          const singleCollectionId = item.display_value[0].collection_id
          const singleCollectionData = get(
            state.shopify,
            singleCollectionId,
            undefined
          )

          if (!singleCollectionData) {
            isValid = false

            setState(
              produce(draft => {
                draft.buildErrors[`${item.id}`] = true
              })
            )
          }
        }

        if (item.display_type === "group") {
          item.display_value.forEach((childrenItem, childrenIndex) => {
            if (childrenItem.collection_name.length === 0) {
              isValid = false

              setState(
                produce(draft => {
                  draft.buildErrors[`${childrenItem.id}`] = true
                })
              )
            }
            const subCollectionId = childrenItem.collection_id
            const subCollectionData = get(
              state.shopify,
              subCollectionId,
              undefined
            )

            if (!subCollectionData) {
              isValid = false

              setState(
                produce(draft => {
                  draft.buildErrors[`${childrenItem.id}`] = true
                })
              )
            }
          })
        }
      })

      if (!isValid) {
        toast("Please check the error area! Edit or Remove")

        return false
      }
    }

    if (state.type === "page") {
      if (state.page.type === "url") {
        if (state.page.value.length === 0) {
          toast("Please fill out the URL.")

          return false
        }

        // if (!isURL(item.page.value)) {
        //   return toast("Please fill out the valid URL.")
        // }
      }

      if (state.page.type === "page" && state.page.value.length === 0) {
        toast("Please select a page.")

        return false
      }
    }

    if (state.type === "social") {
      if (state.social.value.length === 0) {
        toast("Please fill out the URL.")

        return false
      }
    }

    if (state.type === "chat") {
      console.log(state.chat.value.length)
      if (state.chat.value.length === 0) {
        toast("Please fill out the URL.")
        return false
      }
    }

    return true
  }

  const handlePrimary = () => {
    let type
    let direction
    let is_chat

    const isValid = validateMenuData()

    if (!isValid) {
      return false
    }

    switch (state.type) {
      case "collection":
        if (state.collection.isLinkedAll) {
          type = "catalog"
          direction = null
          break
        }
        type = "custom_collection"
        direction = {
          custom_collection: state.build.map(item => {
            const display_value = item.display_value.map(collection =>
              omit(collection, "id")
            )

            return omit({ ...item, display_value }, "id")
          }),
        }
        break
      case "page":
        type = "custom_page"
        if (state.page.type === "url") {
          direction = {
            display_name: addHttp(state.page.value),
            url: addHttp(state.page.value),
          }
        }
        if (state.page.type === "page") {
          direction = { display_name: state.page.name, url: state.page.value }
        }
        break
      case "social":
        type = "custom_social"
        is_chat = false
        direction = [
          {
            icon: social_icon(state.social.type),
            social_account: state.social.type,
            url: state.social.value,
          },
        ]
        break
      case "chat":
        type = "custom_social"
        is_chat = true
        direction = [
          {
            icon: social_icon(state.chat.type),
            social_account: state.chat.type,
            url: state.chat.value,
          },
        ]
        break
      default:
        break
    }

    const nextMenu = {
      id: state.id,
      icon: null,
      visible: true,
      editable: true,
      name: state.name,
      type,
      direction,
    }

    if (nextMenu.type === "custom_social") {
      nextMenu.is_chat = is_chat
    }

    updateMenu(nextMenu)
    onRequestClose()
  }

  const renderWebpage = useCallback(
    () => (
      <TextField
        type="url"
        required
        onChange={handleWebTextChange}
        onFocus={handleWebTextFocus}
        disabled={state.page.type === "page" && state.page.value}
        value={state.page.name ? state.page.name : state.page.value}
        clearButton={state.page.type === "page" && state.page.value}
        onClearButtonClick={() => handleClearButtonClick()}
        placeholder={state.page.placeholder}
        connectedLeft={
          <Select
            value={state.page.type}
            label="Webpage"
            onChange={handleWebTypeChange}
            labelHidden
            options={[
              { label: "Page", value: "page" },
              { label: "URL", value: "url" },
            ]}
          />
        }
        connectedRight={
          state.page.type === "url" && (
            <VerifyLinkButton url={state.page.value} />
          )
        }
      />
    ),
    [state]
  )

  const renderSocialmedia = useCallback(
    () => (
      <TextField
        name="social"
        onChange={handleSocialTextChange}
        value={state.social.value}
        placeholder={state.social.placeholder}
        connectedRight={<VerifyLinkButton url={state.social.value} />}
        connectedLeft={
          <Select
            name={"social"}
            value={state.social.type}
            label="Social"
            onChange={handleSocialTypeChange}
            labelHidden
            options={[
              { label: "Youtube", value: "youtube" },
              { label: "Instagram", value: "instagram" },
              { label: "Facebook", value: "facebook" },
              { label: "Twitter", value: "twitter" },
              { label: "Pinterest", value: "pinterest" },
              { label: "Snapchat", value: "snapchat" },
            ]}
          />
        }
      />
    ),
    [state]
  )

  const renderChat = useCallback(
    () => (
      <TextField
        name={"chat"}
        onChange={handleChatTextChange}
        value={state.chat.value
          .replace(FACEBOOK_CHAT_SCHEME_URL, "")
          .replace(WHATSAPP_CHAT_SCHEME_URL, "")}
        placeholder={state.chat.placeholder}
        connectedRight={<VerifyLinkButton url={state.chat.value} />}
        connectedLeft={
          <Select
            name={"chat"}
            value={state.chat.type}
            label="Chat"
            onChange={handleChatTypeChange}
            labelHidden
            options={[
              { label: "WhatsApp Chat", value: "whatsapp_chat" },
              { label: "Facebook Chat", value: "facebook_chat" },
            ]}
          />
        }
      />
    ),
    [state]
  )

  return (
    <Modal
      // extraClass="modal-menu"
      size={"fullpage"}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={menuId ? "Edit Menu" : "Add Menu"}
      primaryAction={{ content: "Menu Save", onAction: () => handlePrimary() }}
      secondaryAction={{ content: "Cancel", onAction: () => onRequestClose() }}
      ref={scrollRef}
    >
      <div css={[tw`relative h-full`]}>
        <div css={tw`px-4 py-4`}>
          <div className="flex flex-row flex-wrap -mx-4">
            <div className="flex-1 mx-4">
              <TextField
                ref={titleRef}
                label={"Menu name"}
                name={"name"}
                type={"text"}
                maxLength="50"
                value={state.name}
                onChange={handleMenuNameChange}
                placeholder={"Menu name"}
                showCharacterCount
              />
            </div>
            <div className="flex-1 mx-4">
              <ChoiceList
                title="Menu type"
                choices={[
                  { label: "Collection", value: "collection" },
                  {
                    label: "Web page",
                    value: "page",
                    renderChildren: renderWebpage,
                  },
                  {
                    label: "Social media",
                    value: "social",
                    renderChildren: renderSocialmedia,
                  },
                  {
                    label: "Chat",
                    value: "chat",
                    renderChildren: renderChat,
                  },
                ]}
                selected={state.type}
                onChange={handleTypeChange}
              />
            </div>
          </div>
        </div>
        <div
          css={[
            tw`hidden h-full bg-gray-100`,
            state.type === "collection" && tw`block`,
          ]}
        >
          <ShopifyProvider>
            {state.loading.build ? (
              <div className="w-full h-full flex items-center justify-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <CollectionBuilder
                buildRefs={buildRefs}
                build={state.build}
                errors={state.buildErrors}
                shopify={state.shopify}
                isLinkedAllCollection={state.collection.isLinkedAll}
                setState={setState}
                getBuildLength={getBuildLength}
                sendToast={sendToast}
                COLLECTION_ITEM_LIMIT={COLLECTION_ITEM_LIMIT}
                openSearchModal={handleOpenSearchModal}
                toggleLinkAllCollection={handleToggleLinkAllCollection}
                getCollectionSuccess={getCollectionSuccess}
              />
            )}
          </ShopifyProvider>
        </div>
      </div>
      <ShopifyModalProvider
        meta={searchModal.meta}
        isOpen={searchModal.isOpen}
        onPrimaryAction={handleSearchPrimary}
        onRequestClose={handleSearchModalClose}
      />
    </Modal>
  )
}

const mapStateToProps = (state, { meta }) => {
  const menuId = get(meta, "menuId", undefined)

  return {
    menuId,
    menuMeta: menuId ? state.design.menu.byId[menuId] : undefined,
  }
}

const mapDispatchToProps = {
  updateMenu,
  getCollectionSuccess,
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuModal)

// rollback을 위한 정보를 추가하는 부분 ( 실제 연결되는 path가 아니고 migration이 완료되고 검증 되었을 때 삭제해야 함 )
function social_icon(account_type) {
  var icon_path
  switch (account_type) {
    case "instagram":
      icon_path = "editmenu_icon_social_instagram.svg"
      break
    case "facebook":
      icon_path = "editmenu_icon_social_facebook.svg"
      break
    case "youtube":
      icon_path = "editmenu_icon_social_youtube.svg"
      break
    case "twitter":
      icon_path = "editmenu_icon_social_twitter.svg"
      break
    case "pinterest":
      icon_path = "editmenu_icon_social_pinterest.svg"
      break
    case "snapchat":
      icon_path = "editmenu_icon_social_snapchat.svg"
      break
    case "facebook_chat":
      icon_path = "editmenu_icon_social_facebookmessenger.svg"
      break
    case "whatsapp_chat":
      icon_path = "editmenu_icon_social_whatsapp.svg"
      break
    default:
      icon_path = "editmenu_icon_social_facebook.svg"
      break
  }
  return "/asset/design/" + icon_path
}
