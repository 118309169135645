import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import moment from "moment"
import tw, { styled, css } from "twin.macro"
import { getFirebase } from "react-redux-firebase"
import { isNull } from "lodash"
import produce from "immer"
import Pagination from "general/components/Pagination"
import Page from "general/components/Page"
import SkeletonBodyText from "general/components/SkeletonBodyText"
import Overlay from "general/components/Overlay"
import Spinner from "general/components/Spinner"
import ConfirmModal from "modal/components/ConfirmModal"

const ITEM_LIMIT = 15

const Badge = styled.span`
  ${tw`py-1 px-2 leading-none inline-block text-white uppercase rounded-full text-xs`}

  ${({ primary }) => {
    return primary && tw`bg-primary-500`
  }}
  ${({ success }) => {
    return success && tw`bg-green-500`
  }}
  ${({ incomplete }) => {
    return incomplete && tw`bg-gray-500`
  }}
`

export default function PushHistoryPage() {
  const firebase = getFirebase()
  const auth = useSelector(state => state.firebase.auth)
  const profile = useSelector(state => state.firebase.profile)
  const shopifyUrl = profile.shopify_url

  const ref = firebase
    .firestore()
    .collection("user_list")
    .doc(auth.uid)
    .collection("push")
    .orderBy("time", "desc")
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState(null)
  const [page, setPage] = useState(0)
  const [last, setLast] = useState(null)
  const [first, setFirst] = useState(null)
  const [hasMore, setHasMore] = useState(false)
  const [confirm, setConfirm] = useState({
    isOpen: false,
    meta: {
      documentId: undefined,
    },
  })

  useEffect(() => {
    const unsubscribe = ref.limit(ITEM_LIMIT).onSnapshot(async history => {
      const nextList = []

      if (history.size > 0) {
        history.forEach(doc => {
          nextList.push({
            id: doc.id,
            ...doc.data(),
          })
        })

        const hasMore = await checkForMoreData(history)

        setHasMore(hasMore)
        handleLastVisible(history)
      }

      setList(nextList)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const handleLastVisible = data => {
    const firstVisible = data.docs.length && data.docs[0]
    const lastVisible = data.docs[data.docs.length - 1]

    if (lastVisible) {
      setLast(lastVisible)
    } else {
      setLast(null)
    }

    if (firstVisible) {
      setFirst(firstVisible)
    } else {
      setFirst(null)
    }
  }

  const fetchPushHistory = async (type, cursor) => {
    setLoading(true)
    let historyRef = ref.limit(ITEM_LIMIT)

    if (type === "next") {
      historyRef = ref.startAfter(cursor).limit(ITEM_LIMIT)
    }

    if (type === "prev") {
      historyRef = ref.endBefore(cursor).limitToLast(ITEM_LIMIT)
    }

    historyRef.onSnapshot(async history => {
      const nextList = []
      const hasMore = await checkForMoreData(history)

      history.forEach(doc => {
        nextList.push({
          id: doc.id,
          ...doc.data(),
        })
      })

      setHasMore(hasMore)
      handleLastVisible(history)
      setList(nextList)
      setLoading(false)
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })

      if (type === "next") {
        setPage(page + 1)
      }
      if (type === "prev") {
        setPage(page - 1)
      }
    })
  }

  const checkForMoreData = async data => {
    const lastDocInCurrentQuery = data.docs[data.docs.length - 1]
    const nextSnapshot = await ref
      .startAfter(lastDocInCurrentQuery)
      .limit(ITEM_LIMIT)
      .get()

    if (nextSnapshot.docs.length === 0) {
      return false
    }

    return true
  }

  const handlePrev = () => {
    fetchPushHistory("prev", first)
  }
  const handleNext = () => {
    fetchPushHistory("next", last)
  }

  const handleCancel = id => {
    setConfirm(
      produce(draft => {
        draft.isOpen = true
        draft.meta.documentId = id
      })
    )
  }
  const handleCloseConfirm = () => {
    setConfirm(
      produce(draft => {
        draft.isOpen = false
        draft.meta.documentId = undefined
      })
    )
  }
  const onDeleteAction = () => {
    const id = confirm.meta.documentId
    const ref = firebase
      .firestore()
      .collection("user_list")
      .doc(auth.uid)
      .collection("push")
      .doc(id)

    ref.update({ valid: false, updated_date: new Date() })
  }

  const renderStatus = (valid, sent) => {
    if (!valid) {
      return "Canceled"
    }

    if (sent) {
      return "Completed"
    }

    return "Scheduled"
  }

  return (
    <>
      <Page
        title={"Push History"}
        breadcrumbs={[{ content: "Push", url: "/app/push" }]}
      >
        {isNull(list) ? (
          <div>
            <div className="bg-white shadow-md rounded-sm px-6 py-6">
              <SkeletonBodyText />
            </div>
          </div>
        ) : (
          <>
            <div className="shadow-md rounded-sm overflow-hidden">
              <div className="relative">
                {loading && (
                  <div>
                    <Overlay light />
                    <div className="absolute inset-0 flex items-center justify-center z-20">
                      <Spinner color="primary" />
                    </div>
                  </div>
                )}
                <div className="w-full h-full">
                  <div className="relative">
                    <div className="flex-row px-4 py-4 bg-white font-semibold hidden md:flex">
                      <div className="flex-1">Message</div>
                      <div className="w-2/12">Status</div>
                      <div className="w-2/12">Date</div>
                    </div>
                    <div className="border-t border-b border-gray-200">
                      {list.map((item, index) => {
                        const status = renderStatus(
                          item.valid,
                          item.sent
                        ).toLowerCase()

                        return (
                          <div
                            className="flex flex-col md:flex-row md:items-center bg-white border-t px-4 py-4 first:border-0 border-gray-200"
                            key={`item-${index}`}
                          >
                            <div className="flex flex-row justify-between md:order-3  md:w-2/12 md:text-right">
                              <div className="text-gray-600 text-sm mb-1 md:mb-0 ">
                                {moment(item.time.toDate())
                                  .local()
                                  .format("ll HH:mm")}
                              </div>
                              {status === "scheduled" && (
                                <button
                                  onClick={() => handleCancel(item.id)}
                                  type="button"
                                  className="text-primary-500 leading-none text-sm md:hidden"
                                >
                                  <span>Cancel</span>
                                </button>
                              )}
                            </div>
                            <div className="md:order-1 md:flex-1">
                              {item.title && (
                                <span className="font-semibold block leading-tight">
                                  {item.title}
                                </span>
                              )}
                              <span className="block leading-tight">
                                {item.message}
                              </span>
                              {status === "scheduled" && (
                                <button
                                  onClick={() => handleCancel(item.id)}
                                  type="button"
                                  className="text-primary-500 leading-none text-sm hidden md:inline-block"
                                >
                                  <span>Cancel</span>
                                </button>
                              )}
                            </div>
                            <div className="mt-1 md:mt-0 md:order-2 md:w-2/12">
                              <Badge
                                primary={status === "scheduled"}
                                success={status === "completed"}
                                incomplete={status == "canceled"}
                              >
                                {status}
                              </Badge>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center bg-white py-4 px-6 lg:px-8">
                <Pagination
                  hasPrevious={page > 0}
                  onPrevious={() => {
                    handlePrev()
                  }}
                  hasNext={hasMore}
                  onNext={() => {
                    handleNext()
                  }}
                />
              </div>
            </div>
          </>
        )}
      </Page>
      <ConfirmModal
        isOpen={confirm.isOpen}
        onRequestClose={handleCloseConfirm}
        primaryAction={{
          content: "Delete",
          onAction: () => {
            onDeleteAction()
            handleCloseConfirm()
          },
        }}
        secondaryAction={{
          content: "Cancel",
          onAction: () => {
            handleCloseConfirm()
          },
        }}
        title={"Are you sure to cancel the scheduled push?"}
      />
    </>
  )
}
