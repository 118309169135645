import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import tw, { styled, css } from "twin.macro"
import Slider from "react-slick"
import { get, isNull, some } from "lodash"
import { useSelector } from "react-redux"

import Overlay from "general/components/Overlay"
import WarningOverlay from "design/components/WarningOverlay"

import NoImageSlide from "assets/images/design/block/collection/noimage-slide.png"
import { useBlockImage } from "reaverUtil/image"

const Wrapper = styled.div`
  ${tw`block`}

  .slick-dots li.slick-active button:before {
    color: ${({ isActive }) => isActive && `#ddd !important`};
  }
  .slick-dots li:hover button:before {
    color: ${({ isActive }) => isActive && `#ddd !important`};
  }
  .slick-dots li button:before {
    color: ${({ isActive }) => isActive && `rgba(255,255,255,0.4)`};
  }

  .slick-prev:before,
  .slick-next:before {
    ${tw`text-primary-500`}
  }

  .slick-slide > div {
    font-size: 0;
    line-height: 0;
  }
`

const CollectionItem = ({ thumbnail, title, overlay }) => {
  const primaryColor = useSelector(state => state.design.ui.colors.primary)

  return (
    <div css={tw`relative bg-gray-100`}>
      <div css={tw`pb-9/16`}>
        {thumbnail && (
          <img
            src={thumbnail}
            alt={title}
            className="absolute inset-0 w-full h-full object-cover"
            css={css`
              background-color: ${primaryColor};
            `}
          />
        )}
        {thumbnail && <Overlay />}
        <div css={tw`absolute inset-0 z-20 block text-center`}>
          <div css={tw`table table-fixed w-full h-full`}>
            <div className="table-cell align-middle px-4 ">
              <h2
                className="text-lg font-medium text-gray-800"
                css={[
                  css`
                    line-height: 1.4;
                    white-space: normal;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    word-wrap: break-word;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                  `,
                  tw`overflow-hidden`,
                  thumbnail && tw`text-white`,
                ]}
              >
                {title}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function CollectionBannerBlock(props) {
  const { id, collection_banner_info, preview, setBlockValid } = props

  const [list, setList] = useState([])
  const [error, setError] = useState(false)

  const collections = useSelector(state => state.shopify.collections.byId)

  const images = useBlockImage()

  useEffect(() => {
    if (collection_banner_info) {
      if (collection_banner_info === 0) {
        return setList([])
      }

      const ids = collection_banner_info.map(item => item.id)
      const nextList = ids.map(id => {
        return get(collections, id, null)
      })

      if (nextList.length > 0) {
        const hasDeletedItems = some(nextList, item => isNull(item))

        setError(hasDeletedItems)
        hasDeletedItems && setBlockValid({ id, isValid: false })
      }

      setList(nextList)
    }
  }, [collection_banner_info])

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: preview ? false : true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const renderContent = () => {
    if (preview) {
      return (
        <Slider {...settings}>
          <CollectionItem
            overlay={false}
            title={"Collection Banner"}
            thumbnail={images["collection-hover"].src}
          />
          <CollectionItem
            overlay={false}
            title={"Collection Banner"}
            thumbnail={images["collection-hover"].src}
          />
          <CollectionItem
            overlay={false}
            title={"Collection Banner"}
            thumbnail={images["collection-hover"].src}
          />
        </Slider>
      )
    }

    if (list.length === 0) {
      return (
        <Slider {...settings}>
          <CollectionItem title={"Collection Banner"} />
          <CollectionItem title={"Collection Banner"} />
          <CollectionItem title={"Collection Banner"} />
        </Slider>
      )
    }

    return (
      <Slider {...settings}>
        {list.map((collection, index) => {
          return (
            <CollectionItem
              key={index}
              title={get(collection, "title")}
              thumbnail={get(collection, "image.src", NoImageSlide)}
            />
          )
        })}
      </Slider>
    )
  }

  return (
    <Wrapper isActive={list && list.length > 0}>
      {renderContent()}
      {error && <WarningOverlay />}
    </Wrapper>
  )
}

CollectionBannerBlock.propTypes = {
  preview: PropTypes.bool,
}
CollectionBannerBlock.defaultProps = {
  prview: false,
}
