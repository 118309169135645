import React, { useState, useEffect } from "react"
import { Router, globalHistory } from "@reach/router"
import { Link, navigate } from "gatsby"
import tw, { styled, css } from "twin.macro"
import { get, isEqual, isUndefined, debounce, includes } from "lodash"
import { useSelector } from "react-redux"
import moment from "moment"
import { toast } from "react-toastify"
import { Squash as Hamburger } from "hamburger-react"

import SyncFlag from "./SyncFlag"
import PreviewModal from "modal/components/PreviewModal"
import LogoSymbol from "assets/svgs/logo-symbol.svg"

const Header = styled.header`
  ${tw`block bg-primary-600 shadow-sm relative z-50 fixed top-0 left-0 right-0 md:relative`}
  flex-shrink: 0;
`
const TopNav = styled.nav`
  ${tw`-mx-3 items-center`}
  a, button {
    ${tw`text-white text-sm uppercase font-medium px-3 transition duration-200`}

    &:hover {
      ${tw`text-gray-300`}
    }
  }
`

const Brand = styled.div`
  ${tw`flex flex-row cursor-pointer`}
`
const StoreName = styled.div`
  ${tw`text-white font-semibold ml-2`}
`

const NewFlag = styled.span`
  font-size: 9px;
  ${tw`absolute text-primary-500`}
  right: -18px;
  animation: blinker 2s linear infinite;
  transform: rotate(45deg);

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`

const SubNav = styled.nav``
const SubNavItem = styled(Link)`
  ${tw`relative flex text-base border-b border-b-2 border-transparent text-gray-900 hover:text-primary-500 no-underline pt-3 pb-3 mx-4 leading-none transition duration-200`}
  margin-bottom: -1px;

  ${({ active }) => active && tw`text-primary-500 border-primary-600`}
  &.active {
    ${tw`text-primary-500 border-primary-600`}
  }
`
const SubNavList = styled.div`
  ${tw`relative overflow-auto flex flex-row flex-no-wrap flex-1 whitespace-no-wrap -mx-4`}

  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &::-webkit-scrollbar {
    display: none;
  }
`
const SubNavButtonGroup = styled.div`
  ${tw`py-1 flex flex-row flex-wrap items-end justify-end -mx-2`}
  width: 280px;
`
const Container = styled.div`
  ${tw`flex flex-row flex-no-wrap`}
`
const MenuItem = styled.div`
  ${tw`border-b border-gray-200 bg-white`}
  & > button {
    ${tw`w-full flex items-center justify-between pl-4 py-3 font-medium`}

    padding-right: 0.7em;
  }
  svg {
    ${tw`w-6 h-6 text-gray-400`}
  }
`

const MobileMenu = ({ isOpen, onClose }) => {
  return (
    <div
      css={[
        tw`fixed inset-0 h-full transition duration-300`,
        css`
          z-index: 49;
        `,
        isOpen ? tw`visible opacity-100` : tw`invisible opacity-0`,
      ]}
    >
      <div
        css={[
          tw`bg-gray-100 h-full`,
          css`
            margin-top: 48px;
          `,
        ]}
      >
        <div>
          <MenuItem>
            <button
              type="button"
              onClick={() => {
                navigate("/app/push")
                onClose(false)
              }}
            >
              <span>Push</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </MenuItem>
          <MenuItem>
            <button
              type="button"
              onClick={() => {
                navigate("/app/stats")
                onClose(false)
              }}
            >
              <span>Stats</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </MenuItem>
        </div>
      </div>
    </div>
  )
}

function SiteHeader(props) {
  const { appName, subnav, location } = props
  const { openDialog } = props

  const profile = useSelector(({ firebase }) => firebase.profile)
  const isDesignChanged = useSelector(({ design }) => design.isDesignChanged)
  const isMenuChanged = useSelector(({ design }) => design.isMenuChanged)
  const [preview, setPrview] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const externalLinkInstagram = process.env.GATSBY_EXTERNAL_LINK_INSTAGRAM
  const externalLinkHelpcenter = process.env.GATSBY_EXTERNAL_LINK_HELPCENTER

  useEffect(() => {
    const handleResize = event => {
      if (window.innerWidth >= 768 && isMenuOpen) {
        setIsMenuOpen(false)
      }
    }

    window.addEventListener("resize", debounce(handleResize, 250))

    return () => {
      // cleanup
      window.removeEventListener("resize", debounce(handleResize, 250))
    }
  }, [isMenuOpen])

  const handleLogout = () => {
    // window.sessionStorage.clear()
    // console.log("logout")
    navigate("/logout")
  }

  const renderUpgradeButtonTitle = () => {
    const { payment } = profile
    const plan = get(payment, "plan", undefined)

    if (!plan) {
      return "Launch Now"
    }

    return "Upgrade Plan"
  }

  const renderTrialDays = () => {
    const { payment } = profile
    const plan = get(payment, "plan", undefined)

    if (!plan) {
      const trialPeriod = get(payment, "trial_expired_at", undefined)

      if (!trialPeriod) {
        return "Trial period expired"
      }

      // const diff = moment(trialPeriod.toDate()).diff(moment(), "days")
      const diff = moment
        .duration(moment(trialPeriod.toDate()).diff(moment()))
        .asDays()

      if (diff <= 0) {
        return "Trial period expired"
      }

      if (diff == 0) {
        return "1 Free Trial Day Left."
      }

      return `${Math.ceil(diff)} Free trial days left`
    }

    return null
    // const trial = get(payment, "trial_expired")

    // console.log("expire", trial.toDate())

    // console.log(moment(), moment(trial.toDate()))

    // if (isUndefined(plan)) {
    // return <span>{trial.toDate()} Day Remaining</span>
    // }
  }

  /* const renderActiveNav = item => {
    return (
      location.pathname === item.to || includes(item.submenu, location.pathname)
    )
  } */

  return (
    <Header>
      <div
        css={tw`flex flex-row items-center flex-wrap justify-between py-3 px-4 lg:px-8 z-50 relative`}
      >
        {/* <button
          className="leading-none inline-block md:hidden cursor-pointer"
          type="butotn"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        > */}
        <div css={tw`absolute right-0 inline-block md:hidden`}>
          <Hamburger
            size={20}
            toggled={isMenuOpen}
            toggle={setIsMenuOpen}
            color="#fafafa"
          />
        </div>
        {/* </button> */}
        <div className={`flex flex-row items-center`}>
          <Brand onClick={() => navigate("/app/design")}>
            <LogoSymbol css={tw`w-auto h-6`} />
            <StoreName>{appName}</StoreName>
          </Brand>
          <div
            className="flex flex-row inline-center"
            css={css`
              &::before {
                content: "";
                display: inline-block;
                height: 13px;
                width: 1px;
                background-color: #ffffff34;
                background-size: 1px 100%;
                background-position: left bottom;
                margin: 4px 14px 0;
              }
            `}
          >
            <SyncFlag />
          </div>
        </div>
        <TopNav css={tw`flex-row flex-wrap hidden md:flex`}>
          <button
            onClick={() => openDialog({ type: "HOW_IT_WORKS" })}
            className="outline-none focus:outline-none"
          >
            <div className="flex flex-row items-center">
              <svg
                className="w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M6.672 1.911a1 1 0 10-1.932.518l.259.966a1 1 0 001.932-.518l-.26-.966zM2.429 4.74a1 1 0 10-.517 1.932l.966.259a1 1 0 00.517-1.932l-.966-.26zm8.814-.569a1 1 0 00-1.415-1.414l-.707.707a1 1 0 101.415 1.415l.707-.708zm-7.071 7.072l.707-.707A1 1 0 003.465 9.12l-.708.707a1 1 0 001.415 1.415zm3.2-5.171a1 1 0 00-1.3 1.3l4 10a1 1 0 001.823.075l1.38-2.759 3.018 3.02a1 1 0 001.414-1.415l-3.019-3.02 2.76-1.379a1 1 0 00-.076-1.822l-10-4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="leading-none">How it works</span>
            </div>
          </button>
          <a href={externalLinkInstagram} target="_blank">
            <div className="flex flex-row items-center">
              <span className="leading-none icon-instagram mr-2"></span>
              <span className="leading-none">Our customers</span>
            </div>
          </a>
          <a href={externalLinkHelpcenter} target="_blank">
            <div className="flex flex-row items-center">
              <svg
                className="w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <span className="leading-none">Help</span>
            </div>
          </a>
          {process.env.NODE_ENV === "development" && (
            <button onClick={() => handleLogout()}>
              <div className="flex flex-row items-center">
                <svg
                  className="w-4 h-4 mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
                <span className="leading-none">Logout</span>
              </div>
            </button>
          )}
        </TopNav>
      </div>
      <MobileMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
      <div css={tw`px-4 lg:px-8 bg-white shadow-sm hidden md:block`}>
        <SubNav css={tw`flex flex-row justify-between items-center flex-wrap`}>
          <SubNavList>
            <Container>
              {subnav.map(item => (
                <SubNavItem
                  key={item.title}
                  to={item.to}
                  onClick={event => {
                    event.preventDefault()
                    if (isDesignChanged) {
                      return toast(
                        "Your changes have not been saved. Please save and try again."
                      )
                    }

                    if (isMenuChanged) {
                      return toast(
                        "Your changes have not been saved. Please save and try again."
                      )
                    }

                    navigate(item.to)
                  }}
                  activeClassName="active"
                  /* active={renderActiveNav(item)} */
                  partiallyActive={true}
                >
                  {item.title}
                  {get(item, "options.new", false) && <NewFlag>NEW</NewFlag>}
                </SubNavItem>
              ))}
            </Container>
          </SubNavList>
          <div className="flex flex-row items-center">
            <span className="text-sm leading-nong text-gray-600">
              {renderTrialDays()}
            </span>
            <SubNavButtonGroup>
              <div className="mx-1">
                <button
                  onClick={() => setPrview(true)}
                  css={tw`leading-tight border border-primary-500 bg-white text-sm font-medium text-primary-500 rounded-md px-2 py-1 uppercase inline-flex items-center`}
                >
                  <svg
                    className="w-4 h-4 mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    <path
                      fillRule="evenodd"
                      d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>Preview</span>
                </button>
                <PreviewModal
                  isOpen={preview}
                  onRequestClose={() => setPrview(false)}
                />
              </div>
              <div className="mx-1">
                <button
                  onClick={() => {
                    if (isDesignChanged) {
                      return toast(
                        "Your changes have not been saved. Please save and try again."
                      )
                    }

                    if (isMenuChanged) {
                      return toast(
                        "Your changes have not been saved. Please save and try again."
                      )
                    }

                    navigate("/app/pricing")
                  }}
                  css={tw`leading-tight border border-primary-500 bg-primary-500 text-sm font-medium text-white rounded-md px-2 py-1 uppercase inline-block inline-flex items-center`}
                >
                  <svg
                    className="w-4 h-4 mr-1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                    />
                  </svg>
                  <span>{renderUpgradeButtonTitle()}</span>
                </button>
              </div>
            </SubNavButtonGroup>
          </div>
        </SubNav>
      </div>
    </Header>
  )
}

function areEqual(prevProps, nextProps) {
  return (
    isEqual(prevProps.appName, nextProps.appName) &&
    isEqual(prevProps.location, nextProps.location)
  )
}
export default React.memo(SiteHeader, areEqual)
