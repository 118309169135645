import React, { useEffect } from "react"
import { get, isUndefined } from "lodash"
import { connect, useSelector } from "react-redux"
import { closeDialog } from "../redux"
import moment from "moment"
import { track } from "services/analytics"

import HowItWorks from "dialog/components/HowItWorks"
import PowerPlan from "dialog/components/PowerPlan"
import GrowthPlan from "dialog/components/GrowthPlan"
import GrowthPlanListing from "dialog/components/GrowthPlanListing"
import EditIntegration from "dialog/components/EditIntegration"
import TrialExpired from "dialog/components/TrialExpired"
import PromotePush from "dialog/components/PromotePush"

const MODAL_TYPES = {
  HOW_IT_WORKS: HowItWorks,
  POWER_PLAN: PowerPlan,
  GROWTH_PLAN: GrowthPlan,
  GROWTH_PLAN_LISTING: GrowthPlanListing,
  EDIT_INTEGRATION: EditIntegration,
  TRIAL_EXPIRED: TrialExpired,
  PROMOTE_PUSH: PromotePush,
}

function DialogProvider({ type, modalProps, closeDialog }) {
  const isOpen = type ? true : false
  const ModalContent = get(MODAL_TYPES, type, undefined)
  const profile = useSelector(({ firebase }) => firebase.profile)

  useEffect(() => {
    if (isOpen) {
      document.body.className = "overflow-y-hidden"
    } else {
      document.body.className = ""
    }

    return () => {
      document.body.className = ""
    }
  }, [isOpen])

  if (isUndefined(ModalContent)) {
    return null
  }

  if (type) {
    const plan = get(profile, "payment.plan", undefined)
    const trialExpiredAt = get(profile, "payment.trial_expired_at", undefined)
    const diff = moment(trialExpiredAt.toDate()).diff(moment(), "days")
    const trial = trialExpiredAt ? diff : 0

    // let name = type;

    // if(type === 'TRIAL_EXPIRED') {
    //   name = 'expired_message_modal'
    // }
    // upgrade_message_modal_on_applisting
    // upgrade_message_modal_2_payment
    track("VIEW_POPUP", {
      name: type,
      plan,
      trial,
    })
  }

  return (
    <ModalContent
      modalProps={modalProps}
      onClose={() => closeDialog()}
      open={isOpen}
    />
  )

  // return (
  //   <Modal open={isOpen} onClose={() => closeDialog()} >
  //     {ModalContent && <ModalContent {...props} onClose={closeDialog} />}
  //   </Modal>
  // )
}

const mapStateToProps = state => {
  return {
    type: state.dialog.type,
    modalProps: state.dialog.modalProps,
  }
}

const mapDispatchToProps = {
  closeDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(DialogProvider)
