import React from "react"
import { Link, navigate } from "gatsby"
import tw, { styled, css } from "twin.macro"
import { get } from "lodash"
import { useSelector } from "react-redux"

import firebase from "gatsby-plugin-firebase"

import Modal from "dialog/components/Modal"
import Button from "general/components/Button"

import Logo from "assets/images/modal/logo-jcurve-powerplan.png"
import IconSquare from "assets/images/modal/icon-square.png"
import IconCart from "assets/images/modal/icon-cart.png"
import GrowthPlanPromote from "assets/images/modal/growthplan-promote.png"

export default function GrowthPlan(props) {
  const { open, onClose, modalProps } = props
  const auth = useSelector(({ firebase }) => firebase.auth)
  const profile = useSelector(({ firebase }) => firebase.profile)
  const { shopify_url, payment } = profile
  const platform = get(payment, "platform", "android")
  const nextPlatformLabel = platform === "android" ? "iOS" : "Android"

  const changePlatform = () => {
    const DB = firebase.firestore()
    const batch = DB.batch()
    const applistingRef = DB.collection(shopify_url).doc("applisting")
    const userRef = DB.collection("user_list").doc(auth.uid)

    const nextPlatform = platform === "android" ? "ios" : "android"

    batch.update(applistingRef, {
      platform: nextPlatform,
    })
    batch.update(userRef, {
      "payment.platform": nextPlatform,
    })

    batch.commit().then(() => {
      onClose()
    })
  }
  const title = get(modalProps, "title", undefined)

  return (
    <Modal open={open} onClose={onClose}>
      <div className="px-8 py-8 text-center">
        <div className="mb-6">
          <img
            src={GrowthPlanPromote}
            alt="Growth Plan"
            className="inline-block max-w-sm mb-6"
          />
          <h3 className="text-2xl font-semibold">
            {title ? (
              title
            ) : (
              <span>
                Go for <span className="text-primary-500">GROWTH!</span>
              </span>
            )}
          </h3>
          <p className="text-lg text-gray-600">
            Make 300% more sales with iOS&Android Apps
          </p>
        </div>
        <div>
          <div>
            <Button
              primary
              size="large"
              onClick={() => {
                navigate("/app/pricing")
                onClose()
              }}
            >
              Upgrade Now
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
