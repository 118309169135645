import firebase from "gatsby-plugin-firebase"
import axios from "axios"
import Cookies from "universal-cookie"

const FUNCTION_URL = process.env.GATSBY_FIREBASE_FUNCTIONS_URL

export const requestPayment = async ({ name, interval, price, custom }) => {
  try {
    const token = await firebase.auth().currentUser.getIdToken(true)
    const response = await axios({
      method: "post",
      url: `${FUNCTION_URL}createRecurringApplicationCharge`,
      data: {
        name,
        interval,
        trialDays: 0,
        price,
        custom: custom || false,
        test: process.env.NODE_ENV === "development",
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    })

    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    window.Sentry.captureException(error)
  }
}

export const getShopDomain = () => {
  const cookies = new Cookies()
  const queryParams = window.location.search || cookies.get(`hmac_query`)
  const urlParams = new URLSearchParams(queryParams)
  let parentDomain =
    document.location.ancestorOrigins && document.location.ancestorOrigins[0] // if chrome iframe
  const shopDomain = urlParams.get("shop")

  if (typeof parentDomain !== "undefined") {
    parentDomain = parentDomain.replace("https://", "")
  }

  return shopDomain || parentDomain
}
