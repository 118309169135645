import React, { useRef, useState } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import tw from "twin.macro"
import { isEqual, get, assign, upperCase } from "lodash"
import produce from "immer"
import { toast } from "react-toastify"
import Button from "general/components/Button"
import ConfirmModal from "modal/components/ConfirmModal"
import { validate } from "uuid"
import moment from "moment"
import { navigate } from "gatsby"

const Footer = styled.div`
  ${tw`text-center flex items-center justify-center fixed bottom-0 left-0 right-0 bg-white py-2`}
  z-index: 49;
  box-shadow: 0 0 10px rgba(173, 173, 173, 0.3);
`

function EditorFooter({
  onDiscardAction,
  onSaveAction,
  saveDesign,
  isDesignChanged,
  isMenuChanged,
  setSelectedSection,
  validateBlocks,
  openDialog,
  closeDialog,
}) {
  const saveToastRef = useRef(null)
  const [confirm, setConfirm] = useState({
    isOpen: false,
  })
  const profile = useSelector(state => state.firebase.profile)
  const sections = useSelector(state => state.design.sections)
  const blocks = useSelector(state => state.design.blocks.byId)
  const isMultipage = get(profile, "settings.multipage", false)
  const paid = get(profile, "payment.paid", false)
  const trialExpiredAt = get(profile, "payment.trial_expired_at", undefined)
  const isTrialExpired = trialExpiredAt
    ? moment(trialExpiredAt.toDate()).diff(moment()) <= 0
    : true

  const handleCloseConfirm = () => {
    setConfirm(
      produce(draft => {
        draft.isOpen = false
      })
    )
  }

  const handleSave = () => {
    let isBlockValid = true
    let invalidBlockCount = 0
    const invalidBlocks = {}
    toast.dismiss(saveToastRef.current)

    sections.ids.forEach(sectionId => {
      const section = get(sections, `byId.${sectionId}`, undefined)

      assign(invalidBlocks, {
        [sectionId]: {
          title: section.title,
          blocks: [],
        },
      })

      if (section) {
        const sectionBlocks = section.blocks

        if (sectionBlocks.length > 0) {
          sectionBlocks.forEach(blockId => {
            const block = blocks[blockId]

            if (get(block, "isValid", false) === false) {
              invalidBlockCount++
              invalidBlocks[sectionId].blocks.push(block)
            }
          })
        }
      }
    })

    if (invalidBlockCount > 0) {
      isBlockValid = false
    }

    if (isBlockValid === false) {
      validateBlocks()

      let message = ""

      Object.keys(invalidBlocks).forEach(sectionId => {
        const blocks = invalidBlocks[sectionId].blocks

        if (invalidBlocks[sectionId].blocks.length > 0) {
          if (isMultipage) {
            message = message.concat(`• ${invalidBlocks[sectionId].title}<br/>`)
          }

          blocks.forEach(item => {
            const type = upperCase(get(item, "type", ""))
            message = message.concat(
              `- <span class="text-sm">${type}</span><br/>`
            )
          })
        }
      })

      return (saveToastRef.current = toast(
        <div
          dangerouslySetInnerHTML={{
            __html: `<span class="block mb-2">Please check the error or remove the empty block below:</span> ${message}`,
          }}
        ></div>,
        {
          closeOnClick: true,
          autoClose: false,
        }
      ))
    }

    let isSectionValid = true

    sections.ids.forEach(sectionId => {
      const section = get(sections, `byId.${sectionId}`, undefined)

      if (!isSectionValid) {
        return false
      }

      if (section) {
        // if (section.blocks.length === 0) {
        //   isSectionValid = false
        //   setSelectedSection(sectionId)
        //   return (saveToastRef.current = toast(
        //     `Please add any blocks or remove the ${section.title} section`
        //   ))
        // }
      }
    })

    if (isSectionValid && isBlockValid) {
      if (!paid) {
        if (isTrialExpired) {
          return openDialog({
            type: "TRIAL_EXPIRED",
            modalProps: {
              secondaryAction: () => {
                closeDialog()
                saveDesign({
                  afterAction: {
                    type: "redirect",
                    action: "/app/pricing",
                  },
                })
              },
              content: "or save it temporally",
              onAction: () => {
                closeDialog()
                onSaveAction()
              },
            },
          })
        }
      }

      setConfirm(
        produce(draft => {
          draft.isOpen = true
          draft.title = "Do you want to save your changes?"
          draft.primaryAction = {
            content: "Save",
            onAction: () => {
              onSaveAction()
              handleCloseConfirm()
            },
          }
          draft.secondaryAction = {
            content: "Cancel",
            onAction: () => {
              handleCloseConfirm()
            },
          }
        })
      )
    }
  }

  return (
    <Footer>
      <div css={tw`flex flex-row flex-wrap -mx-1`}>
        <div css={tw`inline-block w-32 mx-1`}>
          <Button
            fullWidth
            outline
            size={"slim"}
            onClick={() =>
              setConfirm(
                produce(draft => {
                  draft.isOpen = true
                  draft.title = "Are you sure you want to discard all changes?"
                  draft.primaryAction = {
                    content: "Discard",
                    onAction: () => {
                      onDiscardAction()
                      handleCloseConfirm()
                    },
                  }
                  draft.secondaryAction = {
                    content: "Cancel",
                    onAction: () => {
                      handleCloseConfirm()
                    },
                  }
                })
              )
            }
            disabled={!isDesignChanged && !isMenuChanged}
          >
            Discard
          </Button>
        </div>
        <div css={tw`inline-block w-32 mx-1`}>
          <Button
            fullWidth
            primary
            size={"slim"}
            onClick={handleSave}
            disabled={!isDesignChanged && !isMenuChanged}
          >
            Save
          </Button>
        </div>
      </div>

      <ConfirmModal
        isOpen={confirm.isOpen}
        onRequestClose={handleCloseConfirm}
        primaryAction={confirm.primaryAction}
        secondaryAction={confirm.secondaryAction}
        title={confirm.title}
      />
    </Footer>
  )
}

function areEqual(prevProps, nextProps) {
  return (
    isEqual(prevProps.isDesignChanged, nextProps.isDesignChanged) &&
    isEqual(prevProps.isMenuChanged, nextProps.isMenuChanged)
  )
}
export default React.memo(EditorFooter, areEqual)
