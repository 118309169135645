import React from "react"
import Button from "general/components/Button"
import tw, { styled } from "twin.macro"

const Wrapper = styled.div`
  ${tw`bg-white px-4 py-4 rounded-md shadow-md`}
`

export default function Card() {
  return (
    <Wrapper>
      <h2 className="text-lg font-semibold">card title</h2>
      <p className="text-gray-800 pb-4">body content</p>
      <Button primary>Submit</Button>
    </Wrapper>
  )
}
