import React from "react"
import tw, { styled, css } from "twin.macro"

const Wrapper = styled.div`
  ${tw`bg-white rounded-sm shadow-sm px-4 py-4 border border-gray-200 relative`}

  ${({ subdued }) => subdued && tw`bg-gray-200`}
`

export default function Card({ title, children, subdued }) {
  return (
    <Wrapper subdued={subdued}>
      {title && (
        <h3 className="text-lg font-medium leading-tight mb-2">{title}</h3>
      )}
      <div className="text-gray-600">{children}</div>
    </Wrapper>
  )
}
