import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import tw from "twin.macro"

const Wrapper = styled.div`
  ${tw`text-white flex flex-row items-center justify-between px-3 py-3`}
  background-color: #2b2b2b;

  h2 {
    ${tw`text-xs font-medium`}
    min-width: 0;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
  button {
    ${tw`uppercase block border border-white rounded-sm text-xs font-semibold text-left ml-2`}
    padding: 2px 6px;
  }
`

export default function PromocodeBlock({ promo_title, promo_code }) {
  const title =
    !promo_title || promo_title.length === 0
      ? "Marketing message for promotion code"
      : promo_title
  const code = !promo_code || promo_code.length === 0 ? "Code" : promo_code

  return (
    <Wrapper>
      <h2>{title}</h2>
      <button>{code}</button>
    </Wrapper>
  )
}

PromocodeBlock.propTypes = {
  preview: PropTypes.bool,
  promo_title: PropTypes.string,
  promo_code: PropTypes.string,
}
