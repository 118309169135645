import React from "react"
import tw, { styled, css } from "twin.macro"
import ListItem from "./ThumbnailListItem"

function ThumbnailList({ list, selected, onPrimaryAction, onRemoveAction }) {
  return (
    <div css={tw`grid grid-cols-3 gap-4 mt-4`}>
      {list.map((item, index) => (
        <ListItem
          key={`${index}`}
          thumbnail={item.image_url}
          isActive={index === selected}
          onAction={() => onPrimaryAction(index)}
          onRemoveAction={() => onRemoveAction(index)}
        />
      ))}
    </div>
  )
}

export default React.memo(ThumbnailList)
