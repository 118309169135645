import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react"
import tw from "twin.macro"
import { css } from "styled-components"
import { useForm } from "react-hook-form"
import produce from "immer"
import { get } from "lodash"

import TextField from "general/components/TextField"
import FormLayout from "general/components/FormLayout"
import BlockItem from "design/components/BlockItem"

const FormPromocode = forwardRef(({ onSubmit, meta }, ref) => {
  const inputRef = useRef(null)
  const [preview, setPreview] = useState({
    promo_title: get(meta, "promo_title", ""),
    promo_code: get(meta, "promo_code", ""),
  })
  const { handleSubmit, register, errors } = useForm()

  const getValues = data => {
    onSubmit(data)
  }

  useEffect(() => {
    inputRef.current.focus()
  }, [])

  const handleChange = event => {
    const target = event.target.name
    const value =
      event.target.value.length === 0 ? undefined : event.target.value

    setPreview(
      produce(draft => {
        draft[target] = value
      })
    )
  }

  useImperativeHandle(ref, () => ({
    formSubmit: handleSubmit(getValues),
  }))

  const maxLength = {
    CODE: 12,
    TITLE: 100,
  }

  return (
    <div>
      <div css={tw`block bg-gray-100 border-b border-gray-300 px-6 py-6`}>
        <div
          css={[
            tw`mx-auto `,
            css`
              width: 375px;
            `,
          ]}
        >
          <span css={tw`block text-base text-gray-900 font-medium mb-2`}>
            Preview
          </span>
          <BlockItem type="promocode" info={preview} />
        </div>
      </div>
      <form css={tw`px-6 py-8`}>
        <FormLayout>
          <TextField
            name="promo_code"
            type="text"
            value={preview.promo_code || ""}
            onChange={handleChange}
            label="Promotion code"
            placeholder={`Write a discount code up to ${maxLength.CODE} characters.`}
            maxLength={maxLength.CODE}
            ref={event => {
              inputRef.current = event
              register(event, { required: true, maxLength: maxLength.CODE })
            }}
            error={errors.promo_code && "This field is required"}
          />
          <TextField
            name="promo_title"
            type="text"
            value={preview.promo_title || ""}
            onChange={handleChange}
            label="Promotion title"
            placeholder={`Write a marketing message up to ${maxLength.TITLE} bytes.`}
            maxLength={maxLength.TITLE}
            ref={event => {
              register(event, { required: true, maxLength: maxLength.TITLE })
            }}
            error={errors.promo_title && "This field is required"}
          />
        </FormLayout>
      </form>
    </div>
  )
})

export default FormPromocode
