import React from "react"
import tw, { styled, css } from "twin.macro"

const Timeline = styled.div`
  ${tw`mt-4`}
`

const TimelineItem = ({ color, icon, label, children }) => {
  return (
    <div
      css={[
        tw`relative`,
        css`
          padding-left: 40px;
          padding-bottom: 32px;
          &:last-child {
            .timeline-item__bar::after {
              background: transparent;
            }
          }
        `,
      ]}
    >
      <div
        css={[
          tw`font-medium absolute text-gray-900 leading-tight`,
          css`
            left: 0;
            top: 2px;
          `,
        ]}
      >
        {label}
      </div>
      <div
        className="timeline-item__bar"
        css={css`
          width: 80px;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          &::after {
            width: 2px;
            right: 6px;
            left: auto;
            content: "";
            background: ${color};
            display: block;
            position: absolute;
            top: 24px;
            bottom: 0;
          }
        `}
      >
        <div
          css={css`
            width: 15px;
            height: 15px;
            position: absolute;
            right: 0;
            top: 4px;
          `}
        >
          {icon}
        </div>
      </div>
      <div
        css={css`
          margin-left: 60px;
        `}
      >
        {children}
      </div>
    </div>
  )
}

function ListingTimeline({ color, label, children }) {
  return <Timeline>{children}</Timeline>
}

ListingTimeline.Item = TimelineItem

export default ListingTimeline
