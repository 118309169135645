import React from "react"
import { connect } from "react-redux"
import tw from "twin.macro"
import { get } from "lodash"

import ColorPicker from "design/components/ColorPicker"

import { updateColors } from "design/redux"

function ColorSection({ primary, accent, button, updateColors }) {
  return (
    <div css={tw`grid grid-cols-1 gap-4`}>
      <div>
        <h3 css={tw`text-sm font-medium mb-1 text-gray-700`}>Primary Color</h3>
        <ColorPicker
          color={primary}
          type="primary"
          updateColors={updateColors}
        />
      </div>
      <div>
        <h3 css={tw`text-sm font-medium mb-1 text-gray-700`}>Accent Color</h3>
        <ColorPicker color={accent} type="accent" updateColors={updateColors} />
      </div>
      <div>
        <h3 css={tw`text-sm font-medium mb-1 text-gray-700`}>Button Color</h3>
        <ColorPicker color={button} type="button" updateColors={updateColors} />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const ui = state.design.ui

  return {
    primary: get(ui, "colors.primary"),
    accent: get(ui, "colors.accent"),
    button: get(ui, "colors.button"),
  }
}

const mapDispatchToProps = { updateColors }

export default connect(mapStateToProps, mapDispatchToProps)(ColorSection)
