import React from "react"
import { navigate } from "gatsby"
import tw, { css } from "twin.macro"
import { get } from "lodash"

import Modal from "dialog/components/Modal"
import Button from "general/components/Button"

import Content from "assets/images/modal/trial-expired.png"

export default function TrialExpired(props) {
  const { open, onClose, modalProps } = props
  const secondaryAction = get(modalProps, 'secondaryAction', undefined);

  return (
    <Modal open={open} onClose={onClose}>
      <div className="px-8 py-8 text-center">
        <img
          src={Content}
          alt="Trial Expired"
          className="inline-block max-w-sm mb-6"
        />
        <h3 css={tw`text-2xl font-medium leading-snug text-gray-900 mb-6`}>
          Your trial period has expired. :(
          <br />
          Want to keep enjoying your mobile app?
        </h3>
        <div>
          <Button
            primary
            // onClick={() => {
            //   onClose()
            //   navigate("/app/pricing")
            // }}
            onClick={secondaryAction ? secondaryAction : () => { onClose(); navigate('/app/pricing') }}
          >
            Upgrade Now
          </Button>
        </div>
        <div>
          {get(modalProps, "content") && (
            <button
              className="text-primary-500 mt-2 underline"
              onClick={modalProps.onAction}
              type="button"
            >
              {modalProps.content}
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}
