import React, { useState, useEffect } from "react"
import styled from "styled-components"
import tw from "twin.macro"
import PropTypes from "prop-types"
import { get, isNull } from "lodash"
import { useSelector } from "react-redux"
import { useBlockImage } from "reaverUtil/image"
import WarningOverlay from "design/components/WarningOverlay"

const Item = ({ thumbnail, preview }) => {
  return (
    <div css={tw`bg-gray-100 relative block`}>
      {thumbnail && !preview ? (
        <img src={thumbnail} css={tw`w-full h-auto`} />
      ) : (
        <div css={tw`relative`}>
          <div css={tw`block pb-9/16 bg-gray-100`}></div>
          {thumbnail && (
            <img
              src={thumbnail}
              alt="Custom Image Banner"
              css={tw`absolute inset-0 w-full h-full object-cover`}
            />
          )}
          {!thumbnail && (
            <div
              css={tw`absolute inset-0 flex items-center text-center justify-center`}
            >
              <h2 css={tw`text-xl font-medium text-gray-800`}>
                Custom <br />
                Image Banner
              </h2>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default function CustomBannerBlock(props) {
  const {
    action_type,
    action_value,
    image_url,
    preview,
    id,
    setBlockValid,
  } = props
  const images = useBlockImage()
  const collections = useSelector(state => state.shopify.collections.byId)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (action_type === "view_collection") {
      const collectionId = action_value
      const collection = get(collections, collectionId, null)
      const hasDeletedCollection = isNull(collection)
      hasDeletedCollection && setBlockValid({ id, isValid: false })

      return setError(hasDeletedCollection)
    }
    return setError(null)
  }, [action_type, action_value])

  if (preview) {
    return <Item preview thumbnail={images["custom_banner_banner"].src} />
  }

  return (
    <div>
      <Item thumbnail={image_url} />
      {error && <WarningOverlay />}
    </div>
  )
}

CustomBannerBlock.propTypes = {
  preview: PropTypes.bool,
}
CustomBannerBlock.defaultProps = {
  preview: false,
}
