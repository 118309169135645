import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw, { css } from "twin.macro"
import { get, some, isNull } from "lodash"
import GraphemeSplitter from "grapheme-splitter"

import WarningOverlay from "design/components/WarningOverlay"
import PlaceholderColthes from "assets/images/design/block/placeholder-clothes.png"
import PlaceholderCamera from "assets/images/design/block/placeholder-camera.png"
import PlaceholderBeauty from "assets/images/design/block/placeholder-beauty.png"
import PlaceholderBag from "assets/images/design/block/placeholder-bag.png"
import PlaceholderChair from "assets/images/design/block/placeholder-chair.png"
import { useBlockImage } from "reaverUtil/image"
import WarningImage from "assets/images/design/icon-menu-warning-circle.png"

const Item = styled.div`
  ${tw`mr-4 text-center relative`}
  flex: 0 0 auto;

  ${({ viewType }) => viewType === "rectangle" && tw`mr-3`}

  ${({ viewType, orientation }) => {
    switch (viewType) {
      case "rectangle":
        if (orientation === "horizontal") {
          return `width: 40%`
        }
        return `width: 28%`
      default:
        return `width: 19%;`
    }
  }};

  .thumbnail {
    /* border-radius: 0; */
    ${({ viewType, orientation }) => {
      switch (viewType) {
        case "rectangle":
          if (orientation === "horizontal") {
            return tw`pb-9/16 rounded-md`
          }

          return `padding-bottom: 150%; border-radius: 8px;`
        // return tw`pb-9/16 rounded-md`
        // return tw`pb-9/16 rounded-md`
        default:
          return `padding-bottom: 100%`
      }
    }};

    .overlay {
      ${tw`absolute inset-0 z-20 left-0 right-0 top-0 bottom-0`}
      background: rgba(0, 0, 0, 0.2);
    }
  }

  .content {
    ${tw`flex items-center justify-center`}

    ${({ viewType }) => {
      switch (viewType) {
        case "rectangle":
          return tw`absolute inset-0`
        default:
          return tw`relative pt-1`
      }
    }};

    h3 {
      ${tw`block overflow-hidden truncate text-gray-800 relative z-30`}
      font-size: 13px;
      line-height: 1.3em;
      white-space: normal;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      word-wrap: break-word;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      ${({ viewType }) =>
        viewType === "rectangle" && [
          tw`text-white font-medium`,
          css`
            font-size: 14px;
          `,
        ]}

      ${({ isPlaceholder }) => {
        return isPlaceholder && tw`text-gray-800`
      }}
    }
  }
`

const Wrapper = styled.div`
  ${tw`flex flex-row flex-no-wrap overflow-hidden px-4 py-4`}
`

const CollectionItem = ({
  thumbnail,
  title,
  viewType,
  portrait,
  isPlaceholder,
  orientation,
}) => {
  const splitter = new GraphemeSplitter()
  const primaryColor = useSelector(state => state.design.ui.colors.primary)

  if (portrait) {
    return (
      <Item
        viewType={"rectangle"}
        isPlaceholder={isPlaceholder}
        orientation={orientation}
      >
        <div
          className="thumbnail"
          css={[
            tw`relative overflow-hidden border border-gray-200 bg-gray-100 mb-1`,
          ]}
        >
          {thumbnail ? (
            <img
              src={thumbnail}
              alt={title}
              css={[
                tw`w-full h-full inset-0 object-cover object-center absolute`,
                isPlaceholder && tw`hidden`,
              ]}
            />
          ) : (
            <div
              css={[
                tw`w-full h-full inset-0 absolute bg-gray-100 inline-flex justify-center font-bold items-center text-4xl uppercase text-black`,
                css`
                  background-color: ${primaryColor};
                `,
                primaryColor === "#000000" && tw`text-white`,
              ]}
            >
              {title.length > 0 && splitter.splitGraphemes(title)[0]}
            </div>
          )}
          {!isPlaceholder && <div className="overlay" />}
        </div>
        <div className="content">
          <h3>{title}</h3>
        </div>
      </Item>
    )
  }

  return (
    <Item viewType={viewType}>
      <div
        className="thumbnail"
        css={[
          tw`relative overflow-hidden border border-gray-200 rounded-full bg-gray-100 mb-1`,
        ]}
      >
        {thumbnail ? (
          <img
            src={thumbnail}
            alt={title}
            css={tw`w-full h-full inset-0 object-cover object-center absolute`}
          />
        ) : (
          <div
            css={[
              tw`w-full h-full inset-0 absolute bg-gray-100 inline-flex justify-center font-bold items-center text-4xl uppercase text-black`,
              css`
                background-color: ${primaryColor};
              `,
              primaryColor === "#000000" && tw`text-white`,
            ]}
          >
            {title.length > 0 && splitter.splitGraphemes(title)[0]}
          </div>
        )}
      </div>
      <div className="content">
        <h3>{title}</h3>
      </div>
    </Item>
  )
}

export default function CollectionListBlock(props) {
  const {
    layout,
    preview,
    collection_ids,
    id,
    setBlockValid,
    orientation,
  } = props
  const [list, setList] = useState([])
  const [error, setError] = useState(false)
  const collections = useSelector(state => state.shopify.collections.byId)
  const supportPortrait =
    useSelector(state => state.design.ui.supportPortrait) || false
  const images = useBlockImage()

  useEffect(() => {
    if (collection_ids) {
      const nextList = collection_ids.map(id => {
        return get(collections, id, null)
      })

      if (nextList.length > 0) {
        const hasDeletedItems = some(nextList, item => isNull(item))

        setError(hasDeletedItems)
        hasDeletedItems && setBlockValid({ id, isValid: false })
      }

      setList(nextList)
    }
  }, [collection_ids, collections])

  const renderContent = () => {
    if (preview) {
      let previewList = [
        {
          title: "Clothing",
          thumbnail: images["colleciton-hover-ciao-bello-espadrilles"].src,
        },
        {
          title: "Shoes",
          thumbnail: images["collection_list-hover-shoes"].src,
        },
        {
          title: "Jewelry",
          thumbnail: images["collection_list-hover-jewelry"].src,
        },
        {
          title: "Watches",
          thumbnail: images["collection_list-hover-watches"].src,
        },
        {
          title: "Bags",
          thumbnail: images["collection_list-hover-bags"].src,
        },
      ]

      if (supportPortrait) {
        previewList = [
          {
            title: "Feminie Styles",
            thumbnail:
              images["collection_list-hover-vertical-feminine-styles"].src,
          },
          {
            title: "Resort Wear",
            thumbnail: images["collection_list-vertical-hover-resort-wear"].src,
          },
          {
            title: "Cocktail Party Dress",
            thumbnail:
              images["collection_list-vertical-hover-cocktail-party-dress"].src,
          },
          {
            title: "Jewelry",
            thumbnail: images["collection_list-vertical-hover-jewelry"].src,
          },
        ]
      }

      return previewList.map((collection, index) => (
        <CollectionItem
          portrait={supportPortrait}
          key={index}
          title={collection.title}
          thumbnail={collection.thumbnail}
        />
      ))
    }

    if (list.length === 0) {
      return (
        <>
          <CollectionItem
            portrait={supportPortrait}
            title={"Collection"}
            thumbnail={PlaceholderColthes}
            isPlaceholder={true}
            orientation={orientation}
          />
          <CollectionItem
            portrait={supportPortrait}
            title={"Collection"}
            thumbnail={PlaceholderCamera}
            isPlaceholder={true}
            orientation={orientation}
          />
          <CollectionItem
            portrait={supportPortrait}
            title={"Collection"}
            thumbnail={PlaceholderBeauty}
            isPlaceholder={true}
            orientation={orientation}
          />
          <CollectionItem
            portrait={supportPortrait}
            title={"Collection"}
            thumbnail={PlaceholderBag}
            isPlaceholder={true}
            orientation={orientation}
          />
          <CollectionItem
            portrait={supportPortrait}
            title={"Collection"}
            thumbnail={PlaceholderChair}
            isPlaceholder={true}
            orientation={orientation}
          />
        </>
      )
    }

    return (
      list.length > 0 &&
      list.map((item, index) => {
        if (!item) {
          return (
            <CollectionItem
              key={`collectionItem-${index}`}
              portrait={supportPortrait}
              title={"Deleted"}
              thumbnail={WarningImage}
              orientation={orientation}
            />
          )
        }
        return (
          <CollectionItem
            key={`collectionItem-${index}`}
            portrait={supportPortrait}
            title={get(item, "title", "Deleted")}
            thumbnail={get(item, "image.transformedSrc", undefined)}
            orientation={orientation}
          />
        )
      })
    )
  }

  return (
    <Wrapper layout={layout}>
      {renderContent()}
      {error && <WarningOverlay />}
    </Wrapper>
  )
}

CollectionListBlock.propTypes = {
  layout: PropTypes.string,
  preview: PropTypes.bool,
}
CollectionListBlock.defaultProps = {
  layout: "circle",
  preview: false,
}
