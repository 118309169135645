import React from "react"
import { isEqual } from "lodash"

import ShopifyProvider from "shopify/containers/ShopifyProvider"
import SearchModal from "./SearchModal"

function ShopifyModalProvider(props) {
  const { meta, isOpen, onPrimaryAction, onRequestClose, disabledEmpty } = props

  return (
    <ShopifyProvider>
      {isOpen && (
        <SearchModal
          meta={meta}
          isOpen={isOpen}
          onPrimaryAction={onPrimaryAction}
          onRequestClose={onRequestClose}
          disabledEmpty={disabledEmpty}
        />
      )}
    </ShopifyProvider>
  )
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps, nextProps)
}
export default React.memo(ShopifyModalProvider, areEqual)
