import { connect } from "react-redux"

import ScreenSwitch from "design/components/ScreenSwitch"
import { toggleMenu } from "design/redux"

const mapStateToProps = state => {
  const isMenuChanged = state.design.isMenuChanged
  const isDesignChanged = state.design.isDesignChanged

  let disabled = state.design.blocks.isLoaded

  if (isMenuChanged || isDesignChanged) {
    disabled = true
  }

  return {
    isActive: state.design.isMenuActive,
    isMenuChanged: state.design.isMenuChanged,
    isDesignChanged: state.design.isDesignChanged,
    disabled,
  }
}

const mapDispatchToProps = {
  toggleMenu,
}

export default connect(mapStateToProps, mapDispatchToProps)(ScreenSwitch)
