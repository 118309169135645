import React, { useRef, useState, useEffect } from "react"
import tw from "twin.macro"
import styled, { css } from "styled-components"
import { get, isNull, find } from "lodash"
import { useLazyQuery, gql } from "@apollo/client"
import moment from "moment"
import produce from "immer"
import { getFirebase } from "react-redux-firebase"

import Modal from "general/components/Modal"
import Overlay from "general/components/Overlay"
import ChoiceList from "general/components/ChoiceList"
import TextField from "general/components/TextField"
import DatetimePicker from "general/components/DatetimePicker"
import FormLayout from "general/components/FormLayout"
import CountdownBlock from "design/components/BlockItem/CountdownBlock"
import SearchAuto from "shopify/components/SearchAuto"
import FormBlockImageUpload from "design/components/FormBlockImageUpload"
import noImage from "assets/images/design/block/noimage.png"
import Button from "general/components/Button"
import VerifyLinkButton from "form/components/VerifyLinkButton"
import { isURL, addHttp } from "reaverUtil/form"
import WarningImage from "assets/images/design/icon-menu-warning-sqaure.png"
import compareVersions from "compare-versions"

const GET_COLLECTIONS_BY_IDS = gql`
  query getCollectionsByIds($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on Collection {
        id
        title
        image(maxWidth: 150, maxHeight: 150, crop: CENTER) {
          transformedSrc(maxWidth: 150, maxHeight: 150, crop: CENTER)
          originalSrc
        }
      }
    }
  }
`

const ImageControl = styled.div`
  ${tw`relative cursor-pointer`}

  .image-control {
    ${tw`hidden`}
  }

  &:hover .image-control {
    ${tw`flex`}
  }
`

export default function EditCountdownBlockModal({
  isOpen,
  onRequestClose,
  onAction,
  meta,
  updateCollectionsSuccess,
  current_version,
}) {
  const firebase = getFirebase()

  const uploadRef = useRef(null)

  const [item, setItem] = useState({
    title: "",
    event_finish: firebase.firestore.Timestamp.fromDate(new Date()),
    selected: "view_collection",
    image_url: null,
    view_collection: [],
    view_web: "",
    view_static: undefined,
    ratio: null,
  })

  const [errors, setErrors] = useState({
    title: undefined,
    event_finish: undefined,
    view_web: undefined,
    view_collection: undefined,
  })

  useEffect(() => {
    if (meta) {
      const {
        event_title,
        event_finish,
        action_type,
        action_value,
        image_url,
      } = meta

      let data

      if (action_type === "view_collection") {
        data = { [action_type]: [action_value] }
      } else {
        data = { [action_type]: action_value }
      }

      setItem({
        ...item,
        image_url: image_url ? image_url : null,
        title: event_title,
        event_finish: event_finish
          ? event_finish
          : firebase.firestore.Timestamp.fromDate(new Date()),
        selected: action_type ? action_type : "view_collection",
        ratio: get(meta, 'ratio', 0),
        ...data,
      })
    }
  }, [meta])
  const [loadCollections, { loading, error, data }] = useLazyQuery(
    GET_COLLECTIONS_BY_IDS,
    {
      variables: {
        ids: item.view_collection,
      },
    }
  )
  useEffect(() => {
    if (item.selected === "view_collection") {
      loadCollections({ variables: { ids: item.view_collection } })
    }
  }, [item.view_collection])

  const handleCollectionRemove = id => {
    const newCollection = item.view_collection.filter(item => item !== id)

    setItem(
      produce(draft => {
        draft.view_collection = newCollection
      })
    )
  }

  const handleDateChange = date => {
    const nextDatetime = firebase.firestore.Timestamp.fromDate(
      moment(date).toDate()
    )

    setItem(
      produce(draft => {
        draft.event_finish = nextDatetime
      })
    )
    setErrors(
      produce(draft => {
        draft.event_finish = undefined
      })
    )
  }

  const handleStartDateChange = date => {
    const nextDatetime = firebase.firestore.Timestamp.fromDate(
      moment(date).toDate()
    )

    setItem(
      produce(draft => {
        draft.event_start = nextDatetime

        const diff = moment(item.event_finish.toDate()).diff(
          moment(nextDatetime.toDate())
        )

        if (diff <= 0) {
          draft.event_finish = nextDatetime
        }
      })
    )
  }

  const handleUpload = (imageUrl, data) => {
    setItem(
      produce(draft => {
        draft.image_url = imageUrl
        draft.ratio = data.ratio
      })
    )
  }

  const handlePrimary = () => {
    const action_type = item.selected

    const diff = moment(item.event_finish.toDate()).diff(moment())

    // if (item.title.length === 0) {
    //   return setErrors(
    //     produce(draft => {
    //       draft.title = "This field is required."
    //     })
    //   )
    // }

    if (diff <= 0) {
      return setErrors(
        produce(draft => {
          draft.event_finish = "Please select end date of event"
        })
      )
    }

    let isValid = true;

    switch (action_type) {
      case "view_collection":
        if (item.view_collection.length === 0) {
          return setErrors(
            produce(draft => {
              draft.view_collection = "Please select a collection"
            })
          )
        }

        item.view_collection.forEach(itemId => {
          const selectedCollection = find(data.nodes, obj => obj && obj.id === itemId)

          if (!selectedCollection) {
            setErrors(
              produce(draft => {
                draft.view_collection = "Please select a new collection."
              })
            )

            return isValid = false
          }
        })

        break
      case "view_web":
        if (item.view_web.length === 0) {
          setErrors(
            produce(draft => {
              draft.view_web = "Please fill out website address field"
            })
          )

          return isValid = false
        }
        // if (!isURL(item.view_web)) {
        //   return setErrors(
        //     produce(draft => {
        //       draft.view_web = "Please fill out valid website address."
        //     })
        //   )
        // }
        break
      case "view_static":
      default:
        break
    }

    if (!isValid) {
      return false;
    }

    let action_value = item[action_type]

    if (action_type === "view_collection") {
      action_value = get(item.view_collection, 0, undefined)
    }
    if (action_type === "view_web") {
      action_value = addHttp(action_value)
    }
    if (action_type === "view_static") {
      action_value = ""
    }

    const validData = {
      image_url: item.image_url,
      event_title: item.title || "",
      action_type,
      action_value,
      event_finish: item.event_finish,
      ratio: item.ratio,
      push_reservation : [] //Rollback 대비 호환성을 위해 임시 유지 (todo : 안정화후 제거)
    }

    onAction(validData)
  }

  const handleTypeChange = value => {
    setItem(
      produce(draft => {
        draft.selected = value
      })
    )
  }

  const handleCollectionChange = (selected, item) => {
    if (selected.length > 0) {
      updateCollectionsSuccess({ [item.node.id]: item.node })
    }
    setItem(
      produce(draft => {
        draft.view_collection = selected
      })
    )
    setErrors(
      produce(draft => {
        draft.view_collection = null
      })
    )
  }

  const handleTitleChange = event => {
    const title = event.target.value

    if (errors.title) {
      setErrors(
        produce(draft => {
          draft.title = undefined
        })
      )
    }

    setItem(
      produce(draft => {
        draft.title = title
      })
    )
  }

  const handleTextChange = event => {
    const text = event.target.value

    setItem(
      produce(draft => {
        draft.view_web = text
      })
    )
  }

  const renderCollection = () => {
    return (
      <div>
        <SearchAuto
          resourceType="collections"
          onChange={handleCollectionChange}
          selected={item.view_collection}
          error={errors.view_collection}
        />
        <div>
          {!loading &&
            data &&
            data.nodes.length > 0 &&
            item.view_collection.map(itemId => {
              const item = find(data.nodes, obj => obj && obj.id === itemId)

              if (!item) {
                return (
                  <div
                    key={itemId}
                    css={tw`p-2 bg-red-100 flex flex-row w-full items-center justify-between mt-2`}
                  >
                    <div
                      css={[
                        tw`flex flex-row items-center truncate `,
                        css`
                          max-width: 360px;
                          min-width: 0;
                        `,
                      ]}
                    >
                      <div css={tw`w-12 relative mr-4`}>
                        <div css={tw`pb-1/1`}></div>
                        <img
                          css={tw`absolute inset-0 w-full h-full object-cover`}
                          src={WarningImage}
                          alt={"Deleted collection"}
                        />
                      </div>
                      <h3
                        css={[
                          tw`text-gray-800 font-normal truncate flex-1 pr-4`,
                          css`
                            min-width: 0;
                          `,
                          tw`text-red-600`,
                        ]}
                      >
                        Deleted collection
                      </h3>
                    </div>
                    <div css={tw``}>
                      <div
                        css={tw`text-xs text-gray-600 hover:bg-gray-100 w-6 h-6 inline-flex items-center justify-center cursor-pointer rounded-sm transition duration-500 `}
                        onClick={() => handleCollectionRemove(itemId)}
                      >
                        <span className="icon-cancel"></span>
                      </div>
                    </div>
                  </div>
                )
              }

              return (
                <div
                  key={itemId}
                  css={tw`flex flex-row w-full items-center justify-between mt-2`}
                >
                  <div
                    css={[
                      tw`flex flex-row items-center truncate flex-1`,
                      css`
                        min-width: 0;
                      `,
                    ]}
                  >
                    <div css={tw`w-12 relative mr-4`}>
                      <div css={tw`pb-1/1`}></div>
                      <img
                        css={tw`absolute inset-0 w-full h-full object-cover`}
                        src={get(item, "image.transformedSrc", noImage)}
                        alt={get(item, "title", "Deleted collection")}
                      />
                    </div>
                    <h3
                      css={[
                        tw`text-gray-800 font-normal truncate flex-1 pr-4`,
                        css`
                          min-width: 0;
                        `,
                        !item && tw`text-red-600`,
                      ]}
                    >
                      {get(item, "title", "Deleted collection")}
                    </h3>
                  </div>
                  <div css={tw``}>
                    <div
                      css={tw`text-xs text-gray-600 hover:bg-gray-100 w-6 h-6 inline-flex items-center justify-center cursor-pointer rounded-sm transition duration-500 `}
                      onClick={() => handleCollectionRemove(itemId)}
                    >
                      <span className="icon-cancel"></span>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    )
  }
  const renderWeb = () => {
    return (
      <div>
        <TextField
          type="url"
          name="url"
          placeholder="https://yourdomainhere/page"
          value={item.view_web}
          onChange={handleTextChange}
          error={errors.view_web}
          connectedRight={<VerifyLinkButton url={item.view_web} />}
        />
      </div>
    )
  }
  const renderStatic = () => {
    return <div>This function is for image banner purposes only.</div>
  }

  const renderStartMinTime = () => {
    const diff = moment(item.event_start.toDate()).diff(moment())

    // 오늘이 선택한 날 보다 앞이면

    // 오늘이 선택한 날이면
    // 오늘이 선택한 날보다 후면

    return moment(item.event_start.toDate()).format("YYYYMMDD") ===
      moment().format("YYYYMMDD")
      ? moment(item.event_start.toDate()).hours(0).minutes(0).toDate()
      : moment().toDate()
  }

  const renderMinDate = () => {
    const diff = moment(item.event_finish.toDate()).diff(moment())

    if (
      diff <= 0 &&
      moment(item.event_finish.toDate()).format("YYYYMMDD") !==
      moment().format("YYYYMMDD")
    ) {
      return new Date()
    }

    if (
      moment(item.event_finish.toDate()).format("YYYYMMDD") ===
      moment().format("YYYYMMDD")
    ) {
      if (diff <= 0) {
        return new Date()
      }
      return moment(item.event_finish.toDate()).toDate()
    }

    if (diff <= 0) {
      return new Date()
    }

    return moment(item.event_finish.toDate()).format("YYYYMMDD") ===
      moment().format("YYYYMMDD")
      ? moment(item.event_finish.toDate()).toDate()
      : new Date()
  }

  const renderMinTime = () => {
    const diff = moment(item.event_finish.toDate()).diff(moment())

    if (
      diff <= 0 &&
      moment(item.event_finish.toDate()).format("YYYYMMDD") !==
      moment().format("YYYYMMDD")
    ) {
      return moment().hours(23).minutes(59).toDate()
    }

    if (
      moment(item.event_finish.toDate()).format("YYYYMMDD") ===
      moment().format("YYYYMMDD")
    ) {
      if (diff <= 0) {
        return moment().toDate()
        // return moment().hours(23).minutes(59).toDate()
      }

      return moment().toDate()
    }

    return moment(item.event_finish.toDate()).hours(0).minutes(0).toDate()
  }

  const renderMaxTime = () => {
    const diff = moment(item.event_finish.toDate()).diff(moment())

    // console.log("rendermintime", diff)

    if (diff <= 0) {
      return moment().hours(23).minutes(59).toDate()
    }

    return moment(item.event_finish.toDate()).hours(23).minutes(30).toDate()
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      size={"lg"}
      title={"Add Countdown Promotion"}
      subtitle={"Promote the time-limited deal. (*Image is not required.)"}
      primaryAction={{ content: "Save", onAction: () => handlePrimary() }}
    >
      <div css={tw`flex flex-row flex-wrap`}>
        <div css={[tw`bg-gray-100 px-6 py-4 flex-1`]}>
          <div
            css={[
              tw`mx-auto`,
              css`
                width: 375px;
              `,
            ]}
          >
            <span css={tw`block text-base text-gray-900 font-semibold mb-2`}>
              Preview
            </span>
            <div css={tw`shadow-sm`}>
              <ImageControl>
                <FormBlockImageUpload
                  source={item.image_url}
                  onAction={handleUpload}
                  ref={uploadRef}
                />
                {!isNull(item.image_url) && (
                  <div className="absolute inset-0 items-center justify-center px-4 py-8 z-20 image-control">
                    <div className="w-full relative z-20">
                      <div className="mb-2">
                        <Button
                          primary
                          fullWidth
                          onClick={() => uploadRef.current.click()}
                        >
                          Replace Image
                        </Button>
                      </div>
                      <div>
                        <Button
                          fullWidth
                          onClick={() =>
                            setItem(
                              produce(draft => {
                                draft.image_url = null
                                draft.ratio = 0
                              })
                            )
                          }
                        >
                          Delete Image
                        </Button>
                      </div>
                    </div>
                    <Overlay />
                  </div>
                )}
              </ImageControl>
              <CountdownBlock
                preview={false}
                mode={"edit"}
                event_title={item.title}
                event_finish={item.event_finish}
              />
            </div>
          </div>
        </div>
        <div css={tw`flex-1 px-6 py-4`}>
          <FormLayout>
            <TextField
              error={get(errors, "title")}
              label={"Promotion Title"}
              type="text"
              value={item.title}
              onChange={handleTitleChange}
              maxLength={35}
              placeholder="Write a Promotion Title up to 35 chracters."
            />
            <DatetimePicker
              label={`End date & time`}
              selected={moment(item.event_finish.toDate()).toDate()}
              onChange={handleDateChange}
              showTimeSelect={true}
              timeFormat="HH:mm"
              timeIntervals={30}
              minDate={renderMinDate()}
              minTime={renderMinTime()}
              maxTime={renderMaxTime()}
              error={errors.event_finish}
              showDisabledMonthNavigation={true}
              showPopperArrow={false}
              dateFormat="MM/dd/yyyy hh:mm aa"
              todayButton="Today"
            />
            <ChoiceList
              title="Link type"
              choices={[
                {
                  label: "Collection",
                  value: "view_collection",
                  renderChildren: renderCollection,
                },
                {
                  label: "Web Page",
                  value: "view_web",
                  renderChildren: renderWeb,
                },
                // rollback 가능성으로 인해 임시로 version을 분리하는 부분
                ...(current_version >= 1.1
                  ? [
                      {
                        label: "No link",
                        value: "view_static",
                        renderChildren: renderStatic,
                      },
                    ]
                  : []),
              ]}
              selected={item.selected}
              onChange={handleTypeChange}
            />
          </FormLayout>
        </div>
      </div>
    </Modal>
  )
}
