import React, { useState, useRef, useEffect } from "react"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import { get, isEqual, isNull } from "lodash"
import { useLazyQuery } from "@apollo/client"
import { useSelector } from "react-redux"
import { GET_COLLECTION } from "shopify/queries"
import LinkCollectionButton from "modal/components/CollectionBuilder/LinkCollectionButton"
import FormImageUpload from "design/components/FormImageUpload"
import IconHandle from "assets/svgs/icons/icon-handle.svg"
import NoImage from "assets/svgs/bg-menu-item-noimage.svg"

const ButtonAction = styled.button`
  ${tw`text-sm text-gray-700 w-8 h-8 inline-block hover:bg-gray-100 rounded-md`}
`
const ButtonHandle = styled.button`
  ${tw`text-sm text-gray-700 h-8 inline-block `}
  svg {
    ${tw`inline-block`}
  }
`
const ButtonEdit = styled(ButtonAction)`
  ${({ hidden }) => hidden && tw`hidden`}
`
const ButtonDelete = styled(ButtonAction)``

export default function CollectionBuilderItem(props) {
  const { shopify, name, type, image, collection, subitem, provided } = props
  const {
    error,
    openSearchModal,
    changeText,
    removeItem,
    changeImage,
  } = props
  const inputRef = useRef(null)
  const [thumbnail, setThumbnail] = useState(null)

  if (type === "group") {
  }

  const getThumbnailByCollectionId = cid => {
    let thumbnail

    thumbnail = get(shopify, `${cid}.image.transformedSrc`, undefined)

    if (!thumbnail) {
      thumbnail = get(
        shopify,
        `${cid}.products.edges.0.node.images.edges.0.node.transformedSrc`,
        undefined
      )
    }

    return thumbnail
  }

  useEffect(() => {
    if (type === "single") {
      if (!image) {
        return setThumbnail(getThumbnailByCollectionId(collection))
      }

      return setThumbnail(image)
    }
    if (type === "group") {
      if (!image) {
        return setThumbnail(getThumbnailByCollectionId(collection))
      }

      return setThumbnail(image)
    }
    if (type === "subitem") {
      return setThumbnail(image)
    }
  }, [collection, image])

  const renderThumbnail = () => {
    if (type === "subitem") {
      if (image) {
        return (
          <img
            src={image}
            alt=""
            css={tw`absolute inset-0 object-cover w-full h-full`}
          />
        )
      }
    }
    if (thumbnail) {
      if (type === "single" || type === "group") {
        return (
          <FormImageUpload
            source={thumbnail}
            fixedRatio={1}
            onAction={changeImage}
          />
        )
      }

      return (
        <img
          src={thumbnail}
          alt=""
          css={tw`absolute inset-0 object-cover w-full h-full`}
        />
      )
    }

    if (type === "single" || type === "group") {
      return (
        <FormImageUpload
          source={thumbnail}
          fixedRatio={1}
          onAction={changeImage}
        />
      )
    }

    return (
      <div css={tw`py-2 px-2`}>
        <NoImage />
      </div>
    )
  }

  return (
    <div
      css={[
        tw`px-2 flex flex-row items-center justify-between cursor-pointer border-gray-300 w-full bg-white shadow-sm`,
        css`
          min-height: 48px;
        `,
        subitem && tw`border border-dashed`,
        error && tw`border border-red-500 border-solid relative z-20`,
      ]}
    >
      <div css={tw`flex flex-row items-center w-full self-stretch`}>
        <div css={tw`mr-2 flex items-center`}>
          <ButtonHandle type="button" {...provided.dragHandleProps}>
            <IconHandle />
          </ButtonHandle>
        </div>
        {!collection && type === "single" ? (
          <LinkCollectionButton onAction={openSearchModal}>
            <span className="flex flex-row items-center justify-center w-full">
              <svg
                className="w-4 h-4 mr-2 text-primary-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              <span>Link collection</span>
            </span>
          </LinkCollectionButton>
        ) : (
            <div css={tw`text-gray-900 flex flex-row items-center w-full mr-2`}>
              <div css={tw`w-10 mr-2 relative`}>
                <div css={tw`pb-1/1 relative`}></div>
                <div css={tw`absolute inset-0 border border-dashed`}>
                  {renderThumbnail()}
                  {/* {thumbnail ? (
                  type === "single" || type === "group" ? (
                    <FormImageUpload
                      source={thumbnail}
                      fixedRatio={1}
                      onAction={changeImage}
                    />
                  ) : (
                    <img
                      src={thumbnail}
                      alt=""
                      css={tw`absolute inset-0 object-cover w-full h-full`}
                    />
                  )
                ) : type === "single" || type === "group" ? (
                  <FormImageUpload
                    source={thumbnail}
                    fixedRatio={1}
                    onAction={changeImage}
                  />
                ) : (
                  <div css={tw`py-2 px-2`}>
                    <NoImage />
                  </div>
                )} */}
                </div>
              </div>
              <input
                ref={inputRef}
                type="text"
                value={name}
                css={tw`form-input block w-full`}
                onChange={changeText}
                maxLength={255}
                placeholder={type === "group" ? "Add a group name" : "Add a name"}
              />
            </div>
          )}
      </div>
      <div css={[tw`flex flex-row`]}>
        <ButtonEdit
          type="button"
          onClick={() => inputRef.current.focus()}
          hidden={!collection && type === "single"}
        >
          <span className="icon-pencil" />
        </ButtonEdit>
        <ButtonDelete type="button" onClick={removeItem}>
          <span className="icon-trash-empty"></span>
        </ButtonDelete>
      </div>
    </div>
  )
}

// function CollectionBuilderItem(props) {
//   const { name, type, image, collection, subitem, provided } = props
//   const {
//     openSearchModal,
//     changeText,
//     removeItem,
//     changeImage,
//     getCollectionSuccess,
//   } = props
//   const inputRef = useRef(null)
//   const [thumbnail, setThumbnail] = useState(null)
//   const collections = useSelector(state => state.shopify.collections.byId)

//   // const [loadCollection, { loading, error, data }] = useLazyQuery(
//   //   GET_COLLECTION
//   // )

//   useEffect(() => {
//     if (data && type === "single") {
//       if (!isNull(data.node)) {
//         getCollectionSuccess(data.node)
//       }

//       if (!thumbnail) {
//         setThumbnail(get(data, "node.image.transformedSrc", undefined))
//       }
//     }
//     if (data && type === "subitem" && subitem) {
//       if (!thumbnail) {
//         setThumbnail(get(data, "node.image.transformedSrc", undefined))
//       }
//     }
//   }, [data])

//   useEffect(() => {
//     if (type === "single" && collection) {
//       if (image) {
//         return setThumbnail(image)
//       }

//       const collectionImage = get(
//         collections,
//         `${collection}.image.transformedSrc`,
//         undefined
//       )

//       if (collectionImage) {
//         setThumbnail(collectionImage)
//       } else {
//         // loadCollection({
//         //   variables: {
//         //     id: collection,
//         //   },
//         // })
//       }
//     }

//     if (type === "group" && image) {
//       return setThumbnail(image)
//     }

//     if (type === "subitem" && collection) {
//       // if (image) {
//       setThumbnail(image)
//       // }
//       //  else {
//       //   loadCollection({
//       //     variables: {
//       //       id: collection,
//       //     },
//       //   })
//       // }
//     }
//   }, [collection, image])

//   useEffect(() => {
//     // if (collection && !image) {
//     //   loadCollection({
//     //     variables: {
//     //       id: collection,
//     //     },
//     //   })
//     // }
//   }, [])

//   return (
//     <div
//       css={[
//         tw`px-2 flex flex-row items-center justify-between cursor-pointer border-gray-300 w-full bg-white shadow-sm`,
//         css`
//           min-height: 48px;
//         `,
//         subitem && tw`border border-dashed`,
//       ]}
//     >
//       <div css={tw`flex flex-row items-center w-full self-stretch`}>
//         <div css={tw`mr-2 flex items-center`}>
//           <ButtonHandle type="button" {...provided.dragHandleProps}>
//             <IconHandle />
//           </ButtonHandle>
//         </div>
//         {!collection && type === "single" ? (
//           <LinkCollectionButton onAction={openSearchModal}>
//             Link collection
//           </LinkCollectionButton>
//         ) : (
//           <div css={tw`text-gray-900 flex flex-row items-center w-full mr-2`}>
//             <div css={tw`w-10 mr-2 relative`}>
//               <div css={tw`pb-1/1 relative`}></div>
//               <div css={tw`absolute inset-0 border border-dashed`}>
//                 {thumbnail ? (
//                   type === "single" || type === "group" ? (
//                     <FormImageUpload
//                       source={thumbnail}
//                       fixedRatio={1}
//                       onAction={changeImage}
//                     />
//                   ) : (
//                     <img
//                       src={thumbnail}
//                       alt=""
//                       css={tw`absolute inset-0 object-cover w-full h-full`}
//                     />
//                   )
//                 ) : type === "single" || type === "group" ? (
//                   <FormImageUpload
//                     source={thumbnail}
//                     fixedRatio={1}
//                     onAction={changeImage}
//                   />
//                 ) : (
//                   <div css={tw`py-2 px-2`}>
//                     <NoImage />
//                   </div>
//                 )}
//               </div>
//             </div>
//             <input
//               ref={inputRef}
//               type="text"
//               value={name}
//               css={tw`form-input block w-full`}
//               onChange={changeText}
//               maxLength={255}
//             />
//           </div>
//         )}
//       </div>
//       <div css={[tw`flex flex-row`]}>
//         <ButtonEdit
//           type="button"
//           onClick={() => inputRef.current.focus()}
//           hidden={!collection && type === "single"}
//         >
//           <span className="icon-pencil" />
//         </ButtonEdit>
//         <ButtonDelete type="button" onClick={removeItem}>
//           <span className="icon-trash-empty"></span>
//         </ButtonDelete>
//       </div>
//     </div>
//   )
// }

// function areEqual(prevProps, nextProps) {
//   console.log("COLLECTION BUILDER ITEM", isEqual(prevProps, nextProps))
//   console.log("isEqual?", prevProps, nextProps)
//   return isEqual(prevProps, nextProps)
// }
// export default React.memo(CollectionBuilderItem, areEqual)
