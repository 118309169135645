import Cookies from "universal-cookie"

export const getPlan = plan => {
  if (plan) {
    switch (plan) {
      case "starter":
      case "starter_yearly":
        return "starter"
      case "growth":
      case "growth_yearly":
        return "growth"
      case "power":
      case "power_yearly":
        return "power"
      case "platinum":
        case "platinum_yearly":
          return "platinum"
      case "enterprise":
      case "enterprise_yearly":
        return "enterprise"
      default:
        return undefined
    }
  }

  return undefined
}
