import React from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"

function ProgressSpinner({ percentage, theme }) {
  return (
    <div>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={buildStyles({
          pathColor: theme.colors.primary[400],
          textColor: theme.colors.primary[400],
        })}
      />
    </div>
  )
}

ProgressSpinner.propTypes = {
  percentage: PropTypes.number,
}

ProgressSpinner.defaultProps = {
  percentage: 0,
}

export default withTheme(ProgressSpinner)
