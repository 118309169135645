import React from "react"
import { toast } from "react-toastify"

import { isURL, addHttp } from "reaverUtil/form"

export default function VerifyLinkButton({ url }) {
  return (
    <button
      onClick={() => {
        if (!url || url.replace(/ /g, "").length === 0) {
          return toast("Please fill out the URL.")
        }

        let http = url

        if (!isURL(http)) {
          http = addHttp(http)
        }

        window.open(http, "_blank")
      }}
      type="button"
      className="border px-2 border-gray-300 flex-grow h-full rounded-tr-md rounded-br-md"
    >
      <svg
        className="w-6 h-6 text-gray-900"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
        />
      </svg>
    </button>
  )
}
