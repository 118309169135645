import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import tw from "twin.macro"
import { priceFormat } from "reaverUtil/currency"
import { useSelector } from "react-redux"
import ProductBlock from "./ProductBlock"

const Wrapper = styled.div`
  ${tw`truncate`}
  margin-right: -2px;
  margin-left: -2px;

  span {
    ${tw`font-medium leading-none text-sm `}
    margin-right: 2px;
    margin-left: 2px;
  }
`

const Price = styled.span`
  ${({ highlight }) => (highlight ? tw`text-primary-500` : tw`text-gray-900`)}}
`

export default function ProductPrice({
  locale,
  symbol,
  price,
  compareAtPrice,
}) {
  const currencyCode = useSelector(
    ({ firebase }) => firebase.profile.shop_owner.currency
  )
  const hasSale = parseFloat(price) < parseFloat(compareAtPrice)

  return (
    <Wrapper>
      <Price highlight={hasSale}>
        {symbol}
        {priceFormat(price, locale)}
      </Price>
      {hasSale && (
        <span css={tw`font-normal text-gray-500 line-through`}>
          {symbol}
          {priceFormat(compareAtPrice, currencyCode, locale)}
        </span>
      )}
    </Wrapper>
  )
}

ProductPrice.defaultProps = {
  symbol: "$",
  locale: "en",
}
