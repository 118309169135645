import React from "react"
import { navigate } from "gatsby"
import tw, { styled, css } from "twin.macro"
import Button from "general/components/Button"

export default function PageActions({ primaryAction, secondaryActions }) {
  return (
    <div className="flex flex-row justify-between border-t border-gray-300 py-4 mt-4">
      <div>
        {secondaryActions &&
          secondaryActions.map((item, index) => {
            return (
              <Button
                key={`secondary-${index}`}
                onClick={item.onAction}
                type="button"
              >
                {item.content}
              </Button>
            )
          })}
      </div>
      <div>
        {primaryAction && (
          <Button
            type="button"
            primary
            onClick={primaryAction.onAction}
            loading={primaryAction.loading}
          >
            {primaryAction.content}
          </Button>
        )}
      </div>
    </div>
  )
}
