import { connect } from "react-redux"

import BlockItem from "design/components/BlockItem"
import { getCollectionSuccess, updateCollectionsSuccess } from "shopify/redux"
import { setBlockValid } from "design/redux"

const mapStateToProps = state => {
  return {
    selectedCartIconType: state.design.ui.cartIconType,
  }
}

const mapDispatchToProps = { getCollectionSuccess, updateCollectionsSuccess, setBlockValid }

export default connect(undefined, mapDispatchToProps)(BlockItem)
