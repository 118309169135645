import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Spinner = styled.div`
  @-webkit-keyframes fill_anim {
    0% {
      background-position: 0px 0;
    }
    30% {
      background-position: 0px 40px;
    }
    100% {
      background-position: 0px 0;
    }
  }

  @keyframes fill_anim {
    0% {
      background-position: 0px 0;
    }
    30% {
      background-position: 0px 40px;
    }
    100% {
      background-position: 0px 0;
    }
  }
  display: block;
  font-size: 34px;
  font-weight: 900;
  background: -webkit-linear-gradient(#d20459 50%, #ee96ba 50%);
  background-size: 100% 80px;
  z-index: 1000000;
  background-clip: text;
  animation: fill_anim 3s infinite ease-out;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: fill_anim 3s infinite ease-out;
`

export default function LogoSpinner({ text }) {
  return <Spinner>{text}</Spinner>
}

LogoSpinner.propTypes = {
  text: PropTypes.string,
}

LogoSpinner.defaultProps = {
  text: "JCURVE",
}
