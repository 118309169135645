import BgIphone from "assets/images/push/iphone.jpg"
import React, { useState, useEffect } from "react"
import BgIphoneScreen from "assets/images/push/screen.png"
import tw, { styled, css } from "twin.macro"
import PushBubble from "./PushBubble"
import Button from "general/components/Button"

const Wrapper = styled.div`
  width: 370px;
`
const DeviceImg = styled.img.attrs({
  src: BgIphone,
})`
  width: 370px;
  height: 630px;
  object-fit: none;
  object-position: ${({ position }) => `${position} center`};
`
const DeviceScreen = styled.div.attrs({})`
  ${tw`absolute`}
  background-image: url(${BgIphoneScreen});
  background-position: 15px 13px;
  background-repeat: no-repeat;
  top: 42px;
  left: 61px;
  width: 250px;
  height: 540px;
`

export default function Device({
  title,
  message,
  thumbnails,
  appName,
  appIcon,
  expended,
  setExpended,
}) {
  const [position, setPosition] = useState("-370px")
  // const [expended, setExpended] = useState(false)
  const titlePlhr = "Hey Everyone!"
  const messagePlhr =
    "Enter your marketing message here to boost your sales. ex) 80% OFF only Black Friday one day!"

  const getTitle = () => {
    if (message.length > 0 && title.length === 0) {
      return ""
    }

    return title.length === 0 ? titlePlhr : title
  }

  useEffect(() => {
    const phone_bg_lenght = 8

    let push_rand = 1
    push_rand = Math.floor(Math.random() * phone_bg_lenght) + 1
    const pos = push_rand * -370 + "px"

    setPosition(pos)
  }, [])

  return (
    <Wrapper>
      <div css={tw`relative`}>
        <DeviceImg position={position} />
        <DeviceScreen>
          <PushBubble
            appName={appName}
            appIcon={appIcon}
            title={getTitle()}
            message={message.length === 0 ? messagePlhr : message}
            thumbnails={thumbnails}
            expended={expended}
          />
        </DeviceScreen>
      </div>
      {thumbnails && thumbnails.length > 0 && (
        <div className="text-center">
          <Button
            outline
            primary
            size="slim"
            onClick={() => setExpended(!expended)}
          >
            {expended ? "View collapsed" : "View expended"}
          </Button>
        </div>
      )}
    </Wrapper>
  )
}
