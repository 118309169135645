import { connect } from "react-redux"

import CartIconList from "design/components/CartIconList"
import { setCartIconType } from "design/redux"

const mapStateToProps = state => {
  return {
    selectedCartIconType: state.design.ui.cartIconType,
  }
}

const mapDispatchToProps = { setCartIconType }

export default connect(mapStateToProps, mapDispatchToProps)(CartIconList)
