import React, { useRef } from "react"
import { connect } from "react-redux"
import Modal from "../../general/components/Modal"
import ShopifyProvider from "../../shopify/containers/ShopifyProvider"
import EditCollectionBlockModal from "../components/EditCollectionBlockModal"
import EditCollectionListBlockModal from "../components/EditCollectionListBlockModal"
import EditCollectionBannerBlockModal from "../components/EditCollectionBannerBlockModal"
import FormPromocode from "../components/FormPromocode"
import FormCollection from "../components/FormCollection"
import FormSingleCollection from "../components/FormSingleCollection"
import EditPromocodeBlockModal from "../components/EditPromocodeBlockModal"
import EditProductBlockModal from "../components/EditProductBlockModal"
import EditCustomBannerBlockModal from "../components/EditCustomBannerBlockModal"
import EditSlideshowBlockModal from "../components/EditSlideshowBlockModal/index"
import EditCountdownBlockModal from "../components/EditCountdownBlockModal"
import { updateBlock } from "../../design/redux"
import { updateCollectionsSuccess, updateProductsSuccess } from "shopify/redux"

import EditCollectionSelectionModal from "modal/components/EditCollectionSelectionModal/index"

const MODAL_TYPES = {
  promocode: EditPromocodeBlockModal,
  collection: EditCollectionBlockModal,
  collection_list: EditCollectionSelectionModal,
  collection_banner: EditCollectionSelectionModal,
  product: EditProductBlockModal,
  custom_banner: EditCustomBannerBlockModal,
  slideshow: EditSlideshowBlockModal,
  countdown: EditCountdownBlockModal,
}

function EditBlockModal({
  isOpen,
  onRequestClose,
  block,
  blockId,
  updateBlock,
  updateCollectionsSuccess,
  updateProductsSuccess,
  current_version,
}) {
  const { type } = block

  const handleSubmit = formData => {
    updateBlock({ id: blockId, info: formData })
    onRequestClose()
  }

  const CustomModal = MODAL_TYPES[type]

  return (
    <ShopifyProvider>
      {CustomModal && (
        <CustomModal
          type={type}
          meta={block.info}
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          current_version={current_version}
          onAction={handleSubmit}
          shouldCloseOnEsc={true}
          updateCollectionsSuccess={updateCollectionsSuccess}
          updateProductsSuccess={updateProductsSuccess}
        />
      )}
    </ShopifyProvider>
  )
}

const mapStateToProps = (state, { meta }) => {
  const { blockId } = meta
  const { current_version } = state.firebase.profile.settings

  return {
    blockId,
    block: state.design.blocks.byId[blockId],
    current_version,
  }
}

const mapDispatchToProps = {
  updateBlock,
  updateCollectionsSuccess,
  updateProductsSuccess,
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBlockModal)
