import React from "react"
import Overlay from "general/components/Overlay"

export default function InvalidOverlay() {
  return (
    <div className="absolute inset-0 flex items-center justify-center">
      <Overlay primary />
    </div>
  )
}
