import { connect } from "react-redux"
import { get } from "lodash"

import SiteHeader from "navigation/components/SiteHeader/index"

import { openDialog } from "dialog/redux"

const mapStateToProps = (state, props) => {
  return {
    location: props.location,
    appName: get(state, "firebase.profile.settings.app_name"),
  }
}
const mapDispatchToProps = { openDialog }

export default connect(mapStateToProps, mapDispatchToProps)(SiteHeader)
