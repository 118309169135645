import React from "react"
import tw, { styled, css } from "twin.macro"

import SiteHeader from "navigation/containers/SiteHeader"

const Wrapper = styled.div`
  ${tw`flex flex-col w-full h-full`}
`

const SUBNAV = [
  { title: "Design", to: "/app/design" },
  { title: "Push", to: "/app/push", submenu: ["/app/push/history"] },
  { title: "Stats", to: "/app/stats" },
  { title: "Settings", to: "/app/settings" },
  {
    title: "Integration",
    to: "/app/integration",
    options: {
      new: true,
    },
  },
  { title: "My Apps", to: "/app/listing" },
]

export default function index(props) {
  const { children, location } = props

  return (
    <Wrapper id="app">
      <SiteHeader subnav={SUBNAV} location={location} />
      <main css={tw`h-full flex-grow`}>{children}</main>
    </Wrapper>
  )
}
