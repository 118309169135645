import React from "react"
import Modal from "dialog/components/Modal"

import Content from "assets/images/how-it-works.png"

export default function HowItWorks(props) {
  const { open, onClose } = props

  return (
    <Modal open={open} onClose={onClose}>
      <div className="relative z-10">
        <img
          src={Content}
          alt="How it works"
          className="w-full bg-gray-100 relative z-10"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 940 1460"
          className="absolute inset-0 z-20"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              id="design_tutorial_link"
              onClick={() => window.open("https://youtu.be/2l8kh6sOD2s")}
              fill="#F2F2F2"
              fill-opacity="0"
              fill-rule="nonzero"
              d="M81 449h142v125H81z"
              className="cursor-pointer"
            ></path>
            <path
              id="menu_tutorial_link"
              onClick={() => window.open("https://youtu.be/HA3yvDi1FYw")}
              fill="#FFAAAA"
              fill-opacity="0"
              d="M260 449h142v125H260z"
              className="cursor-pointer"
            ></path>
          </g>
        </svg>
      </div>
    </Modal>
  )
}
