import React, { forwardRef } from "react"
import tw from "twin.macro"

const Select = forwardRef(
  ({ options, value, label, disabled, labelHidden, onChange, name }, ref) => {
    return (
      <label css={tw`block`}>
        {!labelHidden && (
          <span css={tw`block text-base text-gray-900 font-medium mb-2`}>
            {label}
          </span>
        )}
        <select
          ref={ref}
          disabled={disabled}
          defaultValue={value}
          name={name}
          css={[
            tw`form-select block w-full focus:outline-none focus:shadow-none focus:relative focus:border-primary-500 focus:z-20`,
            !labelHidden && tw`mt-2`,
          ]}
          onChange={onChange}
        >
          {options &&
            options.map(item => {
              return (
                <option value={item.value} key={item.value}>
                  {item.label}
                </option>
              )
            })}
        </select>
      </label>
    )
  }
)
export default Select
