import React, { useRef, useState, useEffect } from "react"
import tw, { css } from "twin.macro"
import { useSelector } from "react-redux"
import { times, find, get, has, union, includes } from "lodash"
import produce from "immer"
import arrayToObject from "reaverUtil/arrayToObject"
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd"

import Modal from "general/components/Modal"
import ShopifySearch from "shopify/components/ShopifySearch"
import EmptyItem from "./EmptyItem"
import Item from "./Item"

const COLLECTION_LIMIT = {
  collection_banner: 6,
  collection_list: 10,
}

export default function EditCollectionSelectionModal(props) {
  const { isOpen, type, meta } = props
  const { onRequestClose, onAction, updateCollectionsSuccess } = props

  const shopifyCollections = useSelector(
    state => state.shopify.collections.byId
  )

  const searchRef = useRef(null)

  const [selected, setSelected] = useState([])
  const [collections, setCollections] = useState([])

  useEffect(() => {
    if (meta) {
      switch (type) {
        case "collection_banner":
          const { collection_banner_info } = meta
          const ids = collection_banner_info
            ? union(collection_banner_info.map(item => item.id))
            : []

          if (ids && ids.length > 0) {
            const cidsData = []

            ids.forEach(item => {
              const collection = get(shopifyCollections, item, undefined)
              if (collection) {
                return cidsData.push(collection)
              }
            })

            if (cidsData && cidsData.length > 0) {
              setCollections(cidsData)
            }

            setSelected(ids)
          }
          break
        case "collection_list":
          if (has(meta, "collection_ids")) {
            const ids = union(meta.collection_ids).splice(
              0,
              COLLECTION_LIMIT[type]
            )

            // const cidsData = ids.map(item => get(shopifyCollections, item))
            if (ids && ids.length > 0) {
              const cidsData = []

              ids.forEach(item => {
                const collection = get(shopifyCollections, item, undefined)
                if (collection) {
                  return cidsData.push(collection)
                }
              })

              if (cidsData && cidsData.length > 0) {
                setCollections(cidsData)
              }

              setSelected(ids)
            }
          }
          break
        default:
          break
      }
    }
  }, [])

  const handleChangeSelected = (nextSelected, data) => {
    if (
      selected.length < COLLECTION_LIMIT[type] ||
      selected.length > nextSelected.length
    ) {
      setSelected(nextSelected)

      if (includes(selected, data.id)) {
        setCollections(
          produce(draft => {
            const index = collections.findIndex(item => item.id === data.id)
            if (index !== -1) draft.splice(index, 1)
          })
        )
      } else {
        setCollections(
          produce(draft => {
            draft.push(data)
          })
        )
      }
    }
  }

  const handleItemClick = collectionId => {
    // setCollections(collections.filter(item => item.id !== collectionId))
    setSelected(selected.filter(item => item !== collectionId))
  }

  const handlePrimary = () => {
    let payload

    if (type === "collection_banner") {
      const data =
        selected.length === 0
          ? []
          : selected.map(item => {
              return { id: item }
            })

      payload = { collection_banner_info: data }
    }

    if (type === "collection_list") {
      const data = selected.length === 0 ? [] : selected

      payload = { collection_ids: data }
    }

    if (collections) {
      const shopifyCollections = arrayToObject(collections, "id")
      updateCollectionsSuccess(shopifyCollections)
    }

    return onAction(payload)
  }

  const handleOnDragEnd = (sourceId, sourceIndex, targetIndex, targetId) => {
    // console.log("handleOnDragEnd", sourceId, sourceIndex, targetIndex, targetId)
    if (
      sourceIndex > selected.length - 1 ||
      targetIndex > selected.length - 1
    ) {
      // console.log("error")
      return false
    }

    const nextState = swap(selected, sourceIndex, targetIndex)
    setSelected(nextState)
  }

  // console.log("selected", selected)

  return (
    <Modal
      size={"lg"}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={"Add Collections"}
      subtitle={`Add up to ${COLLECTION_LIMIT[type]} collections to show in your block. (*Collection’s Image Recommended)`}
      primaryAction={{ content: "Save", onAction: () => handlePrimary() }}
    >
      <div className="flex flex-row items-center justify-center">
        <div className=" bg-gray-100" css={css``}>
          <GridContextProvider onChange={handleOnDragEnd}>
            <GridDropZone
              id="items"
              boxesPerRow={4}
              rowHeight={110}
              style={{
                width: "424px",
                height: "360px",
                margin: "0 15px",
                padding: "15px 0",
              }}
            >
              {selected.map(selectedId => {
                const collection = find(
                  collections,
                  collection => collection.id === selectedId
                )

                return (
                  <GridItem key={selectedId}>
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {!collection ? (
                        <Item
                          error={true}
                          key={selectedId}
                          title={"Deleted Collection"}
                          onAction={() => handleItemClick(selectedId)}
                        />
                      ) : (
                        <Item
                          key={selectedId}
                          title={get(collection, "title", "Deleted")}
                          image={get(collection, "image.transformedSrc")}
                          // onAction={() => console.log(selectedId)}
                          onAction={() => handleItemClick(selectedId)}
                        />
                      )}
                    </div>
                  </GridItem>
                )
              })}
              {times(COLLECTION_LIMIT[type] - selected.length, index => (
                <GridItem key={`empty${index}`}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <EmptyItem
                      key={`empty${index}`}
                      // onAction={() => console.log(index)}
                      onAction={() => searchRef.current.focus()}
                    />
                  </div>
                </GridItem>
              ))}
            </GridDropZone>
          </GridContextProvider>

          <div css={tw`grid grid-cols-4 gap-3 px-6 py-4 hidden`}>
            {times(COLLECTION_LIMIT[type] - selected.length, index => (
              <EmptyItem
                key={`empty${index}`}
                onAction={() => searchRef.current.focus()}
              />
            ))}
          </div>
        </div>
        <div className="flex-1 overflow-hidden border-l border-gray-300">
          <ShopifySearch
            ref={searchRef}
            onChange={handleChangeSelected}
            selected={selected}
            allowMultiple
          />
        </div>
      </div>
    </Modal>
  )
}
