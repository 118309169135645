import React, { useState } from "react"
import Modal from "react-modal"
import Button from "general/components/Button"
import { requestPayment } from "services/shopify"
import { track } from "../../services/analytics"

function numberWithCommas(x, paymentType) {
  let suffix = ""
  if (paymentType === "monthly") {
    suffix = "/mo"
  } else if (paymentType === "yearly") {
    suffix = "/year"
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + suffix
}

export default function CustomPaymentModal({
  isOpen,
  price,
  paymentType,
  name,
  message,
  onRequestClose,
}) {

  const [isLoading, setIsLoading] = useState(false)

  track("VIEWED_CUSTOM_PAYMENT",{type: paymentType, price: price, name: name})

  const handleClick = async (price, paymentType, name) => {

    setIsLoading(true)
    let interval = "EVERY_30_DAYS" 

    if (paymentType === "onetime") { // expired at 를 건드리지 않는다. ex) 일부 기능 custom fee
      interval = "ONETIME" 
    } else if (paymentType === "yearly") { // annual이면서 expired_at를 업데이트한다.  ex) monthly 에서 할인해준 yearly결제같은경우 , 1년 지난 고객 을 재 yealry 를 기존가격으로 제공
      interval = "ANNUAL" 
    } else if (paymentType === "monthly"){
      interval = "EVERY_30_DAYS" //기본 monthly  ex) 깍아줘요...해서 plan을 다른가격에 제공
    }

    track("SELECT_CUSTOM_PAYMENT",{type: paymentType, price: price, name: name})

    const data = {
      name,
      price,
      interval,
      custom: true,
    }

    const paymentUrl = await requestPayment(data)

    if (paymentUrl) {
      return (window.top.location.href = paymentUrl)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal modal--sm"
      style={{
        overlay: {
          overflowY: "auto",
          overflowX: "hidden",
          background: "rgba(33,43,54,.4)",
          zIndex: 500,
        },
        content: {},
      }}
    >
      <div className="modal-content bg-white w-full relative py-12 px-4 text-center rounded-lg">
        <div className="absolute z-20" style={{ right: "0.5em", top: "0.5em" }}>
          <button
            onClick={() => onRequestClose()}
            className="rounded-md py-1 px-2 hover:bg-gray-100 transition-colors duration-500"
          >
            <i className="icon-cancel text-gray-600 text-sm" />
          </button>
        </div>
        <h3 className="text-xl lg:text-2xl font-bold">
          JCURVE CUSTOM PLAN
        </h3>
        <p className="text-lg lg:text-1xl font-light mb-8">
          {message ? message : "Select & Approve the charge"}
        </p>
          <h4 className="text-4xl font-bold leading-none mr-2">
            ${numberWithCommas(price, paymentType)}
          </h4>
          <span className={`text-gray-500 ${paymentType==="onetime" && "block mt-1"}`}>
            {paymentType==="onetime" ? `This is the one-time Charge` : paymentType==="yearly" ? "This is the yearly charge" : "This is the monthy recurring charge"}
          </span>
        
        <div className="mt-8">
          <Button 
          primary 
          loading={isLoading}
          onClick={() => handleClick(price, paymentType, name)}>
            SELECT
          </Button>
        </div>
      </div>
    </Modal>
  )
}
