import React from "react"
import tw, { styled, css } from "twin.macro"

const Button = styled.button`
  ${tw`py-2 px-2 cursor-pointer text-center leading-none border border-gray-300 text-gray-900 block w-full lg:inline-block lg:w-auto flex-1`}
  position: relative;
  z-index: 10;
  display: inline-block;
  min-width: 2.6rem;
  margin: 0;
  background: var(
    --p-action-secondary,
    linear-gradient(to bottom, white, #f9fafb)
  );
  -webkit-user-select: none;
  user-select: none;
  text-decoration: none;

  &:hover {
    background: linear-gradient(to bottom, #f9fafb, #f4f6f8);
    border-color: #c4cdd5;
  }

  &:disabled {
    background: #f4f6f8;
    color: #919eab;
    cursor: default;
    box-shadow: none;
  }
`
const Icon = styled.span`
  ${tw`inline-block`}
`

export default function Pagination(props) {
  const { hasPrevious, hasNext, onPrevious, onNext } = props

  return (
    <div className="flex flex-row flex-wrap lg:block">
      <Button type="button" onClick={onPrevious} disabled={!hasPrevious}>
        <Icon>
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            />
          </svg>
        </Icon>
      </Button>
      <Button type="button" onClick={onNext} disabled={!hasNext}>
        <Icon>
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </Icon>
      </Button>
    </div>
  )
}
