import React, { useRef, useState, useEffect } from "react"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import { times, get, isEqual, find } from "lodash"
import produce from "immer"
import { useLazyQuery } from "@apollo/client"
import { toast } from "react-toastify"

import Modal from "general/components/Modal"
import Button from "general/components/Button"
import ChoiceList from "general/components/ChoiceList"
import TextField from "general/components/TextField"
import FormBlockImageUpload from "design/components/FormBlockImageUpload"
import SearchAuto from "shopify/components/SearchAuto"
import VerifyLinkButton from "form/components/VerifyLinkButton"
import ThumbnailList from "./ThumbnailList"

import { GET_COLLECTIONS_BY_IDS } from "shopify/queries"
import usePrevious from "reaverUtil/usePrevious"
import { isURL, addHttp } from "reaverUtil/form"

import thumbnailNoImage from "assets/images/design/block/noimage.png"
import NoImage from "assets/images/design/block/slideshow/noimage-slide-4by3.jpg"
import IconRemoveCircle from "assets/images/design/icon-remove-circle.png"
import WarningImage from 'assets/images/design/icon-menu-warning-sqaure.png'

const Item = styled.div`
  ${tw`pb-3/4 border border-dashed border-gray-300 bg-cover bg-center hover:border-primary-500 bg-white cursor-pointer`}

  ${({ source }) => {
    return `background-image: url('${source}');`
  }}

  ${({ isActive }) => isActive && tw`border-primary-500`}
`
const SLIWSHOW_ITEM_LIMIT = 6

export default function EditSlideshowBlockModal(props) {
  const { isOpen, meta } = props
  const { onRequestClose, onAction, updateCollectionsSuccess } = props
  const uploadRef = useRef(null)
  const searchInputRef = useRef(null)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const [slides, setSlides] = useState(
    times(SLIWSHOW_ITEM_LIMIT, () => {
      return {
        ratio: "0.75",
        image_url: null,
        view_collection: undefined,
        view_web: "",
        view_staic: "",
        selected: "view_collection",
        errors: {
          view_collection: undefined,
          view_web: undefined,
        },
      }
    })
  )
  // const [errors, setErrors] = useState(times())

  const previousCollection = usePrevious(
    slides[currentSlideIndex].view_collection
  )

  const [loadCollections, { loading, error, data }] = useLazyQuery(
    GET_COLLECTIONS_BY_IDS,
    {
      variables: {
        ids: get(slides[currentSlideIndex], "view_collection", undefined),
      },
    }
  )

  useEffect(() => {
    if (
      !isEqual(slides[currentSlideIndex].view_collection, previousCollection)
    ) {
      loadCollections({
        variables: { ids: slides[currentSlideIndex].view_collection },
      })
    }
  }, [slides[currentSlideIndex].view_collection])

  useEffect(() => {
    if (meta && meta.slideshow_info && meta.slideshow_info.length > 0) {
      let newArray = [...slides]

      meta.slideshow_info.map((item, index) => {
        const type = item.action_type
        const data = {
          image_url: item.image_url,
          ratio: "0.75",
          selected: type,
          [type]: item.action_value,
        }

        newArray[index] = { ...newArray[index], ...data }
      })

      setSlides(newArray)
    }
  }, [])

  const isValidSlide = (index, isEdit) => {
    const item = slides[index]
    const selectedType = item.selected

    console.log(item)

    if (!item.image_url) {
      if (isEdit) {
        return true
      }
      return false
    }

    if (selectedType === "view_collection") {
      if (!item["view_collection"]) {
        setSlides(
          produce(draft => {
            draft[index].errors.view_collection = "Please select a collection"
          })
        )

        return false
      }
      if (item["view_collection"].length === 0) {
        setSlides(
          produce(draft => {
            draft[index].errors.view_collection = "Please select a collection"
          })
        )

        return false
      }
      if (item['view_collection']) {
        if (!loading) {
          const selectedCollection = find(data.nodes, obj => obj && obj.id === item['view_collection'])

          if (!selectedCollection) {
            setSlides(
              produce(draft => {
                draft[index].errors.view_collection = "Please select a new collection"
              })
            )

            return false;
          }
        }
      }
    }

    if (selectedType === "view_web") {
      if (!item["view_web"]) {
        return setSlides(
          produce(draft => {
            draft[index].errors.view_web =
              "Please fill out website address field"
          })
        )
      }
      if (item["view_web"].length === 0) {
        return setSlides(
          produce(draft => {
            draft[index].errors.view_web =
              "Please fill out website address field"
          })
        )
      }

      // if (!isURL(item["view_web"])) {
      //   return setSlides(
      //     produce(draft => {
      //       draft[index].errors.view_web =
      //         "Please fill out valid website address."
      //     })
      //   )
      // }
    }

    return true
  }

  const handleClick = index => {
    const item = slides[currentSlideIndex]
    const selectedType = item.selected

    const isValid = isValidSlide(currentSlideIndex, true)

    if (!isValid) {
      toast("Please finish this setting first.")
      return false
    }

    // if (item.image_url) {
    //   if (
    //     (selectedType === "view_collection" &&
    //       !item["view_collection"].length > 0) ||
    //     (selectedType === "view_web" && !item["view_web"].length > 0)
    //   ) {
    //     return alert("Please finish this setting first.")
    //   }
    // }

    setCurrentSlideIndex(index)
  }

  const handleRemoveItem = index => {
    const data = {
      ratio: "0.75",
      image_url: null,
      view_collection: [],
      view_web: "",
      view_staic: "",
      selected: "view_collection",
      errors: {
        view_collection: undefined,
        view_web: undefined,
      },
    }

    setSlides(
      produce(draft => {
        draft[index] = data
      })
    )
    setCurrentSlideIndex(0)

    // let newArray = [...slides]

    // const data = {
    //   image_url: null,
    //   view_collection: [],
    //   view_web: "",
    //   view_staic: "",
    //   selected: "view_collection",
    // }

    // newArray[currentSlideIndex] = { ...data }

    // setSlides(newArray)
    // setCurrentSlideIndex(0)
  }

  const handleImage = imageUrl => {
    setSlides(
      produce(slides, draft => {
        draft[currentSlideIndex].image_url = imageUrl
        draft[currentSlideIndex].ratio = "0.75"
      })
    )
  }

  const handlePrimary = () => {
    console.log('click primary')
    const validSlides = []
    const isValidCurrentSlide = isValidSlide(currentSlideIndex)


    if (!isValidCurrentSlide) {
      return false
    }

    slides.forEach(item => {
      if (!item.image_url) {
        return false
      }

      if (item.selected === "view_collection") {
        if (item["view_collection"].length === 0) {
          // setErrors(
          //   produce(draft => {
          //     draft.view_collection = "Please select a collection"
          //   })
          // )

          return false
        }

        validSlides.push({
          image_url: item.image_url,
          // ratio: "0.75",
          action_type: "view_collection",
          action_value: item["view_collection"],
        })
      }
      if (item.selected === "view_web") {
        if (item["view_web"].length === 0) {
          // setErrors(
          //   produce(draft => {
          //     draft.view_web = "Please fill out website address field"
          //   })
          // )
          return false
        }

        validSlides.push({
          image_url: item.image_url,
          // ratio: "0.75",
          action_type: "view_web",
          action_value: addHttp(item["view_web"]),
        })
      }
      if (item.selected === "view_static") {
        validSlides.push({
          image_url: item.image_url,
          // ratio: "0.75",
          action_type: "view_static",
          action_value: "",
        })
      }
    })

    return onAction({ ratio: "0.75", slideshow_info: validSlides })
  }

  const handleTextChange = event => {
    const value = event.target.value

    return setSlides(
      produce(draft => {
        draft[currentSlideIndex].errors.view_web = undefined
        draft[currentSlideIndex].view_web = value
      })
    )
  }

  const handleCollectionChange = (collectionId, item) => {
    console.log(collectionId)
    if (collectionId.length > 0) {
      updateCollectionsSuccess({ [item.node.id]: item.node })
    }

    console.log('searchInputRef', searchInputRef)

    searchInputRef.current.value = '';

    return setSlides(
      produce(draft => {
        draft[currentSlideIndex].errors.view_collection = undefined
        draft[currentSlideIndex].view_collection = get(collectionId, 0)
      })
    )
  }

  const handleCollectionRemove = collectionId => {
    // const newCollection = slides[currentSlideIndex].view_collection.filter(
    //   item => item !== collectionId
    // )

    setSlides(
      produce(draft => {
        draft[currentSlideIndex].view_collection = undefined
      })
    )
  }

  const renderCollection = () => {
    const renderSelectedCollection = itemId => {
      const item = find(data.nodes, obj => obj && obj.id === itemId)

      if (!item) {
        return (
          <div
            key={itemId}
            css={tw`p-2 bg-red-100 flex flex-row w-full items-center justify-between mt-2`}
          >
            <div
              css={[
                tw`flex flex-row items-center truncate `,
                css`
                  max-width: 360px;
                  min-width: 0;
                `,
              ]}
            >
              <div css={tw`w-12 relative mr-4`}>
                <div css={tw`pb-1/1`}></div>
                <img
                  css={tw`absolute inset-0 w-full h-full object-cover`}
                  src={WarningImage}
                  alt={"Deleted collection"}
                />
              </div>
              <h3
                css={[
                  tw`text-gray-800 font-normal truncate flex-1 pr-4`,
                  css`
                    min-width: 0;
                  `,
                  tw`text-red-600`,
                ]}
              >
                Deleted collection
              </h3>
            </div>
            <div css={tw``}>
              <div
                css={tw`text-xs text-gray-600 hover:bg-gray-100 w-6 h-6 inline-flex items-center justify-center cursor-pointer rounded-sm transition duration-500 `}
                onClick={() => handleCollectionRemove(itemId)}
              >
                <span className="icon-cancel"></span>
              </div>
            </div>
          </div>
        )
      }

      return (
        <div
          key={itemId}
          css={tw`flex flex-row w-full items-center justify-between mt-2`}
        >
          <div
            css={[
              tw`flex flex-row items-center truncate flex-1`,
              css`
                min-width: 0;
              `,
            ]}
          >
            <div css={tw`w-12 relative mr-4`}>
              <div css={tw`pb-1/1`}></div>
              <img
                css={tw`absolute inset-0 w-full h-full object-cover`}
                src={get(item, "image.transformedSrc", thumbnailNoImage)}
                alt={get(item, "title", "Deleted collection")}
              />
            </div>
            <h3
              css={[
                tw`text-gray-800 font-normal truncate flex-1 pr-4`,
                css`
                  min-width: 0;
                `,
                !item && tw`text-red-600`,
              ]}
            >
              {get(item, "title", "Deleted collection")}
            </h3>
          </div>
          <div css={tw``}>
            <div
              css={tw`text-xs text-gray-600 hover:bg-gray-100 w-6 h-6 inline-flex items-center justify-center cursor-pointer rounded-sm transition duration-500 `}
              onClick={() => handleCollectionRemove(itemId)}
            >
              <span className="icon-cancel"></span>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div>
        <SearchAuto
          ref={searchInputRef}
          resourceType="collections"
          onChange={handleCollectionChange}
          error={slides[currentSlideIndex].errors.view_collection}
          selected={[slides[currentSlideIndex].view_collection]}
        />
        <div>
          {!loading &&
            data &&
            data.nodes.length > 0 &&
            slides[currentSlideIndex].view_collection &&
            renderSelectedCollection(slides[currentSlideIndex].view_collection)}
        </div>
      </div>
    )
  }
  const renderWeb = () => {
    return (
      <div>
        <TextField
          type="url"
          name="url"
          placeholder="https://yourdomainhere/page"
          value={slides[currentSlideIndex].view_web}
          onChange={handleTextChange}
          error={slides[currentSlideIndex].errors.view_web}
          connectedRight={
            <VerifyLinkButton url={slides[currentSlideIndex].view_web} />
          }
        />
      </div>
    )
  }
  const renderStatic = () => {
    return <div>This function is for image banner purposes only.</div>
  }

  const handleTypeChange = value => {
    return setSlides(
      produce(draft => {
        draft[currentSlideIndex].errors[value] = undefined
        draft[currentSlideIndex].selected = value
      })
    )
  }

  return (
    <Modal
      size="lg"
      primaryAction={{ content: "Save", onAction: () => handlePrimary() }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title={"Add multiple promotion banners! (Best Image Aspect Ratio  = 4:3)"}
      subtitle={
        "Show your promotion banners as slideshow! Link to the collection or web page"
      }
    >
      <div css={tw`flex flex-row flex-wrap`}>
        <div css={tw`flex-1 px-6 py-4 bg-gray-100`}>
          <div
            css={[
              css`
                width: 360px;
              `,
              tw`mx-auto`,
            ]}
          >
            <FormBlockImageUpload
              fixedRatio={0.75}
              bckgroundSize={"contain"}
              noImagePlaceHolder={NoImage}
              ref={uploadRef}
              source={get(slides[currentSlideIndex], "image_url", undefined)}
              onAction={handleImage}
            />
            <div
              css={tw`text-center mt-4 flex flex-row flex-wrap items-center justify-center`}
            >
              <div css={tw`mx-1`}>
                <Button
                  primary
                  size="slim"
                  onClick={() => uploadRef.current.click()}
                >
                  {slides[currentSlideIndex].image_url
                    ? `Replace image`
                    : `Upload image`}
                </Button>
              </div>
              {/* <div css={tw`mx-1`}>
              <Button size="slim" onClick={handleRemoveItem}>
                Remove Item
              </Button>
            </div> */}
            </div>
            <ThumbnailList
              list={slides}
              selected={currentSlideIndex}
              onPrimaryAction={handleClick}
              onRemoveAction={handleRemoveItem}
            />
          </div>
        </div>
        <div
          css={tw`flex-1 border-l border-gray-300 overflow-hidden px-6 py-4 relative`}
        >
          {!slides[currentSlideIndex].image_url && (
            <div
              css={[
                tw`absolute inset-0 z-40`,
                css`
                  background: rgba(64, 64, 64, 0.6);
                `,
              ]}
            ></div>
          )}
          <ChoiceList
            title="Link type"
            choices={[
              {
                label: "Collection",
                value: "view_collection",
                renderChildren: renderCollection,
              },
              {
                label: "Web Page",
                value: "view_web",
                renderChildren: renderWeb,
              },
              {
                label: "No link",
                value: "view_static",
                renderChildren: renderStatic,
              },
            ]}
            selected={slides[currentSlideIndex].selected}
            onChange={handleTypeChange}
          />
        </div>
      </div>
    </Modal>
  )
}
