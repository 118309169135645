import React from "react"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import produce from "immer"
import { isNull, isUndefined, isEqual, size, filter } from "lodash"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { v4 as uuidv4 } from "uuid"

import Button from "general/components/Button"
import Toggle from "general/components/Toggle"
import CollectionBuilderList from "modal/components/CollectionBuilder/CollectionBuilderList"
import CollectionBuilderItem from "modal/components/CollectionBuilder/CollectionBuilderItem"
import LinkCollectionButton from "modal/components/CollectionBuilder/LinkCollectionButton"
import BgLinkedAll from "assets/images/design/bg-menu-link-all-collection.png"
import BgMenuCollectionList from "assets/svgs/bg-menu-collection-list.svg"
import Sticky from "react-sticky-el"

const portal = typeof window !== "undefined" && document.createElement("div")
portal && portal.classList.add("portal-collection-builder-dnd")

if (typeof window !== "undefined" && !document.body) {
  throw new Error("body not ready for portal creation!")
}

typeof window !== "undefined" && document.body.appendChild(portal)

export default function CollectionBuilder(props) {
  const { build, errors, buildRefs, shopify, isLinkedAllCollection } = props
  const {
    setState,
    toggleLinkAllCollection,
    openSearchModal,
    getCollectionSuccess,
    getBuildLength,
    sendToast,
    COLLECTION_ITEM_LIMIT,
  } = props
  const toastId = React.useRef(null)

  const handleAddItem = ({ type }) => {
    const item = {
      id: uuidv4(),
      display_type: type,
      display_name: "",
      display_image: null,
      display_value: [],
    }

    if (
      getBuildLength() === COLLECTION_ITEM_LIMIT &&
      (type === "single" || type === "group")
    ) {
      sendToast()
      return false
    }

    setState(
      produce(draft => {
        draft.build.push(item)
      })
    )
  }

  const handleRemoveItem = (parentIndex, childrenIndex) => {
    if (isUndefined(childrenIndex)) {
      return setState(
        produce(draft => {
          draft.build.splice(parentIndex, 1)
        })
      )
    }

    setState(
      produce(draft => {
        draft.build[parentIndex].display_value.splice(childrenIndex, 1)
      })
    )
  }

  const handleImageChange = (imageUrl, parentIndex) => {
    setState(
      produce(draft => {
        draft.build[parentIndex].display_image = imageUrl
      })
    )
  }

  const handleTextChange = (text, parentIndex, childrenIndex) => {
    if (!isUndefined(childrenIndex)) {
      return setState(
        produce(draft => {
          draft.build[parentIndex].display_value[
            childrenIndex
          ].collection_name = text
        })
      )
    }

    setState(
      produce(draft => {
        draft.build[parentIndex].display_name = text
      })
    )
  }

  const handleDragEnd = result => {
    if (isUndefined(result) || isNull(result.destination)) {
      return false
    }

    if (result.source.index !== result.destination.index) {
      const from = result.source.index
      const to = result.destination.index

      return setState(
        produce(draft => {
          if (to < 0) return draft

          const dragged = build[from]

          draft.build.splice(from, 1)
          draft.build.splice(to, 0, dragged)
        })
      )
    }
  }

  const handleSubitemDragEnd = result => {
    if (isUndefined(result) || isNull(result.destination)) {
      return false
    }

    if (result.source.index !== result.destination.index) {
      const from = result.source.index
      const to = result.destination.index
      const parentIndex = parseInt(result.source.droppableId)

      return setState(
        produce(draft => {
          if (to < 0) return draft

          const dragged = build[parentIndex].display_value[from]

          draft.build[parentIndex].display_value.splice(from, 1)
          draft.build[parentIndex].display_value.splice(to, 0, dragged)
        })
      )
    }
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div css={tw`relative w-full `} className="boundary">
        {/* <div
          css={[
            tw`sticky top-0 flex flex-row justify-between items-center w-full h-12 px-4 bg-white z-40 border-b`,
          ]}
        > */}
        <Sticky
          scrollElement=".modal__body"
          boundaryElement=".boundary"
          hideOnBoundaryHit={false}
          stickyStyle={{
            // position: "relative",
            // top: 0,
            zIndex: 50,
            width: "100%",
          }}
        >
          <div
            css={[
              tw`flex flex-row justify-between items-center w-full h-12 px-4 bg-white z-40 border-b relative`,
            ]}
          >
            <div css={tw`inline-block leading-none`}>
              <Toggle
                label={"Link all collections"}
                checked={isLinkedAllCollection}
                onChange={toggleLinkAllCollection}
              />
            </div>
            {!isLinkedAllCollection && (
              <div css={tw`flex flex-row -mx-1`}>
                <div css={tw`mx-1`}>
                  <Button
                    size="slim"
                    outline
                    type="button"
                    onClick={() => handleAddItem({ type: "single" })}
                  >
                    <span css={tw`flex flex-row items-center`}>
                      <svg
                        css={tw`w-3 h-3 mr-2`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                      <span>Add collection</span>
                    </span>
                  </Button>
                </div>
                <div css={tw`mx-1`}>
                  <Button
                    size="slim"
                    outline
                    type="button"
                    onClick={() => handleAddItem({ type: "group" })}
                  >
                    <span css={tw`flex flex-row items-center`}>
                      <svg
                        css={tw`w-3 h-3 mr-2`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                      <span>Add group</span>
                    </span>
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Sticky>
        <div
          css={[
            isLinkedAllCollection && [
              tw`flex items-center justify-center text-center `,
              css`
                min-height: 420px;
              `,
            ],
          ]}
        >
          {isLinkedAllCollection ? (
            <div>
              <img
                src={BgLinkedAll}
                alt="Linked all colleciton"
                css={tw`w-48 inline-block mb-4`}
              />
              <h2 css={tw`text-primary-500 text-xl font-semibold`}>
                All collections will be linked.
              </h2>
              <p css={tw`text-gray-900 text-lg font-medium`}>
                User can see all collections in mobile app.
              </p>
            </div>
          ) : (
            <Droppable droppableId={"MENU-COLLECTION"}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  snapshot={snapshot}
                  {...provided.droppableProps}
                  css={[
                    tw`h-full bg-gray-100`,
                    css`
                      min-height: 420px;
                    `,
                    build.length > 0 && tw` px-4 pb-2`,
                  ]}
                >
                  <CollectionBuilderList
                    buildRefs={buildRefs}
                    list={build}
                    errors={errors}
                    shopify={shopify}
                    portal={portal}
                    removeItem={handleRemoveItem}
                    changeText={handleTextChange}
                    changeImage={handleImageChange}
                    DragEndSubitem={handleSubitemDragEnd}
                    openSearchModal={openSearchModal}
                    getCollectionSuccess={getCollectionSuccess}
                  />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </div>
      </div>
    </DragDropContext>
  )
}
