import React from "react"
import tw, { styled, css } from "twin.macro"
import NoImage from "assets/images/design/block/slideshow/noimage-4by3.png"
import IconRemoveCircle from "assets/images/design/icon-remove-circle.png"

const Item = styled.div`
  ${tw`pb-3/4 border border-dashed border-gray-300 bg-cover bg-center hover:border-primary-500 bg-white cursor-pointer relative z-20 overflow-hidden`}

  /* ${({ source }) => {
    return `background-image: url('${source}');`
  }} */

  ${({ isActive }) => isActive && tw`border-primary-500`}
`

const ListItem = ({ onAction, onRemoveAction, isActive, thumbnail }) => {
  return (
    <div css={tw`relative`}>
      {thumbnail && (
        <div
          onClick={onRemoveAction}
          css={[
            tw`absolute z-40 cursor-pointer`,
            css`
              top: 6px;
              right: 6px;
            `,
          ]}
        >
          <img
            src={IconRemoveCircle}
            alt="Remove"
            css={tw`w-4 h-4 relative z-40`}
          />
        </div>
      )}
      <Item isActive={isActive} onClick={onAction}>
        <img
          src={thumbnail ? thumbnail : NoImage}
          alt=""
          css={tw`absolute inset-0 w-full h-full object-cover object-center`}
        />
      </Item>
    </div>
  )
}

export default React.memo(ListItem)
