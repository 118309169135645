import React, { useRef, useState } from "react"
import tw, { css } from "twin.macro"
import Modal from "react-modal"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/material.css"
import { toast } from "react-toastify"
import firebase from "gatsby-plugin-firebase"
import axios from "axios"
import Spinner from "general/components/Spinner"

import PreviewApp from "assets/images/preview-app.jpg"
import PreviewIconApple from "assets/images/preview-icon-apple.png"
import PreviewIconGoogle from "assets/images/preview-icon-google.png"
import QRCODE from "assets/images/preview-qrcode.png"

const PreviewModal = ({ isOpen, onRequestClose }) => {
  const appstoreUrl = process.env.GATSBY_EXTERNAL_LINK_APPSTORE_JCURVE
  const googleplayUrl = process.env.GATSBY_EXTERNAL_LINK_GOOGLEPLAY_JCURVE

  const phoneRef = useRef(null)
  const [loading, setLoading] = useState(false)
  const [phone, setPhone] = useState("")
  const [isValid, setIsValid] = useState(false)

  const handlePhone = phone => {
    setPhone(phone)
  }

  const handleSendPreview = async () => {
    if (phone.length === 0) {
      return toast("Please fill out the your Phone number")
    }

    const token = await firebase.auth().currentUser.getIdToken(true)
    const FUNCTION_URL = process.env.GATSBY_FIREBASE_FUNCTIONS_URL

    setLoading(true)

    axios({
      method: "get",
      url: `${FUNCTION_URL}sendSMS_v2`,
      params: {
        phoneNumber: encodeURIComponent(phone),
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        setLoading(false)
        setPhone("")

        if (response.data.status === 200) {
          return toast(
            'Success! Please check the phone. Not received? Find "JCurve" on appstore!'
          )
        }
        if (response.data.status === 500) {
          return toast(
            'Ooops. We cannot send the link. No worry. Find "JCurve" on appstore!'
          )
        }
        if (response.data.status === 404) {
          return toast(
            'Ooops. Invalid phone number. please check your phone number or Find "JCurve" on appstore!'
          )
        }
      })
      .catch(error => window.Sentry.captureException(error))
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal modal--md"
      style={{
        overlay: {
          overflowY: "auto",
          overflowX: "hidden",
          background: "rgba(33,43,54,.4)",
          zIndex: 500,
        },
        content: {},
      }}
    >
      <div className="modal-content bg-white w-full relative">
        <div
          className="absolute z-20"
          css={css`
            right: 0.5em;
            top: 0.5em;
          `}
        >
          <button
            onClick={() => onRequestClose()}
            css={tw`rounded-md py-1 px-2 hover:bg-gray-100 transition-colors duration-500`}
          >
            <i className="icon-cancel" css={tw`text-gray-600 text-sm`} />
          </button>
        </div>
        <div className="flex flex-col md:flex-row flex-wrap items-center">
          <div className="flex-1 px-4 py-4">
            <h2 className="text-2xl font-semibold leading-tight mb-2">
              Get ready to <br />
              preview your app!
            </h2>
            <p className="text-gray-600">Send the app right to your phone</p>

            <div className="relative z-40 my-8">
              <div
                className="flex flex-row"
                css={css`
                  .react-tel-input .form-control {
                    width: 100%;
                  }
                `}
              >
                <PhoneInput
                  ref={phoneRef}
                  inputProps={{
                    autoFocus: true,
                  }}
                  country={"us"}
                  disabled={loading}
                  value={phone}
                  onChange={phone => handlePhone(phone)}
                  isValid={val => {
                    if (phone) {
                      const {
                        selectedCountry: { format, dialCode },
                        formattedNumber,
                      } = phoneRef.current.state
                      const condition =
                        format.length === formattedNumber.length &&
                        (val.startsWith(dialCode) || dialCode.startsWith(val))

                      return condition
                    }
                  }}
                />
                <button
                  onClick={() => handleSendPreview()}
                  className="text-center bg-primary-500 text-white uppercase font-medium w-40 ml-2"
                >
                  {loading ? <Spinner color={"white"} /> : "Send Link"}
                </button>
              </div>
            </div>

            <div className="relative mb-2">
              <hr
                className="absolute left-0 right-0"
                css={css`
                  top: 50%;
                  margin-bottom: -1px;
                `}
              />
              <span className="block text-center">
                <span className="bg-white px-2 inline-block relative z-20 text-gray-600 font-medium text-sm">
                  Search <span className="text-primary-500">‘JCurve’</span>
                  {` `}
                  preview app
                </span>
              </span>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <a
                className="flex-1 border bg-gray-100 inline-flex items-center justify-center py-2 px-4 text-gray-900 rounded-sm"
                href={appstoreUrl}
                target="_blank"
              >
                <img src={PreviewIconApple} alt="" className="w-4 mr-2" />
                <span>App Store</span>
              </a>
              <a
                className="flex-1 border bg-gray-100 inline-flex items-center justify-center py-2 px-4 text-gray-900 rounded-sm"
                href={googleplayUrl}
                target="_blank"
              >
                <img src={PreviewIconGoogle} alt="" className="w-4 mr-2" />
                <span>Google Play</span>
              </a>
            </div>
          </div>
          <div className="flex-1 hidden md:block">
            <div className="relative">
              <img src={PreviewApp} alt="" />
              <img
                src={QRCODE}
                alt=""
                className="absolute p-2 bottom-0 right-0"
                css={css`
                  width: 100px;
                  height: 100px;
                `}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default PreviewModal
