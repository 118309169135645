import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
import { isEqual } from "lodash"

import PromocodeBlock from "./PromocodeBlock"
import CollectionBannerBlock from "./CollectionBannerBlock"
import CollectionListBlock from "./CollectionListBlock"
import CollectionBlock from "./CollectionBlock"
import ProductBlock from "./ProductBlock"
import CustomBannerBlock from "./CustomBannerBlock"
import CountdownBlock from "./CountdownBlock"
import SlideshowBlock from "./SlideshowBlock"
import InvalidOverlay from "design/components/InvalidOverlay"

const TYPES = {
  promocode: PromocodeBlock,
  collection: CollectionBlock,
  collection_banner: CollectionBannerBlock,
  collection_list: CollectionListBlock,
  collection_horizontal: CollectionBlock,
  product: ProductBlock,
  collection_vertical: CollectionBlock,
  custom_banner: CustomBannerBlock,
  collection_carousel: CollectionBlock,
  countdown: CountdownBlock,
  slideshow: SlideshowBlock,
}

const Wrapper = styled.div`
  ${tw`bg-white relative z-10 `}
`

function BlockItem(props) {
  const {
    id,
    type,
    info,
    isValid,
    error,
    getCollectionSuccess,
    updateCollectionsSuccess,
    setBlockValid,
  } = props

  const Component = TYPES[type]

  if (!props.isValid) {
  }

  return (
    <Wrapper>
      {Component ? (
        <Component
          id={id}
          isValid={isValid}
          type={type}
          {...info}
          getCollectionSuccess={getCollectionSuccess}
          updateCollectionsSuccess={updateCollectionsSuccess}
          setBlockValid={setBlockValid}
        />
      ) : (
        <div css={tw`bg-red-500 text-white py-4 px-4`}>{type}</div>
      )}
      {error && <InvalidOverlay />}
    </Wrapper>
  )
}

function areEqual(prevProps, nextProps) {
  return isEqual(prevProps, nextProps)
}
export default React.memo(BlockItem, areEqual)
