import React, { useRef, useState, useEffect } from "react"
import styled, { css } from "styled-components"
import tw from "twin.macro"
import { useSelector } from "react-redux"
import BlockList from "design/components/BlockList"
import { Scrollbars } from "react-custom-scrollbars"
import { get, isNull } from "lodash"
import moment from "moment"
import AskHelpBanner from "./AskHelpBanner"

const BLOCK_TYPES = {
  promocode: {
    name: "Promotion Code",
    type: "promocode",
  },
  collection_banner: {
    name: "Collection",
    type: "collection_banner",
  },
  collection_list: {
    name: "Collection List",
    type: "collection_list",
  },
  collection_horizontal: {
    name: "Collection Horizontal",
    type: "collection",
    info: { display_type: "horizontal" },
  },
  product: {
    name: "Product",
    type: "product",
  },
  collection_vertical: {
    name: "Collection Vertical",
    type: "collection",
    info: { display_type: "vertical", item_count: 2 },
  },
  custom_banner: {
    name: "Custom Banner",
    type: "custom_banner",
  },
  collection_carousel: {
    name: "Collection Carousel",
    type: "collection",
    info: { display_type: "carousel" },
  },
  countdown: {
    name: "Countdown",
    type: "countdown",
    info: {
      event_title: "Time-Limited Promotion",
    },
  },
  slideshow: {
    name: "Slideshow",
    type: "slideshow",
  },
}

const Wrapper = styled.div`
  /* ${tw`absolute bg-white left-0 top-0 bottom-0 overflow-y-scroll overflow-x-hidden h-full z-20 shadow-md hidden lg:block`} */
  ${tw`absolute bg-white left-0 top-0 bottom-0 h-full z-20 shadow-md hidden lg:block`}
  /* padding-bottom: 48px; */

  ${({ isFixed }) => isFixed && tw`fixed`}
  ${({ disabled }) =>
    disabled && [
      tw`overflow-y-hidden`,
      css`
        &::after {
          content: "";
          background-color: rgba(255, 255, 255, 0.8);
          ${tw`fixed block inset-0 z-50`};

          width: calc(269px + 2em);

          @media (min-width: 1280px) {
            width: calc(360px + 2em);
          }
        }
      `,
    ]}

  width: calc(269px + 2em);

  @media (min-width: 1280px) {
    width: calc(360px + 2em);
  }
`
const Container = styled.div`
  ${tw`px-4 w-full`}
`

export default function EditorSidebar({ isFixed, disabled }) {
  const parentRef = useRef(null)
  const [height, setHeight] = useState(null)
  const profile = useSelector(state => state.firebase.profile)

  useEffect(() => {
    const headerHeight = 89
    const footerHeight = 46

    const gutter = isFixed ? footerHeight : headerHeight + footerHeight

    setHeight(window.innerHeight - gutter)

    const updateSize = () => {
      const gutter = isFixed ? footerHeight : headerHeight + footerHeight

      setHeight(window.innerHeight - gutter)
    }

    window.addEventListener("resize", updateSize)

    return () => {
      window.removeEventListener("resize", updateSize)
    }
  }, [isFixed])

  const createdAt = get(profile, "created_at", null)
  const newbie = createdAt
      ? moment(createdAt.toDate()).diff(moment("2021-05-16"), "days") > 0 && // 4월 19일 이후
      isNull(get(profile, "request_help_design", null)) // request_help_design이 Null인 경우
    : false

  return (
    <Wrapper
      isFixed={isFixed}
      disabled={disabled}
      ref={parentRef}
      id="editor-sidebar-wrapper"
    >
      <Scrollbars style={{ height }}>
        {newbie && <AskHelpBanner />}
        <Container className="editor-sidebar__container">
          <BlockList types={BLOCK_TYPES} />
        </Container>
      </Scrollbars>
    </Wrapper>
  )
}
