import React, { useEffect } from "react"
import Modal from "react-modal"
import Button from "general/components/Button"

const customStyles = {
  overlay: {
    overflowY: "auto",
    overflowX: "hidden",
    background: "rgba(33,43,54,.4)",
    zIndex: 500,
  },
  content: {
    minWidth: "300px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: "1em",
    transform: "translate(-50%, -50%)",
    padding: 0,
  },
}

export default function FeedbackModal(props) {
  const { isOpen, title, source } = props
  const { onRequestClose, primaryAction, onAfterClose } = props

  useEffect(() => {
    if (isOpen) {
      document.body.className = "overflow-y-hidden"
    } else {
      document.body.className = ""
    }
    return () => {
      document.body.className = ""
    }
  }, [isOpen])

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        onAfterClose={onAfterClose}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="px-8 py-8 text-center">
          <div className="w-40 mx-auto mb-4">
            <img src={source} alt="" className="w-full" />
          </div>
          <h2 className="text-lg font-medium">{title}</h2>
          {primaryAction && (
            <div className="mt-4 text-center">
              <Button onClick={primaryAction.onAction} primary>
                {primaryAction.content}
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}
